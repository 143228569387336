import React, { useEffect, useState } from 'react';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import { useSelector, useDispatch } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';
import { providerActions, userActions } from '../../_actions';
import { history } from '../../_helpers';
// import { useParams } from 'react-router-dom';

export default function UpdateProvider(props) {
  const dispatch = useDispatch();
  const [providerData, setProviderData] = useState({
    id: props.match.params.id
  })
  const [userData, setUserData] = useState({})
  const [organisation, setOrganisation] = useState({})

  useEffect(() => {
    dispatch(providerActions.getProviderSeekerById(apiConstants.GET_PROVIDER_SEEKER_BY_ID, {id:props.match.params.id}));
  }, [])

  // useEffect(() => {
  //   dispatch(userActions.updateUserProfile(apiConstants.UPDATE_USER_PROFILE, {id: props.match.params.id}));
  // }, [])


  const providerDetails = useSelector(state => state.getProviderSeekerById.providerDetails);

  useEffect(()=>{
    
    if(providerDetails.data){
      setUserData(providerDetails.data.userdata)
      setOrganisation(providerDetails.data.organisation)
    }
  }, [providerDetails.data])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProviderData({ ...providerData, [name]: value })
  }
 

const handleUpdate = () => {
  dispatch(userActions.updateUserProfile(apiConstants.UPDATE_USER_PROFILE, providerData));
  let obj = {
    limit: 10,
    offset: 0
  }
  dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER, obj));
  history.push('/provider')
}

  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Provider
              </li>
            </ol>
          </nav>
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-2 text-gray-800">
                Update Provider
              </h1>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="table-responsive">
                  <div className="row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6 formBorder">
                      <div className="p-5">
                        <div>
                          <h1 className="h4 text-gray-900 mb-4 headingBorderBottom">{providerDetails.first_name} {providerDetails.last_name}</h1>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <label>Name</label>
                            <input type="text" className="form-control form-control-user" id="txtName" name="first_name"
                              placeholder="Firstname" defaultValue={userData.first_name} onChange={handleChange}/>
                          </div>
                          <div className="col-sm-6">
                            <label>Last Name</label>
                            <input type="text" className="form-control form-control-user" id="txtCompanyName" name="last_name"
                              placeholder="Last Name" defaultValue={userData.last_name} onChange={handleChange}/>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <label>Company Name</label>
                            <input type="text" className="form-control form-control-user" id="txtName"
                              placeholder="department" defaultValue={organisation.company_name} disabled/>
                          </div>
                          <div className="col-sm-6">
                            <label>Job Title</label>
                            <input type="text" className="form-control form-control-user" id="txtCompanyName" name="job_title"
                              placeholder="Company Name" defaultValue={userData.job_title} onChange={handleChange}/>
                          </div>
                        </div>
                      
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <label>Address</label>
                            <input type="text" className="form-control form-control-user" id="txtAddress" name="company_street_address"
                              placeholder="Address" defaultValue={organisation.company_street_address} onChange={handleChange}/>
                          </div>
                          <div className="col-sm-6">
                            <label>Email</label>
                            <input type="text" className="form-control form-control-user" id="txtEmail" name="email"
                              placeholder="Email" defaultValue={userData.email} onChange={handleChange} disabled/>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <label>Contact No.</label>
                            <input type="text" className="form-control form-control-user" id="txtContactNo" name="phone"
                              placeholder="Phone" defaultValue={userData.phone} onChange={handleChange}/>
                          </div>
                          <div className="col-sm-6">
                            <label>State</label>
                            <input type="text" className="form-control form-control-user" id="txtBuying" name="state"
                              placeholder="Company Address" defaultValue={organisation.state} onChange={handleChange}/>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <label>Country</label>
                            <input type="text" className="form-control form-control-user" id="txtApprovalDate" name="country"
                              placeholder="Updated" defaultValue={organisation.country} disabled/>
                          </div>
                          <div className="col-sm-6">
                            <label>Post code</label>
                            <input type="text" className="form-control form-control-user" id="txtBuying" name="post_code"
                              placeholder="Company Address" defaultValue={organisation.post_code} onChange={handleChange}/>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                          <span className="text">
                        <button className="btn btn-primary" onClick={handleUpdate}>Update</button>
                        </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
