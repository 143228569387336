import React, { useEffect, useState } from 'react';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import { useSelector, useDispatch } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';
import { companyActions } from '../../_actions';
// import { history } from '../../_helpers';

export default function CompanyDetailRoute(props) {
  const dispatch = useDispatch();
  const [imagesArr, setImagesArr] = useState([])
  const [organisation, setOrganisation] = useState({})
  const [orgnisation_data_id, setOrgnisation_data_id] = useState({})
  const [companyData, setCompanyData] = useState({})
  const [videoUrl, setVideoUrl] = useState({video_url:''})
  const [multipleImages, setMultipleImages] = useState([])
  const [coverImage, setCoverImages] = useState(null)
  const [logoImage, setLogoImage] = useState(null)
  const getOrganisationDetail = useSelector(state => state.getOrganisationById);
  const getCompanyById = useSelector(state => state.getCompanyById);
  
  useEffect(() => {
    var id = window.location.href.split("=")[1];
    let dataObj = {
      id: id
    }
    // dispatch(companyActions.getOrganisationByOrgId(apiConstants.GET_ORGANISATION_BY_ID, dataObj));
    dispatch(companyActions.getOrganisationDetailsById(apiConstants.GET_ORGANISATION_DETAILS, dataObj));
  }, [])
   
  useEffect(()=>{
    if(getOrganisationDetail.getOrgById){
      setOrganisation(getOrganisationDetail.getOrgById.data.organisation)
      // var tempImg = getOrganisationDetail && getOrganisationDetail.getOrgById.data.organisation.images ? JSON.parse(getOrganisationDetail.getOrgById.data.organisation.images) : []
      // setMultipleImages(tempImg)
    }
  },[getOrganisationDetail.getOrgById])

  useEffect(()=>{
     if(getCompanyById && getCompanyById.getCompanyById && getCompanyById.getCompanyById.data.organisation){
      setOrgnisation_data_id(getCompanyById.getCompanyById.data.organisation)
     }
  },[getCompanyById.getCompanyById])

  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Company
            </li>
            </ol>
          </nav>
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-2 text-gray-800">
                Update Company
            </h1>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="table-responsive">

                  <div className="row">

                    <div className="col-md-6 col-xs-6">
                      <div className="form-group">
                        <label>Company Name</label>
                        <input
                          value={orgnisation_data_id && orgnisation_data_id.company_name}
                          type="text"
                          placeholder='Company Name'
                          className="form-control"
                          name="company_name"
                          disabled
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.eventTitle_error}</p> */}
                    </div>

                    <div className="col-md-6 col-xs-6">
                      <div className="form-group">
                        <label>Cover Photo</label>
                        <input
                          type="file"
                          
                          className="form-control"
                          name="cover_photo"
                          accept=".png,.jpg,.jpeg"
                          readOnly
                        />
                        {
                          (organisation && organisation.cover_photo !== '') ?  <img src={`${process.env.REACT_APP_IMAGE_PATH}/organisation/${organisation.cover_photo}`} width="50px" height="50px" /> : ''
                        }
                      </div>
                      {/* <p className={classes.error}>{this.state.eventDescription_error}</p> */}
                    </div>


                    <div className="col-md-6 col-xs-6">
                      <div className="form-group">
                        <label>Company Logo</label>
                       
                        <input
                          type="file"
                          // value={organisation ? organisation.logo : ''}
                          readOnly
                          className="form-control"
                          name="logo"
                          accept=".png,.jpg,.jpeg"
                        />
                         {
                          (organisation && organisation.logo !== '') ?  <img src={`${process.env.REACT_APP_IMAGE_PATH}/organisation/${organisation.logo}`} width="50px" height="50px" /> : ''
                        }
                      </div>
                      {/* <p className={classes.error}>{this.state.eventDate_error}</p> */}
                    </div>


                    <div className="col-md-6 col-xs-6">
                      <div className="form-group">
                        <label>Title</label>
                        <input
                          // value={data.title}
                          readOnly
                          defaultValue={organisation ? organisation.title : ''}
                          type="text"
                          min="0"
                          placeholder="Title"
                          className="form-control"
                          name="title"
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.eventTime_error}</p> */}
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>Organisation Details</label>
                        <textarea
                          // value={data.organisation_detail}
                          readOnly
                          defaultValue={organisation ? organisation.organisation_detail : ''}
                          className="form-control"
                          placeholder="Organisation Details"
                          name="organisation_detail"
                        >
                        </textarea>
                      </div>
                      {/* <p className={classes.error}>{this.state.address_error}</p> */}
                    </div>

                    <div className="col-md-6 col-xs-6">
                      <div className="form-group">
                        <label>Mission Value 1</label>
                        <input
                          // value={data.mission_value1}
                          readOnly
                          defaultValue={organisation ? organisation.mission_value1 : ''}
                          type="text"
                          min="0"
                          placeholder="Mission Value 1"
                          className="form-control"
                          name="mission_value1"
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.city_error}</p> */}
                    </div>

                    <div className="col-md-6 col-xs-6">
                      <div className="form-group">
                        <label>Mission value 2</label>
                        <input
                          // value={data.mission_value2}
                          readOnly
                          defaultValue={organisation ? organisation.mission_value2 : ''}
                          type="text"
                          min="0"
                          placeholder="Mission Value 2"
                          className="form-control"
                          name="mission_value2"
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.city_error}</p> */}
                    </div>

                    <div className="col-md-6 col-xs-6">
                      <div className="form-group">
                        <label>Mission Value 3</label>
                        <input
                          // value={data.mission_value3}
                          readOnly
                          defaultValue={organisation ? organisation.mission_value3 : ''}
                          type="text"
                          min="0"
                          placeholder="Mission Value 3"
                          className="form-control"
                          name="mission_value3"
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.city_error}</p> */}
                    </div>

                    <div className="col-md-6 col-xs-6">
                      <div className="form-group">
                        <label>Mission Value 4</label>
                        <input
                          // value={data.mission_value4}
                          readOnly
                          defaultValue={organisation ? organisation.mission_value4 : ''}
                          type="text"
                          min="0"
                          placeholder="Mission Value 4"
                          className="form-control"
                          name="mission_value4"
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.city_error}</p> */}
                    </div>

                    <div className="col-md-6 col-xs-6">
                      <div className="form-group">
                        <label>Images</label>
                        <input
                          type="file"
                          //  onChange={handleChangeImages}
                          className="form-control"
                          name="images"
                          accept=".png,.jpg,.jpeg"
                          multiple
                          readOnly
                        />
                        {/* {
                          multipleImages && multipleImages.length &&
                          multipleImages.map((filepath,key)=>{
                            return(
                              <img key={key} src={`${process.env.REACT_APP_IMAGE_PATH}/organisation/${filepath}`} width="50px" height="50px" style={{margin:"10px"}} />
                            )
                          })
                        } */}
                         
                      </div>
                      {/* <p className={classes.error}>{this.state.city_error}</p> */}
                    </div>

                    <div className="col-md-6 col-xs-6">
                      <div className="form-group">
                        <label>Video Title</label>
                        <input
                          // value={data.video_title}
                          readOnly
                          defaultValue={organisation ? organisation.video_title : ''}
                          type="text"
                          min="0"
                          placeholder="Video Title"
                          className="form-control"
                          name="video_title"
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.city_error}</p> */}
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>Video Url</label>
                        <input
                          // value={data.video_url}
                          readOnly
                          defaultValue={organisation ? organisation.video_url : ''}
                          type="text"
                          min="0"
                          placeholder="Video Url"
                          className="form-control"
                          name="video_url"
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.city_error}</p> */}
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
