export const experienceConstants = {
    
    GET_EXPERIENCE_REQUEST: 'GET_EXPERIENCE_REQUEST',
    GET_EXPERIENCE_SUCCESS: 'GET_EXPERIENCE_SUCCESS',
    GET_EXPERIENCE_FAILURE: 'GET_EXPERIENCE_FAILURE',

    GET_EXPERIENCE_BY_ID_REQUEST: 'GET_EXPERIENCE_BY_ID_REQUEST',
    GET_EXPERIENCE_BY_ID_SUCCESS: 'GET_EXPERIENCE_BY_ID_SUCCESS',
    GET_EXPERIENCE_BY_ID_FAILURE: 'GET_EXPERIENCE_BY_ID_FAILURE',

    ADD_EXPERIENCE_REQUEST: 'ADD_EXPERIENCE_REQUEST',
    ADD_EXPERIENCE_SUCCESS: 'ADD_EXPERIENCE_SUCCESS',
    ADD_EXPERIENCE_FAILURE: 'ADD_EXPERIENCE_FAILURE',

    EDIT_EXPERIENCE_REQUEST: 'EDIT_EXPERIENCE_REQUEST',
    EDIT_EXPERIENCE_SUCCESS: 'EDIT_EXPERIENCE_SUCCESS',
    EDIT_EXPERIENCE_FAILURE: 'EDIT_EXPERIENCE_FAILURE',

    UNPUBLISH_EXPERIENCE_REQUEST: 'UNPUBLISH_EXPERIENCE_REQUEST',
    UNPUBLISH_EXPERIENCE_SUCCESS: 'UNPUBLISH_EXPERIENCE_SUCCESS',
    UNPUBLISH_EXPERIENCE_FAILURE: 'UNPUBLISH_EXPERIENCE_FAILURE',

    DELETE_EXPERIENCE_REQUEST: 'DELETE_EXPERIENCE_REQUEST',
    DELETE_EXPERIENCE_SUCCESS: 'DELETE_EXPERIENCE_SUCCESS',
    DELETE_EXPERIENCE_FAILURE: 'DELETE_EXPERIENCE_FAILURE'

    
};
