import { bookingRequestConstants } from '../_constants';
import * as commonAction from './commonAction';
import {  commonService } from '../_services';
import {history} from '../_helpers'

export const bookingRequestActions = {
    getBookingList,
    addBooking,
    getBookingById,
    editBooking,
    searchBookingList,
    sortBooking
};

//-- Booking list
function getBookingList(apiName, data) {

    return dispatch => {
        dispatch(commonAction.request(bookingRequestConstants.GET_BOOKING_REQUEST));
        commonService.withOutToken(apiName, data)
            .then(
                bookingData => {
                    dispatch(commonAction.success(bookingRequestConstants.GET_BOOKING_SUCCESS, bookingData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(bookingRequestConstants.GET_BOOKING_FAILURE, error));
                  }
            );
    };
}

function sortBooking(apiName, data) {

    return dispatch => {
        dispatch(commonAction.request(bookingRequestConstants.GET_BOOKING_REQUEST));
        commonService.withTokenAndBashUrl(apiName, data)
            .then(
                bookingData => {
                    dispatch(commonAction.success(bookingRequestConstants.GET_BOOKING_SUCCESS, bookingData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(bookingRequestConstants.GET_BOOKING_FAILURE, error));
                  }
            );
    };
}

function searchBookingList(apiName, data) {

    return dispatch => {
        dispatch(commonAction.request(bookingRequestConstants.GET_BOOKING_REQUEST));
        commonService.withTokenAndBashUrl(apiName, data)
            .then(
                bookingData => {
                    dispatch(commonAction.success(bookingRequestConstants.GET_BOOKING_SUCCESS, bookingData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(bookingRequestConstants.GET_BOOKING_FAILURE, error));
                  }
            );
    };
}

function getBookingById(apiName, data) {

    return dispatch => {
        dispatch(commonAction.request(bookingRequestConstants.GET_BOOKING_BY_ID_REQUEST));
        commonService.withOutTokenAndBaseUrl(apiName, data)
            .then(
                bookingData => {
                    dispatch(commonAction.success(bookingRequestConstants.GET_BOOKING_BY_ID_SUCCESS, bookingData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(bookingRequestConstants.GET_BOOKING_BY_ID_FAILURE, error));
                  }
            );
    };
}

function addBooking(apiName, data) {

    return dispatch => {
        dispatch(commonAction.request(bookingRequestConstants.POST_BOOKING_REQUEST));
        commonService.withOutTokenAndFormData(apiName, data)
            .then(
                bookingData => {
                    dispatch(commonAction.success(bookingRequestConstants.POST_BOOKING_SUCCESS, bookingData.data));
                    history.push('/booking')
                  },
                  error => {
                    dispatch(commonAction.failure(bookingRequestConstants.POST_BOOKING_FAILURE, error));
                  }
            );
    };
}

function editBooking(apiName, data) {

    return dispatch => {
        dispatch(commonAction.request(bookingRequestConstants.EDIT_BOOKING_REQUEST));
        commonService.withOutTokenAndFormData(apiName, data)
            .then(
                bookingData => {
                    dispatch(commonAction.success(bookingRequestConstants.EDIT_BOOKING_SUCCESS, bookingData.data));
                    history.push('/booking')
                  },
                  error => {
                    dispatch(commonAction.failure(bookingRequestConstants.EDIT_BOOKING_FAILURE, error));
                  }
            );
    };
}