import { experienceConstants } from '../_constants';
import * as commonAction from './commonAction';
import { commonService } from '../_services';
import { history } from '../_helpers';
import { apiConstants } from '../_constants/api.constants';

export const experienceActions = {
  getExperienceList,
  addExperience,
  editExperience,
  getExperienceById,
  getExperienceByProviderId,
  sortExperienceList,
  unpublishExperience,
  deleteExperience,
};

//-- getExperienceList
function getExperienceList(apiName, experienceData) {
  return (dispatch) => {
    dispatch(
      commonAction.request(
        experienceConstants.GET_EXPERIENCE_REQUEST,
        experienceData,
      ),
    );
    commonService.withTokenAndBashUrl(apiName, experienceData).then(
      (experience) => {
        dispatch(
          commonAction.success(
            experienceConstants.GET_EXPERIENCE_SUCCESS,
            experience.data,
          ),
        );
      },
      (error) => {
        dispatch(
          commonAction.failure(
            experienceConstants.GET_EXPERIENCE_FAILURE,
            error.response.data.error,
          ),
        );
      },
    );
  };
}
function sortExperienceList(apiName, experienceData) {
  return (dispatch) => {
    dispatch(
      commonAction.request(
        experienceConstants.GET_EXPERIENCE_REQUEST,
        experienceData,
      ),
    );
    commonService.withTokenAndBashUrl(apiName, experienceData).then(
      (experience) => {
        dispatch(
          commonAction.success(
            experienceConstants.GET_EXPERIENCE_SUCCESS,
            experience.data,
          ),
        );
      },
      (error) => {
        dispatch(
          commonAction.failure(
            experienceConstants.GET_EXPERIENCE_FAILURE,
            error.response.data.error,
          ),
        );
      },
    );
  };
}

function addExperience(apiName, experienceData) {
  let obj = {
    limit: 25,
    offset: 0,
  };

  return (dispatch) => {
    dispatch(
      commonAction.request(
        experienceConstants.ADD_EXPERIENCE_REQUEST,
        experienceData,
      ),
    );
    commonService.withOutTokenAndFormData(apiName, experienceData).then(
      (experience) => {
        dispatch(
          commonAction.success(
            experienceConstants.ADD_EXPERIENCE_SUCCESS,
            experience.data,
          ),
        );
        // history.push('/experience')
        dispatch(
          getExperienceList(apiConstants.GET_ALL_EXPERIENCE_DETAILS, obj),
        );
      },
      (error) => {
        dispatch(
          commonAction.failure(
            experienceConstants.ADD_EXPERIENCE_FAILURE,
            error.response.data.error,
          ),
        );
      },
    );
  };
}

function getExperienceById(apiName, experienceData) {
  return (dispatch) => {
    dispatch(
      commonAction.request(
        experienceConstants.GET_EXPERIENCE_BY_ID_REQUEST,
        experienceData,
      ),
    );
    commonService.withOutTokenAndBaseUrl(apiName, experienceData).then(
      (experience) => {
        dispatch(
          commonAction.success(
            experienceConstants.GET_EXPERIENCE_BY_ID_SUCCESS,
            experience.data,
          ),
        );
      },
      (error) => {
        dispatch(
          commonAction.failure(
            experienceConstants.GET_EXPERIENCE_BY_ID_FAILURE,
            error.response.data.error,
          ),
        );
      },
    );
  };
}
function getExperienceByProviderId(apiName, experienceData) {
  return (dispatch) => {
    dispatch(
      commonAction.request(
        experienceConstants.GET_EXPERIENCE_BY_ID_REQUEST,
        experienceData,
      ),
    );
    commonService.withOutTokenAndBaseUrl(apiName, experienceData).then(
      (experience) => {
        dispatch(
          commonAction.success(
            experienceConstants.GET_EXPERIENCE_BY_ID_SUCCESS,
            experience.data,
          ),
        );
      },
      (error) => {
        dispatch(
          commonAction.failure(
            experienceConstants.GET_EXPERIENCE_BY_ID_FAILURE,
            error.response.data.error,
          ),
        );
      },
    );
  };
}

function editExperience(apiName, experienceData) {
  let obj = {
    limit: 25,
    offset: 0,
  };
  return (dispatch) => {
    dispatch(
      commonAction.request(
        experienceConstants.EDIT_EXPERIENCE_REQUEST,
        experienceData,
      ),
    );
    commonService.withOutTokenAndFormData(apiName, experienceData).then(
      (experience) => {
        dispatch(
          commonAction.success(
            experienceConstants.EDIT_EXPERIENCE_SUCCESS,
            experience.data,
          ),
        );
        dispatch(
          getExperienceList(apiConstants.GET_ALL_EXPERIENCE_DETAILS, obj),
        );
        // window.location.reload()
        history.push('/experience');
      },
      (error) => {
        dispatch(
          commonAction.failure(
            experienceConstants.EDIT_EXPERIENCE_FAILURE,
            error.response.data.error,
          ),
        );
      },
    );
  };
}

function unpublishExperience(apiName, experienceData) {
  return (dispatch) => {
    dispatch(
      commonAction.request(
        experienceConstants.UNPUBLISH_EXPERIENCE_REQUEST,
        experienceData,
      ),
    );
    commonService.withOutTokenAndFormData(apiName, experienceData).then(
      (experience) => {
        dispatch(
          commonAction.success(
            experienceConstants.UNPUBLISH_EXPERIENCE_SUCCESS,
            experience.data,
          ),
        );
        // window.location.reload()
        history.push('/experience');
      },
      (error) => {
        dispatch(
          commonAction.failure(
            experienceConstants.UNPUBLISH_EXPERIENCE_FAILURE,
            error.response.data.error,
          ),
        );
      },
    );
  };
}

function deleteExperience(apiName, experienceData) {
  return (dispatch) => {
    dispatch(
      commonAction.request(
        experienceConstants.DELETE_EXPERIENCE_REQUEST,
        experienceData,
      ),
    );
    commonService.withOutTokenAndFormData(apiName, experienceData).then(
      (experience) => {
        dispatch(
          commonAction.success(
            experienceConstants.DELETE_EXPERIENCE_SUCCESS,
            experience.data,
          ),
        );
      },
      (error) => {
        dispatch(
          commonAction.failure(
            experienceConstants.DELETE_EXPERIENCE_FAILURE,
            error.response.data.error,
          ),
        );
      },
    );
  };
}
