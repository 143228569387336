export const reportConstants = {
    GET_DRIVER_LIST_REQUEST: 'GET_DRIVER_LIST_REQUEST',
    GET_DRIVER_LIST_SUCCESS: 'GET_DRIVER_LIST_SUCCESS',
    GET_DRIVER_LIST_FAILURE: 'GET_DRIVER_LIST_FAILURE',

    GET_ACTIVATED_USER_REQUEST:'GET_ACTIVATED_USER_REQUEST',
    GET_ACTIVATED_USER_SUCCESS:'GET_ACTIVATED_USER_SUCCESS',
    GET_ACTIVATED_USER_FAILURE:'GET_ACTIVATED_USER_FAILURE',

    GET_PENDING_COUNTS_REQUEST:'GET_PENDING_COUNTS_REQUEST',
    GET_PENDING_COUNTS_SUCCESS:'GET_PENDING_COUNTS_SUCCESS',
    GET_PENDING_COUNTS_FAILURE:'GET_PENDING_COUNTS_FAILURE'
};
