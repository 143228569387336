import { providerConstants } from '../_constants';

const initialState = {
  allProvider: [],
  providerDetails: {}
}

export function getProvider(state = initialState, action) {
  switch (action.type) {
    case providerConstants.GET_PROVIDER_REQUEST:
      return {
        ...state,
        getAllProvider: false
      }
    case providerConstants.GET_PROVIDER_SUCCESS:
      return {
        ...state,
        allProvider: action.successAction.data.data,
        total_count: action.successAction.data.total_count,
        pages: action.successAction.data.pages
      }
    case providerConstants.GET_PROVIDER_FAILURE:
      return {
        getAllProvider: action
      }
    default:
      return state
  }
}

export function addProviderSeeker(state = {}, action) {
  // console.log("add=>", action)
  switch (action.type) {
    case providerConstants.ADD_PROVIDER_SEEKER_REQUEST:
      return {
        addProviderSeeker: false
      }
    case providerConstants.ADD_PROVIDER_SEEKER_SUCCESS:
      return {
        addProviderSeeker: action.successAction.data
      }
    case providerConstants.ADD_PROVIDER_SEEKER_FAILURE:
      return {
        addProviderSeeker: false
      }
    default:
      return state
  }
}

export function getProviderSeekerById(state = initialState, action) {
  switch (action.type) {
    case providerConstants.GET_PROVIDER_SEEKER_BY_ID_REQUEST:
      return {
        ...state,
        getAllProvider: false
      }
    case providerConstants.GET_PROVIDER_SEEKER_BY_ID_SUCCESS:
      return {
        ...state,
        providerDetails: action.successAction.data
      }
    case providerConstants.GET_PROVIDER_SEEKER_BY_ID_FAILURE:
      return {
        ...state,
        getAllProvider: false
      }
    default:
      return state
  }
}