import React, { Component } from "react";
import { connect } from 'react-redux';

class PartnerAddUpdate extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        const userDetails = this.props.userData;
        const { siteConstants } = this.props.language;
        //console.log("TEST ", this.props.varAddEditTitle);
        let addEditTitle = siteConstants.LABEL_EDIT_PARTNER;
        if (this.props.addEditTitle == 'addPartner')
            addEditTitle = siteConstants.LABEL_ADD_PARTNER;

        //-- Manage Profile Image 
        let profileImage = "./assets/img/svg/user.svg";
        // if (this.state.user.profile_image !== undefined && this.state.user.profile_image !== "") {
        //     profileImage = process.env.REACT_APP_IMAGE_PATH + "/uploads/"+ user.id + '/' + this.state.user.profile_image;

        return (
            <div className="container-fluid">

                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">{addEditTitle}</h1>
                    <a href="#" onClick={this.props.backButton} className="btn btn-secondary btn-icon-split">
                        <span className="icon text-white-50">
                            <i className="fas fa-arrow-left"></i>
                        </span>
                        <span className="text">{siteConstants.BTN_BACK}</span>
                    </a>
                </div>

                <div className="card shadow mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="row">
                                <div className="col-sm-3"></div>
                                <div className="col-sm-6 formBorder">
                                    <div className="p-5">
                                        <div className="form-group">
                                            <div className="text-center">
                                                <div className="container profilePic">
                                                    <img
                                                        className="img-profile rounded-circle"
                                                        style={{ width: "150px" }}
                                                        src={profileImage}
                                                    />
                                                    <div className="overlay">
                                                        <a
                                                            href="#"
                                                            className="icon"
                                                            title="User Profile"
                                                            onClick={this.handleImageChange}
                                                        >
                                                            <i className="fas fa-edit"></i>
                                                        </a>
                                                    </div>
                                                    <input
                                                        className="fileInput form-control form-control-user"
                                                        type="file"
                                                        name="userImage"
                                                        onChange={e => this.handleImageSubmit(e)}
                                                        style={{ display: "none" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <label>{siteConstants.LABEL_F_NAME}</label>
                                                <input type="text" className="form-control form-control-user" id="first_name"
                                                    placeholder={userDetails.first_name} />
                                            </div>
                                            <div className="col-sm-6">
                                                <label>{siteConstants.LABEL_L_NAME}</label>
                                                <input type="text" className="form-control form-control-user" id="last_name"
                                                    placeholder={userDetails.last_name} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6">
                                                <label>{siteConstants.LABEL_EMAIL}</label>
                                                <input type="text" className="form-control form-control-user" id="email"
                                                    placeholder={userDetails.email} />
                                            </div>
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <label>{siteConstants.LABEL_CONTACT_NO}</label>
                                                <input type="text" className="form-control form-control-user" id="phone_number"
                                                    placeholder={userDetails.phone_number} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6">
                                                <label>{siteConstants.LABEL_PASSWORD}</label>
                                                <input type="text" className="form-control form-control-user" id="password1"
                                                    placeholder={userDetails.password1} />
                                            </div>
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <label>{siteConstants.LABEL_CONFIRM_PASSWORD}</label>
                                                <input type="text" className="form-control form-control-user" id="password2"
                                                    placeholder={userDetails.password2} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <label>{siteConstants.LABEL_ADDRESS_1}</label>
                                                <input type="text" className="form-control form-control-user" id="address1"
                                                    placeholder={userDetails.address1} />
                                            </div>
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <label>{siteConstants.LABEL_ADDRESS_2}</label>
                                                <input type="text" className="form-control form-control-user" id="address2"
                                                    placeholder={userDetails.address2} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <label>{siteConstants.LABEL_CITY}</label>
                                                <input type="text" className="form-control form-control-user" id="city"
                                                    placeholder={userDetails.city} />
                                            </div>
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <label>{siteConstants.LABEL_STATE}</label>
                                                <input type="text" className="form-control form-control-user" id="state"
                                                    placeholder={userDetails.state} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <label>{siteConstants.LABEL_POSTAL_CODE}</label>
                                                <input type="text" className="form-control form-control-user" id="postal_code"
                                                    placeholder={userDetails.postal_code} />
                                            </div>
                                            <div className="col-sm-6">
                                                <label>{siteConstants.LABEL_USER_CREATED_DATE}</label>
                                                <input type="text" className="form-control form-control-user" id="createdAt"
                                                    placeholder={userDetails.createdAt} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-lg-12">
                                                <button type="button" className="btn btn-primary" onClick="">{siteConstants.BTN_SAVE}</button>&nbsp;
                                                <button type="button" className="btn btn-info" onClick={this.props.backButton}>{siteConstants.BTN_CANCEL}</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-sm-3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//-- Here we are adding Reducer names, so it can be get data from reducers using store
function mapStateToProps(state) {
    const { language } = state;
    return {
        language
    };
}

const connectedPartnerAddUpdate = connect(mapStateToProps)(PartnerAddUpdate);
export default connectedPartnerAddUpdate;