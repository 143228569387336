import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { apiConstants } from "../../_constants/api.constants";
import { providerActions, userActions } from "../../_actions";
import SideBar from "../Common/SideBar/SideBar";

export default function SeekerDetailRoute() {
 
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const [organisation, setOrganisation] = useState({});
  const providerDetails = useSelector(
    (state) => state.getProviderSeekerById.providerDetails
  );

  useEffect(() => {
    var id = window.location.href.split("=")[1];
    dispatch(
      providerActions.getProviderSeekerById(
        apiConstants.GET_PROVIDER_SEEKER_BY_ID,
        { id: id }
      )
    );
  }, []);

  useEffect(() => {
    if (providerDetails.data) {
      setUserData(providerDetails.data.userdata);
      setOrganisation(providerDetails.data.organisation);
    }
  }, [providerDetails.data]);

  return (
    <div id="wrapper">
    <SideBar />
    <div id="content-wrapper" className="d-flex flex-column">
      <div id="content">
        <div className="container-fluid">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Seeker Details</h1>
          </div>

          <div className="card shadow mb-4">
            <div className="card-body">
              <div className="table-responsive">
                <div className="row">
                  <div className="col-sm-3"></div>
                  <div className="col-sm-6 formBorder">
                    <div className="p-5">
                      <div>
                        {/* <b>{userDetails.name}</b> */}
                        <h1 className="h4 text-gray-900 mb-4 headingBorderBottom">
                          Name
                        </h1>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                          <label>First Name</label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            id="txtName"
                            value={userData.first_name}
                            readOnly
                          />
                        </div>
                        <div className="col-sm-6">
                          <label>Last Name</label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            id="txtCompanyName"
                            name="last_name"
                            placeholder="Last Name"
                            value={userData.last_name}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-6">
                          <label>Company Name</label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            id="txtCompanyName"
                            placeholder={organisation.company_name}
                            readOnly
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label>Department</label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            id="txtName"
                            name="department"
                            placeholder="department"
                            value={userData.department}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                          <label>Address</label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            id="txtAddress"
                            placeholder="Address"
                            value={organisation.company_street_address}
                            readOnly
                          />
                        </div>
                        <div className="col-sm-6">
                          <label>Email</label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            id="txtEmail"
                            value={userData.email}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                          <label>Contact No.</label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            id="txtContactNo"
                            value={userData.phone}
                            readOnly
                          />
                        </div>
                        <div className="col-sm-6">
                          <label>State</label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            id="txtBuying"
                            placeholder="Company Address"
                            value={userData.createdAt}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-6">
                          <label>Company Address</label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            id="txtBuying"
                            value={organisation.company_street_address}
                            readOnly
                          />
                        </div>
                        <div className="col-sm-6">
                          <label>Post code</label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            id="txtBuying"
                            placeholder="Post code"
                            value={userData.post_code}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>
    </div>
  );
}
