import { feedbackConstants } from '../_constants/feedback.constants';
import * as commonAction from './commonAction';
import {  commonService } from '../_services';

export const feedbackActions = {
    getFeedbackList,
    addFeedback,
    getFeedbackById,
    deleteFeedback,
    sortFeedbackList
};


//-- Location list
function getFeedbackById(apiName, id) {

    return dispatch => {
        dispatch(commonAction.request(feedbackConstants.GET_FEEDBACK_BY_ID_REQUEST, id));
        commonService.withOutTokenAndBaseUrl(apiName, id)
            .then(
                feedbackData => {
                    dispatch(commonAction.success(feedbackConstants.GET_FEEDBACK_BY_ID_SUCCESS, feedbackData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(feedbackConstants.GET_FEEDBACK_BY_ID_FAILURE, error.response.data.error));
                  }
            );
    };
}

//-- Feedback list
function getFeedbackList(apiName,data) {

    return dispatch => {
        dispatch(commonAction.request(feedbackConstants.GET_ALL_FEEDBACK_REQUEST));
        commonService.withOutTokenAndBaseUrl(apiName,data)
            .then(
                feedbackData => {
                    dispatch(commonAction.success(feedbackConstants.GET_ALL_FEEDBACK_SUCCESS, feedbackData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(feedbackConstants.GET_ALL_FEEDBACK_FAILURE, error.response.data.error));
                  }
            );
    };
}
// Feedback Sort
function sortFeedbackList(apiName,data) {
    return dispatch => {
        dispatch(commonAction.request(feedbackConstants.GET_ALL_FEEDBACK_REQUEST));
        commonService.withOutTokenAndBaseUrl(apiName,data)
            .then(
                feedbackData => {
                    dispatch(commonAction.success(feedbackConstants.GET_ALL_FEEDBACK_SUCCESS, feedbackData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(feedbackConstants.GET_ALL_FEEDBACK_FAILURE, error.response.data.error));
                  }
            );
    };
}

//-- Location ad
function addFeedback(apiName, data) {
    return dispatch => {
        dispatch(commonAction.request(feedbackConstants.ADD_FEEDBACK_REQUEST, data));
        commonService.withOutTokenAndBaseUrl(apiName, data)
            .then(
                feedbackData => {
                    dispatch(commonAction.success(feedbackConstants.ADD_FEEDBACK_SUCCESS, feedbackData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(feedbackConstants.ADD_FEEDBACK_FAILURE, error.response.data.error));
                  }
            );
    };
}
function deleteFeedback(apiName, data) {

    return dispatch => {
        dispatch(commonAction.request(feedbackConstants.DELETE_FEEDBACK_REQUEST, data));
        commonService.withToken(apiName, data)
            .then(
                feedbackData => {
                    dispatch(commonAction.success(feedbackConstants.DELETE_FEEDBACK_SUCCESS, feedbackData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(feedbackConstants.DELETE_FEEDBACK_FAILURE, error.response.data.error));
                  }
            );
    };
}


