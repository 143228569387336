import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TopBar from '../Common/TopBar/TopBar';
import SideBar from '../Common/SideBar/SideBar';
import Footer from '../Common/Footer/Footer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import PageLimit from '../Common/PageLimit/PageLimit';
import Pagination from '../Common/Pagination/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';
// import { companyConstants } from '../../_constants';
import { companyActions } from '../../_actions';
// import * as commonAction from '../../_actions/commonAction';
// import { Badge } from 'antd';
// import { Switch } from 'antd';
import 'antd/dist/antd.css';
import CompanyDetail from './CompanyDetail';
import { CSVLink } from 'react-csv';
import AddCompany from './AddCompany';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

export default function Company() {
  const [companyDetails, setCompanyDetails] = useState(false);
  const [saveCompany, setSaveCompany] = useState({});
  const [addCompany, setCompanyAdd] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [deleteDetail, setDeleteDetail] = useState('');
  const [companyFlag, setCompanyFlag] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [company, setCompany] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredCompany, setFilteredCompany] = useState([]);
  const [NewOffset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const location = useLocation();

  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
  const dispatch = useDispatch();

  const companyData = useSelector((state) => state.getCompany);
  // const total_count = useSelector(state => state.getAllCompany.total_count);
  // const total_pages = useSelector(state => state.getAllCompany.pages);

  useEffect(() => {
    let obj = {
      limit: 25,
      offset: 0,
    };
    dispatch(companyActions.getCompany(apiConstants.GET_ORGANISATION, obj));
  }, [location]);

  useEffect(() => {
    if (companyData?.getCompany) {
      setCompany(companyData.getCompany.data.organisation);
    }
  }, [companyData]);

  const companyDetail = (company) => {
    // let obj = {
    //   limit: 10,
    //   offset:0
    // }
    // dispatch(companyActions.getOrganisationDetails(apiConstants.GET_ORGANISATION, obj));
    setCompanyDetails(true);
    setSaveCompany(company);
  };

  const backButton = () => {
    setCompanyDetails(false);
  };

  const closeDeletePopup = () => {
    setDeletePopUp(false);
  };

  const deleteCompany = (id) => {
    setDeletePopUp(true);
    setDeleteDetail(id);
  };

  const openAddCompanyDialog = () => {
    setCompanyAdd(true);
  };

  const closeAddCompanyDialog = () => {
    setCompanyAdd(false);
    // dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER));
  };
  // Search function defined for search
  useEffect(() => {
    setFilteredCompany(
      company.filter((data) =>
        data.company_name?.toLowerCase().includes(search.toLowerCase()),
      ),
    );
  }, [search, company]);

  const makeHttpRequestWithPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    let obj = {
      limit: limit,
      offset: limit * (pageNumber - 1),
    };
    setOffset(obj.offset);
    dispatch(companyActions.getCompany(apiConstants.GET_ORGANISATION, obj));
  };

  const changeTableData = (e) => {
    setLimit(e.target.value);
    let obj = {
      limit: parseInt(e.target.value),
      offset: 0,
    };
    dispatch(companyActions.getCompany(apiConstants.GET_ORGANISATION, obj));
  };

  const handleDelete = () => {
    let formData = new FormData();
    formData.append('id', deleteDetail);
    formData.append('is_deleted', 1);

    dispatch(
      companyActions.updateOrganisationByOrgId(
        apiConstants.UPDATE_ORGANISATION_BY_ID,
        formData,
      ),
    );
    setDeletePopUp(false);
  };

  const handleSort = (e) => {
    if (companyFlag == 1) {
      setCompanyFlag(0);
    } else if (companyFlag == 0) {
      setCompanyFlag(1);
    }
    let obj = {
      sortByStatus: companyFlag,
      sortBy: e.target.name,
    };
    //  setCompany(company.sort((a,b) => parseFloat(a.user_id) - parseFloat(b.user_id)))
    dispatch(companyActions.sortCompany(apiConstants.SORT_COMPANY, obj));
  };

  const headers = [
    // { label: "ID", key: "id" },
    // { label: "User Id", key: "user_id" },
    { label: 'Organisation Type', key: 'organisation_type' },
    { label: 'Company Name', key: 'company_name' },
    { label: 'Company Street Address', key: 'company_street_address' },
    { label: 'Subrub', key: 'subrub' },
    { label: 'Post Code', key: 'post_code' },
    { label: 'State', key: 'state' },
    { label: 'Country', key: 'country' },
    { label: 'Company Street Address', key: 'company_street_address' },
  ];

  return (
    <div id='wrapper'>
      <SideBar />
      <div id='content-wrapper' className='d-flex flex-column'>
        <div id='content'>
          <TopBar />
          <nav className='' aria-label='breadcrumb'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <a href='/dashboard'>Dashboard</a>
              </li>
              <li className='active breadcrumb-item' aria-current='page'>
                Company
              </li>
            </ol>
          </nav>
          {!companyDetails ? (
            <div className='container-fluid'>
              <div className='d-sm-flex align-items-center justify-content-between mb-4'>
                <h1 className='h3 mb-2 text-gray-800'>Company</h1>
                <div className='d-sm-flex align-items-center justify-content-between'>
                  {/* <CSVLink className="btn btn-primary mr-3" data={(company !== undefined && company.length > 0 ? company : []).map(Object.values)} headers={headers.map(Object.values)}>Export</CSVLink> */}
                  <CSVLink
                    className='btn btn-primary mr-3'
                    data={company || []}
                    headers={headers}
                  >
                    Export
                  </CSVLink>
                </div>
              </div>
              <div className='card shadow mb-4'>
                <div className='card-body'>
                  <div className='table-responsive'>
                    <div className='row'>
                      {/* Page limit drop down */}
                      {/* <PageLimit funChangePageLimit={changeTableData} /> */}

                      <div className='col-sm-12 col-md-6'>
                        <div
                          id='dataTable_filter'
                          className='dataTables_filter'
                        >
                          <label>
                            <input
                              type='search'
                              onChange={(e) => setSearch(e.target.value)}
                              className='form-control form-control-sm'
                              placeholder='Search'
                              aria-controls='dataTable'
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <table
                      className='table table-bordered'
                      width='100%'
                      cellSpacing='0'
                    >
                      <thead>
                        <tr>
                          <th scope='col'>No.</th>
                          <th scope='col'>Type</th>
                          <th scope='col'>
                            Company
                            <img
                              src='assets/img/svg/sort.png'
                              name='company_name'
                              className='imageSort'
                              onClick={handleSort}
                            />
                          </th>
                          <th scope='col'>
                            Company Address
                            <img
                              src='assets/img/svg/sort.png'
                              name='address'
                              className='imageSort'
                              onClick={handleSort}
                            />
                          </th>
                          <th scope='col'>
                            Country
                            <img
                              src='assets/img/svg/sort.png'
                              className='imageSort'
                              name='country'
                              onClick={handleSort}
                            />
                          </th>
                          {/* <th scope="col">Status</th> */}
                          <th scope='col'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredCompany !== undefined &&
                        filteredCompany.length > 0
                          ? filteredCompany.map((company, index) => {
                              var count = 0;
                              //  var pageData = config.perPageData;
                              count = index + NewOffset + 1;
                              return (
                                <tr key={index}>
                                  <td scope='row'>{count}</td>

                                  <td>{company.organisation_type}</td>
                                  <td>{company.company_name}</td>
                                  <td>{company.company_street_address}</td>
                                  <td>{company.Country?.name}</td>
                                  {/* <td>
                                  {company.is_deleted == 0 ? <Badge className="ml-3" status="success" text="Active" /> : <Badge className="ml-3" status="error" text="Inactive" />}
                              </td> */}
                                  <td>
                                    {/* <a className="actionIcon" title="View" href="#!" onClick={() => companyDetail(company)}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a> */}
                                    {/* <Link className="actionIcon" title="Edit" to={`/update-company/${company.id}`}><span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span></Link> */}
                                    {user.data.role === 'super admin' ? (
                                      <>
                                        <a href='#!' onClick={() => {}}>
                                          <span className='text'>
                                            <Link
                                              to={`/update-company/${company.id}`}
                                              className='btn btn-primary'
                                            >
                                              Add Company Details
                                            </Link>
                                          </span>
                                        </a>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                  {/* calling the another componenet */}
                  <Pagination
                    totalPage={
                      companyData && companyData.getCompany
                        ? companyData.getCompany.data.pages
                        : 0
                    }
                    currentPage={currentPage}
                    funPagination={makeHttpRequestWithPage}
                  />
                </div>
              </div>
            </div>
          ) : (
            <CompanyDetail data={saveCompany} backButton={backButton} />
          )}
        </div>
        {addCompany === true ? (
          <AddCompany
            displayPopUp={addCompany}
            hidePopUp={closeAddCompanyDialog}
          />
        ) : (
          ''
        )}
        <Dialog
          open={deletePopUp}
          fullWidth={true}
          aria-labelledby='delete-product-notify'
        >
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            Are you sure?
            <h5 className='mt-3'>You want to delete {deleteDetail}?</h5>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => handleDelete()}
            >
              Delete
            </Button>
            <Button variant='contained' onClick={closeDeletePopup}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Footer />
      </div>
    </div>
  );
}
