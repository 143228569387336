import React, { useEffect, useState } from 'react';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import { useSelector, useDispatch } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';
// import { Form } from 'antd';
import { providerActions, locationActions } from '../../_actions';


export default function LocationDetailRoute(props) {
  const [temp, setTemp] = useState({
    lati: -33.865143,
    long: 151.209900
  })

  const [address, setAddress] = useState('')
  const [latlng, setLatLng] = useState({});
  const [providerId, setProviderId] = useState('')
  const [locationName, setLocationName] = useState('')
  const [createdBy, setCreatedBy] = useState(JSON.parse(sessionStorage.getItem('user')).data.id)
  const provider = useSelector(state => state.getProvider.allProvider);
  const location = useSelector(state => state.getLocationById.getLocationById);
  const dispatch = useDispatch();

  useEffect(() => {
    var id = window.location.href.split("=")[1];
    let obj = {
      id: id
    }
    dispatch(locationActions.getLocationListById(apiConstants.GET_LOCATION_BY_ID, obj ))
  }, [])

  useEffect(() => {
    if(location && location.location){
      setLocationName(location.location.location_name)
      setLatLng({
        lattitude: location.location.lattitude,
        longitude: location.location.longitude
      })
      setAddress(location.location.location_address)
    }
  }, [location])


  useEffect(() => {
    let obj = {
      limit: 10,
      offset: 0
    }
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER, obj));
  }, [])



  return (
    <div id='wrapper'>
    <SideBar />
    <div id="content-wrapper" className="d-flex flex-column">
      <div id="content">
        <TopBar />
        <nav className="" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="active breadcrumb-item" aria-current="page">
              Location
          </li>
          </ol>
        </nav>
        <div className="container-fluid">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-2 text-gray-800">
              Update Location
          </h1>
          </div>
          <div className="card shadow mb-4">
            <div className="card-body">
              <div className="table-responsive">

          <div className="row">
          <div className="col-md-12 col-xs-12">
              <div className="form-group">
                <label>Provider Name</label>
                <select className="form-control" name="type" value={location && location.location ? location.location.provider_id : '' } disabled>
                {provider !== undefined && provider.length > 0 ? provider.map((provider, index) => {
                          return (
                            <option value={provider.id}>{provider.first_name}</option>
                          )
                        }) : null}
                </select>
              </div>
              {/* <p className={classes.error}>{this.state.eventTime_error}</p> */}
            </div>
            <div className="col-md-12 col-xs-12">
              <div className="form-group">
                <label>Enter Location</label>
                <input
                type="text"
                className="form-control"
                name="location_name"
                 defaultValue={
                  location && location.location ? location.location.location_address : ''
                }
                readOnly
                />
              </div>
              {/* <p className={classes.error}>{this.state.eventTitle_error}</p> */}
            </div>

            <div className="col-md-12 col-xs-12">
              <div className="form-group">
                <label>Location Name</label>
                <input
                defaultValue={
                  location && location.location ? location.location.location_name : ''
                }
                  type="text"
                  className="form-control"
                  name="location_name"
                  readOnly
                />
              </div>
              {/* <p className={classes.error}>{this.state.eventTime_error}</p> */}
            </div>
         
        {/* </div> */}
          </div>
          </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
