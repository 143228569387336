import { shortlistConstants } from '../_constants/shortlist.constants';

//-- get the list of terms
export function getShortlist(state = {}, action) {
  switch (action.type) {
    case shortlistConstants.GET_ALL_SHORTLIST_REQUEST:
      return {
        getShortlist: false
      };
    case shortlistConstants.GET_ALL_SHORTLIST_SUCCESS:
      return { getShortlist:action.successAction};

    case shortlistConstants.GET_ALL_SHORTLIST_FAILURE:
      return {
        getShortlist: false,
      };

    default:
      return state
  }
}

export function addShortlist(state = {}, action) {
  switch (action.type) {
    case shortlistConstants.ADD_SHORTLIST_REQUEST:
      return {
        addShortlist: false
      };

    case shortlistConstants.ADD_SHORTLIST_SUCCESS:
      return { addShortlist:action.successAction};

    case shortlistConstants.ADD_SHORTLIST_FAILURE:
      return {
        addShortlist: false,
      };

    default:
      return state
  }
}

export function getShortlistById(state = {}, action) {
  switch (action.type) {
    case shortlistConstants.GET_SHORTLIST_BY_ID_REQUEST:
      return {
        getShortlistById: false
      };

    case shortlistConstants.GET_SHORTLIST_BY_ID_SUCCESS:
      return { getShortlistById:action.successAction};

    case shortlistConstants.GET_SHORTLIST_BY_ID_FAILURE:
      return {
        getShortlistById: false,
      };

    default:
      return state
  }
}


