import React, { Component } from 'react';

export class NotFound extends Component {

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-md-offset-4">
                        <div className="login-panel panel panel-default">
                            <div className="panel-heading">
                                <h3 className="panel-title">NotFound</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        );
    }
}


export default NotFound;