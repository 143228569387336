import React, { useEffect, useState } from 'react';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import PageLimit from "../Common/PageLimit/PageLimit";
import Pagination from "../Common/Pagination/Pagination";
import 'antd/dist/antd.css';
import { Link } from 'react-router-dom';
import Toggle from 'react-toggle';
import ViewCompanyReview from './ViewCompanyReview'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import {questionsActions} from "../../_actions/questions.action";
import { apiConstants } from '../../_constants/api.constants';

export default function SubmitedAnswer() {
  const [questions, setQuestions] = useState([])
  const [companyReview, setCompanyReview] = useState([])
  const [reviewDetails, setReviewDetails] = useState(false)
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [sortFlag, setSortFlag] = useState(0);
  const [deleteDetail, setDeleteDetail] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filteredCompanyReview, setFilteredCompanyReview] = useState([]);
  const [saveCompanyReview, setSaveCompanyReview] = useState([])
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')))
  const dispatch = useDispatch();
  const getCompanyReview = useSelector(state => state.getCompanyReview);
  const getQuestionsList = useSelector(state => state.getQuestionsList);

  useEffect(() => {
    let obj = {
      limit: 10,
      offset: 0
    }
    dispatch(questionsActions.getCompanyReview(apiConstants.GET_ALL_COMPANYREVIEW, obj));
    dispatch(questionsActions.getQuestionsList(apiConstants.GET_ALL_QUESTIONS));
  }, [])

  const makeHttpRequestWithPage = (pageNumber) => {
    setCurrentPage(pageNumber)
    let obj = {
      limit: 10,
      offset:pageNumber - 1
    }
    dispatch(questionsActions.getCompanyReview(apiConstants.GET_ALL_COMPANYREVIEW, obj));
}

useEffect(()=> {
  if(getQuestionsList.getQuestionsList && getQuestionsList.getQuestionsList.data){
    setQuestions(getQuestionsList.getQuestionsList.data)
  
  }
},[getQuestionsList.getQuestionsList])

  useEffect(()=> {
    if(getCompanyReview.getCompanyReview && getCompanyReview.getCompanyReview.data){
      setCompanyReview(getCompanyReview.getCompanyReview.data)
      
    }
  },[getCompanyReview])

  const reviewDetail = (companyreview) => {
    setReviewDetails(true)
    setSaveCompanyReview(companyreview)
  }

  const backButton = () => {
    setReviewDetails(false)
  }

   // Search function defined for search
   useEffect(() => {
    setFilteredCompanyReview(
      companyReview.filter((data) =>
      data?.company_name?.toLowerCase().includes(search?.toLowerCase()) ||
      data?.user_name?.toLowerCase().includes(search?.toLowerCase())
      )
    );
  }, [search, companyReview]);

  const handleChange = (e, id) => {
   let obj = {
      id: id,
      status: 1
    }

    dispatch(questionsActions.editCompanyReview(apiConstants.edit_COMPANYREVIEW, obj));
  }

  const closeDeletePopup = () => {
    setDeletePopUp(false);
  }

  const deleteReview = (id) => {
    setDeletePopUp(true);
    setDeleteDetail(id)
  }

  const handleDelete = () => {
    let obj = {
      id: deleteDetail,
      is_deleted: 1
    }
  
    dispatch(questionsActions.editCompanyReview(apiConstants.edit_COMPANYREVIEW, obj));
    setDeletePopUp(false);
  }

  const changeTableData = (e) => {
    let obj = {
      limit: parseInt(e.target.value),
      offset: 0,
    };
    dispatch(questionsActions.getCompanyReview(apiConstants.GET_ALL_COMPANYREVIEW, obj));
  };

  // const handleSort = (e) => {
  //   if(sortFlag == 1){
  //     setSortFlag(0)
  //   }else if(sortFlag == 0){
  //     setSortFlag(1)
  //   }
  //  let obj = {
  //    sortByStatus: sortFlag,
  //    sortBy: e.target.name
  //  }
  //  dispatch(feedbackActions.sortFeedbackList(apiConstants.SORT_FEEDBACK, obj))
  // }

  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Submted Answer
              </li>
            </ol>
          </nav>
          {!reviewDetails ?
            <div className="container-fluid">
              
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="row">
                      {/* Page limit drop down */}
                      <PageLimit funChangePageLimit={changeTableData} />

                      <div className="col-sm-12 col-md-6">
                        <div id="dataTable_filter" className="dataTables_filter">
                          <label>
                            <input type="search" onChange={(e) => setSearch(e.target.value)} className="form-control form-control-sm" placeholder="Search" aria-controls="dataTable" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <table className="table table-bordered" width="100%" cellSpacing="0">
                      <thead>
                        <tr>
                          <th scope="col">No.</th>
                          <th scope="col">User Name
                          {/* <img src="assets/img/svg/sort.png" name="feedback" className="imageSort" onClick={handleSort}/> */}
                          </th>
                          <th scope="col">Company Name</th>
                          <th scope="col">Approval</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                      {filteredCompanyReview !== undefined && filteredCompanyReview.length ? filteredCompanyReview.map((companyReview, index) => {
                       return (
                        <tr key={index}>
                          <td scope="row">{index + 1}</td>
                          <td>{companyReview.user_name}</td>                  
                          <td>{companyReview.company_name}</td>
                          <td><Toggle defaultChecked={companyReview.status === 0 ? false : true} onChange={(e)=>handleChange(e, companyReview.id)} /></td>
                          <td>
                          <Link className="actionIcon" title="Edit" to={`/viewCompanyReview/${companyReview.id}`}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></Link>
                                {
                                user.data.role === 'super admin' ? <> <Link className="actionIcon" title="Edit" to={`/editCompanyReview/${companyReview.id}`}><span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span></Link>
                                <a className="actionIcon" title="Delete" href="#!" onClick={() => deleteReview(companyReview.id)}><span><img src="assets/img/svg/Bin.png" alt="Delete" /></span></a>
                              </> : '' 
                                }
                          </td>
                        </tr>
                          )
                        }) : null}
                      </tbody>
                    </table>
                  </div>
                  {/* calling the another componenet */}
                  <Pagination totalPage={getCompanyReview && getCompanyReview.getCompanyReview ? getCompanyReview.getCompanyReview.pages : 0} currentPage={1} funPagination={makeHttpRequestWithPage}/>
                </div>
              </div>
            </div> :
            <ViewCompanyReview data={saveCompanyReview} questionList={questions} backButton={backButton} />
          }
        </div>
        <Dialog open={deletePopUp} fullWidth={true} aria-labelledby="delete-product-notify" >
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            Are you sure?
            <h5 className="mt-3">You want to delete Company Review</h5>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleDelete}>Delete</Button>
            <Button variant="contained" onClick={closeDeletePopup}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Footer />
      </div>
    </div>
  )
}
