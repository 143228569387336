import { commonService } from '../_services';
import { history } from '../_helpers';
import { apiConstants } from '../_constants/api.constants';
import * as commonAction from './commonAction';
import { experienceEnvCauseConstants } from '../_constants';

export const experienceEnvCauseActions = {
  getExperienceEnvCauseById,
};

function getExperienceEnvCauseById(apiName, experienceEnvCauseData) {
  return (dispatch) => {
    dispatch(
      commonAction.request(
        experienceEnvCauseConstants.GET_EXPERIENCE_ENV_CAUSE_BY_ID_REQUEST,
        experienceEnvCauseData,
      ),
    );
    commonService.withTokenAndBashUrl(apiName, experienceEnvCauseData).then(
      (experienceEnvCause) => {
        dispatch(
          commonAction.success(
            experienceEnvCauseConstants.GET_EXPERIENCE_ENV_CAUSE_BY_ID_SUCCESS,
            experienceEnvCause.data,
          ),
        );
      },
      (error) => {
        dispatch(
          commonAction.failure(
            experienceEnvCauseConstants.GET_EXPERIENCE_ENV_CAUSE_BY_ID_FAILURE,
            error.response.data.error,
          ),
        );
      },
    );
  };
}
