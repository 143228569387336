import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { apiConstants } from '../../../_constants/api.constants';

class SideBar extends Component {
  logout() {
    sessionStorage.setItem('user', null);
  }

  render() {
    const { siteConstants } = this.props.language;
    return (
      <ul
        className='navbar-nav bg-gradient-primary sidebar sidebar-dark accordion'
        id='accordionSidebar'
      >
        {/* <!-- Sidebar - Brand --> */}
        <a
          className='sidebar-brand d-flex align-items-center justify-content-center'
          href='index.html'
        >
          <div className='sidebar-brand-icon rotate-n-15'>
            <i className='fas fa-laugh-wink'></i>
          </div>
          <div className='sidebar-brand-text mx-3'>
            {siteConstants.SITE_NAME}
          </div>
        </a>

        {/* <!-- Divider --> */}
        <hr className='sidebar-divider my-0' />

        {/* <!-- Nav Item - Dashboard --> */}
        <li className='nav-item active'>
          <Link className='nav-link' to='/dashboard'>
            <i className='fas fa-fw fa-tachometer-alt'></i>{' '}
            <span>{siteConstants.MENU_DASHBOARD}</span>
          </Link>
        </li>

        {/* <!-- Divider --> */}
        <hr className='sidebar-divider' />

        <li className='nav-item'>
          <Link className='nav-link' to='/provider'>
            <i className='fas fa-fw fa-table'></i> <span>Provider</span>
          </Link>
        </li>

        <li className='nav-item'>
          <Link className='nav-link' to='/seeker'>
            <i className='fas fa-fw fa-table'></i> <span>Seeker</span>
          </Link>
        </li>

        <li className='nav-item'>
          <Link className='nav-link' to='/company'>
            <i className='fas fa-fw fa-table'></i> <span>Company</span>
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link' to='/environmental_cause'>
            <i className='fas fa-fw fa-table'></i>{' '}
            <span>Environmental Cause</span>
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link' to='/country'>
            <i className='fas fa-fw fa-table'></i> <span>Country</span>
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link' to='/location'>
            <i className='fas fa-fw fa-table'></i> <span>Location</span>
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link' to='/experience'>
            <i className='fas fa-fw fa-table'></i> <span>Experiences</span>
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link' to='/activity-type'>
            <i className='fas fa-fw fa-table'></i> <span>Activity Types</span>
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link' to='/schedule_experience'>
            <i className='fas fa-fw fa-table'></i>{' '}
            <span>Schedule Experiences</span>
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link' to='/booking'>
            <i className='fas fa-fw fa-table'></i> <span>Bookings</span>
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link' to='/booking-request'>
            <i className='fas fa-fw fa-table'></i> <span>Bookings Request</span>
          </Link>
        </li>

        {/* <li className="nav-item">
                    <Link className="nav-link" to="#"><i className="fas fa-fw fa-table"></i> <span>Company Reviews</span></Link>
                </li> */}
        {/* <li className="nav-item">
                    <Link className="nav-link" to="#"><i className="fas fa-fw fa-table"></i> <span>Company Reviewers</span></Link>
                </li> */}
        <li className='nav-item'>
          <Link className='nav-link' to='/experience_feedback'>
            <i className='fas fa-fw fa-table'></i>{' '}
            <span>Experience Feedback</span>
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link' to='/shortlist'>
            <i className='fas fa-fw fa-table'></i> <span>Shortlists</span>
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link' to='/transaction'>
            <i className='fas fa-fw fa-table'></i> <span>Transactions</span>
          </Link>
        </li>

        {/* <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                        <i className="fas fa-fw fa-cog"></i>
                        <span>{siteConstants.MENU_ROLE_MGT}</span>
                    </a>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <Link className="collapse-item" to="/roles">{siteConstants.MENU_ROLES}</Link>
                            <Link className="collapse-item" to="/pages">{siteConstants.MENU_PAGES}</Link>
                            <Link className="collapse-item" to="/permissions">{siteConstants.MENU_PERMISSION}</Link>
                        </div>
                    </div>
                </li> */}

        <li className='nav-item'>
          <Link className='nav-link' to='/notification'>
            <i className='fas fa-fw fa-table'></i> <span>Notification</span>
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link' to='/questions'>
            <i className='fas fa-fw fa-table'></i> <span>Questions</span>
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link' to='/submitedanswer'>
            <i className='fas fa-fw fa-table'></i> <span>Company Review</span>
          </Link>
        </li>

        <li className='nav-item'>
          <a
            className='nav-link collapsed'
            href='#'
            data-toggle='collapse'
            data-target='#collapseFive'
            aria-expanded='true'
            aria-controls='collapseFive'
          >
            <i className='fas fa-fw fa-cog'></i>
            <span>{siteConstants.MENU_REPORTS}</span>
          </a>
          <div
            id='collapseFive'
            className='collapse'
            aria-labelledby='headingTwo'
            data-parent='#accordionSidebar'
          >
            <div className='bg-white py-2 collapse-inner rounded'>
              <Link className='collapse-item' to='/reports'>
                {siteConstants.MENU_REPORTS}
              </Link>
              <Link className='collapse-item' to='/revenue'>
                {siteConstants.MENU_REPORTS}
              </Link>
              <Link className='collapse-item' to='/ride'>
                {siteConstants.MENU_REPORTS}
              </Link>
            </div>
          </div>
        </li>

        {/* <!-- Divider --> */}
        <hr className='sidebar-divider' />

        <li className='nav-item'>
          <Link className='nav-link' to='/login'>
            <i className='fas fa-fw fa-table'></i>{' '}
            <span>{siteConstants.MENU_LOGOUT}</span>
          </Link>
        </li>
      </ul>
    );
  }
}

//-- Here we are adding Reducer names, so it can be get data from reducers using store
function mapStateToProps(state) {
  const { language } = state;
  return {
    language,
  };
}

const connectedSideBar = connect(mapStateToProps)(SideBar);
export default connectedSideBar;
