import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import {
  userProfile,
  getUsersList,
  userDetails,
  dashboardData,
} from './userProfile.reducer';
import { getEventsList } from './event.reducer';
import { alert } from './alert.reducer';
import { language } from './language.reducer';
import {
  getExperienceList,
  editExperience,
  addExperience,
  getExperienceById,
  unpublishExperience,
  deleteExperience,
} from './experience.reducer';
import {
  getScheduleExperienceList,
  editScheduleExperience,
  addScheduleExperience,
  getScheduleExperienceById,
  getScheduleExperienceByProviderId,
  deleteScheduleExperience,
} from './schedule_experience.reducer';
import {
  getLocationList,
  addLocation,
  getLocationById,
} from './location.reducer';
import {
  getProvider,
  addProviderSeeker,
  getProviderSeekerById,
} from './provider.reducer';
import { getSeeker } from './seeker.reducer';
import {
  getBooking,
  addBooking,
  getBookingById,
  editBooking,
} from './booking.reducer';
import {
  getEnviromentalCause,
  getEnviromentalCauseById,
} from './environmentalCause.reducer';
import {
  addCompany,
  getCompanyById,
  getAllCompany,
  getOrganisationById,
  updateOrganisationById,
  getCompany,
} from './company.reducer';
import {
  getFeedbackList,
  getFeedbackById,
  addFeedback,
} from './feedback.reducer';
import {
  getShortlist,
  getShortlistById,
  addShortlist,
} from './shortlist.reducer';
import { getTransactionById, getTransactionList } from './transaction.reducer';
import {
  getNotificationList,
  getNotificationSort,
} from './notification.reducer';
import {
  getQuestionsList,
  getCompanyReview,
  editCompanyReview,
  getCompanyReviewById,
} from './questions.reducer';
import {
  getBookingRequest,
  getBookingRequestById,
  editBookingRequest,
} from './booking_request.reducer';
import {
  getActivityTypeList,
  addActivityType,
  editActivityType,
  archiveActivityType,
  filterActivityTypes,
} from './activity_type.reducer';
import { getExperienceActivityTypeById } from './experience_activity_type.reducer';
import { getExperienceEnvCauseById } from './experience_env_cause.reducers';
import { getCountryList } from './country.reducer';

const rootReducer = combineReducers({
  authentication,
  userProfile,
  getUsersList,
  userDetails,
  getEventsList,
  alert,
  language,
  getProvider,
  getSeeker,
  getEnviromentalCause,
  getEnviromentalCauseById,
  addProviderSeeker,
  getProviderSeekerById,
  addCompany,
  getCompanyById,
  getAllCompany,
  getOrganisationById,
  updateOrganisationById,
  getExperienceList,
  getLocationList,
  addLocation,
  getLocationById,
  getCompany,
  editExperience,
  addExperience,
  getExperienceById,
  getScheduleExperienceList,
  editScheduleExperience,
  addScheduleExperience,
  getScheduleExperienceById,
  getBooking,
  addBooking,
  getScheduleExperienceByProviderId,
  getBookingById,
  editBooking,
  getFeedbackList,
  getFeedbackById,
  addFeedback,
  getShortlist,
  getShortlistById,
  addShortlist,
  getTransactionById,
  getTransactionList,
  getQuestionsList,
  getCompanyReview,
  editCompanyReview,
  getCompanyReviewById,
  getNotificationList,
  getNotificationSort,
  unpublishExperience,
  deleteExperience,
  deleteScheduleExperience,
  getBookingRequest,
  getBookingRequestById,
  editBookingRequest,
  dashboardData,
  getActivityTypeList,
  addActivityType,
  editActivityType,
  archiveActivityType,
  getExperienceActivityTypeById,
  filterActivityTypes,
  getExperienceEnvCauseById,
  getCountryList,
});

export default rootReducer;
