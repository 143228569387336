import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import PageLimit from "../Common/PageLimit/PageLimit";
import Pagination from "../Common/Pagination/Pagination";
import { useSelector, useDispatch } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';
import { providerActions, userActions } from '../../_actions';
import { Badge } from 'antd';
// import { Switch } from 'antd';
import 'antd/dist/antd.css';
import ProviderDetails from './ProviderDetails';
import AddProvider from './AddProvider';
import { CSVLink } from "react-csv";

export default function Provider() {
  const [providerDetails, setProviderDetails] = useState(false);
  const [saveProvider, setSaveProvider] = useState({});
  const [providerAdd, setProviderAdd] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [experienceAdd, setExperienceAdd] = useState(false);
  const [deleteDetail, setDeleteDetail] = useState('');
  const [deletedId, setDeletedId] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2)
  const [limit, setLimit] = useState(10)
  const [providerId, setProviderId] = useState('')
  const [providerFlag, setProviderFlag] = useState(0);
  const [NewOffset, setOffset] = useState(0);
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')))
  const dispatch = useDispatch();

  const provider = useSelector(state => state.getProvider.allProvider);
  const total_count = useSelector(state => state.getProvider.total_count);
  const total_pages = useSelector(state => state.getProvider.pages);

  useEffect(() => {
    let obj = {
      limit: 10,
      offset: 0
    }
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER, obj));
  }, [])

  const searchUsers = (e) => {
    let obj = {
      searchQuery: e.target.value,
      limit: limit,
      offset: 0
    }
    dispatch(providerActions.getProvider(apiConstants.SEARCH_PROVIDER, obj));
  }

  const providerDetail = (provider) => {
    setProviderDetails(true);
    setSaveProvider(provider);
  }

  const backButton = () => {
    setProviderDetails(false);
  }

  const openAddProviderDialog = () => {
    setProviderAdd(true);
  }


  const closeAddProviderDialog = () => {
    setProviderAdd(false);
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER));
  }

  const closeDeletePopup = () => {
    setDeletePopUp(false);
  }

  const handleDelete = () => {
    let obj = {
      id: deletedId,
      status: 1,
      is_deleted: 1
    }
    dispatch(userActions.updateUserProfile(apiConstants.UPDATE_USER_PROFILE, obj));
    setDeletePopUp(false);
  }


  const deleteProvider = (name, id) => {
    setDeletePopUp(true);
    setDeleteDetail(name)
    setDeletedId(id)
  }

  const changeTableData = (e) => {
    let obj = {
      limit: e.target.value,
      offset: 0
    }
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER, obj));
  }

  const handleSort = (e) => {
    if(providerFlag == 1){
      setProviderFlag(0)
    }else if(providerFlag == 0){
      setProviderFlag(1)
    }
    let obj = {
      sortByStatus: providerFlag,
      sortBy: e.target.name
    }
    //  setCompany(company.sort((a,b) => parseFloat(a.user_id) - parseFloat(b.user_id)))
    dispatch(providerActions.getProvider(apiConstants.SORT_PROVIDER, obj))
  }

  const makeHttpRequestWithPage = (pageNumber) => {
    setCurrentPage(pageNumber)
    let obj = {
      limit: limit,
      offset:pageNumber
    }
    setOffset(obj.offset)
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER, obj));
  }

  // const headers = [
  //   { label: "ID", key: "id" },
  //   { label: "ID1", key: "id1" },
  //   { label: "Role Type", key: "role_type" },
  //   { label: "Status", key: "status" },
  //   { label: "First Name", key: "first_name" },
  //   { label: "Last Name", key: "last_name" },
  //   { label: "Compnay Name", key: "company_name" },
  //   // { label: "Deparment", key: "department" },
  //   { label: "Job Title", key: "job_title" },
  //   { label: "Phone", key: "phone" },
  //   // { label: "Company Street Address", key: "company_street_address" },
  //   // { label: "SubRub", key: "subrub" },
  //   // { label: "Post Code", key: "post_code" },
  //   // { label: "State", key: "state" },
  //   // { label: "Country", key: "country" },
  //   { label: "Email", key: "email" },
  //   { label: "Password", key: "password" },
  //   { label: "Forgot Password Code", key: "forgot_password_code" },
  //   { label: "is_deleted", key: "is_deleted" },
  //   { label: "isVerified", key: "isVerified" },
  //   { label: "created_by", key: "created_by" },
  //   { label: "updated_by", key: "updated_by" },
  //   { label: "createdAt", key: "createdAt" },
  //   { label: "updatedAt", key: "updatedAt" },
  // ];

  const headers = [
    { label: "Role Type", key: "role_type" },
    { label: "Status", key: "status"},
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name"},
    { label: "Email", key: "email" },
    { label: "Job Title", key: "job_title" },
    { label: "Phone", key: "phone" }
  ];


  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Provider
              </li>
            </ol>
          </nav>
          {!providerDetails ?
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-2 text-gray-800">
                  Provider
                </h1>
                <div className="d-sm-flex align-items-center justify-content-between">
                  {/* <CSVLink className="btn btn-primary mr-3" data={(provider || []).map(Object.values)} headers={headers.map(Object.values)}>Export</CSVLink> */}
                  <CSVLink className="btn btn-primary mr-3" data={provider || []} headers={headers}>Export</CSVLink>
                  
                  <a href="#!"
                    onClick={() => console.log("provider add")}
                  >
                    <span className="text">
                      <button onClick={openAddProviderDialog} className="btn btn-primary">Add New Provider</button>
                    </span>
                  </a>
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="row">
                      {/* Page limit drop down */}
                      <PageLimit funChangePageLimit={changeTableData} />

                      <div className="col-sm-12 col-md-6">
                        <div id="dataTable_filter" className="dataTables_filter">
                          <label>
                            <input type="search" className="form-control form-control-sm" placeholder="Search" aria-controls="dataTable" onChange={searchUsers} />
                          </label>
                        </div>
                      </div>
                    </div>
                    <table className="table table-bordered" width="100%" cellSpacing="0">
                      <thead>
                        <tr>
                          <th scope="col">No.</th>
                          <th scope="col">Name
                            <img src="assets/img/svg/sort.png" name="company_name" className="imageSort" onClick={handleSort} />
                          </th>
                          <th scope="col">Email</th>
                          <th scope="col">Status</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Actions</th>

                        </tr>
                      </thead>
                      <tbody>
                        {provider !== undefined && provider.length > 0 ? provider.map((provider, index) => {
                          var count = 0;
                          //  var pageData = config.perPageData;
                          count = index + (NewOffset * 10) + 1
                          return (
                            <tr key={count}>
                              <td scope="row">{count}</td>
                              <td>{provider.first_name} {provider.last_name}</td>
                              <td>{provider.email}</td>
                              <td>
                                {/* <Switch size="small" defaultChecked /> */}
                                {/* {provider.status === 0 ? <Badge className="ml-3" status="success" text="Active" /> : <Badge className="ml-3" status="error" text="Inactive" />} */}
                                {provider.status === "Active" ? <Badge className="ml-3" status="success" text="Active" /> : <Badge className="ml-3" status="error" text="Inactive" />}
                                {/* <Badge status="success" text="Active" /> */}
                              </td>
                              <td>{provider.phone}</td>
                              <td>
                                <a className="actionIcon" title="View" href="#!" onClick={() => providerDetail(provider)}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a>
                                {/* <a className="actionIcon" title="View" href="#!" onClick={() => this.popupOpen(users, 'viewOpen')}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a> */}
                                {
                                  user.data.role == 'super admin' ? <><Link className="actionIcon" title="Edit" to={`/update-provider/${provider.id}`}><span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span></Link>
                                    <a className="actionIcon" title="Delete" href="#!" onClick={() => deleteProvider(provider.first_name, provider.id)}><span><img src="assets/img/svg/Bin.png" alt="Delete" /></span></a>
                                  </> : ''
                                }

                              </td>

                            </tr>
                          )
                        }) : null}

                      </tbody>
                    </table>
                  </div>
                  <Pagination totalPage={total_pages} currentPage={currentPage} funPagination={makeHttpRequestWithPage} />
                </div>
              </div>
            </div> :
            <ProviderDetails data={saveProvider} backButton={backButton} />
          }
        </div>

        {/* add provider popup */}
        {
          providerAdd === true ? <AddProvider displayPopUp={providerAdd} hidePopUp={closeAddProviderDialog} /> : ''
        }

        <Dialog open={deletePopUp} fullWidth={true} aria-labelledby="delete-product-notify" >
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            Are you sure?
            <h5 className="mt-3">You want to delete {deleteDetail}</h5>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={() => handleDelete()}>Delete</Button>
            <Button variant="contained" onClick={closeDeletePopup}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Footer />
      </div>
    </div>
  )
}
