import React, {useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';
// import { Form } from 'antd';
import { providerActions, locationActions } from '../../_actions';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
// import create from 'antd/lib/icon/IconFont';

export default function AddLocation({ displayPopUp, hidePopUp }) {
  const [temp, setTemp] = useState({
    lati: -33.865143,
    long: 151.209900
  })

  const [address, setAddress] = useState('')
  const [latlng, setLatLng] = useState({});
  const [providerId, setProviderId] = useState('')
  const [locationName, setLocationName] = useState('')
  const [createdBy, setCreatedBy] = useState(JSON.parse(sessionStorage.getItem('user')).data.id)
  const provider = useSelector(state => state.getProvider.allProvider);

  const dispatch = useDispatch();

  console.log("id==>", JSON.parse(sessionStorage.getItem('user')).data.id)

 const  handleSelect = address => {
  //  console.log("addre==>", address)
   setAddress(address)
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        setLatLng({
          lattitude: latLng.lat,
          longitude: latLng.lng
        })
        console.log('Success', latLng)
      })
      .catch(error => console.error('Error', error));
  };
 
  useEffect(() => {
    // dispatch(companyActions.getOrganisationDetailsById(apiConstants.GET_ORGANISATION_DETAILS, {id: userId}));
  }, [])

  useEffect(() => {
    let obj = {
      limit: 10,
      offset: 0
    }
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER, obj));
  }, [])


  const handleChange = address => {
    setAddress(address)
  };

  const handleOnSelect = (e) => {
    setProviderId(e.target.value)
  }
  const handleSubmit = () => {
    let obj = {
      created_by_admin: parseInt(createdBy),
      location_address: address,
      location_name: locationName,
      provider_id: parseInt(providerId),
      lattitude: latlng.lattitude,
      longitude: latlng.longitude
    }

    dispatch(locationActions.addLocation(apiConstants.ADD_LOCATIONS, obj))
    hidePopUp();
  }
  const options = {
    types: ['address'],
    componentRestrictions: {country: 'au'}
  };
  return (
    <div>
      <Dialog
        open={displayPopUp}
        aria-labelledby="add-products"
        aria-describedby="save-products-description"
        fullWidth={true}
      >
        <DialogTitle>Add Location</DialogTitle>
        <DialogContent>
          <div className="row">
          <div className="col-md-12 col-xs-12">
              <div className="form-group">
                <label>Provider Name</label>
                <select className="form-control" name="type" onChange={handleOnSelect}>
                <option>Please select</option>
                {provider !== undefined && provider.length > 0 ? provider.map((provider, index) => {
                          return (
                            <option value={provider.id}>{provider.first_name}</option>
                          )
                        }) : null}
                </select>
              </div>
              {/* <p className={classes.error}>{this.state.eventTime_error}</p> */}
            </div>
            <div className="col-md-12 col-xs-12">
              <div className="form-group">
                <label>Enter Location</label>
                <PlacesAutocomplete
                    value={address}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    searchOptions={options}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: 'Search Places ...',
                            className: 'form-control',
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
              </div>
              {/* <p className={classes.error}>{this.state.eventTitle_error}</p> */}
            </div>

            <div className="col-md-12 col-xs-12">
              <div className="form-group">
                <label>Location Name</label>
                <input
                  type="text"
                  min="0"
                  onChange={(e) => setLocationName(e.target.value)}
                  placeholder="Title"
                  className="form-control"
                  name="location_name"
                />
              </div>
              {/* <p className={classes.error}>{this.state.eventTime_error}</p> */}
            </div>

          </div>

        </DialogContent>


        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
          <Button variant="contained" onClick={hidePopUp} >Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
