import { userConstants } from '../_constants';
import * as commonAction from './commonAction';
import { userService, commonService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';
import { apiConstants } from '../_constants/api.constants';



//import rootReducer from '../_reducers';
//import { createStore } from 'redux'

//const store = createStore(rootReducer)

export const userActions = {
    login,
    //save,
    logout,
    changePassword,
    getUsersList,
    //updateUserStatus,
    getUserDetails,
    //updateUser,
    updateAdminProfileInfo,
    updateProfileImage,
    changeLanguage,
    updateUserProfile,
    getDashBoardData,
    getAllUserCount,
};

//-- Login function
function login(apiName, username, password) {
    return dispatch => {
        dispatch(commonAction.request(userConstants.LOGIN_REQUEST, { username }));

        userService.login(apiName, username, password)
            .then(
                user => {
                    dispatch(commonAction.success(userConstants.LOGIN_SUCCESS, user));
                    window.location.href ='/dashboard';
                },
                error => {
                    dispatch(commonAction.failure(userConstants.LOGIN_FAILURE, error));
                    dispatch(alertActions.error(error.response.data.error.msg));
                }
            );
    };


}

//-- Save
// function save(user) {
//     return dispatch => {
//         dispatch(commonAction.request(userConstants.PROFILE_REQUEST, { user }));

//         userService.save(user)
//             .then(
//                 user => { 
//                     dispatch(commonAction.success(userConstants.PROFILE_SUCCESS, user));
//                     history.push('/profile');
//                 },
//                 error => {
//                     dispatch(commonAction.failure(userConstants.PROFILE_FAILURE, error.toString()));
//                     dispatch(alertActions.error(error.toString()));
//                 }
//             );
//     };
// }

//-- logout
function logout() {
    //userService.logout();
    sessionStorage.removeItem("user");
    history.push("/login");
    return { type: userConstants.LOGOUT };
}

//-- Change Password
function changePassword(apiName, userData) {
    return dispatch => {
        dispatch(commonAction.request(userConstants.CHANGE_PASSWORD_REQUEST, userData));
        userService.changePassword(apiName, userData)
            .then(
                user => {
                    dispatch(commonAction.success(userConstants.CHANGE_PASSWORD_SUCCESS, user));
                    if (user.success === false) {
                        dispatch(commonAction.failure(userConstants.CHANGE_PASSWORD_ERROR, user.message));
                        dispatch(alertActions.error(user.message));
                    } else {
                        dispatch(alertActions.success("Password Updated Successfully"));
                    }
                    //history.push('/login');
                    //dispatch(alertActions.success('Secret Token sent on your mail box.'));
                },
                error => {
                    dispatch(alertActions.error(error.response.data.error));
                }
            );
    };
}


//-- getUsersList
function getUsersList(apiName, userData) {

    return dispatch => {
        dispatch(commonAction.request(userConstants.USER_LIST_REQUEST, userData));
        userService.getUsersList(apiName, userData)
            .then(
                user => {

                    //console.log("Successfully",user.data);
                    dispatch(commonAction.success(userConstants.USER_LIST_SUCCESS, user.data));
                    if (user.success === false) {
                        dispatch(commonAction.failure(userConstants.USER_LIST_ERROR, user.message));
                        dispatch(alertActions.error(user.message));
                    }
                },
                error => {
                    dispatch(alertActions.error(error.response.data.error));
                }
            );
    };
}

//-- Update user status, admin can chagnge the status of any user
// function updateUserStatus(apiName, userData) {
//     return dispatch => {
//         dispatch(commonAction.request(userConstants.USER_UPDATE_STATUS_REQUEST, userData));
//         userService.updateUserStatus(apiName, userData)
//             .then(
//                 user => {
//                     dispatch(commonAction.success(userConstants.USER_UPDATE_STATUS_SUCCESS, user));
//                     dispatch(alertActions.success(user.data.message));
//                     setTimeout(function () {
//                         dispatch(alertActions.clear())
//                     }, 3000);
//                 },
//                 error => {
//                     dispatch(alertActions.error(error.response.data.error));
//                 }
//             );
//     };
// }

//-- Get user detail on profile page
function getUserDetails(apiName, userData) {
    return dispatch => {
        dispatch(commonAction.request(userConstants.USER_GET_PROFILE_REQUEST, userData));
        commonService.getDataWithToken(apiName, userData)
            .then(
                user => {
                    //console.log("Successfully",user.data);
                    dispatch(commonAction.success(userConstants.USER_GET_PROFILE_SUCCESS, user.data));
                    if (user.success === false) {
                        dispatch(commonAction.failure(userConstants.USER_GET_PROFILE_FAILURE, user.message));
                        dispatch(alertActions.error(user.message));
                    }
                },
                error => {
                    dispatch(alertActions.error(error.response.data.error));
                }
            );
    };
}

//-- Update user Profile... currently we are using same table, so we are updating from common function
//-- might be will update in future
// function updateUser(apiName, userData)
// {
//     return updateAdminProfileInfo(apiName, userData);
// }

//-- Update Admin user Profile
function updateAdminProfileInfo(apiName, userData, apiGetUserData) {
    return dispatch => {
        dispatch(commonAction.request(userConstants.ADMIN_UPDATE_PROFILE_REQUEST, userData));
        commonService.withToken(apiName, userData)
            .then(
                user => {
                    dispatch(commonAction.success(userConstants.ADMIN_UPDATE_PROFILE_SUCCESS, user));
                    dispatch(alertActions.success(user.data.message));
                    dispatch(getUserDetails(apiGetUserData, userData));
                    if (user.success === false) {
                        dispatch(commonAction.failure(userConstants.ADMIN_UPDATE_PROFILE_FAILURE, user.message));
                        dispatch(alertActions.error(user.message));
                    }
                    setTimeout(function () {
                        dispatch(alertActions.clear())
                    }, 3000);
                },
                error => {
                    dispatch(alertActions.error(error.response.data.error));
                }
            );
    };
}

//-- Update Admin Profile Image
function updateProfileImage(apiName, userData, apiGetUserData) {
    return dispatch => {
        dispatch(
            commonAction.request(userConstants.ADMIN_UPDATE_PROFILE_IMAGE_REQUEST, userData)
        );
        userService.updateAdminProfileImage(apiName, userData).then(
            user => {
                dispatch(getUserDetails(apiGetUserData, userData));
                dispatch(
                    commonAction.success(userConstants.ADMIN_UPDATE_PROFILE_IMAGE_SUCCESS, user.data.data)
                );
                dispatch(alertActions.success("Image uploaded successfully"));
                setTimeout(function () {
                    dispatch(alertActions.clear());
                }, 3000);
            },
            error => {
                dispatch(
                    commonAction.failure(userConstants.ADMIN_UPDATE_PROFILE_IMAGE_ERROR, error.response.data.error)
                );
                dispatch(alertActions.error(error.response.data.error));
            }
        );
    };
}

function changeLanguage(language) {
    return { type: language }
}


//------------- REPORTS
function getAllUserCount(apiName) {
    return dispatch => {
      dispatch(commonAction.request(userConstants.USER_COUNT_REQUEST));
      userService.getAllUserCount(apiName).then(
        user => {
          dispatch(commonAction.success(userConstants.USER_COUNT_SUCCESS, user));
      
          dispatch(alertActions.success(user.data.message));
          if (user.success === false) {
            dispatch(
              commonAction.failure(userConstants.USER_COUNT_ERROR, user.message)
            );
            dispatch(alertActions.error(user.message));
          }
          setTimeout(function() {
            dispatch(alertActions.clear());
          }, 3000);
        },
        error => {
          dispatch(alertActions.error(error.response.data.error));
        }
      );
    };
  }


//-- Update Admin Profile Image
function updateUserProfile(apiName, data) {
    return dispatch => {
        dispatch(
            commonAction.request(userConstants.USER_COUNT_REQUEST, data)
        );
        commonService.withOutTokenAndBaseUrl(apiName, data).then(
            user => {
                dispatch(
                    commonAction.success(userConstants.USER_COUNT_SUCCESS, user)
                );
            },
            error => {
                dispatch(
                    commonAction.failure(userConstants.UPDATE_USER_ERROR, error.response.data.error)
                );
            }
        );
    };
}

function getDashBoardData(apiName, data) {
    return dispatch => {
        dispatch(
            commonAction.request(userConstants.GET_DASHBOARD_DATA_REQUEST)
        );
        commonService.getDataWithToken(apiName, data).then(
           
            user => {
                dispatch(
                    commonAction.success(userConstants.GET_DASHBOARD_DATA_SUCCESS, user)
                );
            },
            error => {
                dispatch(
                    commonAction.failure(userConstants.GET_DASHBOARD_DATA_ERROR, error.response.data.error)
                );
            }
        );
    };
}