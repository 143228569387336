import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import FeedbackDetails from './FeedbackDetails';
import { CSVLink } from "react-csv";
import PageLimit from "../Common/PageLimit/PageLimit";
import AddShortlist from './AddShortlist';
// import { Badge } from 'antd';
// import { Switch } from 'antd';
import 'antd/dist/antd.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
// import { experienceActions } from '../../_actions';
import {feedbackActions} from "../../_actions/feedback.action";
import {seekerActions} from "../../_actions/seeker.actions";
import {shortlistActions} from "../../_actions/shortlist.action"
import { apiConstants } from '../../_constants/api.constants';
// import { history } from '../../_helpers';
import Pagination from "../Common/Pagination/Pagination";

export default function Shortlist() {
  const [experienceDetails, setExperienceDetails] = useState(false);
  const [experienceAdd, setExperienceAdd] = useState(false);
  const [savExperience, setSaveExperience] = useState({})
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [shortlist, setShortlist] = useState([])
  const [filteredShortlist, setFilteredShortlist] = useState([]);
  const [newShortlist,  setShortlistData]= useState([])
  const [sortFlag, setSortFlag] = useState(0);
  const [deleteDetail, setDeleteDetail] = useState("");
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [NewOffset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')))
  const dispatch = useDispatch();
  const shortlistData = useSelector(state => state.getShortlist);
  const seeker = useSelector(state => state.getSeeker.allSeeker)

  useEffect(() => {
    let obj = {
      limit: 10,
      offset: 0
    }
    dispatch(shortlistActions.getShortlist(apiConstants.GET_ALL_SHORTLIST, obj));
    dispatch(seekerActions.getSeeker(apiConstants.GET_SEEKER))
  }, [])
  // Search function defined for search
  useEffect(() => {
    setFilteredShortlist(
      shortlist.filter((data) =>
      data.experience_name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, shortlist]);
  useEffect(()=> {
    if(shortlistData.getShortlist && shortlistData.getShortlist.data){
      setShortlist(shortlistData.getShortlist.data)
      
    }
  },[shortlistData.getShortlist])

  console.log("shortlistData", shortlistData)

  const makeHttpRequestWithPage = (pageNumber) => {
    setCurrentPage(pageNumber)
    let obj = {
      limit: limit,
      offset:pageNumber - 1
    }
    setOffset(obj.offset)
    dispatch(shortlistActions.getShortlist(apiConstants.GET_ALL_SHORTLIST, obj));
}

const changeTableData = (e) => {
  setLimit(e.target.value)
  let obj = {
    limit: parseInt(e.target.value),
    offset: 0,
  };
  dispatch(shortlistActions.getShortlist(apiConstants.GET_ALL_SHORTLIST, obj));
};


  useEffect(()=> {
    if(shortlistData.getShortlist && shortlistData.getShortlist.favData){
      setShortlistData(shortlistData.getShortlist.favData)
      
    }
  },[shortlistData.getShortlist])


  console.log(newShortlist, seeker,"djdfhdfbhdbdhgd")

  const openAddExperiencerDialog = () => {
    setExperienceAdd(true);
  }

  const closeAddExperienceDialog = () => {
    setExperienceAdd(false);
  }

  const experienceDetail = (experience) => {
    setExperienceDetails(true);
    setSaveExperience(experience)
  }

  const backButton = () => {
    setExperienceDetails(false);
  }

  const closeDeletePopup = () => {
    setDeletePopUp(false);
  }

  
  const deleteExperience = (id) => {
   
    setDeletePopUp(true);
    setDeleteDetail(id);
  }
  
  const handleDelete = () => {
    let feedbackId = { id: deleteDetail };
    let newObj = {
      // seeker_id: seekerId,
      experience_id: feedbackId,
    };
    // dispatch(feedbackActions.deleteFeedback(apiConstants.DELETE_FEEDBACK,feedbackId)
    // );
    dispatch(shortlistActions.addShortlist(apiConstants.ADD_SHORTLIST, newObj));
    window.location.reload();
  };

  const headers = [
    { label: "ID", key: "id" },
    { label: "Seeker Id", key: "seeker_id" },
    { label: "Experience Id", key: "experience_id" },
    { label: "Experience Name", key: "experience_name" },
    { label: "Seeker Name", key: "seeker_name" },
  ];

  // const headers = [
  //   { label: "Role Type", key: "role_type" },
  //   { label: "First Name", key: "first_name" },
  //   { label: "Last Name", key: "last_name" },
  //   { label: "Department", key: "department" },
  //   { label: "Job Title", key: "job_title" },
  //   { label: "Phone", key: "phone" }, 
  // ];
  const handleSort = (e) => {
    if(sortFlag == 1){
      setSortFlag(0)
    }else if(sortFlag == 0){
      setSortFlag(1)
    }
   let obj = {
     sortByStatus: sortFlag,
     sortBy: e.target.name
   }
  //  setCompany(company.sort((a,b) => parseFloat(a.user_id) - parseFloat(b.user_id)))
   dispatch(feedbackActions.sortFeedbackList(apiConstants.SORT_FEEDBACK, obj))
  }

  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
              Favourite
              </li>
            </ol>
          </nav>
          {!experienceDetails ?
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-2 text-gray-800">
                Favourite
                </h1>
                <div className="d-sm-flex align-items-center justify-content-between">
                  {/* <CSVLink className="btn btn-primary mr-3" data={shortlist.map(Object.values)} headers={headers.map(Object.values)}>Export</CSVLink> */}
                  <CSVLink className="btn btn-primary mr-3" data={shortlist || []} headers={headers}>Export</CSVLink>
                 
                    {/* <span className="text">
                      <button onClick={openAddExperiencerDialog} className="btn btn-primary">Add New Favourite</button>
                    </span> */}
               
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="row">
                      {/* Page limit drop down */}
                      <PageLimit funChangePageLimit={changeTableData} />

                      <div className="col-sm-12 col-md-6">
                        <div id="dataTable_filter" className="dataTables_filter">
                          <label>
                            <input type="search" onChange={(e) => setSearch(e.target.value)} className="form-control form-control-sm" placeholder="Search" aria-controls="dataTable" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <table className="table table-bordered" width="100%" cellSpacing="0">
                      <thead>
                        <tr>
                          <th scope="col">No.</th>
                          <th scope="col">Shortlist Experience Name
                          <img src="assets/img/svg/sort.png" name="feedback" className="imageSort" onClick={handleSort}/>
                          </th>
                          <th scope="col">Seeker Name </th>
                         
                           {/* <th scope="col">Action</th>  */}
                        </tr>
                      </thead>
                      <tbody>
                      {filteredShortlist !== undefined ? filteredShortlist.map((shortlist, index) => {
                         var count = 0;
                         //  var pageData = config.perPageData;
                          count = index + (NewOffset*10)+1
                       return (
                        <tr key={index}>
                          <td scope="row">{count}</td>
                           <td><span className="ml-2 text-primary" style={{cursor:"pointer"}}
                            onClick={()=>{ window.open(`/experience_detail?id=${shortlist.experience_id}`,'_blank')}}
                            > {shortlist.experience_name}</span>
                           </td>
                          <td> <span className="ml-2 text-primary" style={{cursor:"pointer"}}
                            onClick={()=>{ window.open(`/seeker_detail?id=${shortlist.seeker_id}`, '_blank')}}
                            > {shortlist.seeker_name}</span></td>
                         
                         
                          {/* <td> */}
                            {/* <a className="actionIcon" title="View" href="#" onClick={() => experienceDetail(feedback)}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a> */}
                            {/* <a className="actionIcon" title="View" href="#!" onClick={() => this.popupOpen(users, 'viewOpen')}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a> */}
                            {/* <a className="actionIcon" title="Delete" href="#!" onClick={() => deleteExperience(shortlist.id)}><span><img src="assets/img/svg/Bin.png" alt="Delete" /></span></a> */}
                          {/* </td> */}
                        </tr>
                          )
                        }) : null}
                      </tbody>
                    </table>
                  </div>
                  {/* calling the another componenet */}
                  {/* <Pagination totalPage="1" currentPage="0" /> */}
                  <Pagination totalPage={(shortlistData && shortlistData.getShortlist) ? shortlistData.getShortlist.pages : 0 } currentPage={currentPage} funPagination={makeHttpRequestWithPage} />
                </div>
              </div>
            </div> :
            <FeedbackDetails data={savExperience} backButton={backButton} />
          }
        </div>

        {/* add experience popup */}
        {
          experienceAdd === true ? <AddShortlist displayPopUp={experienceAdd} hidePopUp={closeAddExperienceDialog} /> : ''
        }


        <Dialog open={deletePopUp} fullWidth={true} aria-labelledby="delete-product-notify" >
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            Are you sure?
            <h5 className="mt-3">You want to delete feedback</h5>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={() => handleDelete()}>Delete</Button>
            <Button variant="contained" onClick={closeDeletePopup}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Footer />
      </div>
    </div>
  )
}
