import React, { useEffect, useState } from 'react';
import TopBar from '../Common/TopBar/TopBar';
import SideBar from '../Common/SideBar/SideBar';
import Footer from '../Common/Footer/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';
import { companyActions, countryActions } from '../../_actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default function UpdateCompany(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [imagesArr, setImagesArr] = useState([]);
  const [organisation, setOrganisation] = useState({});
  const [orgnisation_data_id, setOrgnisation_data_id] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [videoUrl, setVideoUrl] = useState({ video_url: '' });
  const [multipleImages, setMultipleImages] = useState([]);
  const [coverImage, setCoverImages] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const getOrganisationDetail = useSelector(
    (state) => state.getOrganisationById,
  );
  const getCompanyById = useSelector((state) => state.getCompanyById);

  const [countryData, setCountryData] = useState([]);
  const [selectCountry, setSelectCountry] = useState('');
  const [defaultCountryData, setDefaultCountryData] = useState();
  const countries = useSelector((state) => state.getCountryList);

  useEffect(() => {
    let dataObj = {
      id: props.match.params.id,
    };
    dispatch(
      companyActions.getOrganisationByOrgId(
        apiConstants.GET_ORGANISATION_BY_ID,
        dataObj,
      ),
    );
    dispatch(
      companyActions.getOrganisationDetailsById(
        apiConstants.GET_ORGANISATION_DETAILS,
        dataObj,
      ),
    );
    dispatch(countryActions?.getCountryList(apiConstants.GET_ALL_COUNTRIES));
  }, []);

  useEffect(() => {
    if (getOrganisationDetail.getOrgById) {
      setOrganisation(getOrganisationDetail.getOrgById.data.organisation);
      // var tempImg = JSON.parse(getOrganisationDetail.getOrgById.data.organisation.images)
      // setMultipleImages(tempImg)
    }
  }, [getOrganisationDetail.getOrgById]);

  useEffect(() => {
    if (getCompanyById?.getCompanyById?.data?.organisation) {
      setOrgnisation_data_id(getCompanyById.getCompanyById.data.organisation);
    }
  }, [getCompanyById.getCompanyById]);

  useEffect(() => {
    if (countries?.getCountryList) {
      setCountryData(countries.getCountryList.country);
    }
  }, [countries]);

  useEffect(() => {
    if (
      getCompanyById?.getCompanyById?.data?.organisation &&
      countries?.getCountryList
    ) {
      const countryDataFiltered = countries.getCountryList.country?.filter(
        (val) =>
          val.id ===
          getCompanyById?.getCompanyById?.data?.organisation?.country_id,
      );

      setDefaultCountryData(countryDataFiltered[0] || {});
    }
  }, [countries, getCompanyById]);

  const handleUpdate = () => {
    var youtube_Url = '';
    var youtube_main_url = videoUrl.video_url;

    if (youtube_main_url.includes('=')) {
      youtube_Url =
        'https://www.youtube.com/embed/' + youtube_main_url.split('=')[1];
    } else if (youtube_main_url.includes('/')) {
      youtube_Url =
        'https://www.youtube.com/embed/' + youtube_main_url.split('/')[4];
    } else if (youtube_main_url) {
      youtube_Url = 'https://www.youtube.com/embed/' + youtube_main_url;
    } else {
      youtube_Url = organisation.video_url;
    }

    let formData = new FormData();
    formData.append('id', organisation.id);
    formData.append('cover_photo', coverImage);
    formData.append('slug', orgnisation_data_id.slug);
    formData.append('logo', logoImage);
    formData.append('video_url', youtube_Url);

    // Org update
    const orgData = JSON.stringify({
      orgId: orgnisation_data_id.id,
      external_id: companyData['externalId'],
      sync_enabled: companyData['sync_enabled'],
    });
    formData.append('orgData', orgData);
    formData.append('country_id', selectCountry);

    for (const key in companyData) {
      formData.append(key, companyData[key]);
    }
    for (let i = 0; i < imagesArr.length; i++) {
      formData.append('files', imagesArr[i]);
    }
    dispatch(
      companyActions.updateOrganisationByOrgId(
        apiConstants.UPDATE_ORGANISATION_BY_ID,
        formData,
      ),
    ).then((res) => {
      if (res?.data?.status === true) {
        history.push('/company');
      }
    });
  };

  const handleChangeCoverPhoto = (e) => {
    setCoverImages(e.target.files[0]);
  };

  const handleChangeLogo = (e) => {
    setLogoImage(e.target.files[0]);
  };

  const handleSelectCountry = (e) => {
    setSelectCountry(e.target.value);
  };

  const handleSubmit = () => {
    var youtube_Url = '';
    var youtube_main_url = videoUrl.video_url;

    if (youtube_main_url.includes('=')) {
      youtube_Url =
        'https://www.youtube.com/embed/' + youtube_main_url.split('=')[1];
    } else if (youtube_main_url.includes('/')) {
      youtube_Url =
        'https://www.youtube.com/embed/' + youtube_main_url.split('/')[4];
    } else if (youtube_main_url) {
      youtube_Url = 'https://www.youtube.com/embed/' + youtube_main_url;
    } else {
      youtube_Url = '';
    }
    let formData = new FormData();
    //formData.append("user_id", parseInt(props.match.params.id))
    formData.append('organisation_id', parseInt(props.match.params.id));
    formData.append('cover_photo', coverImage);
    formData.append('logo', logoImage);
    formData.append('video_url', youtube_Url);

    for (const key in companyData) {
      formData.append(key, companyData[key]);
    }

    for (let i = 0; i < imagesArr.length; i++) {
      formData.append('files', imagesArr[i]);
    }

    dispatch(companyActions.addCompany(apiConstants.ADD_COMPANY, formData));
    history.push('/company');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({ ...companyData, [name]: value });
  };
  const handleVideoUrlChange = (e) => {
    const { value } = e.target;
    setVideoUrl({ video_url: value });
  };

  return (
    <div id='wrapper'>
      <SideBar />
      <div id='content-wrapper' className='d-flex flex-column'>
        <div id='content'>
          <TopBar />
          <nav className='' aria-label='breadcrumb'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <a href='/dashboard'>Dashboard</a>
              </li>
              <li className='active breadcrumb-item' aria-current='page'>
                Company
              </li>
            </ol>
          </nav>
          <div className='container-fluid'>
            <div className='d-sm-flex align-items-center justify-content-between mb-4'>
              <h1 className='h3 mb-2 text-gray-800'>Update Company</h1>
            </div>
            <div className='card shadow mb-4'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <div className='row'>
                    <div className='col-md-6 col-xs-6'>
                      <div className='form-group'>
                        <label>Company Name</label>
                        <input
                          value={
                            orgnisation_data_id &&
                            orgnisation_data_id.company_name
                          }
                          type='text'
                          placeholder='Company Name'
                          className='form-control'
                          name='company_name'
                          disabled
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.eventTitle_error}</p> */}
                    </div>

                    <div className='col-md-6 col-xs-6'>
                      <div className='form-group'>
                        <label>External Id</label>
                        <input
                          // value={data.title}
                          onChange={handleChange}
                          defaultValue={
                            orgnisation_data_id &&
                            orgnisation_data_id.external_id
                          }
                          type='text'
                          min='0'
                          placeholder='externalId'
                          className='form-control'
                          name='externalId'
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.eventTime_error}</p> */}
                    </div>

                    <div className='col-md-6 col-xs-6'>
                      <div className='form-group'>
                        <label>Sync Enabled</label>
                        <input
                          type='text'
                          defaultValue={
                            orgnisation_data_id &&
                            orgnisation_data_id.sync_enabled
                          }
                          onChange={handleChange}
                          placeholder='Sync Enabled'
                          className='form-control'
                          name='sync_enabled'
                        />
                      </div>
                    </div>

                    <div className='col-md-6 col-xs-6'>
                      <div className='form-group'>
                        <label>Cover Photo</label>
                        <input
                          type='file'
                          onChange={handleChangeCoverPhoto}
                          className='form-control'
                          name='cover_photo'
                          accept='.png,.jpg,.jpeg'
                        />
                        {organisation && organisation.cover_photo !== '' ? (
                          <img
                            src={`${process.env.REACT_APP_IMAGE_PATH}/organisation/${organisation.cover_photo}`}
                            width='50px'
                            height='50px'
                          />
                        ) : (
                          ''
                        )}
                      </div>
                      {/* <p className={classes.error}>{this.state.eventDescription_error}</p> */}
                    </div>

                    <div className='col-md-6 col-xs-6'>
                      <div className='form-group'>
                        <label>Company Logo</label>

                        <input
                          type='file'
                          // value={organisation ? organisation.logo : ''}
                          onChange={handleChangeLogo}
                          className='form-control'
                          name='logo'
                          accept='.png,.jpg,.jpeg'
                        />
                        {organisation && organisation.logo !== '' ? (
                          <img
                            src={`${process.env.REACT_APP_IMAGE_PATH}/organisation/${organisation.logo}`}
                            width='50px'
                            height='50px'
                          />
                        ) : (
                          ''
                        )}
                      </div>
                      {/* <p className={classes.error}>{this.state.eventDate_error}</p> */}
                    </div>

                    <div className='col-md-6 col-xs-6'>
                      <label>Country</label>
                      <select
                        className='form-control'
                        name='country'
                        onChange={handleSelectCountry}
                      >
                        <option>Select Country</option>
                        {countryData?.length > 0
                          ? countryData.map((country, index) => {
                              return (
                                <option
                                  selected={
                                    country.id === defaultCountryData?.id
                                  }
                                  value={country.id}
                                >
                                  {country.name}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>

                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <label>Title</label>
                        <input
                          // value={data.title}
                          onChange={handleChange}
                          defaultValue={organisation ? organisation.title : ''}
                          type='text'
                          min='0'
                          placeholder='Title'
                          className='form-control'
                          name='title'
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.eventTime_error}</p> */}
                    </div>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <label>Organisation Details</label>
                        <textarea
                          // value={data.organisation_detail}
                          onChange={handleChange}
                          defaultValue={
                            organisation ? organisation.organisation_detail : ''
                          }
                          className='form-control'
                          placeholder='Organisation Details'
                          name='organisation_detail'
                        ></textarea>
                      </div>
                      {/* <p className={classes.error}>{this.state.address_error}</p> */}
                    </div>

                    <div className='col-md-6 col-xs-6'>
                      <div className='form-group'>
                        <label>Mission Value 1</label>
                        <input
                          // value={data.mission_value1}
                          onChange={handleChange}
                          defaultValue={
                            organisation ? organisation.mission_value1 : ''
                          }
                          type='text'
                          min='0'
                          placeholder='Mission Value 1'
                          className='form-control'
                          name='mission_value1'
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.city_error}</p> */}
                    </div>

                    <div className='col-md-6 col-xs-6'>
                      <div className='form-group'>
                        <label>Mission value 2</label>
                        <input
                          // value={data.mission_value2}
                          onChange={handleChange}
                          defaultValue={
                            organisation ? organisation.mission_value2 : ''
                          }
                          type='text'
                          min='0'
                          placeholder='Mission Value 2'
                          className='form-control'
                          name='mission_value2'
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.city_error}</p> */}
                    </div>

                    <div className='col-md-6 col-xs-6'>
                      <div className='form-group'>
                        <label>Mission Value 3</label>
                        <input
                          // value={data.mission_value3}
                          onChange={handleChange}
                          defaultValue={
                            organisation ? organisation.mission_value3 : ''
                          }
                          type='text'
                          min='0'
                          placeholder='Mission Value 3'
                          className='form-control'
                          name='mission_value3'
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.city_error}</p> */}
                    </div>

                    <div className='col-md-6 col-xs-6'>
                      <div className='form-group'>
                        <label>Mission Value 4</label>
                        <input
                          // value={data.mission_value4}
                          onChange={handleChange}
                          defaultValue={
                            organisation ? organisation.mission_value4 : ''
                          }
                          type='text'
                          min='0'
                          placeholder='Mission Value 4'
                          className='form-control'
                          name='mission_value4'
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.city_error}</p> */}
                    </div>

                    <div className='col-md-6 col-xs-6'>
                      <div className='form-group'>
                        <label>Images</label>
                        <input
                          type='file'
                          //  onChange={handleChangeImages}
                          className='form-control'
                          name='images'
                          accept='.png,.jpg,.jpeg'
                          multiple
                        />
                        {multipleImages &&
                          multipleImages.length &&
                          multipleImages.map((filepath, key) => {
                            return (
                              <img
                                key={key}
                                src={`${process.env.REACT_APP_IMAGE_PATH}/organisation/${filepath}`}
                                width='50px'
                                height='50px'
                                style={{ margin: '10px' }}
                              />
                            );
                          })}
                      </div>
                      {/* <p className={classes.error}>{this.state.city_error}</p> */}
                    </div>

                    <div className='col-md-6 col-xs-6'>
                      <div className='form-group'>
                        <label>Video Title</label>
                        <input
                          // value={data.video_title}
                          onChange={handleChange}
                          defaultValue={
                            organisation ? organisation.video_title : ''
                          }
                          type='text'
                          min='0'
                          placeholder='Video Title'
                          className='form-control'
                          name='video_title'
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.city_error}</p> */}
                    </div>

                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <label>Video Url</label>
                        <input
                          // value={data.video_url}
                          onChange={handleVideoUrlChange}
                          defaultValue={
                            organisation ? organisation.video_url : ''
                          }
                          type='text'
                          min='0'
                          placeholder='Video Url'
                          className='form-control'
                          name='video_url'
                        />
                      </div>
                      {/* <p className={classes.error}>{this.state.city_error}</p> */}
                    </div>

                    {organisation !== null ? (
                      <div className='col-lg-12'>
                        <span className='text'>
                          <button
                            className='btn btn-primary'
                            onClick={handleUpdate}
                          >
                            Update
                          </button>
                        </span>
                      </div>
                    ) : (
                      <div className='col-lg-12'>
                        <span className='text'>
                          <button
                            className='btn btn-primary'
                            onClick={handleSubmit}
                          >
                            Add
                          </button>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
