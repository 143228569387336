//-- React Standard
import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
//import compose from 'recompose/compose';
//-- Custom
import { withStyles } from "@material-ui/core/styles";
import { apiConstants } from '../../_constants/api.constants';
import { eventActions } from "../../_actions";
// import { commonService } from "../../_services";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

//-- CSS for Material
const styles = theme => ({
    error: {
        color: 'red',
    },
    // loaderImg: {
    //     margin: '0 auto',
    //     display: 'block',
    //     width: '250px'
    // },
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: "#269abc",
        color: '#fff'
    },
});

class AddEvents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sort: {
                sortKey: null,
                direction: 'desc',
            },
            totalPage: '',
            totalDataCount: '',
            currentPage: 1,
            getCouponList: [],
            deleteOpen: false,
            allCategories: [],
            addCat: false,
  
        };
    }

    /*
    * componentDidMount - Initially it will call and get the advertisments data
    */
    componentDidMount() {
        const { dispatch } = this.props;
        let postData = {};
        postData.page = 1;
        postData.orderBy = "id";
        postData.orderByAscDesc = "ASC";
        postData.perPage = 10;

        //       dispatch(categoryActions.getAllCategory(apiConstants.GET_CATEGORIES, postData));

        //dispatch(categoryActions.getAllCategory(apiConstants.GET_ALL_ACTIVE_CATEGORIES));
        //dispatch(userActions.getCoupounList(apiConstants.GET_ACTIVE_COUPOUN ));
    }

    /*
    * componentWillReceiveProps - Whenever Props change, it will call and store data
    * update the state values with new props values, this method will get called whenever any change happens to props values
    */
    componentWillReceiveProps(props) {
        const { dispatch } = props;
        // GET LIST OF ACTIVE CATEGORIES        
        // if (props.getCategories !== undefined &&
        //     props.getCategories.getCatList !== undefined &&
        //     props.getCategories.getCatList.data !== undefined &&
        //     props.getCategories.getCatList.data.type === "get_active_categories"
        // ) {

        //     const catDataReceived = props.getCategories.getCatList.data;
        //     this.setState({
        //         allCategories: catDataReceived,
        //     })
        // }

        // GET LIST OF ACTIVE COUPONS
        // if (props.getCoupounList) {
        //     if (props.getCoupounList.getCoupounList) {
        //         const catRecvData = props.getCoupounList.getCoupounList;
        //         if (
        //             catRecvData.data.data &&
        //             catRecvData.data.type === "get_active_coupon"
        //         ) {
        //             this.setState({ getCouponList: catRecvData.data })
        //         }
        //     }
        // }

        if (props.saveEvent !== undefined && props.saveEvent) {

            if (
                props.saveEvent.addedEvent !== undefined &&
                props.saveEvent.addedEvent.data !== undefined &&
                props.saveEvent.addedEvent.data.type === 'Event inserted'
            ) {
                if (this.state.notifyAddEvent === true) {
                    this.setState({
                        notifyAddEvent: false,
                        eventTitle: '',
                        eventDescription: '',
                        eventDate: '',
                        eventTime: '',
                        eventMainImage: '',
                    });
                    props.closeAddProductPop(1);
                }
            }
        }
    }

    handleChangeMainImage = (e) => {
        const files = e.target.files
        let i = 0;
        let oddCntr = 0;
        let multiImageArray = [];
        var extArr = ["image/jpeg", "image/jpg", "image/png"];

        if (files.length > 1) {
            for (i = 0; i < files.length; i++) {
                //var filename = files[0].name                
                if (extArr.indexOf(files[i].type) > -1) { }
                else { oddCntr++; }
                multiImageArray[i] = files[i];
            }
            if (oddCntr > 0) {
                let label = [e.target.name] + '_error';
                this.setState({ [label]: "One of the file types not suported" })
                e.target.value = null;
                this.setState({ [e.target.name]: '' })
            } else {
                this.setState({ [e.target.name]: e.target.files })
            }
        } else {
            if (extArr.indexOf(files[0].type) > -1) {
                this.setState({ [e.target.name]: files[0] })
            } else {
                let label = [e.target.name] + '_error';
                this.setState({ [label]: "One of the file type not suported" })
                e.target.value = null;
                this.setState({ [e.target.name]: '' })
            }
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    //save category to collection
    handleSubmit = () => {

        console.log("TEST ",this.state);

        const { dispatch } = this.props;
        const requiredFields = [
            'eventTitle',
            'eventDescription',
            'eventDate',
            'eventTime'
        ];
        requiredFields.map((val) => {
            let lbl = [val] + '_error';
            this.setState({ [lbl]: '' });
        })
        const {
            eventTitle,
            eventDescription,
            eventDate,
            eventTime,
            eventMainImage,

        } = this.state;

        if (eventTitle === undefined || eventTitle === '') { this.setState({ eventTitle_error: 'Title is required' }) }
        else if (eventDescription === undefined || eventDescription === '') { this.setState({ eventDescription_error: 'Description is required' }) }
        else if (eventDate === undefined || eventDate === '') { this.setState({ eventDate_error: 'Date is required' }) }
        else if (eventTime !== undefined && parseInt(eventTime) > parseInt(eventDate)) { this.setState({ eventTime_error: 'Time is required' }) }
        else {
            this.setState({ notifyAddEvent: true });
            let featuredImage = '';
            if (eventMainImage !== undefined && eventMainImage) { featuredImage = eventMainImage; }
            
            const projData = {};
            projData.title = this.state.eventTitle;
            projData.description = this.state.eventDescription;
            projData.speaker_id = 1;
            projData.event_date = this.state.eventDate;
            projData.event_time = this.state.eventTime;
            projData.address1 = this.state.address;
            projData.city = this.state.city;
            projData.state = this.state.state;
            projData.postal_code = this.state.eventTime;
            projData.country = this.state.eventTime;
            projData.status = 0;
            
            const mainImageData = new FormData();
            mainImageData.append('eventMainImage', featuredImage);

            dispatch(eventActions.saveEvent(apiConstants.SAVE_EVENT, projData));
            this.props.closeAddProductPop(0);

            // commonService.withTokenAndFormData(apiConstants.SAVE_PRODUCT_MAIN_IMAGE, mainImageData).then(
            //     res => {
            //         let mainImg = '';
            //         // check if product main image is uploaded and returned by server.
            //         if (res.data.data !== false) { mainImg = res.data.data; }
            //         projData.mainImg = mainImg;

            //         const imageGallery = new FormData();
            //         if (productImageGallery !== undefined && productImageGallery.length > 0 && productImageGallery) {
            //             let loopCntr = productImageGallery.length;
            //             if (loopCntr === 1) { for (let i = 0; i < loopCntr; i++) { imageGallery.append("eventMainImage", productImageGallery[i]); } }
            //             else {
            //                 for (let i = 0; i < loopCntr; i++) { imageGallery.append("productImageGallery", productImageGallery[i]); }
            //             }
            //         } else {
            //             if (productImageGallery !== undefined) {
            //                 imageGallery.append("productImageGallery", productImageGallery);
            //             }
            //         }




            //         // commonService.withTokenAndFormData(apiConstants.SAVE_PRODUCT_IMAGE_GALLERY, imageGallery).then(
            //         //     gallerydata => {

            //         //         let imageGallery = [];
            //         //         // check if gallery images uploaded succesfully and returned by server
            //         //         if (
            //         //             gallerydata.data.data !== undefined &&
            //         //             gallerydata.data.data &&
            //         //             gallerydata.data.data.length > 0
            //         //         ) {
            //         //             gallerydata.data.data.map((imgObj, index) => { imageGallery.push(imgObj.filename); })
            //         //         }
            //         //         projData.imageGallery = imageGallery;

            //         //         dispatch(eventActions.saveEvent(apiConstants.SAVE_PRODUCT, projData));

            //         //     },
            //         //     error => { }
            //         // );

            //     },
            //     error => { }
            // );
        }
    }
    //-- Main function
    render() {
        const { classes, displayPopUp, closeAddProductPop } = this.props;
        const { siteConstants } = this.props.language;
        return (
            <Fragment>
                <Dialog
                    open={displayPopUp}
                    aria-labelledby="add-products"
                    aria-describedby="save-products-description"
                    fullWidth="true"
                >
                    <DialogTitle className={classes.root}>Add Event</DialogTitle>
                    <DialogContent>
                        <div class="row">

                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <label>Title</label>
                                    <input
                                        type="text"
                                        onChange={(e) => this.handleChange(e)}
                                        placeholder='Title'
                                        className="form-control"
                                        name="eventTitle"
                                    />
                                </div>
                                <p className={classes.error}>{this.state.eventTitle_error}</p>
                            </div>

                            <div className="col-md-12 col-xs-12">
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea
                                        onChange={(e) => this.handleChange(e)}
                                        placeholder="Description"
                                        className="form-control"
                                        name="eventDescription"
                                    />
                                </div>
                                <p className={classes.error}>{this.state.eventDescription_error}</p>
                            </div>


                            <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                    <label>Date</label>
                                    <input
                                        type="text"
                                        min="1"
                                        onChange={(e) => this.handleChange(e)}
                                        placeholder="Date"
                                        className="form-control"
                                        name="eventDate"
                                    />
                                </div>
                                <p className={classes.error}>{this.state.eventDate_error}</p>
                            </div>


                            <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                    <label>Time</label>
                                    <input
                                        type="text"
                                        min="0"
                                        onChange={(e) => this.handleChange(e)}
                                        placeholder="Time"
                                        className="form-control"
                                        name="eventTime"
                                    />
                                </div>
                                <p className={classes.error}>{this.state.eventTime_error}</p>
                            </div>
                            <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                    <label>Address</label>
                                    <input
                                        type="text"
                                        min="0"
                                        onChange={(e) => this.handleChange(e)}
                                        placeholder="Address"
                                        className="form-control"
                                        name="address"
                                    />
                                </div>
                                <p className={classes.error}>{this.state.address_error}</p>
                            </div>

                            <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                    <label>City</label>
                                    <input
                                        type="text"
                                        min="0"
                                        onChange={(e) => this.handleChange(e)}
                                        placeholder="City"
                                        className="form-control"
                                        name="city"
                                    />
                                </div>
                                <p className={classes.error}>{this.state.city_error}</p>
                            </div>

                            <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                    <label>Image</label>
                                    <input
                                        type="file"
                                        onChange={(e) => this.handleChangeMainImage(e)}
                                        className="form-control"
                                        name="eventMainImage"
                                        accept=".png,.jpg,.jpeg"
                                    />
                                </div>
                                <p className={classes.error}>{this.state.eventMainImage_error}</p>
                            </div>
                        </div>

                    </DialogContent>


                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={(e) => this.handleSubmit(e)}>{siteConstants.BTN_SAVE}</Button>
                        <Button variant="contained" onClick={() => closeAddProductPop(0)}>{siteConstants.BTN_CANCEL}</Button>
                    </DialogActions>
                </Dialog>

                {/* notify saving data */}
                {/* <Dialog open={this.state.notifyAddEvent} aria-labelledby="save-product-notify" >
                    <DialogTitle>{siteConstants.LABEL_SAVING_PRODUCT_DATA}</DialogTitle>
                    <img className={classes.loaderImg} src={ loader } />
                </Dialog> */}


            </Fragment>
        );
    }
}


//-- Here we are adding Reducer names, so it can be get data from reducers using store
function mapStateToProps(state) {
    const { language, saveEvent } = state;
    return {
        language,
        saveEvent,
        // getCategories,
        // getCategory,
        // getCoupounList
    };
}
export default connect(mapStateToProps)(withStyles(styles)(AddEvents))
