export const transactionConstants = {
    
    GET_TRANSACTION_BY_ID_REQUEST: 'GET_TRANSACTION_BY_ID_REQUEST',
    GET_TRANSACTION_BY_ID_SUCCESS: 'GET_TRANSACTION_BY_ID_SUCCESS',
    GET_TRANSACTION_BY_ID_FAILURE: 'GET_TRANSACTION_BY_ID_FAILURE',

    GET_TRANSACTION_LIST_REQUEST: 'GET_TRANSACTION_LIST_REQUEST',
    GET_TRANSACTION_LIST_SUCCESS: 'GET_TRANSACTION_LIST_SUCCESS',
    GET_TRANSACTION_LIST_FAILURE: 'GET_TRANSACTION_LIST_FAILURE'

};
