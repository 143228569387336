import React, { useDebugValue, useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { activityTypeActions } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';

export default function AddEditActivityType({
    isOpen,
    setIsOpen, 
    isEdit,
    setIsEdit,
    setCurrActivity}) { // isEdit acts as a flag and also contains edit data

    const dispatch = useDispatch()
    const [values, setValues] = useState([])
    const [editValue, setEditValue] = useState('')

    // populate edit value
    useEffect(() => {
        if (isEdit && isEdit.activity_type) {
            setEditValue(isEdit.activity_type)
        }
    }, [isEdit])

    const handleEditChange = (e) => {
        let inputValue = e.target.value;
        if (isEdit) {
            return setEditValue(inputValue)
        }
    };

    const handleAddChange = (e) => {
        let inputValue = e.target.value;
        if (!inputValue) return setValues([])
        if (inputValue?.length <= 3) return

        // below functions allows user to add multiple values separated by commas
        inputValue.trim()

        if (inputValue.includes(',')) {
            const modifiedValues = inputValue.split(',').map(value => value.replace(/^\s+|\s+$/g, '')).filter(value => value?.length > 0)
            setValues(modifiedValues)
        } else {
            const modifiedValues = inputValue.replace(/^\s+|\s+$/g, '')
            setValues([modifiedValues])
        }
    };

    const handleSubmit = async () => {

        // Acitivity type edit flow
        if (isEdit) {
            const { id } = isEdit
            const data = {
                id,
                activity_type: editValue
            }
            //dispatch event
            dispatch(activityTypeActions.editActivityType(apiConstants.EDIT_ACTIVITY_TYPE, data))
            setIsEdit(false)
            setIsOpen(false)
            setCurrActivity('')
            return
        }

        // Acitivity type add flow
        const data = {
            activity_types: JSON.stringify(values)
        }
        //dispatch event
        const resp = dispatch(activityTypeActions.addActivityType(apiConstants.ADD_ACTIVITY_TYPE, data))
        console.log(resp)
        setIsOpen(false)
    }

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="add-products"
            aria-describedby="save-products-description"
            fullWidth={true}
        >
            <DialogTitle>{isEdit ? 'Edit' : 'Add'} Activty Type</DialogTitle>
            <DialogContent>
            <div className="row">
                <div className="col-sm-12 formBorder">
                    <div className="form-group row p-5">
                        <div className="col-sm-12 mb-3 mb-sm-0">
                            <label>Activty Type</label>
                            {isEdit ? <input
                                value={editValue}
                                type="text"
                                className="form-control form-control-user"
                                name="activty_type"
                                placeholder="Activty Type Name (add multiple by separating values by comma)"
                                onChange={handleEditChange}
                            /> : <input
                                type="text"
                                className="form-control form-control-user"
                                name="activty_type"
                                placeholder="Activty Type Name (add multiple by separating values by comma)"
                                onChange={handleAddChange}
                            />
                        }                        
                        </div>
                        {/* <div className="col-sm-2 mb-3 mb-sm-0 d-flex justify-content-center align-items-end">
                            <AddIcon />
                        </div> */}
                    </div>
                </div>
            </div>
            </DialogContent>
            <DialogActions>
                {isEdit ? <Button variant="contained" color="primary" onClick={handleSubmit}>{'Save'}</Button>
                : <Button variant="contained" color="primary" onClick={handleSubmit}>{values?.length > 1 ? 'Publish Multiple' : 'Publish'}</Button>}
                <Button variant="contained" onClick={() => {
                    if (isEdit) {
                        setCurrActivity('')
                        setIsEdit(false)
                    }
                    setIsOpen(false)
                }} >Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}
