import constants from '../utils/constants';
import { authHeader,history } from '../_helpers';
import axios from 'axios';

export const userService = {
    login,
    //save,
    changePassword,
    getUsersList,
    updateAdminProfileImage,


    getAllUserCount,
    //updateUserStatus
};

// //-- We can check admin/user login 
// function login(username, password) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ username, password })
//     };

//     return fetch(`${process.env.REACT_APP_API_URL}/login`, requestOptions)
//         .then(handleResponse)
//         .then(user => {
//             //console.log("TEST ",user);
//             // store user details and jwt token in local storage to keep user logged in between page refreshes
//             sessionStorage.setItem('user', JSON.stringify(user));
//             //sessionStorage.setItem('token', JSON.stringify(user.token));
//             //console.log(user);
//             return user;
//         });
// }
function login(apiName, email, password) {
    const userInfo = {
        email: email,
        password: password
    };
    return axios.post(`${process.env.REACT_APP_API_URL}`+apiName, userInfo)
        //.then(handleResponse)
        .then((response) => {
            // console.log("response : @@@@@@@@@@@@@",response) ;
            if (response.data.data.token) {
                sessionStorage.setItem('user', JSON.stringify(response.data));
                sessionStorage.setItem('token', JSON.stringify(response.data.data.token));
                sessionStorage.setItem('perPageData', constants.perPageData);
                
            }
            return response.data ;
        }).catch((error)=>{ 
            return Promise.reject(error);});
}




// function logout() {
//     // remove user from local storage to log user out
//     sessionStorage.removeItem('user');
//     console.log("SD");
//     history.push('/login');
// }

//-- Save
// function save(user) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ user })
//     };

//     return fetch(`${process.env.REACT_APP_API_URL}/user_profile.php`, requestOptions)
//         .then(handleResponse)
//         .then(user => {
//             // store user details and jwt token in local storage to keep user logged in between page refreshes
//             sessionStorage.setItem('user', JSON.stringify(user));

//             return user;
//         });
// }
//-- Change Password
function changePassword(apiName, userData) {

    let token = JSON.parse(sessionStorage.getItem('token'));
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL+apiName}`,
        headers: { 'Content-Type': 'application/json', 'access_token': token },
        data: userData
    }).then(handleResponse);
}

//-- getUsersList
function getUsersList(apiName, userData) {
    let token = JSON.parse(sessionStorage.getItem('token'));    
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL+apiName}`,
        headers: { 'Content-Type': 'application/json', 'Authorization': token},
        data: userData
    }).then(handleResponse);
}

//-- Update a admin profile image
function updateAdminProfileImage(apiName,userData) {
    var getFile=userData.files;
    var files = new FormData();
    files.append("profile_image", getFile);
    let token = JSON.parse(sessionStorage.getItem('token'));    
    return axios({  
        method: 'PUT',
        url: `${process.env.REACT_APP_API_URL+apiName}`,
        headers: { 'Authorization':token},
        data: files
    }).then(handleResponse);
}


// function updatePic(entity, file, image_name) {
    
//     let formData = new FormData();
//     formData.append(image_name, file);
//     return axios({
//         method: 'POST',
//         url: `${process.env.REACT_APP_API_URL+entity}`,
//         data: formData
//     }).then((handleResponse) => {
//         return handleResponse;
//     });
// }

//-- updateUserStatus
// function updateUserStatus(apiName, userData) {
//     let token = JSON.parse(sessionStorage.getItem('token'));    
//     return axios({
//         method: 'POST',
//         url: `${process.env.REACT_APP_API_URL+apiName}`,
//         headers: { 'Content-Type': 'application/json', 'Authorization': token},
//         data: userData
//     }).then(handleResponse);
// }

// function handleResponse(response) { 
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         //console.log(data);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 // auto logout if 401 response returned from api
//                 logout();
//                 location.reload(true);
//             }

//             const error = (data && data.error) || response.statusText;
//             return Promise.reject(error);
//         }

//         return data;
//     });
// }

function handleResponse(response) {

    //console.log('response', response);

    if (response.status === 200||response.status === 201) {
        return response;
    } else {
        const error = response;
        return Promise.reject(error);
    }

}


// function handleSearchUser(entity, userData){
//      let token = JSON.parse(sessionStorage.getItem('token'));    
//      return axios({
//          method: 'POST',
//          url: `${process.env.REACT_APP_API_URL+entity}`,
//          headers: { 'Content-Type': 'application/json', 'Authorization': token},
//          data: userData
//      }).then(handleResponse);
// }









//------------------- REPORTS
//get all user list
function getAllUserCount(apiName) {
    let token = JSON.parse(sessionStorage.getItem('token'));    
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL+apiName}`,
        headers: { 'Content-Type': 'application/json', 'Authorization':`Bearer ${token}` }
    }).then(handleResponse);
}