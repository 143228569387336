export const activityTypeConstants = {
    GET_ACTIVITY_TYPE_REQUEST: 'GET_ACTIVITY_TYPE_REQUEST',
    GET_ACTIVITY_TYPE_SUCCESS: 'GET_ACTIVITY_TYPE_SUCCESS',
    GET_ACTIVITY_TYPE_FAILURE: 'GET_ACTIVITY_TYPE_FAILURE',

    ADD_ACTIVITY_TYPE_REQUEST: 'ADD_ACTIVITY_TYPE_REQUEST',
    ADD_ACTIVITY_TYPE_SUCCESS: 'ADD_ACTIVITY_TYPE_SUCCESS',
    ADD_ACTIVITY_TYPE_FAILURE: 'ADD_ACTIVITY_TYPE_FAILURE',

    EDIT_ACTIVITY_TYPE_REQUEST: 'EDIT_ACTIVITY_TYPE_REQUEST',
    EDIT_ACTIVITY_TYPE_SUCCESS: 'EDIT_ACTIVITY_TYPE_SUCCESS',
    EDIT_ACTIVITY_TYPE_FAILURE: 'EDIT_ACTIVITY_TYPE_FAILURE',
    
    ARCHIVE_ACTIVITY_TYPE_REQUEST: 'ARCHIVE_ACTIVITY_TYPE_REQUEST',
    ARCHIVE_ACTIVITY_TYPE_SUCCESS: 'ARCHIVE_ACTIVITY_TYPE_SUCCESS',
    ARCHIVE_ACTIVITY_TYPE_FAILURE: 'ARCHIVE_ACTIVITY_TYPE_FAILURE',

    FILTER_ACTIVITY_TYPE_REQUEST: 'FILTER_ACTIVITY_TYPE_REQUEST',
    FILTER_ACTIVITY_TYPE_SUCCESS: 'FILTER_ACTIVITY_TYPE_SUCCESS',
    FILTER_ACTIVITY_TYPE_FAILURE: 'FILTER_ACTIVITY_TYPE_FAILURE',
};