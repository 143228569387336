import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import PageLimit from "../Common/PageLimit/PageLimit";
import Pagination from "../Common/Pagination/Pagination";
import { useSelector, useDispatch } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';
import { seekerActions, userActions, providerActions } from '../../_actions';
import { Badge } from 'antd';
// import { Switch } from 'antd';
import 'antd/dist/antd.css';
import SeekerDetail from './SeekerDetail';
import AddSeeker from './AddSeeker';
import { CSVLink } from "react-csv";

export default function Seeker() {
  const [seekerDetails, setSeekerDetails] = useState(false);
  const [saveSeeker, setSaveSeeker] = useState({})
  const [seekerAdd, setSeekerAdd] = useState(false);
  const [seekerAddCompany, setSeekerAddCompany] = useState(false)
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [deleteDetail, setDeleteDetail] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [deletedId, setDeletedId] = useState('')
  const [limit, setLimit] = useState(10)
  const [seekerFlag, setSeekerFlag] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [NewOffset, setOffset] = useState(0);
  const [seekerId, setSeekerId] = useState('')
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')))
  const dispatch = useDispatch();

  const seeker = useSelector(state => state.getSeeker.allSeeker);
  const total_pages = useSelector(state => state.getSeeker.pages);
  useEffect(() => {
    let obj = {
      limit: limit,
      offset: 0
    }
    dispatch(seekerActions.getSeeker(apiConstants.GET_SEEKER, obj));
  }, [])

  const handleSort = (e) => {
    if(seekerFlag == 1){
      setSeekerFlag(0)
    }else if(seekerFlag == 0){
      setSeekerFlag(1)
    }
   let obj = {
     sortByStatus: seekerFlag,
     sortBy: e.target.name
   }
  //  setCompany(company.sort((a,b) => parseFloat(a.user_id) - parseFloat(b.user_id)))
   dispatch(seekerActions.getSeeker(apiConstants.SORT_SEEKER, obj))
  }

  const searchUsers = (e) => {
    let obj = {
      searchQuery: e.target.value,
      limit: limit,
      offset: 0
    }
    dispatch(seekerActions.getSeeker(apiConstants.SEARCH_SEEKER, obj));
  }

  const seekerDetail = (seeker) => {
    setSeekerDetails(true)
    setSaveSeeker(seeker)
  }

  const backButton = () => {
    setSeekerDetails(false)
  }

  const openAddSeekerDialog = () => {
    setSeekerAdd(true)
  }

  
  const closeAddSeekerDialog = () => {
    setSeekerAdd(false);
    dispatch(seekerActions.getSeeker(apiConstants.GET_SEEKER));
  }

  const closeDeletePopup = () => {
    setDeletePopUp(false);
  }

  const deleteSeeker = (name, id) => {
    setDeletePopUp(true);
    setDeleteDetail(name)
    setDeletedId(id)
  }

  const handleDelete = () => {
    let obj = {
      id: deletedId,
      status: 1,
      is_deleted: 1
    }
    dispatch(userActions.updateUserProfile(apiConstants.UPDATE_USER_PROFILE, obj));
    setDeletePopUp(false);
   }

  const changeTableData = (e) => {
    let obj = {
      limit: e.target.value,
      offset: 0
    }
    dispatch(seekerActions.getSeeker(apiConstants.GET_SEEKER, obj));
}

const makeHttpRequestWithPage = (pageNumber) => {
    setCurrentPage(pageNumber)
    let obj = {
      limit:limit,
      offset:pageNumber
    }
    setOffset(obj.offset)
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER, obj));
}


  // const headers = [
  //   { label: "ID", key: "id" },
  //   { label: "ID1", key: "id1" },
  //   { label: "Role Type", key: "role_type" },
  //   { label: "Status", key: "status" },
  //   { label: "First Name", key: "first_name" },
  //   { label: "Last Name", key: "last_name" },
  //   { label: "Compnay Name", key: "company_name" },
  //   // { label: "Deparment", key: "department" },
  //   { label: "Job Title", key: "job_title" },
  //   { label: "Phone", key: "phone" },
  //   // { label: "Company Street Address", key: "company_street_address" },
  //   // { label: "SubRub", key: "subrub" },
  //   // { label: "Post Code", key: "post_code" },
  //   // { label: "State", key: "state" },
  //   // { label: "Country", key: "country" },
  //   { label: "Email", key: "email" },
  //   { label: "Password", key: "password" },
  //   { label: "Forgot Password Code", key: "forgot_password_code" },
  //   { label: "is_deleted", key: "is_deleted" },
  //   { label: "isVerified", key: "isVerified" },
  //   { label: "created_by", key: "created_by" },
  //   { label: "updated_by", key: "updated_by" },
  //   { label: "createdAt", key: "createdAt" },
  //   { label: "updatedAt", key: "updatedAt" },
  // ];

  const headers = [
    { label: "Role Type", key: "role_type" },
    { label: "Status", key: "status"},
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name"},
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Job Title", key: "job_title" },
  ];

  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Seeker
              </li>
            </ol>
          </nav>
          {!seekerDetails ?
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-2 text-gray-800">
                  Seeker
                </h1>
                <div className="d-sm-flex align-items-center justify-content-between">
                  {/* <CSVLink className="btn btn-primary mr-3" data={seeker.map(Object.values)} headers={headers.map(Object.values)}>Export</CSVLink> */}
                  <CSVLink className="btn btn-primary mr-3" data={seeker || []} headers={headers}>Export</CSVLink>                 
                  <a href="#!"
                    onClick={() => console.log("provider add")}
                  >
                    <span className="text">
                      <button onClick={openAddSeekerDialog} className="btn btn-primary">Add New Seeker</button>
                    </span>
                  </a>
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="row">
                      {/* Page limit drop down */}
                      <PageLimit funChangePageLimit={changeTableData} />

                      <div className="col-sm-12 col-md-6">
                        <div id="dataTable_filter" className="dataTables_filter">
                          <label>
                            <input type="search" className="form-control form-control-sm" placeholder="Search" aria-controls="dataTable" onChange={searchUsers} />
                          </label>
                        </div>
                      </div>
                    </div>
                    <table className="table table-bordered" width="100%" cellSpacing="0">
                      <thead>
                        <tr>
                          <th scope="col">No.</th>
                          <th scope="col">Name
                          <img src="assets/img/svg/sort.png" name="company_name" className="imageSort" onClick={handleSort} />
                          </th>
                          <th scope="col">Email</th>
                          <th scope="col">Status</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Address</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {seeker !== undefined && seeker.length > 0 ? seeker.map((seeker, index) => {
                          var count = 0;
                          //  var pageData = config.perPageData;
                           count = index + (NewOffset*10)+1
                          return (
                            <tr key={index}>
                              <td scope="row">{count}</td>
                              <td>{seeker.first_name} {seeker.last_name}</td>
                              <td>{seeker.email}</td>
                              <td>
                                {/* <Switch size="small" defaultChecked={seeker.status === 0} /> */}
                                {/* {seeker.status === 0 ? <Badge className="ml-3" status="success" text="Active" /> : <Badge className="ml-3" status="error" text="Inactive" />} */}
                                {seeker.status === "Active" ? <Badge className="ml-3" status="success" text="Active" /> : <Badge className="ml-3" status="error" text="Inactive" />}
                                {/* <Badge status="success" text="Active" /> */}
                              </td>
                              <td>{seeker.phone}</td>
                              <td>{seeker.company_street_address}</td>
                              <td>
                                <a className="actionIcon" title="View" href="#!" onClick={() => seekerDetail(seeker)}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a>
                                {/* <a className="actionIcon" title="View" href="#!" onClick={() => this.popupOpen(users, 'viewOpen')}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a> */}
                                {
                                user.data.role == 'super admin' ? <><Link className="actionIcon" title="Edit" to={`/update-seeker/${seeker.id}`}>
                                  <span><img src="assets/img/svg/EditIcon.png" alt="Edit" />
                                  </span></Link> 
                                <a className="actionIcon" title="Delete" href="#!" onClick={() => deleteSeeker(seeker.first_name, seeker.id)}><span><img src="assets/img/svg/Bin.png" alt="Delete" /></span></a>
                                </>
                                : ''
                              }
                                </td>
                            </tr>
                          )
                        }) : null}

                      </tbody>
                    </table>
                  </div>
                  {/* calling the another componenet */}
                  <Pagination totalPage={total_pages} currentPage={currentPage} funPagination={makeHttpRequestWithPage} />
                </div>
              </div>
            </div> :
            <SeekerDetail data={saveSeeker} backButton={backButton} />
          }

        </div>

        {/* add seeker popup */}
        {
          seekerAdd === true ? <AddSeeker displayPopUp={seekerAdd} hidePopUp={closeAddSeekerDialog} /> : ''
        }

        <Dialog open={deletePopUp} fullWidth={true} aria-labelledby="delete-product-notify" >
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            Are you sure?
            <h5 className="mt-3">You want to delete {deleteDetail}?</h5>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={()=>handleDelete()}>Delete</Button>
            <Button variant="contained" onClick={closeDeletePopup}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Footer />
      </div>
    </div>
  )
}
