import React, { useEffect, useState } from 'react';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import PageLimit from "../Common/PageLimit/PageLimit";
// import Pagination from "../Common/Pagination/Pagination";
import 'antd/dist/antd.css';

import { useSelector, useDispatch } from 'react-redux';
import {questionsActions} from "../../_actions/questions.action";
import { apiConstants } from '../../_constants/api.constants';

export default function Questions() {
  const [questions, setQuestions] = useState([])
  const [sortFlag, setSortFlag] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filteredQuestionsList, setFilteredQuestionsList] = useState([]);
  const [search, setSearch] = useState("");

  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')))
  const dispatch = useDispatch();
  const getQuestionsList = useSelector(state => state.getQuestionsList);


  useEffect(() => {
    let obj = {
      limit: 10,
      offset: 0
    }
    dispatch(questionsActions.getQuestionsList(apiConstants.GET_ALL_QUESTIONS));
  }, [])

//   const makeHttpRequestWithPage = (pageNumber) => {
//     setCurrentPage(pageNumber)
//     let obj = {
//       limit: limit,
//       offset:pageNumber - 1
//     }
//     dispatch(feedbackActions.getFeedbackList(apiConstants.GET_ALL_FEEDBACK, obj));
// }

  useEffect(()=> {
    if(getQuestionsList.getQuestionsList && getQuestionsList.getQuestionsList.data){
      setQuestions(getQuestionsList.getQuestionsList.data)
    }
  },[getQuestionsList.getQuestionsList])

   // Search function defined for search
   useEffect(() => {
    setFilteredQuestionsList(
      questions.filter((data) =>
      data.questions.toLowerCase().includes(search.toLowerCase()) || 
      data.question_type.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, questions]);


  // const handleSort = (e) => {
  //   if(sortFlag == 1){
  //     setSortFlag(0)
  //   }else if(sortFlag == 0){
  //     setSortFlag(1)
  //   }
  //  let obj = {
  //    sortByStatus: sortFlag,
  //    sortBy: e.target.name
  //  }
  //  dispatch(feedbackActions.sortFeedbackList(apiConstants.SORT_FEEDBACK, obj))
  // }

  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Questions
              </li>
            </ol>
          </nav>
            <div className="container-fluid">
              
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="row">
                      {/* Page limit drop down */}
                      <PageLimit perPageData="10" />

                      <div className="col-sm-12 col-md-6">
                        <div id="dataTable_filter" className="dataTables_filter">
                          <label>
                            <input type="search" onChange={(e) => setSearch(e.target.value)} className="form-control form-control-sm" placeholder="Search" aria-controls="dataTable" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <table className="table table-bordered" width="100%" cellSpacing="0">
                      <thead>
                        <tr>
                          <th scope="col">No.</th>
                          <th scope="col">Question
                          {/* <img src="assets/img/svg/sort.png" name="feedback" className="imageSort" onClick={handleSort}/> */}
                          </th>
                          <th scope="col">Question type</th>
                          <th scope="col">All Answer</th>
                          <th scope="col">Correct answer</th>
                        </tr>
                      </thead>
                      <tbody>
                      {filteredQuestionsList !== undefined ? filteredQuestionsList.map((questions, index) => {
                       return (
                        <tr key={index}>
                          <td scope="row">{index + 1}</td>
                          <td>{questions.questions}</td>                  
                          <td>{questions.question_type}</td>
                          <td>{questions.all_answers}</td>
                          <td>{questions.correct_answer}</td>
                         
                        </tr>
                          )
                        }) : null}
                      </tbody>
                    </table>
                  </div>
                  {/* calling the another componenet */}
                  {/* <Pagination totalPage={(feedbackData && feedbackData.getFeedbackList) ? feedbackData.getFeedbackList.pages : 0 } currentPage={currentPage} funPagination={makeHttpRequestWithPage} /> */}
                </div>
              </div>
            </div> 
        </div>
        <Footer />
      </div>
    </div>
  )
}
