import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
// import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import { providerActions, experienceActions } from "../../_actions";
import { feedbackActions } from "../../_actions/feedback.action";
import { apiConstants } from "../../_constants/api.constants";

export default function AddFeedback({ displayPopUp, hidePopUp }) {
  const [feedbackData, setFeedbackData] = useState({});
  const [selectExperienceId, setSelectExperience] = useState("");
  const [getExperienceData, setExperience] = useState([]);
  const [userData, setUserData] = useState({});
  const [satisfactionRating, setSatisfactionRating] = useState("");
  const [qualityRating, setQualityRating] = useState("");
  const [managementRating, setManagementRating] = useState("");
  const experience = useSelector((state) => state.getExperienceList);
  const providerDetails = useSelector(
    (state) => state.getProviderSeekerById.providerDetails
  );

  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedbackData({ ...feedbackData, [name]: value });
  };
  useEffect(() => {
    if (Object(providerDetails).data) {
      setUserData(providerDetails.data.userdata);
    }
  }, [providerDetails.data]);

  useEffect(() => {
    let obj = {
      limit: 10,
      offset: 0,
    };
    dispatch(
      experienceActions.getExperienceList(
        apiConstants.GET_ALL_EXPERIENCE_DETAILS,
        obj
      )
    );
  }, []);

  useEffect(() => {
    if (experience.getExperienceList && experience.getExperienceList.data) {
      setExperience(experience.getExperienceList.data);
    }
  }, [experience.getExperienceList]);

  const handleSelectExperience = (e) => {
    setSelectExperience(getExperienceData[e.target.value].id);
    // get seeker id
    let dataObj = {
      id: getExperienceData[e.target.value].id,
    };
    dispatch(
      providerActions.getProviderSeekerById(
        apiConstants.GET_PROVIDER_SEEKER_BY_ID,
        dataObj
      )
    );
  };

  const firstExample = {
    size: 20,
    count: 5,
    color: "black",
    activeColor: "red",
    value: 0,
    a11y: true,
    isHalf: false,
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };
  const secondExample = {
    size: 20,
    count: 5,
    color: "black",
    activeColor: "red",
    value: 0,
    a11y: true,
    isHalf: false,
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };
  const thirdExample = {
    size: 20,
    count: 5,
    color: "black",
    activeColor: "red",
    value: 0,
    a11y: true,
    isHalf: false,
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };

  const handleSubmit = (e, val) => {
    let newObj = {
      seeker_id: userData.id,
      provider_id: '',
      experience_id: selectExperienceId,
      exp_name:'',
      cmp_name:'',
      feedback: feedbackData.feedback,
      satisfaction: satisfactionRating,
      quality: qualityRating,
      management: managementRating,
    };
    dispatch(feedbackActions.addFeedback(apiConstants.ADD_FEEDBACK, newObj));
    hidePopUp();
  };
  return (
    <div>
      <Dialog
        open={displayPopUp}
        aria-labelledby="add-products"
        aria-describedby="save-products-description"
        fullWidth={true}
      >
        <DialogTitle>Add Feedback</DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-sm-12 formBorder">
              <div className="p-5">
                <div className="form-group row">
                  <div className="col-sm-6">
                    <label>Experience Name</label>
                    <select
                      className="form-control"
                      name="experience_name"
                      onChange={handleSelectExperience}
                    >
                      <option>Select Experience</option>
                      {getExperienceData !== undefined &&
                      getExperienceData.length > 0
                        ? getExperienceData.map((data, index) => (
                            <option value={index}>
                              {data.experience_name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                  <div className="col-sm-6">
                    <label>Select Seeker</label>
                    <select className="form-control">
                      <option>Select Seeker</option>
                      {userData.role_type == 'seeker' ? (
                        <option value={userData.id}>
                          {userData.first_name + " " + userData.last_name}
                        </option>
                      ) : null}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Feedback</label>
                      <textarea
                        className="form-control"
                        name="feedback"
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>Satisfaction</label>
                      <div>
                        <ReactStars
                          onChange={(e) => setSatisfactionRating(e)}
                          {...firstExample}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>Quality</label>
                      <div>
                        <ReactStars
                          onChange={(e) => setQualityRating(e)}
                          {...secondExample}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>Management</label>
                      <div>
                        <ReactStars
                          onChange={(e) => setManagementRating(e)}
                          {...thirdExample}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleSubmit(e, 1)}
          >
            Add Feedback
          </Button>
          <Button variant="contained" onClick={hidePopUp}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
