import { eventConstants } from '../_constants';

//-- get the list of terms
export function getEventsList(state = {}, action) {
  switch (action.type) {
    case eventConstants.EVENT_LIST_REQUEST:
      return {
        getEventsList: false
      };

    case eventConstants.EVENT_LIST_SUCCESS:
      //console.log("REDUCER ", action);
      return { isGetAdvertismentList:true , getEventsList:action.successAction};

    case eventConstants.EVENT_LIST_FAILURE:
      return {
        getEventsList: false,
      };

    case eventConstants.EVENT_SEARCH_REQUEST:
      return { isGetAdvertismentList:true , getEventsList:action.successAction.data};

    default:
      return state
  }
}

export function addEvent(state = {}, action) {
  switch (action.type) {
    case eventConstants.EVENT_ADD_REQUEST:
      return {
        addEvent: false
      };

    case eventConstants.EVENT_ADD_SUCCESS:      
      return { addEvent: action.successAction };

    case eventConstants.EVENT_ADD_FAILURE:
      return {
        addEvent: false,
      }; 

    default:
      return state
  }
}


export function deleteEvent(state = {}, action) {
  switch (action.type) {
    case eventConstants.EVENT_DELETE_REQUEST:
      return {
        deleteEvent: false
      };

    case eventConstants.EVENT_DELETE_SUCCESS:      
      return { deleteEvent: action.successAction };

    case eventConstants.EVENT_DELETE_FAILURE:
      return {
        deleteEvent: false,
      }; 

    default:
      return state
  }
}

