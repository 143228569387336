import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import PageLimit from "../Common/PageLimit/PageLimit";
import Pagination from "../Common/Pagination/Pagination";
import { useSelector, useDispatch } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';
// import { companyConstants } from '../../_constants';
import { locationActions } from '../../_actions';
import * as commonAction from '../../_actions/commonAction';
import { Badge } from 'antd';
// import { Switch } from 'antd';
import 'antd/dist/antd.css';
import LocationDetail from './LocationDetail';
import { CSVLink } from "react-csv";
import AddLocation from './AddLocation';

export default function Location() {
  const [locationDetails, setLocationDetails] = useState(false);
  const [saveLocation, setSaveLocation] = useState({})
  const [addLocation, setLocationAdd] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [locationFlag, setLocationFlag] = useState(0);
  const [deleteDetail, setDeleteDetail] = useState('')
  const [locationName, setLocationName] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [location, setLocation] = useState([])
  const [search, setSearch] = useState("");
  const [filteredLocation, setFilteredLocation] = useState([]);
  const [NewOffset, setOffset] = useState(0);

  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')))
  const dispatch = useDispatch();

  // const total_count = useSelector(state => state.getAllCompany.total_count);
  // const total_pages = useSelector(state => state.getAllCompany.pages);

  useEffect(() => {
    let obj = {
      limit: 10,
      offset: 0
    }
    dispatch(locationActions.getLocationList(apiConstants.GET_ALL_LOCATIONS, obj));
  }, [])

  const getLocation = useSelector(state => state.getLocationList);

  useEffect(()=>{
    if(getLocation && getLocation.getLocationList){
      setLocation(getLocation.getLocationList.data)
    }
  },[getLocation])

  const changeTableData = (e) => {
    setLimit(e.target.value)
    let obj = {
      limit: parseInt(e.target.value),
      offset: 0,
    };
    dispatch(locationActions.getLocationList(apiConstants.GET_ALL_LOCATIONS, obj));
  };

  const locationDetail = (location) => {
    setLocationDetails(true)
    setSaveLocation(location)
  }

  const backButton = () => {
    setLocationDetails(false)
  }

  const closeDeletePopup = () => {
    setDeletePopUp(false);
  }

  const deleteLocation = (id,name) => {
    setDeletePopUp(true);
    setDeleteDetail(id)
    setLocationName(name)
  }

  const openAddLocationDialog = () => {
    setLocationAdd(true);
  }

  const closeAddLocationDialog = () => {
    setLocationAdd(false);
    // dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER));
  }
   // Search function defined for search
   useEffect(() => {
    setFilteredLocation(
      location.filter((data) =>
      data.location_address.toLowerCase().includes(search.toLowerCase()) ||
      data.location_name.toLowerCase().includes(search.toLowerCase()) 
      )
    );
  }, [search, location]);

  const makeHttpRequestWithPage = (pageNumber) => {
    setCurrentPage(pageNumber)
    let obj = {
      limit: limit,
      offset:pageNumber - 1
    }
    setOffset(obj.offset)
    dispatch(locationActions.getLocationList(apiConstants.GET_ALL_LOCATIONS, obj));
}

const handleSort = (e) => {
  if(locationFlag == 1){
    setLocationFlag(0)
  }else if(locationFlag == 0){
    setLocationFlag(1)
  }
 let obj = {
   sortByStatus: locationFlag,
   sortBy: e.target.name
 }
 dispatch(locationActions.sortLocation(apiConstants.SORT_LOCATION, obj))
}

 const searchLocation = (e) => {
    let obj = {
      searchQuery: e.target.value,
      limit: limit,
      offset: 0
    }
    dispatch(locationActions.sortLocation(apiConstants.SEARCH_LOCATION, obj));
  }

  const handleDelete = () => {
    let obj = {
      id: deleteDetail,
      is_deleted: 1
    }
  
    dispatch(locationActions.updateLocation(apiConstants.UPDATE_LOCATION, obj))
    setDeletePopUp(false);
  }

  // const headers = [
  //   { label: "ID", key: "id" },
  //   { label: "Provider Id", key: "provider_id" },
  //   { label: "Location Address", key: "location_address" },
  //   { label: "Location Name", key: "location_name" },
  //   { label: "Latitude", key: "lattitude" },
  //   { label: "Longitude", key: "longitude" },
  //   { label: "Is Deleted", key: "is_deleted" },
  //   { label: "Created By", key: "created_by_admin" }
  // ];
  const headers = [
    { label: "First Name", key: "UserData.first_name" },
    { label: "Last Name", key: "UserData.last_name" },
    { label: "Location Address", key: "location_address" },
    { label: "Location Name", key: "location_name" },
    { label: "Latitude", key: "lattitude" },
    { label: "Longitude", key: "longitude" },
    { label: "Status", key: "is_deleted" },
  ];


  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Location
              </li>
            </ol>
          </nav>
          {!locationDetails ?
            <div className="container-fluid">
             
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-2 text-gray-800">
                  Location
                </h1>
                <div className="d-sm-flex align-items-center justify-content-between">
                  {/* <CSVLink className="btn btn-primary mr-3" data={(location !== undefined && location.length ? location : []).map(Object.values)} headers={headers.map(Object.values)}>Export</CSVLink> */}
                  <CSVLink className="btn btn-primary mr-3" data={location || []} headers={headers}>Export</CSVLink>
                  <a href="#!"
                    onClick={() => console.log("provider add")}
                  >
                    <span className="text">
                      <button onClick={openAddLocationDialog} className="btn btn-primary">Add Location</button>
                    </span>
                  </a>
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="row">
                      {/* Page limit drop down */}
                      <PageLimit funChangePageLimit={changeTableData} />

                      <div className="col-sm-12 col-md-6">
                        <div id="dataTable_filter" className="dataTables_filter">
                          <label>
                            <input type="search" onChange={searchLocation} className="form-control form-control-sm" placeholder="Search" aria-controls="dataTable"  />
                          </label>
                        </div>
                      </div>
                    </div>
                    <table className="table table-bordered" width="100%" cellSpacing="0">
                      <thead>
                        <tr>
                          <th scope="col">No.</th>
                          <th width="50%">
                          Location Address 
                            <img src="assets/img/svg/sort.png" name="location_address" className="imageSort" onClick={handleSort}/>
                          </th>
                          <th scope="col">Location Name
                          <img src="assets/img/svg/sort.png" name="location_name" className="imageSort" onClick={handleSort}/>
                          </th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {filteredLocation !== undefined && filteredLocation.length > 0 ? filteredLocation.map((location, index) => {
                           var count = 0;
                           //  var pageData = config.perPageData;
                            count = index + (NewOffset*10)+1
                          return (
                            <tr key={index}>
                              <td scope="row">{count}</td>
                              <td>{location.location_address}</td>
                              <td>{location.location_name}</td>
                              <td>
                                  {/* {location.is_deleted === 0 ? <Badge className="ml-3" status="success" text="Active" /> : <Badge className="ml-3" status="error" text="Inactive" />} */}
                                  {location.is_deleted === "Active" ? <Badge className="ml-3" status="success" text="Active" /> : <Badge className="ml-3" status="error" text="Inactive" />}
                              </td>
                              <td>
                                <a className="actionIcon" title="View" href="#!" onClick={() => locationDetail(location)}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a>
                                {
                                user.data.role == 'super admin' ? <> <Link className="actionIcon" title="Edit" to={`/update-location/${location.id}`}><span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span></Link>
                                <a className="actionIcon" title="Delete" href="#!" onClick={() => deleteLocation(location.id,location.location_name)}><span><img src="assets/img/svg/Bin.png" alt="Delete" /></span></a>
                              </> : '' 
                                }
                                </td>
                            </tr>
                          )
                        }) : null}

                      </tbody>
                    </table>
                  </div>
                  {/* calling the another componenet */}
                  <Pagination totalPage={(getLocation && getLocation.getLocationList) ? getLocation.getLocationList.pages : 0 } currentPage={currentPage} funPagination={makeHttpRequestWithPage} />
                </div>
              </div>
            </div> :
            <LocationDetail data={saveLocation} backButton={backButton} />
          }

        </div>
        {
          addLocation === true ? <AddLocation displayPopUp={addLocation} hidePopUp={closeAddLocationDialog}  /> : ''
        }
        <Dialog open={deletePopUp} fullWidth={true} aria-labelledby="delete-product-notify" >
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            Are you sure?
            <h5 className="mt-3">You want to delete {locationName}?</h5>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleDelete}>Delete</Button>
            <Button variant="contained" onClick={closeDeletePopup}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Footer />
      </div>
    </div>
  )
}
