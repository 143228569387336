import { companyConstants } from '../_constants';
import * as commonAction from './commonAction';
import { commonService } from '../_services';
import { history } from '../_helpers';

export const companyActions = {
  addCompany,
  getOrganisationDetailsById,
  getOrganisationDetails,
  getOrganisationByOrgId,
  updateOrganisationByOrgId,
  getCompany,
  sortCompany,
};

function addCompany(apiName, data) {
  return (dispatch) => {
    dispatch(
      commonAction.request(companyConstants.COMPANY_ADDED_REQUEST, data),
    );
    commonService.withOutTokenAndFormData(apiName, data).then(
      (company) => {
        dispatch(
          commonAction.success(companyConstants.COMPANY_ADDED_SUCCESS, company),
        );
        history.push('/company');
      },
      (error) => {
        dispatch(
          commonAction.failure(companyConstants.COMPANY_ADDED_FAILURE, error),
        );
      },
    );
  };
}

function getOrganisationDetailsById(apiName, data) {
  return (dispatch) => {
    dispatch(
      commonAction.request(companyConstants.GET_ORGANISATION_REQUEST, data),
    );
    commonService.withOutTokenAndBaseUrl(apiName, data).then(
      (company) => {
        dispatch(
          commonAction.success(
            companyConstants.GET_ORGANISATION_SUCCESS,
            company,
          ),
        );
      },
      (error) => {
        dispatch(
          commonAction.failure(
            companyConstants.GET_ORGANISATION_FAILURE,
            error,
          ),
        );
      },
    );
  };
}

function getOrganisationDetails(apiName, data) {
  return (dispatch) => {
    dispatch(
      commonAction.request(companyConstants.GET_ALL_ORGANISATION_REQUEST, data),
    );
    commonService.withOutTokenAndBaseUrl(apiName, data).then(
      (company) => {
        dispatch(
          commonAction.success(
            companyConstants.GET_ALL_ORGANISATION_SUCCESS,
            company,
          ),
        );
      },
      (error) => {
        dispatch(
          commonAction.failure(
            companyConstants.GET_ALL_ORGANISATION_FAILURE,
            error,
          ),
        );
      },
    );
  };
}

function getCompany(apiName, data) {
  return (dispatch) => {
    dispatch(commonAction.request(companyConstants.GET_COMPANY_REQUEST, data));
    commonService.withOutTokenAndBaseUrl(apiName, data).then(
      (company) => {
        dispatch(
          commonAction.success(companyConstants.GET_COMPANY_SUCCESS, company),
        );
      },
      (error) => {
        dispatch(
          commonAction.failure(companyConstants.GET_COMPANY_FAILURE, error),
        );
      },
    );
  };
}

function sortCompany(apiName, data) {
  return (dispatch) => {
    dispatch(commonAction.request(companyConstants.GET_COMPANY_REQUEST, data));
    commonService.withTokenAndBashUrl(apiName, data).then(
      (company) => {
        dispatch(
          commonAction.success(companyConstants.GET_COMPANY_SUCCESS, company),
        );
      },
      (error) => {
        dispatch(
          commonAction.failure(companyConstants.GET_COMPANY_FAILURE, error),
        );
      },
    );
  };
}

function getOrganisationByOrgId(apiName, data) {
  return (dispatch) => {
    dispatch(
      commonAction.request(
        companyConstants.GET_ALL_ORGANISATION_BY_ID_REQUEST,
        data,
      ),
    );
    commonService.withOutTokenAndBaseUrl(apiName, data).then(
      (company) => {
        dispatch(
          commonAction.success(
            companyConstants.GET_ALL_ORGANISATION_BY_ID_SUCCESS,
            company,
          ),
        );
      },
      (error) => {
        dispatch(
          commonAction.failure(
            companyConstants.GET_ALL_ORGANISATION_BY_ID_FAILURE,
            error,
          ),
        );
      },
    );
  };
}

function updateOrganisationByOrgId(apiName, data) {
  return (dispatch) => {
    dispatch(
      commonAction.request(
        companyConstants.UPDATE_ORGANISATION_BY_ID_REQUEST,
        data,
      ),
    );

    return new Promise((resolve, reject) => {
      commonService.withOutTokenAndFormData(apiName, data).then(
        (company) => {
          dispatch(
            commonAction.success(
              companyConstants.UPDATE_ORGANISATION_BY_ID_SUCCESS,
              company,
            ),
          );
          resolve(company); // Resolve the promise with the company data
        },
        (error) => {
          dispatch(
            commonAction.failure(
              companyConstants.UPDATE_ORGANISATION_BY_ID_FAILURE,
              error,
            ),
          );
          reject(error); // Reject the promise with the error
        },
      );
    });
  };
}
