export const bookingRequestConstants = {
    
    GET_BOOKING_REQUEST: 'GET_BOOKING_REQUEST',
    GET_BOOKING_SUCCESS: 'GET_BOOKING_SUCCESS',
    GET_BOOKING_FAILURE: 'GET_BOOKING_FAILURE',

    GET_BOOKING_BY_ID_REQUEST: 'GET_BOOKING_BY_ID_REQUEST',
    GET_BOOKING_BY_ID_SUCCESS: 'GET_BOOKING_BY_ID_SUCCESS',
    GET_BOOKING_BY_ID_FAILURE: 'GET_BOOKING_BY_ID_FAILURE',

    POST_BOOKING_REQUEST: 'POST_BOOKING_REQUEST',
    POST_BOOKING_SUCCESS: 'POST_BOOKING_SUCCESS',
    POST_BOOKING_FAILURE: 'POST_BOOKING_FAILURE',

    EDIT_BOOKING_REQUEST: 'EDIT_BOOKING_REQUEST',
    EDIT_BOOKING_SUCCESS: 'EDIT_BOOKING_SUCCESS',
    EDIT_BOOKING_FAILURE: 'EDIT_BOOKING_FAILURE'
    
};
