import { experienceConstants } from '../_constants';

//-- get the list of terms
export function getExperienceList(state = {}, action) {
  switch (action.type) {
    case experienceConstants.GET_EXPERIENCE_REQUEST:
      return {
        getExperienceList: false,
        loading: true,
      };

    case experienceConstants.GET_EXPERIENCE_SUCCESS:
      return { getExperienceList: action.successAction, loading: false };

    case experienceConstants.GET_EXPERIENCE_FAILURE:
      return {
        getExperienceList: false,
        loading: false,
      };

    default:
      return state;
  }
}

export function addExperience(state = {}, action) {
  switch (action.type) {
    case experienceConstants.ADD_EXPERIENCE_REQUEST:
      return {
        addExperience: false,
      };

    case experienceConstants.ADD_EXPERIENCE_SUCCESS:
      return { addExperience: action.successAction };

    case experienceConstants.ADD_EXPERIENCE_FAILURE:
      return {
        addExperience: false,
      };

    default:
      return state;
  }
}

export function getExperienceById(state = {}, action) {
  switch (action.type) {
    case experienceConstants.GET_EXPERIENCE_BY_ID_REQUEST:
      return {
        getExperienceById: false,
      };

    case experienceConstants.GET_EXPERIENCE_BY_ID_SUCCESS:
      return { getExperienceById: action.successAction };

    case experienceConstants.GET_EXPERIENCE_BY_ID_FAILURE:
      return {
        getExperienceById: false,
      };

    default:
      return state;
  }
}

export function editExperience(state = {}, action) {
  switch (action.type) {
    case experienceConstants.EDIT_EXPERIENCE_REQUEST:
      return {
        editExperience: false,
      };

    case experienceConstants.EDIT_EXPERIENCE_SUCCESS:
      return { editExperience: action.successAction };

    case experienceConstants.EDIT_EXPERIENCE_FAILURE:
      return {
        editExperience: false,
      };

    default:
      return state;
  }
}

export function unpublishExperience(state = {}, action) {
  switch (action.type) {
    case experienceConstants.UNPUBLISH_EXPERIENCE_REQUEST:
      return {
        unpublishExperience: false,
      };

    case experienceConstants.UNPUBLISH_EXPERIENCE_SUCCESS:
      return { unpublishExperience: action.successAction };

    case experienceConstants.UNPUBLISH_EXPERIENCE_FAILURE:
      return {
        unpublishExperience: false,
      };

    default:
      return state;
  }
}

export function deleteExperience(state = {}, action) {
  switch (action.type) {
    case experienceConstants.DELETE_EXPERIENCE_REQUEST:
      return {
        deleteExperience: false,
      };

    case experienceConstants.DELETE_EXPERIENCE_SUCCESS:
      return { deleteExperience: action.successAction };

    case experienceConstants.DELETE_EXPERIENCE_FAILURE:
      return {
        deleteExperience: false,
      };

    default:
      return state;
  }
}
