import { providerConstants } from '../_constants';
import * as commonAction from './commonAction';
import { commonService } from '../_services';
import { apiConstants } from '../_constants/api.constants';
import {seekerActions} from '../_actions/seeker.actions'

export const providerActions = {
  getProvider,
  addProviderSeeker,
  getProviderSeekerById
}

function getProvider(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(providerConstants.GET_PROVIDER_REQUEST, eventData));
    commonService.withTokenAndBashUrl(apiName, eventData).then(
      provider => {
        dispatch(commonAction.success(providerConstants.GET_PROVIDER_SUCCESS, provider));
      },
      error => {
        dispatch(commonAction.failure(providerConstants.GET_PROVIDER_FAILURE, error.response.data.error));
      }
    )
  }
}

function addProviderSeeker(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(providerConstants.ADD_PROVIDER_SEEKER_REQUEST, eventData));
    commonService.withOutTokenAndBaseUrl(apiName, eventData).then(
      providerSeeker => {
        dispatch(commonAction.success(providerConstants.ADD_PROVIDER_SEEKER_SUCCESS, providerSeeker));
      },
      error => {
        dispatch(commonAction.failure(providerConstants.ADD_PROVIDER_SEEKER_FAILURE, error.response.data.error))
      }
    )
  }
}

function getProviderSeekerById(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(providerConstants.GET_PROVIDER_SEEKER_BY_ID_REQUEST, eventData));
    commonService.withOutTokenAndBaseUrl(apiName, eventData).then(
      providerSeekerById => {
        dispatch(commonAction.success(providerConstants.GET_PROVIDER_SEEKER_BY_ID_SUCCESS, providerSeekerById));
      },
      error => {
        dispatch(commonAction.failure(providerConstants.GET_PROVIDER_SEEKER_BY_ID_FAILURE, error))
      }
    )
  }
}