import React, {  useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import InputMask from "react-input-mask";
import Button from '@material-ui/core/Button';
import {  useDispatch } from 'react-redux';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { bookingActions } from '../../_actions';
import { apiConstants } from '../../_constants/api.constants';
// import { history } from '../../_helpers';
// import TextField from '@material-ui/core/TextField';

export default function BookingPayment({ backButton, data }) {
  const dispatch = useDispatch();
  const [paymentData, setPaymentData] = useState({})
  const [expireDate, setExpireDate] = useState('')
  const [paymentType, setPaymentType] = useState('credit_card')

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentData({ ...paymentData, [name]: value })
  }

  const handleExprieDate = (e) => {
    let date = e.target.value
    setExpireDate(date)
  }

  const handleTabChange = (e) => {
    setPaymentType(e)
  }

  const handleSubmit = () => {
    let dateExplor = expireDate.split('/')
    let newData  = {}
    const formData = new FormData()
    if(paymentType === 'credit_card'){
      //  newData ={
      //   ...paymentData,
      //   seeker_id: data.seeker_id,
      //   company_id: data.company_id,
      //   sch_exp_id: data.experienceData.id,
      //   location_id: data.experienceData.location_id,
      //   experience_name: data.experienceData.experience_name,
      //   experience_duration:data.experience_name,
      //   days_time: data.experienceData.days_time,
      //   special_request: data.data.special_request,
      //   supporting_document: "payFormdata.pdf",
      //   team_size: data.data.team_size,
      //   charge_type: data.experienceData.charge_type,
      //   amount: data.experienceData.amount,
      //   payment_type: paymentType,
      //   exp_month:dateExplor[0],
      //   exp_year:dateExplor[1],
      //   created_by_admin: data.created_by_admin
      //   }
      formData.append('company_id', data.company_id)
      formData.append('seeker_id', data.seeker_id)
      formData.append('sch_exp_id', data.experienceData.id)
      formData.append('location_id', data.experienceData.location_id)
      formData.append('created_by_admin', data.created_by_admin)
      formData.append('team_size', parseInt(data.data.team_size))
      formData.append('experience_name', data.experienceData.experience_name)
      formData.append('experience_duration', data.experience_name)
      formData.append('days_time', data.experienceData.days_time)
      formData.append('special_request', data.data.special_request)
      formData.append('supporting_document', data.file)
      formData.append('charge_type', data.experienceData.charge_type)
      formData.append('amount', data.experienceData.amount)
      formData.append('created_by', parseInt(data.experienceData.provider_id))
      formData.append('payment_type', 'credit_card')
      formData.append('exp_month', dateExplor[0])
      formData.append('exp_year', dateExplor[1])
      for (const key in paymentData) {
        formData.append(key, paymentData[key])
      }
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ', ' + pair[1]);
      // }
    }else if(paymentType === 'via_invoice'){
      //  newData ={
      //   ...paymentData,
      //   seeker_id: data.seeker_id,
      //   company_id: data.company_id,
      //   sch_exp_id: data.experienceData.id,
      //   location_id: data.experienceData.location_id,

      //   experience_name: data.experienceData.experience_name,
      //   experience_duration:data.experience_name,
      //   days_time: data.experienceData.days_time,
      //   special_request: data.data.special_request,
      //   supporting_document: "payFormdata.pdf",
      //   team_size: data.data.team_size,
      //   charge_type: data.experienceData.charge_type,
      //   amount: data.experienceData.amount,
      //   payment_type: paymentType,
      //   created_by_admin: data.created_by_admin
      //   }
        
        formData.append('company_id', data.company_id)
        formData.append('seeker_id', data.seeker_id)
        formData.append('sch_exp_id', data.experienceData.id)
        formData.append('location_id', data.experienceData.location_id)
        
        formData.append('team_size', parseInt(data.data.team_size))
        formData.append('experience_name', data.experienceData.experience_name)
        formData.append('experience_duration', data.experience_name)
        formData.append('days_time', data.experienceData.days_time)
        formData.append('special_request', data.data.special_request)
        formData.append('supporting_document', data.file)
        formData.append('charge_type', data.experienceData.charge_type)
        formData.append('amount', data.experienceData.amount)
        formData.append('payment_type', paymentType)
        formData.append('created_by', parseInt(data.experienceData.provider_id))
        formData.append('created_by_admin', data.created_by_admin)
        for (const key in paymentData) {
          formData.append(key, paymentData[key])
        }
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ', ' + pair[1]);
        // }
    }
    dispatch(bookingActions.addBooking(apiConstants.ADD_BOOKINGS, formData))
    // window.location.href = '/booking'
    // history.push('/booking')

  }

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Booking Payment</h1>
        <a href="#" onClick={backButton} className="btn btn-secondary btn-icon-split">
          <span className="icon text-white-50">
            <i className="fas fa-arrow-left"></i>
          </span>
          <span className="text">Back</span>
        </a>
      </div>
      <div className="card shadow">
        <div className="card-body">
          <div className="table-responsive">
            <div className="row">
              <div className="card shadow col-md-4">
                {/* <div className="col-md-4 col-xs-4"> */}
                {/* <img className="card-img-top" alt="Card image cap" id="uploadFile" src={data.previewImageUrl} /> */}
                {/* <div className="col-md-12"> */}
                  <div className="form-group">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          margin="normal"
                          id="time-picker"
                          name="start_time"
                          label="Start Date"
                          defaultValue={data.start_time}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                          disabled
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </div>
                {/* </div> */}
                  <div className="form-group">
                    <label>Staff</label>
                    <input type="number" className="form-control form-control-user" name="team_size" value={data.data.team_size}
                      placeholder="Team Size" disabled />
                  </div>
                  <div className="form-group">
                    <label>Special Request</label>
                    <input type="text" className="form-control form-control-user" name="team_size"
                      placeholder="Special Request" value={data.data.special_request} disabled/>
                  </div>
              </div>

              <div className="container card shadow col-md-8">
                {/* <div className="container"> */}
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" href="#credit_card" role="tab" data-toggle="tab" value="credit_card" onClick={() => handleTabChange('credit_card')}>Credit Card</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#invoice" role="tab" data-toggle="tab" value="via_invoice" onClick={() => handleTabChange('via_invoice')}>VIA INVOICE</a>
                  </li>
                </ul>
                <div className="tab-content">
                <div role="tabpanel" className="tab-pane in active" id="credit_card">
                <div className="row">
                      <div className="col-md-12 col-xs-12">
                        <div className="form-group">
                          <p>Invoice will be sent via email.</p>
                        </div>
                      </div>
                      <div className="col-md-12 col-xs-6">
                        <div className="form-group">
                          <h6>Billing Info</h6>
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>Full Name</label>
                          <input type="text" className="form-control form-control-user" name="full_name"
                            placeholder="Full Name" onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>POST CODE</label>
                          <input type="text" className="form-control form-control-user" name="post_code"
                            placeholder="POST CODE" onChange={handleChange}/>
                        </div>
                      </div>

                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>ADDRESS 1</label>
                          <input type="text" className="form-control form-control-user" name="address1"
                            placeholder="ADDRESS 1" onChange={handleChange}/>
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>CITY</label>
                          <input type="text" className="form-control form-control-user" name="city"
                            placeholder="CITY" onChange={handleChange}/>
                        </div>
                      </div>

                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>ADDRESS 2</label>
                          <input type="text" className="form-control form-control-user" name="address2"
                            placeholder="ADDRESS 2" onChange={handleChange}/>
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>COUNTRY</label>
                          <input type="text" className="form-control form-control-user" name="country"
                            placeholder="COUNTRY" onChange={handleChange}/>
                        </div>
                      </div>
                      <div className="col-md-12 col-xs-12">
                        <div className="form-group">
                          <h5>Card Info</h5>
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <div className="form-group">
                          <InputMask
                            mask="9999 9999 9999 9999"
                            name="card_number"
                            // value='2541525585745252'
                            className="form-control"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-xs-6">
                        <div className="form-group">
                          <InputMask
                            mask="99/99"
                            placeholder="expire date"
                            name="exp_date"
                            // value='2541525585745252'
                            onChange={handleExprieDate}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-xs-6">
                        <div className="form-group">
                          <InputMask
                            mask="999"
                            // value='254'
                            name="cvv"
                            placeholder='CVV'
                            className="form-control"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 col-xs-12">
                        <div className="form-group">
                          <Button variant="contained" color="primary" onClick={handleSubmit}>Pay Now</Button>
                        </div>
                      </div>

                    </div>
                </div>
                <div role="tabpanel" className="tab-pane fade" id="invoice">
                <div className="row">
                      <div className="col-md-12 col-xs-12">
                        <div className="form-group">
                          <p>Invoice will be sent via email.</p>
                        </div>
                      </div>
                      <div className="col-md-12 col-xs-6">
                        <div className="form-group">
                          <h6>Billing Info</h6>
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>Company Name</label>
                          <input type="text" className="form-control form-control-user" name="company_name"
                            placeholder="Company Name" onChange={handleChange}/>
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>Email ID</label>
                          <input type="text" className="form-control form-control-user" name="email_id"
                            placeholder="Email ID" onChange={handleChange}/>
                        </div>
                      </div>

                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>ADDRESS 1</label>
                          <input type="text" className="form-control form-control-user" name="address1"
                            placeholder="ADDRESS 1" onChange={handleChange}/>
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>CITY</label>
                          <input type="text" className="form-control form-control-user" name="city"
                            placeholder="CITY" onChange={handleChange}/>
                        </div>
                      </div>

                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>ADDRESS 2</label>
                          <input type="text" className="form-control form-control-user" name="address2"
                            placeholder="ADDRESS 2" onChange={handleChange}/>
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>COUNTRY</label>
                          <input type="text" className="form-control form-control-user" name="country"
                            placeholder="COUNTRY" onChange={handleChange}/>
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>Post Code</label>
                          <input type="text" className="form-control form-control-user" name="post_code"
                            placeholder="Post Code" onChange={handleChange}/>
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>ABN/ACN</label>
                          <input type="text" className="form-control form-control-user" name="abn_acn"
                            placeholder="ABN/ACN" onChange={handleChange}/>
                        </div>
                      </div>

                      <div className="col-md-6 col-xs-12">
                        <div className="form-group">
                          <label>Contact Name</label>
                          <input type="text" className="form-control form-control-user" name="contact_name"
                            placeholder="Contact Name" onChange={handleChange}/>
                        </div>
                      </div>
                      <div className="col-md-12 col-xs-12">
                        <div className="form-group">
                          <Button variant="contained" color="primary" onClick={handleSubmit}>Request Invoice</Button>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
                {/* <ul className="nav nav-tabs">
                  <li className="nav-item active"><a data-toggle="tab" href="#home">Credit Card</a></li>
                  <li className="nav-item"><a data-toggle="tab" href="#menu1">VIA INVOICE</a></li>
                </ul> */}
                {/* <div className="tab-content">
                  <div id="home" className="tab-pane fade in active">
                    
                  </div>
                  <div id="menu1" className="tab-pane fade">
                    
                  </div>
                </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
