import { shortlistConstants } from '../_constants/shortlist.constants';
import * as commonAction from './commonAction';
import {  commonService } from '../_services';

export const shortlistActions = {
    getShortlist,
    addShortlist,
    getShortlistById,
    deleteShortlist,
};


//-- Location list
function getShortlistById(apiName, id) {

    return dispatch => {
        dispatch(commonAction.request(shortlistConstants.GET_SHORTLIST_BY_ID_REQUEST, id));
        commonService.withOutTokenAndBaseUrl(apiName, id)
            .then(
                shortlist => {
                    dispatch(commonAction.success(shortlistConstants.GET_SHORTLIST_BY_ID_SUCCESS, shortlist.data));
                  },
                  error => {
                    dispatch(commonAction.failure(shortlistConstants.GET_SHORTLIST_BY_ID_FAILURE, error.response.data.error));
                  }
            );
    };
}

//-- Feedback list
function getShortlist(apiName,data) {

    return dispatch => {
        dispatch(commonAction.request(shortlistConstants.GET_ALL_SHORTLIST_REQUEST));
        commonService.withOutTokenAndBaseUrl(apiName,data)
            .then(
                shortlist => {
                    dispatch(commonAction.success(shortlistConstants.GET_ALL_SHORTLIST_SUCCESS, shortlist.data));
                  },
                  error => {
                    dispatch(commonAction.failure(shortlistConstants.GET_ALL_SHORTLIST_FAILURE, error.response.data.error));
                  }
            );
    };
}

function addShortlist(apiName, data) {
    return dispatch => {
        dispatch(commonAction.request(shortlistConstants.ADD_SHORTLIST_REQUEST, data));
        commonService.withOutTokenAndBaseUrl(apiName, data)
            .then(
                shortlist => {
                    dispatch(commonAction.success(shortlistConstants.ADD_SHORTLIST_SUCCESS, shortlist.data));
                  },
                  error => {
                    dispatch(commonAction.failure(shortlistConstants.ADD_SHORTLIST_FAILURE, error.response.data.error));
                  }
            );
    };
}
function deleteShortlist(apiName, data) {

    return dispatch => {
        dispatch(commonAction.request(shortlistConstants.DELETE_SHORTLIST_REQUEST, data));
        commonService.withToken(apiName, data)
            .then(
                shortlist => {
                    dispatch(commonAction.success(shortlistConstants.DELETE_SHORTLIST_SUCCESS, shortlist.data));
                  },
                  error => {
                    dispatch(commonAction.failure(shortlistConstants.DELETE_SHORTLIST_FAILURE, error.response.data.error));
                  }
            );
    };
}


