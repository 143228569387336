import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TopBar from '../Common/TopBar/TopBar';
import SideBar from '../Common/SideBar/SideBar';
import Footer from '../Common/Footer/Footer';
import ExperienceDetails from './ExperienceDetails';
import { CSVLink } from 'react-csv';
import PageLimit from '../Common/PageLimit/PageLimit';
import AddExperience from './AddExperience';
import { Badge } from 'antd';
// import { Switch } from 'antd';
import Toggle from 'react-toggle';
import 'antd/dist/antd.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Box, CircularProgress } from '@material-ui/core';
import Pagination from '../Common/Pagination/Pagination';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { experienceActions } from '../../_actions';
import { apiConstants } from '../../_constants/api.constants';
import swal from 'sweetalert';
import Chip from '@material-ui/core/Chip';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import useDebounce from '../../hooks/useDebounce';

export default function Experience() {
  const [experienceDetails, setExperienceDetails] = useState(false);
  const [experienceAdd, setExperienceAdd] = useState(false);
  const [savExperience, setSaveExperience] = useState({});
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [checked, setChecked] = useState(false);
  const [experience, setExperience] = useState([]);
  const [sortFlag, setSortFlag] = useState(true);
  const [sortByName, setSortByName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteDetail, setDeleteDetail] = useState('');
  const [search, setSearch] = useState('');
  const [dbSearch, setDbSearch] = useState('');
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
  const [filteredExperience, setFilteredExperience] = useState([]);
  const [NewOffset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const experienceData = useSelector((state) => state.getExperienceList);
  const unpublishexperienceData = useSelector(
    (state) => state.unpublishExperience,
  );
  const deleteExperienceData = useSelector((state) => state.deleteExperience);
  const location = useLocation();
  const debouncedSearchValue = useDebounce(dbSearch, 1000);
  const [isSorted, setIsSorted] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const getAllExperienceDetails = () => {
    let obj = {
      limit: 25,
      offset: 0,
    };
    dispatch(
      experienceActions.getExperienceList(
        apiConstants.GET_ALL_EXPERIENCE_DETAILS,
        obj,
      ),
    );
  };

  const searchExperience = (search_value) => {
    let obj = {
      searchQuery: search_value,
      limit: limit,
      offset: 0,
    };
    dispatch(
      experienceActions.getExperienceList(apiConstants.SEARCH_EXPERIENCE, obj),
    );
  };

  const makeHttpRequestWithPage = (pageNumber) => {
    let obj = {
      limit: limit,
      offset: limit * (pageNumber - 1),
    };
    setOffset(obj.offset);
    setCurrentPage(pageNumber);

    if (dbSearch && dbSearch.length) {
      obj['searchQuery'] = dbSearch;
      return dispatch(
        experienceActions.getExperienceList(
          apiConstants.SEARCH_EXPERIENCE,
          obj,
        ),
      );
    }

    if (sortByName) {
      obj['sortBy'] = sortByName;
      obj['sortByStatus'] = sortFlag;
      return dispatch(
        experienceActions.getExperienceList(apiConstants.SORT_EXPERIENCE, obj),
      );
    }

    dispatch(
      experienceActions.getExperienceList(
        apiConstants.GET_ALL_EXPERIENCE_DETAILS,
        obj,
      ),
    );
  };

  const changeTableData = (e) => {
    setLimit(e.target.value);
    let obj = {
      limit: parseInt(e.target.value),
      offset: 0,
    };
    dispatch(
      experienceActions.getExperienceList(
        apiConstants.GET_ALL_EXPERIENCE_DETAILS,
        obj,
      ),
    );
  };

  useEffect(() => {
    if (experienceData?.getExperienceList?.data) {
      setExperience(experienceData.getExperienceList.data);
    }
  }, [experienceData?.getExperienceList]);

  useEffect(() => {
    setLoading(experienceData?.loading);
  }, [experienceData?.loading]);

  useEffect(() => {
    setFilteredExperience(
      experience.filter((data) =>
        data.experience_name.toLowerCase().includes(search.toLowerCase()),
      ),
    );
  }, [search, experience]);

  useEffect(() => {
    if (isSorted) handleSort();
  }, [sortByName, sortFlag]);

  useEffect(() => {
    if (deleteExperienceData?.deleteExperience?.error) {
      return swal(
        'Oops!',
        deleteExperienceData.deleteExperience.message,
        'error',
      ).then((result) => {
        setDeletePopUp(false);
      });
    }
    if (deleteExperienceData?.deleteExperience?.message) {
      setDeletePopUp(false);
      swal(
        'Delete!',
        deleteExperienceData?.deleteExperience?.message,
        'success',
      ).then((result) => {
        getAllExperienceDetails();
      });
    }
  }, [deleteExperienceData]);

  useEffect(() => {
    if (isFiltered) searchExperience(dbSearch);
  }, [debouncedSearchValue]);

  useEffect(() => {
    getAllExperienceDetails();
  }, [location]);

  const openAddExperiencerDialog = () => {
    setExperienceAdd(true);
  };

  const closeAddExperienceDialog = () => {
    setExperienceAdd(false);
  };

  const experienceDetail = (experience) => {
    setExperienceDetails(true);
    setSaveExperience(experience);
  };

  const backButton = () => {
    setExperienceDetails(false);
  };

  const closeDeletePopup = () => {
    setDeletePopUp(false);
  };

  const deleteExperience = (id) => {
    setDeletePopUp(true);
    setDeleteDetail(id);
  };

  // const headers = [
  //   { label: "ID", key: "id" },
  //   { label: "Provider Id", key: "provider_id" },
  //   { label: "Location Id", key: "location_id" },
  //   { label: "Location Name", key: "location_name" },
  //   { label: "Rating", key: "rating" },
  //   { label: "Experience Name", key: "experience_name" },
  //   { label: "Team Size", key: "team_size" },
  //   { label: "Experience duration", key: "experience_duration" },
  //   // { label: "Days time", key: "days_time" },
  //   { label: "Charge Type", key: "charge_type" },
  //   { label: "Amount", key: "amount" },
  //   { label: "Highlights", key: "highlights" },
  //   { label: "Environmental Causes", key: "environmental_causes" },
  //   { label: "Image", key: "images" },
  //   { label: "Video Title", key: "video_title" },
  //   { label: "Video Url", key: "video_url" },
  //   { label: "Tag Line", key: "tag_line" },
  //   { label: "Detail Descriptione", key: "detail_description" },
  //   { label: "Further Information", key: "further_information" },
  //   { label: "Whats Include", key: "whats_include" },
  //   { label: "Per person cost", key: "per_person_cost" },
  //   { label: "Draft", key: "is_draft" },
  //   { label: "Publish", key: "is_publish" },
  //   { label: "Delete", key: "is_deleted" },
  //   { label: "Slug", key: "slug" }
  // ];
  const headers = [
    { label: 'first_name', key: 'Provider.first_name' },
    { label: 'last_name', key: 'Provider.last_name' },
    { label: 'phone', key: 'Provider.phone' },
    { label: 'email', key: 'Provider.email' },
    { label: 'Experience Name', key: 'experience_name' },
    { label: 'Amount', key: 'amount' },
    { label: 'Per person cost', key: 'per_person_cost' },
    { label: 'Experience duration', key: 'experience_duration' },
    { label: 'Location Address', key: 'location_address' },
    { label: 'Location Name', key: 'location_name' },
    { label: 'Slug', key: 'slug' },
    { label: 'Team Size', key: 'team_size' },
    { label: 'Tag Line', key: 'tag_line' },
    { label: 'Video Title', key: 'video_title' },
    { label: 'Video Url', key: 'video_url' },
    { label: 'Whats Include', key: 'whats_include' },
    { label: 'Published', key: 'is_publish' },
    { label: 'Draft', key: 'is_draft' },
  ];

  const handleSort = () => {
    let obj = {
      limit: limit,
      offset: 0,
      sortBy: sortByName,
      sortByStatus: sortFlag,
    };
    dispatch(
      experienceActions.getExperienceList(apiConstants.SORT_EXPERIENCE, obj),
    );
  };

  const handleDelete = () => {
    dispatch(
      experienceActions.deleteExperience(apiConstants.DELETE_EXPERIENCE, {
        id: deleteDetail,
      }),
    );
  };

  const handleChange = (e, id) => {
    let formData = new FormData();
    formData.append('experience_id', id);
    formData.append('is_publish', 1);
    formData.append('is_draft', 0);
    if (e.target.checked) {
      dispatch(
        experienceActions.editExperience(
          apiConstants.EDIT_EXPERIENCE,
          formData,
        ),
      );
    } else {
      dispatch(
        experienceActions.unpublishExperience(
          apiConstants.UNPUBLISH_EXPERIENCE,
          { id: id },
        ),
      );
    }
  };

  if (
    unpublishexperienceData &&
    unpublishexperienceData.unpublishExperience &&
    unpublishexperienceData.unpublishExperience.error
  ) {
    swal(
      'Oops!',
      unpublishexperienceData.unpublishExperience.message,
      'error',
    ).then((result) => {
      window.location.reload();
    });
  } else if (
    unpublishexperienceData &&
    unpublishexperienceData.unpublishExperience &&
    !unpublishexperienceData.unpublishExperience.error
  ) {
    swal(
      'Unpublish',
      unpublishexperienceData.unpublishExperience.message,
      'success',
    ).then((result) => {
      // window.location.reload()
    });
  }

  return (
    <div id='wrapper'>
      <SideBar />
      <div id='content-wrapper' className='d-flex flex-column'>
        <div id='content'>
          <TopBar />
          <nav className='' aria-label='breadcrumb'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <a href='/dashboard'>Dashboard</a>
              </li>
              <li className='active breadcrumb-item' aria-current='page'>
                Experience
              </li>
            </ol>
          </nav>
          {!experienceDetails ? (
            <div className='container-fluid'>
              <div className='d-sm-flex align-items-center justify-content-between mb-4'>
                <h1 className='h3 mb-2 text-gray-800'>Experience</h1>
                <div className='d-flex justify-content-center align-items-centers'>
                  <input
                    type='search'
                    className='form-control form-control-sm'
                    placeholder='Database Search'
                    onChange={(e) => {
                      setIsFiltered(true);
                      setDbSearch(e.target.value);
                    }}
                    aria-controls='dataTable'
                  />
                </div>
                <div className='d-sm-flex align-items-center justify-content-between'>
                  {/* <CSVLink className="btn btn-primary mr-3" data={experience.map(Object.values)} headers={headers.map(Object.values)}>Export</CSVLink> */}
                  <CSVLink
                    className='btn btn-primary mr-3'
                    data={experience || []}
                    headers={headers}
                  >
                    Export
                  </CSVLink>
                  <a href='#!'>
                    <span className='text'>
                      <button
                        onClick={openAddExperiencerDialog}
                        className='btn btn-primary'
                      >
                        Add New Experience
                      </button>
                    </span>
                  </a>
                </div>
              </div>
              <div className='card shadow mb-4'>
                <div className='card-body'>
                  <div className='table-responsive'>
                    <div className='row'>
                      {/* Page limit drop down */}
                      {/* <PageLimit funChangePageLimit={changeTableData} /> */}

                      <div className='col-sm-12 col-md-6'>
                        <div id='' className=''>
                          <label>
                            <input
                              type='search'
                              className='form-control form-control-sm'
                              placeholder='Table Search'
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                              aria-controls='dataTable'
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <table
                      className='table table-bordered'
                      width='100%'
                      cellSpacing='0'
                    >
                      <thead>
                        <tr>
                          <th scope='col'>No.</th>
                          <th scope='col'>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: '100%',
                              }}
                            >
                              <span>Experience Name</span>
                              <img
                                src='assets/img/svg/sort.png'
                                alt=''
                                name='experience_name'
                                className='imageSort'
                                onClick={(e) => {
                                  setIsSorted(true);
                                  setSortByName(e.target.name);
                                  setSortFlag((prevFlag) => !prevFlag);
                                }}
                              />
                            </div>
                          </th>
                          <th scope='col'>Provider Email</th>
                          <th scope='col'>Activity Types</th>
                          <th scope='col'>Cause Preferences</th>
                          <th scope='col'>Source Type</th>
                          {/* <th scope="col">Video Url</th> */}
                          <th scope='col'>Status</th>
                          {/* <th scope="col">Publish/Unpublish</th> */}
                          <th scope='col'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <div className=' width-100 d-flex justify-content-center align-items-center'>
                        <Box className='d-flex justify-content-center'>
                          <CircularProgress />
                        </Box>
                      </div> */}
                        {loading ? (
                          <>
                            <td className='' colSpan={'100%'}>
                              <div className='col-md-12 justify-content-center align-items-center'>
                                <Box className='d-flex justify-content-center'>
                                  <CircularProgress />
                                </Box>
                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                            {filteredExperience !== undefined &&
                            filteredExperience.length > 0
                              ? filteredExperience.map((experience, index) => {
                                  var count = 0;
                                  //  var pageData = config.perPageData;
                                  count = index + NewOffset + 1;
                                  return (
                                    <tr key={index}>
                                      <td scope='row'>{count}</td>
                                      <td>{experience.experience_name}</td>
                                      <td>{experience.Provider?.email}</td>
                                      <td
                                        style={{
                                          width: '10%',
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                            flexWrap: 'wrap',
                                            columnGap: '4px',
                                            rowGap: '8px',
                                          }}
                                        >
                                          {experience?.ActivityTypes?.map(
                                            (val) => (
                                              <Chip
                                                label={val.activity_type}
                                                variant='outlined'
                                              />
                                            ),
                                          )}
                                        </div>
                                      </td>
                                      <td
                                        style={{
                                          width: '10%',
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                            flexWrap: 'wrap',
                                            columnGap: '4px',
                                            rowGap: '8px',
                                          }}
                                        >
                                          {experience?.EnvironmentalCauses?.map(
                                            (val) => (
                                              <Chip
                                                label={val.cause_name}
                                                variant='outlined'
                                                style={{
                                                  flexGrow: 0,
                                                  flexShrink: 0,
                                                  flexBasis: '50%',
                                                }}
                                              />
                                            ),
                                          )}
                                        </div>
                                      </td>

                                      <td>
                                        {experience.crm === 'Rezdy' ? (
                                          <div
                                            style={{
                                              display: 'flex',
                                              gap: '5px',
                                            }}
                                          >
                                            <span>{experience.crm}</span>
                                            <div
                                              style={{
                                                cursor: 'pointer',
                                              }}
                                            >
                                              <a
                                                href={
                                                  experience?.product_code
                                                    ? `${process.env.REACT_APP_REZDY_IFRAME_BOOKING_URL}/${experience?.product_code}`
                                                    : '#'
                                                }
                                                className='button-booking rezdy rezdy-modal'
                                              >
                                                View
                                              </a>
                                            </div>
                                          </div>
                                        ) : (
                                          <>{experience.crm}</>
                                        )}
                                      </td>
                                      {/* <td>{experience.video_url}</td> */}
                                      <td>
                                        {/* <Switch size="small" defaultChecked /> */}
                                        {/* <Badge className="ml-3" status="success" text="Active" /> */}
                                        {experience.is_draft === 'No' ||
                                        experience.is_draft === 0 ? (
                                          <Badge
                                            className='ml-3'
                                            status='success'
                                            text='Published'
                                          />
                                        ) : (
                                          <Badge
                                            className='ml-3'
                                            status='error'
                                            text='Draft'
                                          />
                                        )}
                                        {/* <Badge status="success" text="Active" /> */}
                                      </td>
                                      {/* <td>
                                    <Toggle
                                              defaultChecked={(experience.is_publish === "No") ? 0 : 1}
                                              onChange={(e)=>handleChange(e, experience.id, experience.is_publish)} 
                                              /> */}
                                      {/* {experience.is_deleted == 0 ? <Badge className="ml-3" status="success" text="Active" /> : <Badge className="ml-3" status="error" text="In Active" />} */}
                                      {/* </td> */}
                                      <td>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                            height: '100%',
                                          }}
                                        >
                                          <a
                                            className='actionIcon'
                                            title='View'
                                            href='#!'
                                            onClick={() =>
                                              experienceDetail(experience)
                                            }
                                          >
                                            <span>
                                              <img
                                                src='assets/img/svg/eye-icon.png'
                                                alt='View'
                                              />
                                            </span>
                                          </a>
                                          {/* <a className="actionIcon" title="View" href="#!" onClick={() => this.popupOpen(users, 'viewOpen')}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a> */}
                                          {user.data.role == 'super admin' ? (
                                            <>
                                              {' '}
                                              <Link
                                                className='actionIcon'
                                                title='Edit'
                                                to={`/update-experience/${experience.id}`}
                                              >
                                                <span>
                                                  <img
                                                    src='assets/img/svg/EditIcon.png'
                                                    alt='Edit'
                                                  />
                                                </span>
                                              </Link>
                                              <div
                                                className='actionIcon'
                                                style={{
                                                  cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                  deleteExperience(
                                                    experience.id,
                                                  )
                                                }
                                              >
                                                <span>
                                                  <img
                                                    src='assets/img/svg/Bin.png'
                                                    alt='Delete'
                                                  />
                                                </span>
                                              </div>
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              : null}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* calling the another componenet */}
                  <Pagination
                    totalPage={
                      experienceData && experienceData.getExperienceList
                        ? experienceData.getExperienceList.pages
                        : 0
                    }
                    currentPage={currentPage}
                    funPagination={makeHttpRequestWithPage}
                  />
                </div>
              </div>
            </div>
          ) : (
            <ExperienceDetails data={savExperience} backButton={backButton} />
          )}
        </div>

        {/* add experience popup */}
        {experienceAdd === true ? (
          <AddExperience
            displayPopUp={experienceAdd}
            hidePopUp={closeAddExperienceDialog}
          />
        ) : (
          ''
        )}

        <Dialog
          open={deletePopUp}
          fullWidth={true}
          aria-labelledby='delete-product-notify'
        >
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            Are you sure?
            <h5 className='mt-3'>You want to delete experience</h5>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='secondary'
              onClick={handleDelete}
            >
              Delete
            </Button>
            <Button variant='contained' onClick={closeDeletePopup}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Footer />
      </div>
    </div>
  );
}
