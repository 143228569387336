import { commonService } from '../_services';
import { history } from '../_helpers';
import { apiConstants } from '../_constants/api.constants';
import * as commonAction from './commonAction';
import { experienceActivityTypeConstants } from '../_constants';

export const experienceActivityTypeActions = {
    getExperienceActivityTypeById,
};

function getExperienceActivityTypeById(apiName, experienceActivityTypeData) {
    return dispatch => {
        dispatch(commonAction.request(experienceActivityTypeConstants.GET_EXPERIENCE_ACTIVITY_TYPE_BY_ID_REQUEST, experienceActivityTypeData));
        commonService.withTokenAndBashUrl(apiName, experienceActivityTypeData)
            .then(
                activityType => {
                    dispatch(commonAction.success(experienceActivityTypeConstants.GET_EXPERIENCE_ACTIVITY_TYPE_BY_ID_SUCCESS, activityType.data));
                },
                error => {
                    dispatch(commonAction.failure(experienceActivityTypeConstants.GET_EXPERIENCE_ACTIVITY_TYPE_BY_ID_FAILURE, error.response.data.error));
                }
            );
    };
}