//-- React Standard
import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
//import compose from 'recompose/compose';
import { withStyles } from "@material-ui/core/styles";
import { apiConstants } from '../../_constants/api.constants';
import {  userActions } from "../../_actions";
import { commonService } from "../../_services";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
//import loader from '../../../assets/img/Ring-Loading.gif';

//-- CSS for Material
const styles = theme => ({    
    error: {
        color: 'red',
      },
      loaderImg:{
        margin: '0 auto',
        display:  'block',
        width: '250px'
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: "#269abc",
        color:'#fff'
      },
      pageImg: {
          width: '70px',
          height: '70px',
          position: 'relative',
          padding:'10px'
      },
      removeImg:{
          position: 'absolute',
          top:'-7px',
          right:'-7px',
          padding: '0px 7px 0px 7px',
          border: '1px solid #a09d9d',
          background: '#c1c1c1',
          borderRadius: '50%',
          fontWeight: 'bold',
          cursor: 'pointer',
          color: '#fff'
      },
      borderedImg: {
        position: 'relative',
        width: '70px',
        height: '70px',
        border: '1px #dad7d7 solid',
        borderRadius:'10px',
        float: 'left',
        marginBottom: '15px',
        marginRight: '15px'
      }

  });

class EditEvents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sort: {
                sortKey: null,
                direction: 'desc',
            },
            totalPage: '',
            totalDataCount: '',
            currentPage: 1,
            getCouponList: [],                    
            deleteOpen: false,
            allCategories:[],
            addCat:false,
            soldIndChecked : false,
            
        };
    }

    /*
    * componentDidMount - Initially it will call and get the advertisments data
    */
    componentDidMount() {
        const { dispatch, editProductId } = this.props;
        let postData = {};
        postData.page = 1;
        postData.orderBy = "id";
        postData.orderByAscDesc = "ASC";
        postData.perPage=10;

        const getproduct = {}
        getproduct.productId = editProductId;


       // dispatch(productActions.getSingleProduct(apiConstants.GET_SINGLE_PRODUCT_FOR_SHOPKEEPER, getproduct));
       // dispatch(categoryActions.getAllCategory(apiConstants.GET_ALL_ACTIVE_CATEGORIES));
        dispatch(userActions.getCoupounList(apiConstants.GET_ACTIVE_COUPOUN ));
    }

    /*
    * componentWillReceiveProps - Whenever Props change, it will call and store data
    * update the state values with new props values, this method will get called whenever any change happens to props values
    */
    componentWillReceiveProps(props) { 
        const { dispatch } = props;        
        // GET LIST OF ACTIVE CATEGORIES        
        if ( props.getCategories !== undefined && 
             props.getCategories.getCatList !== undefined && 
             props.getCategories.getCatList.data !== undefined &&
             props.getCategories.getCatList.data.type === "get_active_categories"
            ) {
                
                const catDataReceived = props.getCategories.getCatList.data;
                    this.setState({ 
                        allCategories : catDataReceived,                        
                    })                
            }

            // GET LIST OF ACTIVE COUPONS
            if (props.getCoupounList) {
                if (props.getCoupounList.getCoupounList) {
                    const catRecvData =props.getCoupounList.getCoupounList;
                    if (
                        catRecvData.data.data &&
                        catRecvData.data.type === "get_active_coupon"
                       ) {
                            this.setState({ getCouponList: catRecvData.data })
                          }
                }
            }          

            //get product details to edit
            if(props.getSingleProduct !== undefined) {                
                if(
                    props.getSingleProduct.Product !== undefined &&
                    props.getSingleProduct.Product.data !== undefined &&
                    props.getSingleProduct.Product.data.type === "get-single-product"
                ) {
                    const proData = props.getSingleProduct.Product.data.productData[0];
                    let selectedcatId = '';
                    let selectedCatTitle = '';
                    if(proData.categoryData !==undefined &&
                       proData.categoryData.length > 0 ) {
                        selectedcatId = proData.categoryData[0]._id;
                        selectedCatTitle = proData.categoryData[0].categoryTitle;
                       }
                    this.setState({ 
                        editingProdId : proData._id,
                        productTitle : proData.title,
                        productDescription: proData.description,
                        productRegularPrice: proData.regularPrice,
                        productSalePrice: proData.salePrice,
                        productMainImage_get: proData.mainImage,
                        productImageGallery_get: proData.imageGallery,
                        productCategories: selectedcatId,                        
                        productStock: proData.inStock,
                        productSku: proData.sku,
                        productSoldIndividually: proData.productSoldIndividually,
                    })
                }
            }

            if( props.updateSingleProduct!==undefined ){
                if( props.updateSingleProduct.UpdatedProduct !== undefined &&
                    props.updateSingleProduct.UpdatedProduct.data !== undefined &&
                    props.updateSingleProduct.UpdatedProduct.data.type === 'product-updated' ) {


                        if(this.state.notifyAddProduct===true) {
                            this.setState({
                                notifyAddProduct : false,                          
                                productTitle : '',
                                productDescription: '',
                                productRegularPrice: '',
                                productSalePrice: '',
                                productMainImage: '',
                                productImageGallery: '',
                                productCategories: '',
                                productCoupons: '',
                                productStock: '',
                                productSku: '',
                                productSoldIndividually: ''
                              });
                              props.closeEditProductPop(1);
                          }
                    }

            }
    }   

    handleChangeMainImage = (e) => {
        const files = e.target.files
        let i=0;
        let oddCntr = 0;
        let multiImageArray = [];
        var extArr = ["image/jpeg", "image/jpg", "image/png"];

        if ( files.length > 1){
            for(i=0; i< files.length; i++ ) {
                //var filename = files[0].name                
                if(extArr.indexOf(files[i].type) > -1){}
                else{oddCntr++;}
                multiImageArray[i] = files[i];
            }
            if(oddCntr > 0){ 
                let label = [e.target.name]+'_error';
                this.setState({ [label] : "One of the file types not suported" }) 
                e.target.value = null;
                this.setState({ [e.target.name] :  '' })
            } else{
                this.setState({ [e.target.name] :  e.target.files })
            }
        } else {
            if(extArr.indexOf(files[0].type) > -1){
                this.setState({ [e.target.name] : files[0] })
            } else { 
                let label = [e.target.name]+'_error';
                this.setState({ [label] : "One of the file type not suported" }) 
                e.target.value = null;
                this.setState({ [e.target.name] :  '' })
            }
        }
    }

    handleChangeCheckbox = (e) => {
        let checked = 0;
        if(this.state.soldIndChecked === true ){ checked = 0; }
        else{ checked = 1; }
        this.setState({ 
            soldIndChecked : !this.state.soldIndChecked,
            [e.target.name] : checked
        })
    }

    handleChange = (e) => {
        this.setState({[e.target.name] : e.target.value});
    }

    //save category to collection
    handleSubmit = () => {

        const { dispatch } = this.props;
        const requiredFields = [
            'productTitle',
            'productDescription',
            'productRegularPrice',
            'productSalePrice',
            'productStock',
            'productSku'
        ];
        requiredFields.map( (val) => {
            let lbl = [val]+'_error';
            this.setState({ [lbl] : '' });
        })
        const {
            editingProdId,
            productTitle,
            productDescription,
            productRegularPrice,
            productSalePrice,
            productMainImage,
            productMainImage_get,
            productImageGallery_get,
            productImageGallery,
            productCategories,
            productCoupons,
            productStock,
            productSku,
            productSoldIndividually

        } = this.state;

        if ( productTitle === undefined || productTitle ==='') { this.setState({ productTitle_error : 'Title is required'}) }
        else if ( productDescription === undefined || productDescription ==='') { this.setState({ productDescription_error : 'Description is required'}) }
        else if ( productRegularPrice === undefined || productRegularPrice ==='') { this.setState({ productRegularPrice_error : 'Regular price is required'}) }
        else if ( productSalePrice !== undefined && parseInt(productSalePrice) > parseInt(productRegularPrice) ) { this.setState({ productSalePrice_error : 'Sale price should be less then regular price'}) }
        else if ( productStock === undefined || productStock ==='') { this.setState({ productStock_error : 'Stock qty is required'}) }
        else if ( productSku === undefined || productSku ==='') { this.setState({ productSku_error : 'Stock qty is required'}) }
        else {
            this.setState({ notifyAddProduct : true });
            let featuredImage = '';
            let imgGallery = '';
            let soldInd = 0;
            if(productMainImage!==undefined && productMainImage){ featuredImage = productMainImage; }            
            if(productSoldIndividually!== undefined && productSoldIndividually===1){ soldInd = productSoldIndividually;}
 
            
            const projData = {};
                 projData.editingProdId        = editingProdId;
                  projData.productTitle        = productTitle;
                  projData.productDescription  =  productDescription;
                  projData.productRegularPrice =  productRegularPrice;
                  projData.productSalePrice    =  productSalePrice;
                  projData.productCategories   =  productCategories;
                  projData.productCoupons      =  productCoupons;
                  projData.productStock        =  productStock;
                  projData.productSku          =  productSku;
                  projData.individualSold      =  soldInd;

            const mainImageData = new FormData();  
                  mainImageData.append('productMainImage', featuredImage);
            
            commonService.withTokenAndFormData(apiConstants.SAVE_PRODUCT_MAIN_IMAGE, mainImageData).then(
                res => { 
                            let mainImg = '';
                            // check if product main image is uploaded and returned by server.
                            if(res.data.data !== false){ projData.mainImg = res.data.data; }
                            else{ projData.mainImg = productMainImage_get; }

                            const imageGallery = new FormData();
                            if( productImageGallery !==undefined && productImageGallery.length > 0 && productImageGallery ) {
                                let loopCntr = productImageGallery.length;
                                if( loopCntr === 1){ for (let i = 0 ; i < loopCntr ; i++) { imageGallery.append("productMainImage", productImageGallery[i]); } }
                                else{
                                    for (let i = 0 ; i < loopCntr ; i++) { imageGallery.append("productImageGallery", productImageGallery[i]); }
                                    }
                            } else {
                                    if( productImageGallery !== undefined ){ 
                                        imageGallery.append("productImageGallery", productImageGallery );
                                    }
                                   }
                            
                            
                            

                             commonService.withTokenAndFormData(apiConstants.SAVE_PRODUCT_IMAGE_GALLERY, imageGallery).then(
                                    gallerydata => {

                                        let imageGallery = [];
                                        
                                            // check if gallery images uploaded succesfully and returned by server
                                             if(                                                  
                                                 gallerydata.data.data !== undefined &&
                                                 gallerydata.data.data &&
                                                 gallerydata.data.data.length > 0
                                                 ) {
                                                    gallerydata.data.data.map( (imgObj, index) => { imageGallery.push(imgObj.filename);  })
                                                 } 
                                                 if(imageGallery.length > 0) { 
                                                    projData.imageGallery = productImageGallery_get.concat(imageGallery);
                                                    }
                                                    else{
                                                        projData.imageGallery = productImageGallery_get;
                                                    }
                                                 

                                            // dispatch(productActions.updateProduct(apiConstants.UPDATE_PRODUCT_BY_SHOPKEEPER, projData));
                                           },
                                    error => { }
                                );

                        },
                error => { }
            );
        }
    }

    // remove image after click from gallery
    removeFromGallery = (index) => { 
        const imageData = this.state.productImageGallery_get;        
        imageData.splice(index, 1);
        this.setState({ productImageGallery_get : imageData });
    }

    removeMainImage = () => {
        this.setState({ productMainImage_get : ''})
    }
    //-- Main function
    render() {
        const { classes, displayPopUp, closeEditProductPop } = this.props;
        const { siteConstants } = this.props.language;
        return (
                <Fragment>
                    <Dialog
                        open={displayPopUp}
                        aria-labelledby="products-title"
                        aria-describedby="save-products-description"                        
                        fullWidth="true"
                    >
                        <DialogTitle className={classes.root}>{siteConstants.LABEL_EDIT_PRODUCT_POPUP}</DialogTitle>
                        <DialogContent>
                         <div class="row">

                            <div className="col-md-12 col-xs-12">
                            <div className="form-group">
                                    <label>{siteConstants.LABEL_PRODUCT_TITLE}</label>
                                    <input 
                                        type="text"
                                        onChange={(e)=>this.handleChange(e)}
                                        placeholder={siteConstants.LABEL_PRODUCT_TITLE_PLACEHOLDER}
                                        className="form-control"
                                        name="productTitle"
                                        value={this.state.productTitle}
                                    />                                
                                </div>
                                <p className={classes.error}>{this.state.productTitle_error}</p>
                            </div>

                            <div className="col-md-12 col-xs-12">
                            <div className="form-group">
                                    <label>{siteConstants.LABEL_PRODUCT_DESCRIPTION}</label>
                                    <textarea                                     
                                        onChange={(e)=>this.handleChange(e)}
                                        placeholder={siteConstants.LABEL_PRODUCT_DESCRIPTION_PLACEHOLDER}
                                        className="form-control"
                                        name="productDescription"
                                        value={this.state.productDescription}
                                    />
                                </div>
                                <p className={classes.error}>{this.state.productDescription_error}</p>
                            </div>
                           

                            <div className="col-md-6 col-xs-6">
                            <div className="form-group">
                                    <label>{siteConstants.LABEL_PRODUCT_REGULAR_PRICE}</label>
                                    <input 
                                        type="number"
                                        min="1"
                                        onChange={(e)=>this.handleChange(e)}
                                        placeholder={siteConstants.LABEL_PRODUCT_REGULAR_PRICE_PLACEHOLDER}
                                        className="form-control"
                                        name="productRegularPrice"
                                        value={this.state.productRegularPrice}
                                    />  
                                </div>
                                <p className={classes.error}>{this.state.productRegularPrice_error}</p>
                            </div>
                           

                            <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                    <label>{siteConstants.LABEL_PRODUCT_SALE_PRICE}</label>
                                    <input 
                                        type="number"
                                        min="0"
                                        onChange={(e)=>this.handleChange(e)}
                                        placeholder={siteConstants.LABEL_PRODUCT_SALE_PRICE}
                                        className="form-control"
                                        name="productSalePrice"
                                        value={this.state.productSalePrice}
                                    />  
                                </div>
                                <p className={classes.error}>{this.state.productSalePrice_error}</p>
                            </div>

                            <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                    <label>{siteConstants.LABEL_PRODUCT_MAIN_IMAGE}</label>
                                    <input 
                                        type="file"
                                        onChange={(e)=>this.handleChangeMainImage(e)}
                                        className="form-control"
                                        name="productMainImage"
                                        accept=".png,.jpg,.jpeg" 
                                    /> 
                                </div>
                                <div className="col-md-12 col-xs-12">
                                    { 
                                        this.state.productMainImage_get !== undefined &&
                                        this.state.productMainImage_get !=='' ?
                                        <div className={classes.borderedImg}>
                                            <img src={ `${process.env.REACT_APP_IMAGE_PATH}` + '/uploads/products/'+ this.state.productMainImage_get} className={classes.pageImg}/> 
                                            <span className={classes.removeImg} onClick={ ()=> this.removeMainImage() }>x</span>
                                        </div>
                                        : ''
                                    }
                                </div>
                                <p className={classes.error}>{this.state.productMainImage_error}</p>
                            </div>

                            <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                    <label>{siteConstants.LABEL_PRODUCT_IMAGE_GALLERY}</label>
                                    <input 
                                        type="file"
                                        onChange={(e)=>this.handleChangeMainImage(e)}
                                        className="form-control"
                                        name="productImageGallery"
                                        accept=".png,.jpg,.jpeg"
                                        multiple 
                                    />  
                                </div>
                                <div className="col-md-12 col-xs-12">
                                    
                                    { 
                                        this.state.productImageGallery_get !== undefined &&
                                        this.state.productImageGallery_get.length > 0 && 
                                        this.state.productImageGallery_get.map( (img, index) => {
                                           return <div className={classes.borderedImg}>
                                                <img src={ `${process.env.REACT_APP_IMAGE_PATH}` + '/uploads/products/'+ img} className={classes.pageImg}/> 
                                                <span className={classes.removeImg} onClick={ ()=> this.removeFromGallery(index) }
                                                >x</span>
                                            </div>
                                        })                                                                               
                                    }
                                </div>
                                <p className={classes.error}>{this.state.productImageGallery_error}</p>
                            </div>

                            <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                    <label>{siteConstants.LABEL_PRODUCT_CATEGORY}</label>
                                    <select
                                        className="form-control"
                                        name="productCategories"
                                        onChange={(e)=>this.handleChange(e)}
                                    >
                                        <option>----</option>
                                        {/* loop through all the available categories */}
                                        {
                                            this.state.allCategories !== undefined &&
                                            this.state.allCategories.data !== undefined &&
                                            this.state.allCategories.data.length > 0 &&
                                            this.state.allCategories.data.map( (cat) => {
                                             let selected = false;                                                
                                             if(cat._id === this.state.productCategories){ selected=true; }
                                             return <option value={cat._id} selected={selected}>{cat.categoryTitle}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <p className={classes.error}>{this.state.productCategories_error}</p>
                            </div>

                            <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                    <label>{siteConstants.LABEL_PRODUCT_COUPON}</label>
                                   <select
                                    className="form-control"
                                    name="productCoupons"
                                    onChange={(e)=>this.handleChange(e)}
                                   >
                                       <option>----</option>
                                        {/* loop through all the available coupon */}
                                       {                                           
                                           this.state.getCouponList !== undefined &&
                                           this.state.getCouponList.data !== undefined &&
                                           this.state.getCouponList.data.length > 0 &&
                                           this.state.getCouponList.data.map( (coupn) => {
                                           return <option value={coupn._id}>{coupn.coupon_title}</option>
                                           })
                                       }
                                   </select>
                                </div>
                                <p className={classes.error}>{this.state.productCoupons_error}</p>
                            </div>

                            <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                    <label>{siteConstants.LABEL_PRODUCT_STOCK}</label>
                                    <input 
                                        type="number"
                                        min="0"
                                        onChange={(e)=>this.handleChange(e)}
                                        placeholder={siteConstants.LABEL_PRODUCT_STOCK_PLACEHOLDER}
                                        className="form-control"
                                        name="productStock"
                                        value={this.state.productStock}
                                    />  
                                </div>
                                <p className={classes.error}>{this.state.productStock_error}</p>
                            </div>
                            

                            <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                    <label>{siteConstants.LABEL_PRODUCT_SKU}</label>
                                    <input 
                                        type="text"                                        
                                        onChange={(e)=>this.handleChange(e)}
                                        placeholder={siteConstants.LABEL_PRODUCT_SKU_PLACEHOLDER}
                                        className="form-control"
                                        name="productSku"
                                        value={this.state.productSku}
                                    />  
                                </div>
                                <p className={classes.error}>{this.state.productSku_error}</p>
                            </div>
                            

                            
                            <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                    <label>{siteConstants.LABEL_PRODUCT_SOLD_INDIVIDUALLY}</label><br/>                                    
                                    <input 
                                        type="checkbox"                                        
                                        onChange={(e)=>this.handleChangeCheckbox(e)}                                        
                                        name="productSoldIndividually"      
                                        checked={this.state.productSoldIndividually === 1 ? true : false }                                  
                                    />  
                                </div>
                                <p className={classes.error}>{this.state.productSoldIndividually_error}</p>
                            </div>


                         </div>
                        
                        </DialogContent>

                        
                        <DialogActions>
                            <Button variant="contained" onClick={()=>closeEditProductPop(0)}>{siteConstants.LABEL_CANCEL}</Button>
                            <Button variant="contained" color="primary" onClick={ (e) => this.handleSubmit(e) }>{siteConstants.LABEL_SAVE}</Button>
                        </DialogActions>
                    </Dialog>

                    {/* notify saving data */}
                    <Dialog open={this.state.notifyAddProduct} aria-labelledby="save-product-notify" >
                        <DialogTitle>{siteConstants.LABEL_SAVING_PRODUCT_DATA}</DialogTitle>                        
                        <img className={classes.loaderImg} 
                        // src={ loader } 
                        />
                    </Dialog> 

                
                </Fragment>
        );
    }
}


//-- Here we are adding Reducer names, so it can be get data from reducers using store
function mapStateToProps(state) {
    const { language,saveProduct, getCategories, getCategory, getCoupounList, getSingleProduct, updateSingleProduct } = state;
    return {
        language,
        saveProduct,        
        getCategories,
        getCategory,
        getCoupounList,
        getSingleProduct,
        updateSingleProduct
    };
}
export default connect(mapStateToProps)(withStyles(styles)(EditEvents))
