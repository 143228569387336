export const feedbackConstants = {
    
    GET_ALL_FEEDBACK_REQUEST: 'GET_ALL_FEEDBACK_REQUEST',
    GET_ALL_FEEDBACK_SUCCESS: 'GET_ALL_FEEDBACK_SUCCESS',
    GET_ALL_FEEDBACK_FAILURE: 'GET_ALL_FEEDBACK_FAILURE',

    ADD_FEEDBACK_REQUEST: 'ADD_FEEDBACK_REQUEST',
    ADD_FEEDBACK_SUCCESS: 'ADD_FEEDBACK_SUCCESS',
    ADD_FEEDBACK_FAILURE: 'ADD_FEEDBACK_FAILURE',

    GET_FEEDBACK_BY_ID_REQUEST: 'GET_FEEDBACK_BY_ID_REQUEST',
    GET_FEEDBACK_BY_ID_SUCCESS: 'GET_FEEDBACK_BY_ID_SUCCESS',
    GET_FEEDBACK_BY_ID_FAILURE: 'GET_FEEDBACK_BY_ID_FAILURE',

    DELETE_FEEDBACK_REQUEST: 'DELETE_FEEDBACK_REQUEST',
    DELETE_FEEDBACK_SUCCESS: 'DELETE_FEEDBACK_SUCCESS',
    DELETE_FEEDBACK_FAILURE: 'DELETE_FEEDBACK_FAILURE',
};
