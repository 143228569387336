import { seekerConstants } from '../_constants';

const initialState = {
  allSeeker: []
}

export function getSeeker(state = initialState, action) {
  switch (action.type) {
    case seekerConstants.GET_SEEKER_REQUEST:
      return {
        ...state,
        getAllSeeker: false
      }
    case seekerConstants.GET_SEEKER_SUCCESS:
      return {
        ...state,
        allSeeker: action.successAction.data.data,
        total_count: action.successAction.data.total_count,
        pages: action.successAction.data.pages
      }
    case seekerConstants.GET_SEEKER_FAILURE:
      return {
        ...state,
        getAllProvider: action
      }
    default:
      return state
  }
}