import React, { useEffect, useState } from 'react';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import PageLimit from "../Common/PageLimit/PageLimit";
import Pagination from "../Common/Pagination/Pagination";
import { useSelector, useDispatch } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';
import {  transactionActions } from '../../_actions';
import 'antd/dist/antd.css';
// import LocationDetail from './TransationDetail';
import { CSVLink } from "react-csv";

export default function Transaction() {
  const [addLocation, setLocationAdd] = useState(false);
  const [transaction, setTransaction] = useState([])
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [total_Page, setTotalPage] = useState(0)
  const [NewOffset, setOffset] = useState(0);
  const [filteredTransaction, setFilteredTransaction] = useState([]);

  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')))
  const dispatch = useDispatch();

  useEffect(() => {
    let obj = {
      limit: 10,
      offset:0
    }
    dispatch(transactionActions.getTransactionList(apiConstants.GET_TRANSACATION_LIST, obj));
  }, [])

  const getTransactionList = useSelector(state => state.getTransactionList);

  useEffect(() => {
    if (getTransactionList && getTransactionList.getTransactionList) {
      setTransaction(getTransactionList.getTransactionList.data)
      setTotalPage(getTransactionList.getTransactionList.pages)
    }
  }, [getTransactionList])

  // Search function defined for search
  useEffect(() => {
    setFilteredTransaction(
      transaction.filter((data) =>
        data.payment_status.toLowerCase().includes(search.toLowerCase()) ||
        data.payment_type.toLowerCase().includes(search.toLowerCase()) ||
        data.transaction_id.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, transaction]);

  const makeHttpRequestWithPage = (pageNumber) => {
    setCurrentPage(pageNumber)
    let obj = {
      limit: 10,
      offset: pageNumber - 1
    }
    setOffset(obj.offset)
    dispatch(transactionActions.getTransactionList(apiConstants.GET_TRANSACATION_LIST, obj));
  }

  // const headers = [
  //   { label: "ID", key: "id" },
  //   { label: "Booking Id", key: "booking_id" },
  //   { label: "User Id", key: "user_id" },
  //   { label: "Payment Type", key: "payment_type" },
  //   { label: "Transaction Id", key: "transaction_id" },
  //   { label: "Transaction Type ", key: "transaction_type" },
  //   { label: "Transaction Price", key: "transaction_price" },
  //   { label: "Transaction Description", key: "payment_description" },
  //   { label: "Payment Status", key: "payment_status" }
  // ];
  const headers = [
    { label: "First Name", key: "user.first_name" },
    { label: "Last Name", key: "user.last_name" },
    { label: "Payment Type", key: "payment_type" },
    { label: "Transaction Id", key: "transaction_id" },
    { label: "Transaction Type ", key: "transaction_type" },
    { label: "Transaction Price", key: "transaction_price" },
    // { label: "Transaction Description", key: "payment_description" },
    { label: "Payment Status", key: "payment_status" }
  ];

  const changeTableData = (e) => {
    setLimit(e.target.value)
    let obj = {
      limit: parseInt(e.target.value),
      offset: 0,
    };
    dispatch(transactionActions.getTransactionList(apiConstants.GET_TRANSACATION_LIST, obj));
  };


  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Transaction
              </li>
            </ol>
          </nav>
          <div className="container-fluid">

            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-2 text-gray-800">
                Transaction
                </h1>
              <div className="d-sm-flex align-items-center justify-content-between">
                {/* <CSVLink className="btn btn-primary mr-3" data={(filteredTransaction !== undefined && filteredTransaction.length ? filteredTransaction : []).map(Object.values)} headers={headers.map(Object.values)}>Export</CSVLink> */}
                <CSVLink className="btn btn-primary mr-3" data={filteredTransaction || []} headers={headers}>Export</CSVLink>
              </div>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="table-responsive">
                  <div className="row">
                    {/* Page limit drop down */}
                    <PageLimit funChangePageLimit={changeTableData} />

                    <div className="col-sm-12 col-md-6">
                      <div id="dataTable_filter" className="dataTables_filter">
                        <label>
                          <input type="search" onChange={(e) => setSearch(e.target.value)} className="form-control form-control-sm" placeholder="Search" aria-controls="dataTable" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <table className="table table-bordered" width="100%" cellSpacing="0">
                    <thead>
                      <tr>
                        <th scope="col">No.</th>
                        <th scope="col">User Id</th>
                        <th scope="col">
                          Payment Type
                            <img src="assets/img/svg/sort.png" className="imageSort" />
                        </th>
                        <th scope="col">Transaction Id
                          <img src="assets/img/svg/sort.png" className="imageSort" />
                        </th>
                        <th scope="col">Transaction Price</th>
                        <th scope="col">Transaction Type</th>
                        <th scope="col">Transaction Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTransaction !== undefined && filteredTransaction.length > 0 ? filteredTransaction.map((transaction, index) => {
                         var count = 0;
                         //  var pageData = config.perPageData;
                          count = index + (NewOffset*10)+1
                        return (
                          <tr key={index}>
                            <td scope="row">{count}</td>
                            <td>{transaction.user_id}</td>
                            <td>{transaction.payment_type.charAt(0).toUpperCase() + transaction.payment_type.slice(1)}</td>
                            <td>
                              {transaction.transaction_id}
                            </td>
                            <td>{transaction.transaction_price}</td>
                            <td>{transaction.transaction_type.charAt(0).toUpperCase() + transaction.transaction_type.slice(1)}</td>
                            <td>{transaction.payment_status.charAt(0).toUpperCase() + transaction.payment_status.slice(1)}</td>
                          </tr>
                        )
                      }) : null}

                    </tbody>
                  </table>
                </div>
                <Pagination totalPage={(getTransactionList && getTransactionList.getTransactionList) ? getTransactionList.getTransactionList.pages:0} currentPage="1" funPagination={makeHttpRequestWithPage} />
              </div>
            </div>
          </div>


        </div>
        <Footer />
      </div>
    </div>
  )
}
