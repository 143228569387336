import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import Schedule_experienceDetails from './Schedule_experienceDetails';
import { CSVLink } from "react-csv";
import PageLimit from "../Common/PageLimit/PageLimit";
import Add_schedule_experience from './Add_schedule_experience';
import { Badge } from 'antd';
// import { Switch } from 'antd';
import moment from "moment";
import 'antd/dist/antd.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Pagination from "../Common/Pagination/Pagination";
import { useSelector, useDispatch } from 'react-redux';
import {
  scheduleExperienceActions,
} from "../../_actions/schedule_experience.action";
import { apiConstants } from '../../_constants/api.constants';
import swal from 'sweetalert';

export default function Schedule_experience() {
  const [experienceDetails, setExperienceDetails] = useState(false);
  const [experienceAdd, setExperienceAdd] = useState(false);
  const [savExperience, setSaveExperience] = useState({})
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [experience, setExperience] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [NewOffset, setOffset] = useState(0);
  const [deleteDetail, setDeleteDetail] = useState("");
  const [search, setSearch] = useState("");
  const [sortFlag, setSortFlag] = useState(0);
  const [filteredExperienceSchedule, setFilteredExperienceSchedule] = useState([]);


  const dispatch = useDispatch();
  const getScheduleExperienceList = useSelector(state => state.getScheduleExperienceList);
  const deleteScheduleExperience = useSelector(state => state.deleteScheduleExperience);

  useEffect(() => {
    let obj = {
      limit: 10,
      offset: 0
    }
    dispatch(scheduleExperienceActions.getScheduleExperienceList(apiConstants.GET_ALL_SCHEDULE_EXPERIENCE_DETAILS, obj));
  }, [])

  const makeHttpRequestWithPage = (pageNumber) => {
   
    setCurrentPage(pageNumber)
    let obj = {
      limit: limit,
      offset:pageNumber - 1
    }
     setOffset(obj.offset)
    dispatch(scheduleExperienceActions.getScheduleExperienceList(apiConstants.GET_ALL_SCHEDULE_EXPERIENCE_DETAILS, obj));
}

const changeTableData = (e) => {
  setLimit(e.target.value)
  let obj = {
    limit: parseInt(e.target.value),
    offset: 0,
  };
  dispatch(scheduleExperienceActions.getScheduleExperienceList(apiConstants.GET_ALL_SCHEDULE_EXPERIENCE_DETAILS, obj));
};

  useEffect(()=> {
    if(getScheduleExperienceList.getScheduleExperienceList && getScheduleExperienceList.getScheduleExperienceList.data){
      setExperience(getScheduleExperienceList.getScheduleExperienceList.data)
      
    }
  },[getScheduleExperienceList.getScheduleExperienceList])

  const openAddExperiencerDialog = () => {
    setExperienceAdd(true);
  }

  const closeAddExperienceDialog = () => {
    setExperienceAdd(false);
  }

  const experienceDetail = (experience) => {
    setExperienceDetails(true);
    setSaveExperience(experience)
  }

  const backButton = () => {
    setExperienceDetails(false);
  }

  const closeDeletePopup = () => {
    setDeletePopUp(false);
  }

   // Search function defined for search
   useEffect(() => {
    setFilteredExperienceSchedule(
      experience.filter((data) =>
      data.experience_name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, experience]);
 
  // delete funtion defined here
  const deleteExperience = (id) => {
     setDeletePopUp(true);
     setDeleteDetail(id);
  }
  const handleDelete = () => {
    let id = { id: deleteDetail };
    dispatch(
      scheduleExperienceActions.deleteScheduleExperience(
        apiConstants.DELETE_SCEHDULE_EXPERIENCE,
        {id:deleteDetail}
      )
    );
    setDeletePopUp(false);

  };

  if(deleteScheduleExperience && deleteScheduleExperience.deleteScheduleExperience && deleteScheduleExperience.deleteScheduleExperience.error){
    swal('Oops!',deleteScheduleExperience.deleteScheduleExperience.message , "error").then(result=>{
      window.location.reload()
    });
  }else if(deleteScheduleExperience && deleteScheduleExperience.deleteScheduleExperience && !deleteScheduleExperience.deleteScheduleExperience.error){
    swal('Delete!',deleteScheduleExperience.deleteScheduleExperience.message , "success").then(result=>{
      window.location.reload()
    });
  }

  const headers = [
    { label: "First Name", key: "ScheduleExperienceProvider.first_name" },
    { label: "Last Name", key: "ScheduleExperienceProvider.last_name" },
    { label: "Experience Name", key: "experience_name" },
    { label: "Schedule Status", key: "schedule_status" },
    { label: "Team Size", key: "team_size" },
    { label: "Aount", key: "amount" },
    { label: "Per Person Cost", key: "per_person_cost" },
    { label: "Start Date", key: "start_date" },
    { label: "End Date", key: "end_date" },
    { label: "Repeat End Date", key: "repeat_end_date" },
    { label: "Charge Type", key: "charge_type" },
    { label: "Status", key: "is_deleted" },

  ];
  
  const handleSort = (e) => {
    if(sortFlag == 1){
      setSortFlag(0)
    }else if(sortFlag == 0){
      setSortFlag(1)
    }
   let obj = {
     sortByStatus: sortFlag,
     sortBy: e.target.name
   }
  //  setCompany(company.sort((a,b) => parseFloat(a.user_id) - parseFloat(b.user_id)))
   dispatch(scheduleExperienceActions.sortScheduleExperience(apiConstants.SORT_SCHEDULE_EXPERIENCE, obj))
  }

  const searchScheduleExperience = (e) => {
    let obj = {
      searchQuery: e.target.value,
      limit: limit,
      offset: 0
    }
    dispatch(scheduleExperienceActions.getScheduleExperienceList(apiConstants.SEARCH_SCHEDULE_EXPERIENCE, obj));
  }
  
  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
              Schedule Experience
              </li>
            </ol>
          </nav>
          {!experienceDetails ?
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-2 text-gray-800">
                Schedule Experience
                </h1>
                <div className="d-sm-flex align-items-center justify-content-between">
                  {/* <CSVLink className="btn btn-primary mr-3" data={experience.map(Object.values)} headers={headers.map(Object.values)}>Export</CSVLink> */}
                  <CSVLink className="btn btn-primary mr-3" data={experience || []} headers={headers}>Export</CSVLink>
                  <a href="#!"
                    onClick={() => console.log("provider add")}
                  >
                    <span className="text">
                      <button onClick={openAddExperiencerDialog} className="btn btn-primary">Add New Schedule Experience</button>
                    </span>
                  </a>
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div >
                    <div className="row">
                      {/* Page limit drop down */}
                      <PageLimit funChangePageLimit={changeTableData} />

                      <div className="col-sm-12 col-md-6">
                        <div id="dataTable_filter" className="dataTables_filter">
                          <label>
                            <input type="search" onChange={searchScheduleExperience}  className="form-control form-control-sm" placeholder="Search" aria-controls="dataTable" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <table className="table table-bordered"  width="100%" cellSpacing="0">
                      <thead>
                        <tr>
                          <th scope="col">No.</th>
                          <th scope="col">Experience Name 
                          <img src="assets/img/svg/sort.png" name="experience_name" className="imageSort" onClick={handleSort}/>
                          </th>
                          <th scope="col">Schedule Status</th>
                          {/* <th scope="col">Start Date</th>
                          <th scope="col">End Date</th> */}
                          <th scope="col">Team Size</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Per Person Cost</th>
                          <th scope="col">Status</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                          
                      {experience !== undefined && filteredExperienceSchedule.length>0 && experience.length > 0 ? filteredExperienceSchedule.map((data, index) => {
                       let start_date = moment(data.start_date).format("YYYY-MM-DD");
                       let end_date = moment(data.end_date).format("YYYY-MM-DD");
                       var count = 0;
                       //  var pageData = config.perPageData;
                        count = index + (NewOffset*10)+1
                       
                       return (
                        <tr key={index}>
                          <td scope="row">{count}</td>
                          <td>{data.experience_name}</td>
                          <td>{data.schedule_status}</td>
                          {/* <td>{start_date}</td>
                          <td>{end_date}</td> */}
                          <td>{data.team_size}</td>
                          <td>{data.amount}</td>
                          <td>{data.per_person_cost}</td>
                          <td>
                            {data.is_deleted === "Active" ? <Badge className="ml-3" status="success" text="Active" /> : <Badge className="ml-3" status="error" text="Inactive" />}
                          </td>
                          <td>
                            {/* <a className="actionIcon" title="View" href="#!" onClick={() => experienceDetail(data)}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a> */}
                            {/* <a className="actionIcon" title="View" href="#!" onClick={() => this.popupOpen(users, 'viewOpen')}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a> */}
                            <Link className="actionIcon" title="Edit" to={`/update-schedule_experience/${data.id}`}><span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span></Link>
                            <a className="actionIcon" title="Delete" href="#!" onClick={() => deleteExperience(data.id)}><span><img src="assets/img/svg/Bin.png" alt="Delete" /></span></a>
                          </td>
                        </tr>
                          )
                        }) : null}
                      </tbody>
                    </table>
                  </div>
                  {/* calling the another componenet */}
                  <Pagination totalPage={(getScheduleExperienceList && getScheduleExperienceList.getScheduleExperienceList) ? getScheduleExperienceList.getScheduleExperienceList.pages : 0} currentPage={currentPage} funPagination={makeHttpRequestWithPage} />
                </div>
              </div>
            </div> :
            <Schedule_experienceDetails data={savExperience} backButton={backButton} />
          }
        </div>

        {/* add experience popup */}
        {
          experienceAdd === true ? <Add_schedule_experience displayPopUp={experienceAdd} hidePopUp={closeAddExperienceDialog} /> : ''
        }


        <Dialog open={deletePopUp} fullWidth={true} aria-labelledby="delete-product-notify" >
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            Are you sure?
            <h5 className="mt-3">You want to delete experience</h5>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary"  onClick={() => handleDelete()}>Delete</Button>
            <Button variant="contained" onClick={closeDeletePopup}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Footer />
      </div>
    </div>
  )
}
