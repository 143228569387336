import React from 'react'

export default function CompanyDetail({ backButton, data }) {
  console.log("data==>", data)
  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Company Details</h1>
        <a href="#" onClick={backButton} className="btn btn-secondary btn-icon-split">
          <span className="icon text-white-50">
            <i className="fas fa-arrow-left"></i>
          </span>
          <span className="text">Back</span>
        </a>
      </div>
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <div className="row">

              <div className="col-md-6 col-xs-6">
                <div className="form-group">
                  <label>Company Name</label>
                  <input
                    value={data.company_name}
                    type="text"
                    placeholder='Company Name'
                    className="form-control"
                    name="company_name"
                    disabled
                  />
                </div>
                {/* <p className={classes.error}>{this.state.eventTitle_error}</p> */}
              </div>

              <div className="col-md-6 col-xs-6">
                <div className="form-group">
                  <label>Cover Photo</label>
                  <input
                    type="file"
                    //  onChange={handleChangeCoverPhoto}
                    className="form-control"
                    name="cover_photo"
                    accept=".png,.jpg,.jpeg"
                  />
                </div>
                {/* <p className={classes.error}>{this.state.eventDescription_error}</p> */}
              </div>


              <div className="col-md-6 col-xs-6">
                <div className="form-group">
                  <label>Company Logo</label>
                  <input
                    type="file"
                    //  onChange={handleChangeLogo}
                    className="form-control"
                    name="logo"
                    accept=".png,.jpg,.jpeg"
                  />
                </div>
                {/* <p className={classes.error}>{this.state.eventDate_error}</p> */}
              </div>


              <div className="col-md-6 col-xs-6">
                <div className="form-group">
                  <label>Title</label>
                  <input
                    value={data.title}
                    type="text"
                    min="0"
                    placeholder="Title"
                    className="form-control"
                    name="title"
                    disabled
                  />
                </div>
                {/* <p className={classes.error}>{this.state.eventTime_error}</p> */}
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Organisation Details</label>
                  <textarea
                    value={data.organisation_detail}
                    className="form-control"
                    placeholder="Organisation Details"
                    name="organisation_detail"
                  >
                  </textarea>
                </div>
                {/* <p className={classes.error}>{this.state.address_error}</p> */}
              </div>

              <div className="col-md-6 col-xs-6">
                <div className="form-group">
                  <label>Mission Value 1</label>
                  <input
                    value={data.mission_value1}
                    type="text"
                    min="0"
                    placeholder="Mission Value 1"
                    className="form-control"
                    name="mission_value1"
                    disabled
                  />
                </div>
                {/* <p className={classes.error}>{this.state.city_error}</p> */}
              </div>

              <div className="col-md-6 col-xs-6">
                <div className="form-group">
                  <label>Mission value 2</label>
                  <input
                    value={data.mission_value2}
                    type="text"
                    min="0"
                    placeholder="Mission Value 2"
                    className="form-control"
                    name="mission_value2"
                  />
                </div>
                {/* <p className={classes.error}>{this.state.city_error}</p> */}
              </div>

              <div className="col-md-6 col-xs-6">
                <div className="form-group">
                  <label>Mission Value 3</label>
                  <input
                    value={data.mission_value3}
                    type="text"
                    min="0"
                    placeholder="Mission Value 3"
                    className="form-control"
                    name="mission_value3"
                    disabled
                  />
                </div>
                {/* <p className={classes.error}>{this.state.city_error}</p> */}
              </div>

              <div className="col-md-6 col-xs-6">
                <div className="form-group">
                  <label>Mission Value 4</label>
                  <input
                    value={data.mission_value4}
                    type="text"
                    min="0"
                    placeholder="Mission Value 4"
                    className="form-control"
                    name="mission_value4"
                  />
                </div>
                {/* <p className={classes.error}>{this.state.city_error}</p> */}
              </div>

              <div className="col-md-6 col-xs-6">
                <div className="form-group">
                  <label>Images</label>
                  <input
                    type="file"
                    //  onChange={handleChangeImages}
                    className="form-control"
                    name="images"
                    accept=".png,.jpg,.jpeg"
                    multiple
                    disabled
                  />
                </div>
                {/* <p className={classes.error}>{this.state.city_error}</p> */}
              </div>

              <div className="col-md-6 col-xs-6">
                <div className="form-group">
                  <label>Video Title</label>
                  <input
                    value={data.video_title}
                    type="text"
                    min="0"
                    placeholder="Video Title"
                    className="form-control"
                    name="video_title"
                    disabled
                  />
                </div>
                {/* <p className={classes.error}>{this.state.city_error}</p> */}
              </div>

              <div className="col-lg-12">
                <div className="form-group">
                  <label>Video Url</label>
                  <input
                    value={data.video_url}
                    type="text"
                    min="0"
                    placeholder="Video Url"
                    className="form-control"
                    name="video_url"
                    disabled
                  />
                </div>
                {/* <p className={classes.error}>{this.state.city_error}</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
