import React, { useEffect, useState } from 'react';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import { useSelector, useDispatch } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';
// import { Form } from 'antd';
import { providerActions, locationActions } from '../../_actions';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
// import create from 'antd/lib/icon/IconFont';

export default function UpdateLocation(props) {
  const [temp, setTemp] = useState({
    lati: -33.865143,
    long: 151.209900
  })

  const [address, setAddress] = useState('')
  const [latlng, setLatLng] = useState({});
  const [providerId, setProviderId] = useState('')
  const [locationName, setLocationName] = useState('')
  const [createdBy, setCreatedBy] = useState(JSON.parse(sessionStorage.getItem('user')).data.id)
  const provider = useSelector(state => state.getProvider.allProvider);
  const location = useSelector(state => state.getLocationById.getLocationById);
  const dispatch = useDispatch();

  useEffect(() => {
    let obj = {
      id: props.match.params.id
    }
    dispatch(locationActions.getLocationListById(apiConstants.GET_LOCATION_BY_ID, obj ))
  }, [])

  useEffect(() => {
    if(location && location.location){
      setLocationName(location.location.location_name)
      setLatLng({
        lattitude: location.location.lattitude,
        longitude: location.location.longitude
      })
      setAddress(location.location.location_address)
    }
  }, [location])

 const  handleSelect = address => {
  //  console.log("addre==>", address)
   setAddress(address)
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        setLatLng({
          lattitude: latLng.lat,
          longitude: latLng.lng
        })
        console.log('Success', latLng)
      })
      .catch(error => console.error('Error', error));
  };
 
  useEffect(() => {
    // dispatch(companyActions.getOrganisationDetailsById(apiConstants.GET_ORGANISATION_DETAILS, {id: userId}));
  }, [])

  useEffect(() => {
    let obj = {
      limit: 10,
      offset: 0
    }
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER, obj));
  }, [])


  const handleChange = address => {
    setAddress(address)
  };

  const handleSubmit = () => {
    let obj = {
      id: parseInt(props.match.params.id),
      location_address: address,
      location_name: locationName,
      lattitude: parseInt(latlng.lattitude),
      longitude: parseInt(latlng.longitude)
    }
// console.log("data==>", obj)
    dispatch(locationActions.updateLocation(apiConstants.UPDATE_LOCATION, obj))

  }
  return (
    <div id='wrapper'>
    <SideBar />
    <div id="content-wrapper" className="d-flex flex-column">
      <div id="content">
        <TopBar />
        <nav className="" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="active breadcrumb-item" aria-current="page">
              Location
          </li>
          </ol>
        </nav>
        <div className="container-fluid">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-2 text-gray-800">
              Update Location
          </h1>
          </div>
          <div className="card shadow mb-4">
            <div className="card-body">
              <div className="table-responsive">

          <div className="row">
          <div className="col-md-12 col-xs-12">
              <div className="form-group">
                <label>Provider Name</label>
                <select className="form-control" name="type" value={location && location.location ? location.location.provider_id : '' } disabled>
                {provider !== undefined && provider.length > 0 ? provider.map((provider, index) => {
                          return (
                            <option value={provider.id}>{provider.first_name}</option>
                          )
                        }) : null}
                </select>
              </div>
              {/* <p className={classes.error}>{this.state.eventTime_error}</p> */}
            </div>
            <div className="col-md-12 col-xs-12">
              <div className="form-group">
                <label>Enter Location</label>
                <PlacesAutocomplete
                    value={address}
                    defaultValue={
                      location && location.location ? location.location.location_address : ''
                    }
                    onChange={handleChange}
                    onSelect={handleSelect}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <input
                          
                          {...getInputProps({
                            placeholder: 'Search Places ...',
                            className: 'form-control',
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
              </div>
              {/* <p className={classes.error}>{this.state.eventTitle_error}</p> */}
            </div>

            <div className="col-md-12 col-xs-12">
              <div className="form-group">
                <label>Location Name</label>
                <input
                defaultValue={
                  location && location.location ? location.location.location_name : ''
                }
                  type="text"
                  min="0"
                  onChange={(e) => setLocationName(e.target.value)}
                  placeholder="Title"
                  className="form-control"
                  name="location_name"
                />
              </div>
              {/* <p className={classes.error}>{this.state.eventTime_error}</p> */}
            </div>
            <div className="col-lg-12">
                <span className="text">
                    <button className="btn btn-primary" onClick={handleSubmit}>Update</button>
                </span>
            </div>
        {/* </div> */}
          </div>
          </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
