//-- React Standard
import React, { Component } from "react";
import { connect } from 'react-redux';

//-- Custom
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import { userActions, notificationActions } from '../../_actions';
import { apiConstants } from '../../_constants/api.constants';
import Pagination from "../Common/Pagination/Pagination";
import DeletePopup from "../Common/Popup/DeletePopup";
import PageLimit from "../Common/PageLimit/PageLimit";
//import ViewPopup from "../Common/Popup/ViewPopup";
import UsersDetail from "./UsersDetail";
import PartnerAddUpdate from "./PartnerAddUpdate";
import EditPopup from "../Common/Popup/EditPopup";


class Partners extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sort: {
                sortKey: null,
                direction: 'desc',
            },
            totalPage: '',
            totalDataCount: '',
            currentPage: 1,
            perPageData: 10,
            getUsersList: [],
            viewOpen: false,
            editOpen: false,
            deleteOpen: false,
            users: {
                title: '',
                type: '',
                description: '',
            },
            addButton: false,
            varUserDetails: false,
            partners: '',
            varPartnerAddPage: false,
            varAddEditTitle: '',
        };
    }

    /*
    * componentDidMount - Initially it will call and get the users data
    */
    componentDidMount() {
        // const { dispatch } = this.props;
        let postData = {};
        postData.page = 1;
        postData.orderBy = "id";
        postData.orderByAscDesc = "ASC";

        // dispatch(userActions.getUsersList(apiConstants.GET_USERS, postData));
    }

    /*
    * componentWillReceiveProps - Whenever Props change, it will call and store data
    * update the state values with new props values, this method will get called whenever any change happens to props values
    * componenentDidUpdate()
    */
    componentWillReceiveProps(props) {
        if (props.getUsersList) {
            if (props.getUsersList.getUsersList) {
                if (props.getUsersList.getUsersList.users) {
                    this.setState({
                        totalPage: props.getUsersList.getUsersList.users.totalPages,
                        totalDataCount: props.getUsersList.getUsersList.users.totalCount,
                        getUsersList: props.getUsersList.getUsersList.users.list,
                    })
                }
            }
        }
    }

    /*
    *   Pagination function: It will call from pagination section and change data in table
    */
    makeHttpRequestWithPage = (pageNumber) => {
        // const { dispatch } = this.props;
        this.setState({
            currentPage: pageNumber
        });

        let postData = {};
        postData.page = pageNumber;
        postData.orderBy = "id";
        postData.orderByAscDesc = "ASC";
        postData.perPageData = this.state.perPageData;

        // dispatch(userActions.getUsersList(apiConstants.GET_USERS, postData));
    }

    /*
    * searchUsers - User can search data from the database and it will appear
    */
    searchUsers(keyword) {
        // const { dispatch } = this.props;
        let postData = {};
        postData.page = 1;
        postData.orderBy = "id";
        postData.orderByAscDesc = "ASC";

        if (this.state.perPageData !== 10)
            this.setState({ perPageData: 10 });

        if (keyword.target.value !== '') {
            postData.qwery = keyword.target.value;
        }
        // dispatch(userActions.getUsersList(apiConstants.GET_USERS, postData));
    }

    //-- Popup Handler
    /*
    *   Modal open-close functionality
    */
    popupOpen = (users, type, btnClick) => {
        //-- check add button clicked
        // if(btnClick === 'addButton')
        // {
        //     this.setState({addButton: true});    
        // }
        this.setState({ users, [type]: true })
    };

    /*
    *   Close the popup
    */
    popupClose = (type) => {
        this.setState({
            users: {
                title: '',
                type: '',
                description: '',
            },
            addButton: false,
            [type]: false
        });
    };


    /* 
        we can remove the selected data, using the below function 
    */
    deleteUser = () => {
        let postData = {};
        postData.page = this.state.currentPage;
        postData.orderBy = "id";
        postData.orderByAscDesc = "ASC";

        let obj = {};
        obj.id = this.state.users.id;
        // this.props.dispatch(userActions.deleteUser(apiConstants.DELETE_USER, obj, apiConstants.GET_USERS, postData));
        this.popupClose('deleteOpen');
    }
    /*
    *   User details- we can open seperate page for details
    */
    userDetails = (users) => {
        this.setState({
            users,
            varUserDetails: true
        });
    }

    /*
    *   Call from the Detail page
    */
    backButton = () => {
        this.setState({
            varPartnerAddPage: false
        });
    }

    /*
    *   Page Limit handle
    */
    handlePageLimitChange = (e) => {
        const { dispatch } = this.props;
        let postData = {};
        postData.page = 1;
        postData.orderBy = "id";
        postData.orderByAscDesc = "ASC";
        postData.perPageData = parseInt(e.target.value);

        this.setState({ perPageData: parseInt(e.target.value) });
        // dispatch(userActions.getUsersList(apiConstants.GET_USERS, postData));
    }

    /*
    *   Partners can add/edit from this area
    */
    partnersAddUpdate = (e, data) => {
        e.preventDefault();

        if (this.handleValidation(data).formIsValid) {
            let postData = {};
            postData.page = 1;
            postData.orderBy = "id";
            postData.orderByAscDesc = "ASC";

            this.props.dispatch(notificationActions.addUpdatePartner(apiConstants.ADD_UPDATE_PARTNER_DATA, data.defaultData, apiConstants.GET_PARTNERS, postData));
            this.popupClose('editOpen');

            return ({ errors: {}, formIsValid: true });
        } else {
            return this.handleValidation(data);
        }
    }

    /*
    *   addUpdatePartner
    */
    addUpdatePartner = (type) => {
        this.setState({
            varPartnerAddPage: true,
            varAddEditTitle: type
        });
    }

    //-- Main function
    render() {
        const { siteConstants } = this.props.language;
        //console.log("TEST ", siteConstants);
        return (
            <div id="wrapper">
                <SideBar />

                <div id="content-wrapper" className="d-flex flex-column">
                    {/* <!-- Main Content --> */}
                    <div id="content">

                        <TopBar />

                        {/* <!-- Begin Page Content --> */}
                        {/* Checking the condition for detail list */}
                        {!this.state.varUserDetails && !this.state.varPartnerAddPage ?
                            <div className="container-fluid">

                                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                    <h1 className="h3 mb-0 text-gray-800">{siteConstants.LABEL_PARTNERS}</h1>
                                    <a href="#" onClick={() => this.addUpdatePartner('addPartner')}>
                                        <span className="text">
                                            <button className="btn btn-primary">{siteConstants.BTN_ADD}</button>
                                        </span>
                                    </a>
                                </div>


                                <div className="card shadow mb-4">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <div className="row">
                                                {/* Page limit drop down */}
                                                <PageLimit funChangePageLimit={this.handlePageLimitChange} perPageData={this.state.perPageData} />

                                                <div className="col-sm-12 col-md-6">
                                                    <div id="dataTable_filter" className="dataTables_filter">
                                                        <label>
                                                            <input type="search" palaceholder={siteConstants.GEN_SEARCH} className="form-control form-control-sm" placeholder="" aria-controls="dataTable" onChange={(e) => this.searchUsers(e)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <table className="table table-bordered" width="100%" cellSpacing="0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">{siteConstants.GEN_NUMBER}</th>
                                                        <th scope="col">{siteConstants.GEN_NAME}</th>
                                                        <th scope="col">{siteConstants.LABEL_EMAIL}</th>
                                                        <th scope="col">{siteConstants.LABEL_CELL_PHONE}</th>
                                                        <th scope="col">{siteConstants.LABEL_STATUS}</th>
                                                        <th width="10%">{siteConstants.LABEL_ACTION}</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th scope="col">{siteConstants.GEN_NUMBER}</th>
                                                        <th scope="col">{siteConstants.GEN_NAME}</th>
                                                        <th scope="col">{siteConstants.LABEL_EMAIL}</th>
                                                        <th scope="col">{siteConstants.LABEL_CELL_PHONE}</th>
                                                        <th scope="col">{siteConstants.LABEL_STATUS}</th>
                                                        <th width="10%">{siteConstants.LABEL_ACTION}</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    {
                                                        this.state.getUsersList != undefined && this.state.getUsersList.length > 0 ? this.state.getUsersList.map((users, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td scope="row">{users.index}</td>
                                                                    <td>{users.name}</td>
                                                                    <td>{users.company_name}</td>
                                                                    <td>{users.dealer_certification_number}</td>
                                                                    <td>{users.phone_number}</td>
                                                                    <td>{siteConstants.LABEL_PUSH_MESSAGE}</td>
                                                                    <td>
                                                                        <a className="actionIcon" title="View" href="javascript:void(0)" onClick={() => this.userDetails(users)}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a>
                                                                        {/* <a className="actionIcon" title="View" href="javascript:void(0)" onClick={() => this.popupOpen(users, 'viewOpen')}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a> */}
                                                                        {/* <a className="actionIcon" title="Edit" href="javascript:void(0)" onClick={() => this.popupOpen(users, 'editOpen')}><span><img src="assets/img/EditIcon.png" alt="Edit" /></span></a> */}
                                                                        <a className="actionIcon" title="Delete" href="javascript:void(0)" onClick={() => this.popupOpen(users, 'deleteOpen')}><span><img src="assets/img/svg/Bin.png" alt="Delete" /></span></a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) : <tr>
                                                                <td colSpan="5">{siteConstants.GEN_NO_RECORD}</td>
                                                            </tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        {/* calling the another componenet */}
                                        <Pagination totalPage={this.state.totalPage} currentPage={this.state.currentPage} currentPage={this.state.currentPage} funPagination={this.makeHttpRequestWithPage} />



                                    </div>
                                </div>

                            </div>
                            : (this.state.varPartnerAddPage) ?
                                <PartnerAddUpdate userData={this.state.users} backButton={this.backButton} addEditTitle={this.state.varAddEditTitle}/>
                                : <UsersDetail userData={this.state.users} backButton={this.backButton} />}
                        {/* <!-- /.container-fluid --> */}

                    </div>
                    {/* <!-- End of Main Content --> */}

                    <Footer />
                </div>

                {/* Start Popup -Edit categories details  */}
                <EditPopup popupType="PartnersComponenet" addButton={this.state.addButton} editOpen={this.state.editOpen} funPopupClose={this.popupClose} funPartnersAddUpdate={(e, data) => this.partnersAddUpdate(e, data)} defaultData={this.state.partners} />

                {/* Delete popup */}
                <DeletePopup deleteOpen={this.state.deleteOpen} funPopupClose={this.popupClose} funDelete={this.deleteUser} />
            </div>
        );
    }
}


//-- Here we are adding Reducer names, so it can be get data from reducers using store
function mapStateToProps(state) {
    const { getUsersList, language } = state;
    return {
        getUsersList,
        language
    };
}

const connectedPartners = connect(mapStateToProps)(Partners);
export { connectedPartners as Partners };