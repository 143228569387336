import { commonService } from '../_services';
import { history } from '../_helpers';
import * as commonAction from './commonAction';
import { countryConstants } from '../_constants';
import { apiConstants } from '../_constants/api.constants';

export const countryActions = {
  getCountryList,
  enableCountry,
};

function getCountryList(apiName, countryData) {
  return (dispatch) => {
    dispatch(
      commonAction.request(countryConstants.GET_COUNTRY_REQUEST, countryData),
    );
    commonService.withOutTokenAndBaseUrl(apiName, countryData).then(
      (country) => {
        dispatch(
          commonAction.success(
            countryConstants.GET_COUNTRY_SUCCESS,
            country.data,
          ),
        );
      },
      (error) => {
        dispatch(
          commonAction.failure(
            countryConstants.GET_COUNTRY_FAILURE,
            error.response.data.error,
          ),
        );
      },
    );
  };
}

export function enableCountry(apiName, countryData) {
  let obj = {
    limit: 25,
    offset: 0,
  };

  return (dispatch) => {
    dispatch(
      commonAction.request(
        countryConstants.ENABLE_COUNTRY_REQUEST,
        countryData,
      ),
    );

    return commonService.withOutTokenAndBaseUrl(apiName, countryData).then(
      (country) => {
        dispatch(
          commonAction.success(
            countryConstants.ENABLE_COUNTRY_SUCCESS,
            country.data,
          ),
        );
        dispatch(getCountryList(apiConstants.GET_ALL_COUNTRIES, obj));
        return country;
      },
      (error) => {
        dispatch(
          commonAction.failure(
            countryConstants.ENABLE_COUNTRY_FAILURE,
            error.response.data.error,
          ),
        );
        throw error;
      },
    );
  };
}
