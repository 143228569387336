import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
import TopBar from '../Common/TopBar/TopBar';
import SideBar from '../Common/SideBar/SideBar';
import Footer from '../Common/Footer/Footer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import PageLimit from '../Common/PageLimit/PageLimit';
import Pagination from '../Common/Pagination/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';
import { environmentalCauseAction } from '../../_actions';
import { Badge } from 'antd';
// import { Switch } from "antd";
import moment from 'moment';
import 'antd/dist/antd.css';
import EnvironmentalCauseDetail from './environmentalCauseDetail';
import AddEnvironmentalCause from './AddEnvironmentalCause';
// import UpdateEnvironmentalCause from "./updateEnvironmentalCause";
import { CSVLink } from 'react-csv';
import { Box, CircularProgress } from '@material-ui/core';
import useDebounce from '../../hooks/useDebounce';

export default function Environmental_cause() {
  const [environmentalCauseDetails, setEnvironmentalCauseDetails] =
    useState(false);
  const [saveEnvironmentalCause, setSaveEnviromentalCause] = useState({});
  const [environmentalCauseAdd, setEnviromentalCauseAdd] = useState(false);
  const [updateEnviromentalCauseData, setUpdateEnviromentalCauseData] =
    useState();
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [deleteDetail, setDeleteDetail] = useState('');
  const [updateDetail, setUpdateDetail] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [enviromentalCauseId, setEnviromentalCauseId] = useState({});
  const [enviromentalCause, setEnviromentalCause] = useState({});
  const [NewOffset, setOffset] = useState(0);
  const [filteredEnvironmental, setFilteredEnvironmetal] = useState([]);
  const [isArchiveModal, setIsArchiveModal] = useState(false);
  const [isArchiveAction, setIsArchiveAction] = useState(false);
  const [dbFilterEnvCause, setDbFilterEnvCause] = useState();
  const [isFiltered, setIsFiltered] = useState(false); // to check if search filter functionality is triggered or not
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const debouncedSearchValue = useDebounce(dbFilterEnvCause?.cause_name, 1000);

  const dispatch = useDispatch();

  const environmental_cause = useSelector(
    (state) => state.getEnviromentalCause,
  );

  const total_page = useSelector((state) => state.getEnviromentalCause.pages);
  const environmental_cause_by_Id = useSelector(
    (state) => state.getEnviromentalCauseById.allEnviromentalCause,
  );

  useEffect(() => {
    if (environmental_cause_by_Id?.environmental_cause) {
      setEnviromentalCauseId(environmental_cause_by_Id?.environmental_cause);
      setEditLoading(false);
    }
  }, [environmental_cause_by_Id]);

  const getAllEnvCauses = () => {
    let obj = {
      limit: parseInt(limit),
      offset: 0,
    };
    dispatch(
      environmentalCauseAction.getEnvironmentalCause(
        apiConstants.GET_ENVIRONMENTAL_CAUSE,
        obj,
      ),
    );
  };

  useEffect(() => {
    getAllEnvCauses();
  }, []);

  const environmental_causeDetail = (environmental_cause) => {
    setEnvironmentalCauseDetails(true);
    setSaveEnviromentalCause(environmental_cause);
  };

  const backButton = () => {
    setEnvironmentalCauseDetails(false);
  };

  const openAddEnvironmentalDialog = () => {
    setEnviromentalCauseAdd(true);
  };

  const closeAddEnvironmentalDialog = () => {
    setEnviromentalCauseAdd(false);
    // dispatch(
    //   environmentalCauseAction.getEnvironmentalCause(
    //     apiConstants.GET_ENVIRONMENTAL_CAUSE
    //   )
    // );
  };

  const closeUpdateEnviromental = () => {
    setUpdatePopUp(false);
    dispatch(
      environmentalCauseAction.getEnvironmentalCause(
        apiConstants.GET_ENVIRONMENTAL_CAUSE,
      ),
    );
  };

  const closeDeletePopup = () => {
    setDeletePopUp(false);
  };

  const deleteSeeker = (id) => {
    setDeletePopUp(true);
    // let userId = {id:id}
    setDeleteDetail(id);
  };
  const updateEnviromental = (id) => {
    let userId = { id: id };
    setUpdateDetail(userId);
    dispatch(
      environmentalCauseAction.getEnvironmentalCauseById(
        apiConstants.GET_ENVIRONMENTAL_CAUSE_BY_ID,
        userId,
      ),
    );
  };

  const changeTableData = (e) => {
    setLimit(e.target.value);
    let obj = {
      limit: parseInt(e.target.value),
      offset: 0,
    };
    dispatch(
      environmentalCauseAction.getEnvironmentalCause(
        apiConstants.GET_ENVIRONMENTAL_CAUSE,
        obj,
      ),
    );
  };
  // Search function defined for search
  useEffect(() => {
    setFilteredEnvironmetal(environmental_cause?.allEnviromentalCause);
  }, [environmental_cause]);

  useEffect(() => {
    setLoading(environmental_cause?.loading);
  }, [environmental_cause?.loading]);

  const makeHttpRequestWithPage = (pageNumber) => {
    let obj = {
      limit: limit,
      offset: limit * (pageNumber - 1),
    };
    setCurrentPage(pageNumber);
    setOffset(obj.offset);
    if (dbFilterEnvCause) {
      obj['is_deleted'] = dbFilterEnvCause.is_deleted;
      obj['cause_name'] = dbFilterEnvCause.cause_name;
      return dispatch(
        environmentalCauseAction.getEnvironmentalCause(
          apiConstants.FILTER_ENV_CAUSE,
          obj,
        ),
      );
    }
    dispatch(
      environmentalCauseAction.getEnvironmentalCause(
        apiConstants.GET_ENVIRONMENTAL_CAUSE,
        obj,
      ),
    );
  };

  const handleChangeUpdate = (e) => {
    const { name, value } = e.target;
    setUpdateEnviromentalCauseData({
      ...updateEnviromentalCauseData,
      [name]: value,
    });
  };

  const handleArchiveSelect = (e) => {
    setDbFilterEnvCause({
      ...dbFilterEnvCause,
      is_deleted: e.target.value,
    });
  };

  const searchEnvCause = (dbFilterEnvCause) => {
    let obj = {
      limit: 25,
      offset: 0,
    };
    obj['is_deleted'] = dbFilterEnvCause.is_deleted;
    obj['cause_name'] = dbFilterEnvCause.cause_name;

    dispatch(
      environmentalCauseAction.getEnvironmentalCause(
        apiConstants.FILTER_ENV_CAUSE,
        obj,
      ),
    );
  };

  useEffect(() => {
    if (isFiltered) {
      searchEnvCause(dbFilterEnvCause);
    }
  }, [dbFilterEnvCause?.is_deleted]);

  useEffect(() => {
    if (isFiltered) {
      searchEnvCause(dbFilterEnvCause);
    }
  }, [debouncedSearchValue]);

  const handleUpdate = () => {
    let newItem = { ...updateEnviromentalCauseData, ...updateDetail };
    if (isArchiveModal) {
      newItem = {
        ...newItem,
        id: updateDetail,
        is_deleted: isArchiveAction === 'archive' ? 1 : 0,
      };
    }
    dispatch(
      environmentalCauseAction.updateEnvironmentalCause(
        apiConstants.UPDATE_ENVIRONMENTAL_CAUSE,
        newItem,
      ),
    );
    setUpdatePopUp(false);
    setUpdateDetail('');
    setUpdateEnviromentalCauseData();
    if (isArchiveModal) setIsArchiveModal(false);
  };

  const handleDelete = () => {
    let userId = { id: deleteDetail };
    dispatch(
      environmentalCauseAction.deleteEnvironmentalCause(
        apiConstants.DELETE_ENVIRONMENTAL_CAUSE,
        userId,
      ),
    );
    setDeletePopUp(false);
  };

  const headers = [
    // { label: "ID", key: "id" },
    { label: 'Cause Name', key: 'cause_name' },
    { label: 'Status', key: 'status' },
    // { label: "Is Deleted", key: "is_deleted" },
    // { label: "Created By", key: "created_by_admin" }
  ];

  return (
    <div id='wrapper'>
      <SideBar />
      <div id='content-wrapper' className='d-flex flex-column'>
        <div id='content'>
          <TopBar />
          <nav className='' aria-label='breadcrumb'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <a href='/dashboard'>Dashboard</a>
              </li>
              <li className='active breadcrumb-item' aria-current='page'>
                Environmental Cause
              </li>
            </ol>
          </nav>
          {!environmentalCauseDetails ? (
            <div className='container-fluid'>
              <div className='d-sm-flex align-items-center justify-content-between mb-4'>
                <h1 className='h3 mb-2 text-gray-800'>Environmental Cause</h1>
                <div className='d-sm-flex align-items-center justify-content-between'>
                  {/* <CSVLink
                    className="btn btn-primary mr-3"
                    data={environmental_cause.map(Object.values)}
                    headers={headers.map(Object.values)}
                  >
                    Export
                  </CSVLink> */}
                  <CSVLink
                    className='btn btn-primary mr-3'
                    data={environmental_cause?.allEnviromentalCause}
                    headers={headers}
                  >
                    Export
                  </CSVLink>
                  <a href='#!' onClick={() => console.log('provider add')}>
                    <span className='text'>
                      <button
                        onClick={openAddEnvironmentalDialog}
                        className='btn btn-primary'
                      >
                        Add New Environmental Cause
                      </button>
                    </span>
                  </a>
                </div>
              </div>
              <div className='card shadow mb-4'>
                <div className='card-body'>
                  <div className='table-responsive'>
                    <div className='row mb-4'>
                      {/* Page limit drop down */}
                      {/* <PageLimit funChangePageLimit={changeTableData} /> */}

                      <div className='col-sm-3 col-md-2'>
                        <label>Search by name</label>
                        <div className='d-flex justify-content-center align-items-centers'>
                          <input
                            type='search'
                            className='form-control form-control-sm'
                            placeholder='Search...'
                            onChange={(e) => {
                              setIsFiltered(true);
                              setDbFilterEnvCause({
                                ...dbFilterEnvCause,
                                cause_name: e.target.value,
                              });
                            }}
                            aria-controls='dataTable'
                          />
                        </div>
                      </div>
                      <div className='col-sm-3 col-md-2'>
                        <label>Archived</label>
                        <select
                          className='form-control form-control-sm'
                          name='provide_id'
                          onChange={handleArchiveSelect}
                        >
                          <option value={''}>Show All</option>
                          <option value={'yes'}>Yes</option>
                          <option value={'no'}>No</option>
                        </select>
                      </div>
                    </div>
                    <table
                      className='table table-bordered'
                      width='100%'
                      cellSpacing='0'
                    >
                      <thead>
                        <tr>
                          <th scope='col'>No.</th>
                          <th scope='col'>Name</th>
                          <th scope='col'>Archived</th>
                          <th scope='col'>Created At</th>
                          <th scope='col'>Updated At</th>
                          <th scope='col'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <td className='' colSpan={'100%'}>
                            <div className='col-md-12 justify-content-center align-items-center'>
                              <Box className='d-flex justify-content-center'>
                                <CircularProgress />
                              </Box>
                            </div>
                          </td>
                        ) : (
                          <>
                            {filteredEnvironmental !== undefined &&
                            filteredEnvironmental.length > 0
                              ? filteredEnvironmental.map((data, index) => {
                                  let createdAt = moment(data.createdAt).format(
                                    'YYYY-MM-DD',
                                  );
                                  let updatedAt = moment(data.updatedAt).format(
                                    'YYYY-MM-DD',
                                  );
                                  var count = 0;
                                  //  var pageData = config.perPageData;
                                  count = index + NewOffset + 1;

                                  return (
                                    <tr key={index}>
                                      <td scope='row'>{count}</td>
                                      <td>{data.cause_name}</td>
                                      <td>{data.is_deleted ? 'Yes' : 'No'}</td>
                                      <td>{createdAt}</td>
                                      <td>{updatedAt}</td>

                                      <td>
                                        {/* <a className="actionIcon" title="View" href="#!" onClick={() => environmental_causeDetail(environmental_cause)}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a> */}
                                        {/* <a className="actionIcon" title="View" href="#!" onClick={() => this.popupOpen(users, 'viewOpen')}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a> */}
                                        <a
                                          className='actionIcon mr-3'
                                          title='Edit'
                                          href='#!'
                                          onClick={() => {
                                            setEditLoading(true);
                                            setUpdatePopUp(true);
                                            updateEnviromental(data.id);
                                          }}
                                        >
                                          <span>
                                            <img
                                              src='assets/img/svg/EditIcon.png'
                                              alt='Edit'
                                            />
                                          </span>
                                        </a>
                                        <span>
                                          {data.is_deleted ? (
                                            <Button
                                              onClick={() => {
                                                setIsArchiveModal(true);
                                                setUpdateDetail(data?.id);
                                                setIsArchiveAction('unarchive');
                                              }}
                                              variant='contained'
                                              size='xs'
                                            >
                                              Unarchive
                                            </Button>
                                          ) : (
                                            <Button
                                              onClick={() => {
                                                setIsArchiveModal(true);
                                                setUpdateDetail(data?.id);
                                                setIsArchiveAction('archive');
                                              }}
                                              variant='contained'
                                              size='xs'
                                            >
                                              Archive
                                            </Button>
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })
                              : null}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* calling the another componenet */}
                  <Pagination
                    totalPage={total_page}
                    currentPage={currentPage}
                    funPagination={makeHttpRequestWithPage}
                  />
                </div>
              </div>
            </div>
          ) : (
            <EnvironmentalCauseDetail
              data={saveEnvironmentalCause}
              backButton={backButton}
            />
          )}
        </div>

        {/* add environmental_cause popup */}
        {environmentalCauseAdd === true ? (
          <AddEnvironmentalCause
            displayPopUp={environmentalCauseAdd}
            hidePopUp={closeAddEnvironmentalDialog}
          />
        ) : (
          ''
        )}
        {/* {
          updatePopUp === true ? <UpdateEnvironmentalCause id={updateDetail} displayPopUp={updatePopUp} hidePopUp={closeUpdateEnvironmentalDialog} /> : ''
        } */}
        <Dialog
          open={updatePopUp}
          aria-labelledby='add-products'
          aria-describedby='save-products-description'
          fullWidth={true}
        >
          <DialogTitle>Update Environmental Cause</DialogTitle>
          <DialogContent>
            {editLoading ? (
              <div className='d-flex justify-content-center align-items-center'>
                <CircularProgress size={40} />
              </div>
            ) : (
              <div className='row'>
                <div className='col-md-12 col-xs-12'>
                  <div className='form-group'>
                    <label>Cause Name</label>
                    <input
                      defaultValue={enviromentalCauseId.cause_name}
                      type='text'
                      onChange={handleChangeUpdate}
                      placeholder='Cause Name'
                      className='form-control'
                      name='cause_name'
                    />
                  </div>
                  {/* <p className={classes.error}>{this.state.eventTitle_error}</p> */}
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleUpdate()}
            >
              Update
            </Button>
            <Button variant='contained' onClick={closeUpdateEnviromental}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isArchiveModal}
          fullWidth={true}
          aria-labelledby='delete-product-notify'
        >
          <DialogTitle>
            {isArchiveAction && isArchiveAction.toUpperCase()}
          </DialogTitle>
          <DialogContent>
            <h5 className='mt-3'>
              Are you sure you want to {isArchiveAction} environmental cause?
            </h5>
            {isArchiveAction === 'archive' && (
              <h6 className='mt-3'>
                It will delete all mappings with experience.
              </h6>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='secondary'
              onClick={handleUpdate}
            >
              {isArchiveAction}
            </Button>
            <Button
              variant='contained'
              onClick={() => setIsArchiveModal(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deletePopUp}
          fullWidth={true}
          aria-labelledby='delete-product-notify'
        >
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            Are you sure?
            <h5 className='mt-3'>You want to delete {deleteDetail}?</h5>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => handleDelete()}
            >
              Delete
            </Button>
            <Button variant='contained' onClick={closeDeletePopup}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Footer />
      </div>
    </div>
  );
}
