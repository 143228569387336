import React, { useEffect, useState } from 'react';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import PageLimit from "../Common/PageLimit/PageLimit";
import Pagination from "../Common/Pagination/Pagination";
import 'antd/dist/antd.css';
// import config from '../../config';
import { useSelector, useDispatch } from 'react-redux';
import {notificationActions} from "../../_actions/notification.action";
import { apiConstants } from '../../_constants/api.constants';

export default function Notification() {
  const [notification, setNotification] = useState([])
  const [notificationFlag, setNotificationFlag] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [filteredNotification, setFilteredNotification] = useState([]);
  const [NewOffset, setOffset] = useState(0);
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')))
  const dispatch = useDispatch();
  const getNotificationsList = useSelector(state => state.getNotificationList);

  useEffect(() => {
    let obj = {
      limit: limit,
      offset: 0
    }
    dispatch(notificationActions.getNotificationsList(apiConstants.GET_NOTIFICATION, obj));
  }, [])

  useEffect(() => {
    setFilteredNotification(
      notification.filter((data) =>
      data.to.toLowerCase().includes(search.toLowerCase()) ||
      data.from.toLowerCase().includes(search.toLowerCase())
      // data.user_fname.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, notification]);
  console.log(getNotificationsList  && getNotificationsList.pages,"bfbfdbghf")

  const changeTableData = (e) => {
    setLimit(e.target.value)
    let obj = {
      limit: parseInt(e.target.value),
      offset: 0,
    };
    dispatch(notificationActions.getNotificationsList(apiConstants.GET_NOTIFICATION, obj));
  };

  const makeHttpRequestWithPage = (pageNumber) => {
    setCurrentPage(pageNumber)
    let obj = {
      limit: limit,
      offset:pageNumber - 1
    }
    setOffset(obj.offset)
    dispatch(notificationActions.getNotificationsList(apiConstants.GET_NOTIFICATION, obj));
}

  useEffect(()=> {
    if(getNotificationsList.getNotificationList && getNotificationsList.getNotificationList.data){
      setNotification(getNotificationsList.getNotificationList.data)
      console.log("getNotificationsList", getNotificationsList && getNotificationsList.getNotificationList.pages)
    }
      
  },[getNotificationsList])

  const handleSort = (e) => {
    if(notificationFlag == 1){
      setNotificationFlag(0)
    }else if(notificationFlag == 0){
      setNotificationFlag(1)
    }
   let obj = {
     limit:limit,
     offset: currentPage,
     sortByStatus: notificationFlag,
     sortBy: e.target.name
   }
  //  setCompany(company.sort((a,b) => parseFloat(a.user_id) - parseFloat(b.user_id)))
  dispatch(notificationActions.getNotificationsList(apiConstants.NOTIFICATION_SORT, obj));
  }

  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Notification
              </li>
            </ol>
          </nav>
            <div className="container-fluid">
              
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="row">
                      {/* Page limit drop down */}
                      <PageLimit funChangePageLimit={changeTableData} />

                      <div className="col-sm-12 col-md-6">
                        <div id="dataTable_filter" className="dataTables_filter">
                          <label>
                            <input type="search" onChange={(e) => setSearch(e.target.value)} className="form-control form-control-sm" placeholder="Search" aria-controls="dataTable" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <table className="table table-bordered" width="100%" cellSpacing="0">
                      <thead>
                        <tr>
                          <th scope="col">No.</th>
                          <th scope="col">To
                          <img src="assets/img/svg/sort.png" name="to" className="imageSort" onClick={handleSort}/>
                          </th>
                          <th scope="col">From
                          <img src="assets/img/svg/sort.png" name="from" className="imageSort" onClick={handleSort}/>
                          </th>
                          <th scope="col">Template Id</th>
                          <th scope="col">User Name
                          <img src="assets/img/svg/sort.png" name="user_fname" className="imageSort" onClick={handleSort}/>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {(filteredNotification !== undefined && filteredNotification) ? (filteredNotification || []).map((notification, index) => {
                         var count = 0;
                        //  var pageData = config.perPageData;
                         count = index + (NewOffset*10)+1
                         
                       return (
                        <tr key={index}>
                          <td scope="row">{count}</td>
                          <td>{notification.to}</td>                  
                          <td>{notification.from}</td>
                          <td>{notification.templateId}</td>
                          <td>{notification.user_fname}</td>
                         
                        </tr>
                          )
                        }) : null}
                      </tbody>
                    </table>
                  </div>
                  {/* calling the another componenet */}
                  {/* <Pagination totalPage={(getNotificationsList && getNotificationsList.pages) ? getNotificationsList.pages : 1 } currentPage={currentPage} funPagination={makeHttpRequestWithPage} /> */}
                   <Pagination totalPage={(getNotificationsList && getNotificationsList.getNotificationList) ? getNotificationsList.getNotificationList.pages : 0} currentPage={currentPage} funPagination={makeHttpRequestWithPage} />
                </div>
              </div>
            </div> 
        </div>
        <Footer />
      </div>
    </div>
  )
}
