import { companyConstants } from '../_constants';

export function addCompany(state = {}, action) {
  switch (action.type) {
    case companyConstants.COMPANY_ADDED_REQUEST:
      return {
        addCompany: false,
      };

    case companyConstants.COMPANY_ADDED_SUCCESS:
      return { addCompany: action.successAction };

    case companyConstants.COMPANY_ADDED_FAILURE:
      return {
        addCompany: false,
      };

    default:
      return state;
  }
}

export function getCompanyById(state = {}, action) {
  switch (action.type) {
    case companyConstants.GET_ORGANISATION_REQUEST:
      return {
        getCompanyById: false,
      };

    case companyConstants.GET_ORGANISATION_SUCCESS:
      return { getCompanyById: action.successAction };

    case companyConstants.GET_ORGANISATION_FAILURE:
      return {
        getCompanyById: false,
      };

    default:
      return state;
  }
}

export function getAllCompany(state = {}, action) {
  switch (action.type) {
    case companyConstants.GET_ALL_ORGANISATION_REQUEST:
      return {
        getAllCompany: false,
      };

    case companyConstants.GET_ALL_ORGANISATION_SUCCESS:
      return { allCompanyData: action.successAction };

    case companyConstants.GET_ALL_ORGANISATION_FAILURE:
      return {
        getAllCompany: false,
      };

    default:
      return state;
  }
}

export function getOrganisationById(state = {}, action) {
  switch (action.type) {
    case companyConstants.GET_ALL_ORGANISATION_BY_ID_REQUEST:
      return {
        getOrganisationById: false,
      };

    case companyConstants.GET_ALL_ORGANISATION_BY_ID_SUCCESS:
      return { getOrgById: action.successAction };

    case companyConstants.GET_ALL_ORGANISATION_BY_ID_FAILURE:
      return {
        getOrganisationById: false,
      };

    default:
      return state;
  }
}

export function updateOrganisationById(state = {}, action) {
  switch (action.type) {
    case companyConstants.UPDATE_ORGANISATION_BY_ID_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: null,
      };

    case companyConstants.UPDATE_ORGANISATION_BY_ID_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        updateOrgById: action.payload,
        error: null,
      };

    case companyConstants.UPDATE_ORGANISATION_BY_ID_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };

    default:
      return state;
  }
}

export function getCompany(state = {}, action) {
  switch (action.type) {
    case companyConstants.GET_COMPANY_REQUEST:
      return {
        getCompany: false,
      };

    case companyConstants.GET_COMPANY_SUCCESS:
      return { getCompany: action.successAction };

    case companyConstants.GET_COMPANY_REQUEST_FAILURE:
      return {
        getCompany: false,
      };

    default:
      return state;
  }
}
