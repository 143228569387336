import React, { useEffect, useState } from 'react';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import { useSelector, useDispatch } from 'react-redux';
import { companyActions,  } from '../../_actions';
import { apiConstants } from '../../_constants/api.constants';
// import UpdateComapny from './UpdateCompany'
// import { Form } from 'antd';
import { history } from '../../_helpers';

export default function AddCompany(props) {
  const [companyData, setCompanyData] = useState({
    title: "",
    organisation_detail: "",
    mission_value1: "",
    mission_value2: "",
    mission_value3: "",
    mission_value4: "",
    video_title: "",
  })
  const [videoUrl, setVideoUrl] = useState({video_url:''})
  const [imagesArr, setImagesArr] = useState([])
  const [coverImage, setCoverImages] = useState('')
  const [logoImage, setLogoImage] = useState('')
  const [organisation, setOrganisation] = useState({})
  const [user_id, setUserId] = useState('')
  const [organisationId, setOrganisationId] = useState('')
  const [companyName, setCompany] = useState('')
  const [flag, setFlag] = useState(false)

  const dispatch = useDispatch();
  const getCompany = useSelector(state => state.getOrganisationById);

  useEffect(() => {
    dispatch(companyActions.getOrganisationByOrgId(apiConstants.GET_ORGANISATION_BY_ID, {id: parseInt(props.match.params.id)}));
  }, [])

  useEffect(()=>{
    if(getCompany.getOrgById){
      setOrganisation(getCompany.getOrgById.data.organisation)
    }
  },[getCompany])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({ ...companyData, [name]: value })
  }
  const handleVideoUrlChange = (e)=>{
    const { value } = e.target;
    setVideoUrl({video_url:value})
  }
  const handleChangeImages = (e) => {
    if (e.target.files.length <= 10) {
      setImagesArr(e.target.files)
    } else {
      e.preventDefault();
      e.target.value = null;
      alert(`Cannot upload files more than 10`);
    }
  }

  const handleChangeCoverPhoto = (e) => {
    setCoverImages(e.target.files[0])
  }

  const handleChangeLogo = (e) => {
    setLogoImage(e.target.files[0])
  }

  const handleSelectProvider = (e) => {
    setOrganisationId(e.target.value)
    // setUserId(e.target.value)
  }

  const handleSubmit = () => {
        var youtube_Url = "";
        var youtube_main_url = videoUrl.video_url
    
        if (youtube_main_url.includes("=")) {
          youtube_Url =
            "https://www.youtube.com/embed/" + youtube_main_url.split("=")[1];
        } else if (youtube_main_url.includes("/")) {
          youtube_Url =
            "https://www.youtube.com/embed/" + youtube_main_url.split("/")[4];
        } else if (youtube_main_url) {
          youtube_Url = "https://www.youtube.com/embed/" + youtube_main_url;
        } else {
          youtube_Url = "";
        }
  
    let formData = new FormData();
    formData.append("user_id", parseInt(props.match.params.id))
    formData.append("organisation_id", parseInt(props.match.params.id));
    formData.append("cover_photo", coverImage);
    formData.append("logo", logoImage);
    formData.append('video_url', youtube_Url)

    for (const key in companyData) {
      formData.append(key, companyData[key])
    }

    for (let i = 0; i < imagesArr.length; i++) {
      formData.append("files", imagesArr[i])
    }

    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    dispatch(companyActions.addCompany(apiConstants.ADD_COMPANY, formData));
    history.push('/company')
  }

  console.log("organisation==>", Object.keys(organisation).length)
  // if(Object.keys(organisation).length){
  //   console.log("true****")
    return (
      <div id='wrapper'>
        <SideBar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopBar />
            <nav className="" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="active breadcrumb-item" aria-current="page">
                  Company
              </li>
              </ol>
            </nav>
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-2 text-gray-800">
                  Add Company
              </h1>
              </div>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="table-responsive">
  
                    <div className="row">
                      {/* <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>Provider Name</label>
                          <select className="form-control" name="type" onChange={handleSelectProvider}>
                            {provider !== undefined && provider.length > 0 ? provider.map((provider, index) => {
                              return (
                                <option value={provider.id}>{provider.first_name}</option>
                              )
                            }) : null}
                          </select>
                        </div>
                      </div> */}
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>Company Name</label>
                          <input
                            value={companyName}
                            type="text"
                            placeholder='Company Name'
                            className="form-control"
                            name="company_name"
                            disabled
                          />
                        </div>
                        {/* <p className={classes.error}>{this.state.eventTitle_error}</p> */}
                      </div>
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            value={companyData.title}
                            type="text"
                            min="0"
                            onChange={handleChange}
                            placeholder="Title"
                            className="form-control"
                            name="title"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>Cover Photo</label>
                          <input
                            type="file"
                            onChange={handleChangeCoverPhoto}
                            className="form-control"
                            name="cover_photo"
                            accept=".png,.jpg,.jpeg"
                          />
                        </div>
                        {/* <p className={classes.error}>{this.state.eventDescription_error}</p> */}
                      </div>
  
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>Company Logo</label>
                          <input
                            type="file"
                            onChange={handleChangeLogo}
                            className="form-control"
                            name="logo"
                            accept=".png,.jpg,.jpeg"
                          />
                        </div>
                        {/* <p className={classes.error}>{this.state.eventDate_error}</p> */}
                      </div>
  
  
                     
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Organisation Details</label>
                          <textarea
                            value={companyData.organisation_detail}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Organisation Details"
                            name="organisation_detail"
                          >
                          </textarea>
                        </div>
                        {/* <p className={classes.error}>{this.state.address_error}</p> */}
                      </div>
  
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>Mission Value 1</label>
                          <input
                            value={companyData.mission_value1}
                            type="text"
                            min="0"
                            onChange={handleChange}
                            placeholder="Mission Value 1"
                            className="form-control"
                            name="mission_value1"
                          />
                        </div>
                        {/* <p className={classes.error}>{this.state.city_error}</p> */}
                      </div>
  
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>Mission value 2</label>
                          <input
                            value={companyData.mission_value2}
                            type="text"
                            min="0"
                            onChange={handleChange}
                            placeholder="Mission Value 2"
                            className="form-control"
                            name="mission_value2"
                          />
                        </div>
                        {/* <p className={classes.error}>{this.state.city_error}</p> */}
                      </div>
  
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>Mission Value 3</label>
                          <input
                            value={companyData.mission_value3}
                            type="text"
                            min="0"
                            onChange={handleChange}
                            placeholder="Mission Value 3"
                            className="form-control"
                            name="mission_value3"
                          />
                        </div>
                        {/* <p className={classes.error}>{this.state.city_error}</p> */}
                      </div>
  
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>Mission Value 4</label>
                          <input
                            value={companyData.mission_value4}
                            type="text"
                            min="0"
                            onChange={handleChange}
                            placeholder="Mission Value 4"
                            className="form-control"
                            name="mission_value4"
                          />
                        </div>
                        {/* <p className={classes.error}>{this.state.city_error}</p> */}
                      </div>
  
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>Images</label>
                          <input
                            type="file"
                            onChange={handleChangeImages}
                            className="form-control"
                            name="images"
                            accept=".png,.jpg,.jpeg"
                            multiple
                          />
                        </div>
                        {/* <p className={classes.error}>{this.state.city_error}</p> */}
                      </div>
  
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <label>Video Title</label>
                          <input
                            value={companyData.video_title}
                            type="text"
                            min="0"
                            onChange={handleChange}
                            placeholder="Video Title"
                            className="form-control"
                            name="video_title"
                          />
                        </div>
                        {/* <p className={classes.error}>{this.state.city_error}</p> */}
                      </div>
  
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Video Url</label>
                          <input
                            value={companyData.video_url}
                            type="text"
                            min="0"
                            onChange={handleVideoUrlChange}
                            placeholder="Video Url"
                            className="form-control"
                            name="video_url"
                          />
                        </div>
                        {/* <p className={classes.error}>{this.state.city_error}</p> */}
                      </div>
                      {
                        organisation != null ? <div className="col-lg-12">
                        <span className="text">
                          <button className="btn btn-primary">Update</button>
                          </span>
                      </div> :  <div className="col-lg-12">
                        <span className="text">
                          <button className="btn btn-primary" onClick={handleSubmit}>Add</button>
                          </span>
                      </div>
                      }
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  // }else{
  //  return (
  //    <>
  //     <UpdateComapny id={parseInt(props.match.params.id)}/>
  //    </>
  //  )
  // }
  
}
