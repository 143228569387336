import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import {
  providerActions,
  locationActions,
  experienceActions,
  environmentalCauseAction,
  experienceActivityTypeActions,
  experienceEnvCauseActions,
  countryActions,
  companyActions,
} from '../../_actions';
import { apiConstants } from '../../_constants/api.constants';
import Select from 'react-select';
// import { history } from '../../_helpers';
import { Editor } from '@tinymce/tinymce-react';
import Chip from '@material-ui/core/Chip';

export default function ExperienceDetails({ backButton, data }) {
  // const [selectedDate, setSelectedDate] = useState(new Date('2014-08-18T21:11:54'));
  const [experienceData, setExperienceData] = useState({});
  const [selectProvider, setSelectProvider] = useState('');
  const [options, setOption] = useState([]);
  const [tempDescription, setDescription] = useState('');
  const [selectLocation, setSelectLocation] = useState('');
  const [imagesArr, setImagesArr] = useState([]);
  const [tempimagesArr, setTempImagesArr] = useState([]);
  const [selectDuration, setSelectDuration] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(
    new Date('2014-08-18T21:11:54'),
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    new Date('2014-08-18T21:11:54'),
  );
  const [experienceSchedule, setExperienceSchedule] = useState([{}]);
  const [chargeTypeFlatFee, setChargeTypeFlatFee] = useState(false);
  const [chargeTypePerPerson, setChargeTypePerPerson] = useState(false);
  const [highlights, setHighlights] = useState({});
  const [locationsData, setLocationData] = useState([]);
  const provider = useSelector((state) => state.getProvider.allProvider);
  const locations = useSelector((state) => state.getLocationById);
  const experience = useSelector((state) => state.getExperienceById);
  const [selectedEnvironmental, setSelectedEnvironmental] = useState([]);
  const dispatch = useDispatch();
  const [activityTypeData, setActivityTypeData] = useState([]);
  const experienceActivityTypeSelector = useSelector(
    (state) => state.getExperienceActivityTypeById,
  );
  const experienceEnvCauseSelector = useSelector(
    (state) => state.getExperienceEnvCauseById,
  );
  const [country, setCountry] = useState('');
  const [orgData, setOrgData] = useState('');
  const countries = useSelector((state) => state.getCountryList);
  const orgs = useSelector((state) => state.getAllCompany);

  useEffect(() => {
    let obj = {
      id: data.id,
    };
    dispatch(
      experienceActions.getExperienceById(
        apiConstants.GET_EXPERIENCE_BY_ID,
        obj,
      ),
    );
    dispatch(
      environmentalCauseAction.getEnvironmentalCause(
        apiConstants.GET_ENVIRONMENTAL_CAUSE,
        obj,
      ),
    );
    dispatch(
      experienceActivityTypeActions.getExperienceActivityTypeById(
        apiConstants.GET_ALL_EXPERIENCE_ACTIVITY_TYPES_BY_ID,
        obj,
      ),
    );
    dispatch(
      experienceEnvCauseActions.getExperienceEnvCauseById(
        apiConstants.GET_ALL_EXPERIENCE_ENV_CAUSES_BY_ID,
        {
          ...obj,
        },
      ),
    );

    if (data?.country_id) {
      dispatch(
        countryActions.getCountryList(apiConstants.GET_ALL_COUNTRIES, {
          id: data.country_id,
        }),
      );
    }

    if (data?.org_id) {
      dispatch(
        companyActions.getOrganisationDetails(
          apiConstants.GET_ORGANISATION_DETAILS,
          {
            id: data.org_id,
          },
        ),
      );
    }
  }, []);

  useEffect(() => {
    if (countries?.getCountryList) {
      setCountry(countries.getCountryList.country[0]?.name);
    }
  }, [countries]);

  useEffect(() => {
    if (orgs?.allCompanyData) {
      setOrgData(orgs.allCompanyData.data?.organisation?.company_name);
    }
  }, [orgs]);

  // not needed
  // useEffect(() => {
  //   if (environmental_cause) {
  //     console.log("environmental_cause", environmental_cause);
  //     //   setOption(environmental_cause)
  //     let arr = [];
  //     environmental_cause?.map((data) => {
  //       let temObj = {
  //         value: data.cause_name,
  //         label: data.cause_name,
  //       };
  //       arr.push(temObj);
  //     });
  //     setOption(arr);
  //   }
  // }, [environmental_cause]);

  useEffect(() => {
    if (
      experienceActivityTypeSelector?.getExperienceActivityTypeById
        ?.experience_activity_type
    ) {
      const data =
        experienceActivityTypeSelector.getExperienceActivityTypeById
          .experience_activity_type;
      const updatedActivityType = data.map((val) => {
        return {
          value: val.ActivityType?.id,
          label: val.ActivityType?.activity_type,
        };
      });
      setActivityTypeData(updatedActivityType);
    }
  }, [experienceActivityTypeSelector]);

  useEffect(() => {
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER));
  }, []);

  useEffect(() => {
    if (locations && locations.getLocationById) {
      setLocationData(locations.getLocationById.data);
    }
  }, [locations]);

  useEffect(() => {
    setHighlights(JSON.parse(data.highlights));
    setExperienceSchedule(JSON.parse(data.days_time));
    // setSelectedEnvironmental(experience.getExperienceById.data.experience.environmental_causes)
    if (data.charge_type == 'flat_fee') {
      setChargeTypeFlatFee(true);
    } else if (data.charge_type == 'per_person') {
      setChargeTypePerPerson(true);
    }

    // let temp = JSON.parse(data.environmental_causes);
    // let temArr = [];
    // temp?.forEach((i) => {
    //   let obj = {
    //     value: i,
    //     label: i,
    //   };
    //   temArr.push(obj);
    // });
    // setSelectedEnvironmental(temArr);

    let tempImageArr = JSON.parse(data.images);
    let temImageArr = [];
    tempImageArr.forEach((i) => {
      temImageArr.push(i);
    });
    setTempImagesArr(temImageArr);
  }, []);

  useEffect(() => {
    if (
      experienceEnvCauseSelector?.getExperienceEnvCauseById
        ?.experience_env_cause
    ) {
      const data =
        experienceEnvCauseSelector.getExperienceEnvCauseById
          .experience_env_cause;
      const updatedEnvCause = data.map((val) => {
        return {
          value: val.EnvironmentalCause?.id,
          label: val.EnvironmentalCause?.cause_name,
        };
      });

      setSelectedEnvironmental(updatedEnvCause);
    }
  }, [experienceEnvCauseSelector]);

  return (
    <div className='container-fluid'>
      <div className='d-sm-flex align-items-center justify-content-between mb-4'>
        <h1 className='h3 mb-0 text-gray-800'>Experience Details</h1>
        <a
          href='#'
          onClick={backButton}
          className='btn btn-secondary btn-icon-split'
        >
          <span className='icon text-white-50'>
            <i className='fas fa-arrow-left'></i>
          </span>
          <span className='text'>Back</span>
        </a>
      </div>

      <div className='card shadow mb-4'>
        <div className='card-body'>
          <div className='table-responsive'>
            <div className='row'>
              <div className='col-sm-12 formBorder'>
                <div className='p-5'>
                  <div className='form-group row'>
                    <div className='col-sm-6'>
                      <label>Provider Name</label>
                      <span
                        className='ml-2 text-primary'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          window.open(
                            `/provider_detail?id=${data.provider_id}`,
                            '_blank',
                          );
                        }}
                      >
                        {' '}
                        See details
                      </span>
                      <br></br>
                      <select
                        className='form-control'
                        name='provider_id'
                        value={data.provider_id}
                        disabled
                      >
                        <option>Select Provider name</option>
                        {provider !== undefined && provider.length > 0
                          ? provider.map((provider, index) => {
                              return (
                                <option value={provider.id}>
                                  {provider.first_name} {provider.last_name}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                    <div className='col-sm-6 mb-3 mb-sm-0'>
                      <label>Country</label>
                      <select
                        className='form-control'
                        name='country'
                        onChange
                        disabled
                      >
                        <option>{country}</option>
                      </select>
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-sm-6'>
                      <label>Location address</label>
                      <select
                        className='form-control'
                        name='location_id'
                        disabled
                      >
                        <option>Select Location</option>
                        <option value={data.location_address} selected>
                          {' '}
                          {data.location_address}{' '}
                        </option>
                        {/* {locationsData !== undefined && locationsData.length > 0 ? locationsData.map((locationsData, index) => {
                          return (
                            <option value={locationsData.id}>{locationsData.location_address}</option>
                          )
                        }) : null} */}
                      </select>
                    </div>

                    <div className='col-sm-6'>
                      <label>Team Size</label>
                      <input
                        type='number'
                        className='form-control form-control-user'
                        name='team_size'
                        placeholder='Team Size'
                        defaultValue={data.team_size}
                        disabled
                      />
                    </div>
                  </div>
                  <div className='form-group'>
                    <label>Organisation</label>
                    <select
                      className='form-control'
                      name='organisation'
                      onChange
                      disabled
                    >
                      <option>{orgData}</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <label>Experience Name</label>
                    <span
                      className='ml-2 text-primary'
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        window.open(
                          `/experience_detail?id=${data.id}`,
                          '_blank',
                        );
                      }}
                    >
                      {' '}
                      See details
                    </span>
                    <br></br>
                    <input
                      type='text'
                      className='form-control form-control-user'
                      name='experience_name'
                      placeholder='Experience Name'
                      defaultValue={data.experience_name}
                      disabled
                    />
                  </div>
                  <div className='form-group'>
                    <label>Experience Duration</label>
                    <select
                      className='form-control'
                      name='type'
                      value={data.experience_duration}
                      disabled
                    >
                      <option value='1'>Half Day</option>
                      <option value='2'>One Day</option>
                      <option value='3'>Multiple days</option>
                    </select>
                  </div>

                  <div className='form-group'>
                    <label
                      className=''
                      style={{
                        display: 'flex',
                      }}
                    >
                      <span className='mr-2'>CRM</span>
                      {data.crm === 'Rezdy' ? (
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                          }}
                        >
                          <div
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            <a
                              href={
                                data?.product_code
                                  ? `${process.env.REACT_APP_REZDY_IFRAME_BOOKING_URL}/${data?.product_code}`
                                  : '#'
                              }
                              className='button-booking rezdy rezdy-modal  text-primary'
                            >
                              View Experience
                            </a>
                          </div>
                        </div>
                      ) : (
                        ``
                      )}
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-user'
                      name='crm'
                      placeholder='CRM'
                      defaultValue={data.crm}
                      disabled
                    />
                  </div>

                  <div className='form-group'>
                    <div>Activity Types</div>
                    {activityTypeData?.length > 0 ? (
                      <div className='d-flex'>
                        {activityTypeData.map((val, index) => (
                          <Chip
                            className='mt-2 mr-2'
                            key={index}
                            label={val?.label}
                          />
                        ))}
                      </div>
                    ) : (
                      <Chip
                        className='mt-2 mr-2'
                        label='No activity types mapped'
                        disabled
                      />
                    )}
                  </div>

                  {experienceSchedule?.map((experiencedata, index) => {
                    return (
                      <div key={index}>
                        <div className='form-group row'>
                          <div className='col-sm-2'>
                            <label>Day {index + 1}</label>
                          </div>
                          <div className='col-sm-5'>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid container justifyContent='space-around'>
                                <KeyboardTimePicker
                                  margin='normal'
                                  id='time-picker'
                                  name='start_time'
                                  label='Start Time'
                                  value={experienceSchedule[index].start_time}
                                  defaultValue={
                                    experienceSchedule[index].start_time
                                  }
                                  KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                  }}
                                  disabled
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                          </div>
                          <div className='col-sm-5'>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid container justifyContent='space-around'>
                                <KeyboardTimePicker
                                  margin='normal'
                                  id='time-picker'
                                  label='End Time'
                                  value={experienceSchedule[index].end_time}
                                  defaultValue={
                                    experienceSchedule[index].end_time
                                  }
                                  KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                  }}
                                  disabled
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className='form-group'>
                    <input
                      type='radio'
                      className='form-check-input'
                      name='charge_type'
                      value='flat_fee'
                      checked={chargeTypeFlatFee}
                    />
                    <label>Flat fee</label>
                    <input
                      type='number'
                      className='input-with-radio'
                      name='amount'
                      placeholder='Amount'
                      defaultValue={data.amount}
                      disabled
                    />

                    <input
                      type='radio'
                      className='form-check-input'
                      name='charge_type'
                      value='per_person'
                      checked={chargeTypePerPerson}
                    />
                    <input
                      type='number'
                      className='input-with-radio'
                      name='amount'
                      placeholder='Amount'
                      defaultValue={data.per_person_cost}
                      disabled
                    />
                  </div>

                  <div className='form-group'>
                    <label>Highlights</label>
                    <input
                      type='text'
                      className='form-control form-control-user'
                      placeholder='Highlights 1'
                      value={highlights?.h1}
                      name='h1'
                      disabled
                    />
                  </div>
                  <div className='form-group'>
                    <input
                      type='text'
                      className='form-control form-control-user'
                      placeholder='Highlights 2'
                      value={highlights?.h2}
                      name='h2'
                      disabled
                    />
                  </div>
                  <div className='form-group'>
                    <input
                      type='text'
                      className='form-control form-control-user'
                      placeholder='Highlights 3'
                      value={highlights?.h3}
                      name='h3'
                      disabled
                    />
                  </div>
                  <div className='form-group'>
                    <input
                      type='text'
                      className='form-control form-control-user'
                      placeholder='Highlights 4'
                      value={highlights?.h4}
                      name='h4'
                      disabled
                    />
                  </div>
                  <div className='form-group row'>
                    <div className='col-sm-6'>
                      <label>Environmental Causes</label>

                      <Select
                        name='environmental_causes'
                        value={selectedEnvironmental}
                        defaultValue={selectedEnvironmental}
                        isMulti
                        options={options}
                        className='basic-multi-select'
                        classNamePrefix='select'
                        isDisabled={true}
                      />
                    </div>
                    <div className='col-sm-6 mb-3 mb-sm-0'>
                      <label>Image</label>
                      <input
                        type='file'
                        className='form-control'
                        name='images'
                        accept='.png,.jpg,.jpeg'
                        multiple
                        disabled
                      />
                      {tempimagesArr.map((i) => {
                        return (
                          <img
                            src={`${process.env.REACT_APP_IMAGE_PATH}/experience/${i}`}
                            width='50px'
                            height='50px'
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-sm-6'>
                      <label>Video Title</label>
                      <input
                        type='text'
                        className='form-control form-control-user'
                        name='video_title'
                        placeholder='Video Title'
                        defaultValue={data.video_title}
                        disabled
                      />
                    </div>
                    <div className='col-sm-6'>
                      <label>Video Url</label>
                      <input
                        type='text'
                        className='form-control form-control-user'
                        name='video_url'
                        placeholder='Video Url'
                        defaultValue={data.video_url}
                        disabled
                      />
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div className='form-group'>
                      <label>Detail Description</label>
                      <Editor
                        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                        name='detail_description'
                        initialValue={data.detail_description}
                        disabled
                        init={{
                          selector: 'textarea',
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'table contextmenu paste',
                          ],
                          toolbar:
                            'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat',
                        }}
                      />
                      {/* <textarea className="form-control" name="detail_description"  defaultValue={data.detail_description} disabled></textarea> */}
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div className='form-group'>
                      <label>Further Information</label>
                      <textarea
                        className='form-control'
                        name='further_information'
                        defaultValue={data.further_information}
                        disabled
                      ></textarea>
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div className='form-group'>
                      <label>Whats Include</label>
                      <textarea
                        className='form-control'
                        name='whats_include'
                        defaultValue={data.whats_include}
                        disabled
                      ></textarea>
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div className='form-group'>
                      <label>Tag Line</label>
                      <textarea
                        className='form-control'
                        name='tag_line'
                        defaultValue={data.tag_line}
                        disabled
                      ></textarea>
                    </div>
                  </div>

                  <div className='col-lg-12'>
                    <div className='form-group'>
                      <label>
                        Last Sync Date of Experience is{' '}
                        {new Date(data.last_sync_date).toLocaleDateString(
                          'en-au',
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
