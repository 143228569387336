import React, { useEffect, useState } from 'react';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { apiConstants } from '../../_constants/api.constants';
// import { Form } from 'antd';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
// import UpdateBookingPayment from './UpdateBookingPayment'
// import { history } from '../../_helpers';
import { providerActions, seekerActions, experienceActions,  companyActions, bookingActions, transactionActions } from '../../_actions';
import { MuiPickersUtilsProvider,  KeyboardDatePicker } from '@material-ui/pickers';
// import { getScheduleExperienceById } from '../../_reducers/schedule_experience.reducer';

export default function BookingDetail(props) {

  const [bookingPaymentForm, setBookingPayment] = useState(false)
  const [payment, setPayment] = useState(0);
  const [getExperienceData, setExperience] = useState([]);
  const [scheduleExperienceData, setScheduleExperienceData] = useState([]);
  const [selectProvider, setSelectProvider] = useState('')
  const [seekerId, setSeekerId] = useState('');
  const [companyData, setCompanyData] = useState([])
  const [createdBy, setCreatedBy] = useState(JSON.parse(sessionStorage.getItem('user')).data.id)
  const [selectedDate, setSelectedDate] = useState(new Date('2014-08-18T21:11:54'));
  const [selectedScheduleExperience, setSelectedScheduleExperience] = useState({})
  const [selectExperience, setSelectExperience] = useState({});
  const [getBookingData, setGetBookingData] = useState({})
  const [uploadFile, setUploadFile] = useState({});
  const [formData, setFormData] = useState({});
  const [terms, setTerms] = useState(false)
  const [bookingAmount, setBookingAmount] = useState(0)
  const [scheduleBookingAmount, setScheduleBookingAmount] = useState(0)
  const [remainingAmount, setRemainingAmount] = useState(0)
  const [bookingData, setBookingData] = useState({})
  const [fileFlag, setFileFlag] = useState(false)
  const dispatch = useDispatch();
  const provider = useSelector(state => state.getProvider.allProvider);
  const company = useSelector(state => state.getCompany.getCompany);
  const scheduleExperience = useSelector(state => state.getScheduleExperienceByProviderId);
  const experience = useSelector((state) => state.getExperienceById);
  const seeker = useSelector(state => state.getSeeker.allSeeker)
  const booking = useSelector(state => state.getBookingById)
  const transaction = useSelector(state => state.getTransactionById)


  useEffect(() => {
    
    if (booking && booking.getBookingById) {
      setGetBookingData(booking.getBookingById.data)
      setBookingAmount(booking.getBookingById.data.bookings.amount)
      setScheduleBookingAmount(booking.getBookingById.data.ScheduleExp?.amount)
      setSelectedDate(booking.getBookingById.data.bookings.start_date)
      // let amount = booking.getBookingById.data.ScheduleExp.amount - booking.getBookingById.data.bookings.amount
      // setRemainingAmount(amount)
    }
  }, [booking])

  useEffect(() => {
    let obj = {
      id: parseInt(props.match.params.id)
    }
    dispatch(bookingActions.getBookingById(apiConstants.GET_BOOKING_BY_ID, obj))
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER));
    dispatch(experienceActions.getExperienceList(apiConstants.GET_ALL_EXPERIENCE_DETAILS))
    dispatch(seekerActions.getSeeker(apiConstants.GET_SEEKER))
    dispatch(companyActions.getCompany(apiConstants.GET_ORGANISATION));
    dispatch(transactionActions.getTransactionById(apiConstants.GET_TRANSACATION_BY_ID, obj))
  }, [])

  useEffect(() => {
    if (company && company.data.organisation) {
      setCompanyData(company.data.organisation)
    }
  }, [company])

  useEffect(() => {
    if (experience && experience.getExperienceById) {
      setExperience(experience.getExperienceById.experiences)
    }
  }, [experience])

  useEffect(() => {
    if (scheduleExperience && scheduleExperience.getScheduleExperienceByProviderId) {
      setScheduleExperienceData(scheduleExperience.getScheduleExperienceByProviderId.data)
    }
  }, [scheduleExperience])

  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Booking
          </li>
            </ol>
          </nav>
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-2 text-gray-800">
                Update Booking
          </h1>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="table-responsive">

                  <div className="row">
                    <div className="col-sm-6">
                      <label>Seeker Name</label>
                      <span className="ml-2 text-primary" style={{cursor:"pointer"}}
                       onClick={()=>{ window.open(`/seeker_detail?id=${getBookingData.bookings.seeker_id}`, '_blank')}}
                      > See details</span><br></br>
                      <select className="form-control" name="seeker_name" value={(getBookingData && getBookingData.bookings) ? getBookingData.bookings.seeker_id : ''} disabled>
                        {/* <option>Select Seeker</option> */}
                        {seeker !== undefined && seeker.length > 0 ? seeker.map((seeker, index) => {
                          return (
                            <option value={seeker.id} selected>{seeker.first_name} {seeker.last_name}</option>
                          )
                        }) : null}
                      </select>
                    </div>
                    <div className="col-sm-6">

                    </div>
                    <div className="col-sm-4">
                      <label>Company Name</label>
                      <span className="ml-2 text-primary" style={{cursor:"pointer"}}
                       onClick={()=>{ window.open(`/company_detail?id=${getBookingData.bookings.company_id}`, '_blank')}}
                      > See details</span><br></br>
                      <select className="form-control" name="provide_id" value={(getBookingData && getBookingData.bookings) ? getBookingData.bookings.company_id : ''} disabled>
                     
                        {companyData !== undefined && companyData.length > 0 ? (companyData || []).map((company, index) => {
                          return (
                            <>
                              {
                                company.organisation_type === 'provider' ? <option value={company.user_id} selected>{company.company_name}</option> : ''
                              }
                            </>
                          )
                        }) : null}
                      </select>
                    </div>
                    <div className="col-sm-4">
                      <label>Experience Name</label>
                      <span className="ml-2 text-primary" style={{cursor:"pointer"}}
                       onClick={()=>{ window.open(`/experience_detail?id=${getBookingData.experience.exp_id}` , '_blank')}}
                      > See details</span><br></br>
                      <select className="form-control" name="experience_id" value={(getBookingData && getBookingData.bookings) ? getBookingData.bookings.experience_id : ''} disabled>
                     
                        {/* {getExperienceData !== undefined && getExperienceData.length > 0 ?
                          getExperienceData.map((data, index) => ( */}
                            <option selected>
                              {(getBookingData && getBookingData.bookings) ? getBookingData.bookings.experience_name : ''}
                            </option>
                          {/* // ))

                          // : null} */}
                      </select>
                    </div>

                    <div className="col-sm-4">
                      <label>Schedule Experience</label>
                      <select className="form-control" name="sch_exp_id" value={(getBookingData && getBookingData.ScheduleExp) ? getBookingData.ScheduleExp.id : ''} disabled>
                        {/* {scheduleExperienceData && scheduleExperienceData !== undefined && scheduleExperienceData.length > 0 ? (scheduleExperienceData || []).map((scheduleExperienceData, index) => {
                          return (
                            <option value={index}>{scheduleExperienceData.experience_name}</option>
                          )
                        }) : null} */}
                          <option selected>
                              {(getBookingData && getBookingData.ScheduleExp) ? getBookingData.ScheduleExp.experience_name : ''}
                            </option>
                      </select>
                    </div>
                    <div className="col-md-6 col-xs-6">
                      <div className="form-group">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justifyContent="space-around">
                            <KeyboardDatePicker
                              margin="normal"
                              id="time-picker"
                              name="days_time"
                              label="Start Date"
                              value={selectedDate}
                              defaultValue={selectedDate}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              disabled
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                   
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Team Size</label>
                        <input type="number" className="form-control form-control-user" name="team_size"
                          placeholder="Team Size" defaultValue={(getBookingData && getBookingData.bookings) ? getBookingData.bookings.team_size : ''}  disabled/>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-6">
                      <div className="form-group">
                        <a href={getBookingData && getBookingData.supportingDoc}>
                        <Button variant="contained" color="primary" download="Download confirmation">Download Supporting Document</Button>
                        </a>
                        <br />
                        <span>{(getBookingData && getBookingData.bookings) ? getBookingData.bookings.supporting_document : ''}</span>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-6">
                      <div className="form-group">
                        <a href={getBookingData && getBookingData.pdfData }>
                        <Button variant="contained" color="primary" download="Download confirmation">Download confirmation</Button>
                        </a>
                        <br />
                        {/* <span>{(getBookingData) ? getBookingData.pdfData : ''}</span> */}
                      </div>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group">
                        <label>Special Request</label>
                        <input type="text" className="form-control form-control-user" name="special_request"
                          placeholder="Special Request" defaultValue={(getBookingData && getBookingData.bookings) ? getBookingData.bookings.special_request : ''} disabled/>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-group">
                        <p>Original Amount</p>
                        <h1>${(getBookingData && getBookingData.bookings) ? getBookingData.bookings.total_amount : ''}</h1>

                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-group">
                        <p>Additional Fee</p>
                        <h1>${(getBookingData && getBookingData.bookings) ? getBookingData.bookings.additional_amount : ''}</h1>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-group">
                        <p>Refundable Fee</p>
                        <h1>${(getBookingData && getBookingData.bookings) ? getBookingData.bookings.refundable_amount : ''}</h1>
                      </div>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group">
                        <h2>Transaction Details</h2>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <p>Transaction Id</p>
                        {/* <h1>${scheduleBookingAmount}</h1> */}
                            <span>{transaction && transaction.getTransactionById && transaction.getTransactionById.transaction ? transaction.getTransactionById.transaction.transaction_id : 'N/A'}</span>

                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <p>Payment Type</p>
                        <span>{transaction && transaction.getTransactionById && transaction.getTransactionById.transaction ? transaction.getTransactionById.transaction.payment_type : 'N/A'}</span>


                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <p>Transaction Type</p>
                        <span>{transaction && transaction.getTransactionById && transaction.getTransactionById.transaction ? transaction.getTransactionById.transaction.transaction_type : 'N/A'}</span>


                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <p>Transaction Price</p>
                        <span>{transaction && transaction.getTransactionById && transaction.getTransactionById.transaction ? transaction.getTransactionById.transaction.transaction_price : 'N/A'}</span>


                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <p>Payment Status</p>
                        <span>{transaction && transaction.getTransactionById && transaction.getTransactionById.transaction ? transaction.getTransactionById.transaction.payment_status : 'N/A'}</span>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
