import { notificationConstants } from '../_constants';


//-- get the list of terms
export function getNotificationList(state = {}, action) {
  switch (action.type) {
    case notificationConstants.GET_ALL_NOTIFICATIONS_REQUEST:
      return {
        getNotificationList: false
      };

    case notificationConstants.GET_ALL_NOTIFICATIONS_SUCCESS:
      return { getNotificationList:action.successAction};

    case notificationConstants.GET_ALL_NOTIFICATIONS_FAILURE:
      return {
        getNotificationList: false,
      };

    default:
      return state
  }
}

export function getNotificationSort(state = {}, action) {
  switch (action.type) {
    case notificationConstants.GET_NOTIFICATIONS_SORT_REQUEST:
      return {
        getNotificationSort: false
      };

    case notificationConstants.GET_NOTIFICATIONS_SORT_SUCCESS:
      return { getNotificationSort:action.successAction};

    case notificationConstants.GET_NOTIFICATIONS_SORT_FAILURE:
      return {
        getNotificationSort: false,
      };

    default:
      return state
  }
}
