export const ENGLISH = {
    SITE_NAME: 'Admin',
    //-- Error Messages
    ERR_BLANK_USER_NAME: 'Enter user name',
    ERR_BLANK_USER_PASSWORD: 'Enter password',
    ERR_BLANK_EMAIL: 'Enter a email address',
    ERR_BLANK_NEW_PASSWORD: 'Enter a new password',
    ERR_BLANK_CONFIRM_PASSWORD: 'Enter a Confirm password',
    ERR_BLANK_PASSWORD_NOT_MATCH: 'Password not matched',
    ERR_CAN_NOT_BLANK: 'Cannot be empty',
    ERR_ONLY_LETTERS: 'Only letters',
    ERR_EMAIL_NOT_VALID: 'Email is not valid',

    //-- General Messages
    GEN_ARE_YOU_SURE: 'Are you sure ?',
    GEN_NO_RECORD: 'No Record Found',
    GEN_ADD_USER: 'Add User',
    GEN_EDIT_USER: 'Edit User',
    GEN_SEARCH: 'Search',
    GEN_FIRST: 'First',
    GEN_LAST: 'Last',
    GEN_USERS: 'Users',
    GEN_DASHBOARD: 'Dashboard',
    GEN_DESCRIPTION: 'Description',
    GEN_REMARK: 'Remark',
    GEN_NUMBER: 'No',
    GEN_TITLE: 'Title',
    GEN_TYPE: 'Type',
    GEN_IMAGE: 'Image',
    GEN_ICON: 'Icon',
    GEN_NAME: 'Name',
    GEN_MESSAGE: 'Message',
    GEN_VIEW_DETAILS: 'View Details',
    GEN_PLEASE_SELECT: 'Please select',
    GEN_SETTINGS: 'Settings',
    GEN_OPTION: 'Options',
    GEN_STAFF: 'Staff',
    GEN_EVENTS: 'Events',
    GEN_SPEAKERS: 'Speakers',

    //-- Headings
    LABEL_NAME: 'Name',
    LABEL_USER_NAME: 'User Name',
    LABEL_F_NAME: 'First Name',
    LABEL_L_NAME: 'Last Name',
    LABEL_EMAIL: 'Email',
    LABEL_CONTACT_NO: 'Contact No',
    LABEL_PASSWORD: 'Password',
    LABEL_PHONE: 'Phone',
    LABEL_STATUS: 'Status',
    LABEL_ACTION: 'Action',
    LABEL_CLOSE: 'Close',
    LABEL_REMEMBER_ME: 'Remember Me',
    LABEL_FORGET_PASSWORD: 'Forget Password',
    LABEL_FORGET_PASSWORD_MESSAGE: 'Enter your email address below and we’ll send you updated password on your email.',
    LABEL_BACK_TO: 'Back to',
    LABEL_LOGIN: 'Login',
    LABEL_USER_MGT: 'Users Management',
    LABEL_SETTINGS: 'Settings',
    LABEL_GENERAL_SETTINGS: 'General Settings',
    LABEL_ADMIN_DASHBOARD: 'Admin Dashboard',
    LABEL_CHANGE_PASSWORD: 'Change Password',
    LABEL_NEW_PASSWORD: 'New Password',
    LABEL_CONFIRM_PASSWORD: 'Confirm Password',
    LABEL_PROFILE: 'Profile',
    LABEL_ADDRESS: 'Address',
    LABEL_REMARK: 'Remark',
    LABEL_USER_NAME: 'User Name',

    //-- buttons
    BTN_ADD: 'Add',
    BTN_SAVE: 'Save',
    BTN_DELETE: 'Delete',
    BTN_CANCEL: 'Cancel',
    BTN_BACK: 'Back',

    //-- Menus
    MENU_LOGOUT: 'Logout',
    MENU_DASHBOARD: 'Dashboard',
    MENU_USER_PROFILE: 'User Profile',
    MENU_ROLE_MGT: 'Role & Permission',
    MENU_TRADE_MGT: 'Trade Management',
    MENU_CATEGORY_MGT: 'Category Management',
    MENU_PRODUCTS: 'Products Management',
    MENU_PAYMENT_TYPE_MGT: 'Payment Types',
    MENU_DISPLAY_ADV_MGT: 'Advertisements',
    MENU_NOTIFICATION_MGT: 'Notification Management',
    MENU_COMMISSION_MGT: 'Commission Settings',
    MENU_GENERAL_SETTINGS: 'General Settings',
    MENU_ROLES: 'Roles',
    MENU_PERMISSION: 'Permission',
    MENU_PARTNERS: 'Partners',
    MENU_STAFF: 'Staff',
    MENU_PAGES: 'Pages',
    MENU_TAGS_MGT: 'Tags',
    MENU_COUPON: 'Coupons',
    MENU_REPORTS: 'Reports',

    //-- General messages 
    GEN_USERS_DESCRIPTION: 'This is testing',
    GEN_DISPLAY_DESCRIPTION: 'You can manage everything from this dashboard.',
    MSG_LOGOUT_WINDOW_READY: 'Ready to Leave?',
    MSG_LOGOUT_WINDOW_DESCRIPTION: 'Select "Logout" below if you are ready to end your current session.',


    /* Users */
    LABEL_COMPANY_NAME: 'Company Name',
    LABEL_CELL_PHONE: 'Cell Phone',
    LABEL_APPROVAL: 'Approval',
    LABEL_USER_DETAILS: 'User Details',
    LABEL_USER_CREATED_DATE: 'Created Date',
    LABEL_USER_APPROVAL_DATE: 'Approval Date',
    LABEL_ADDRESS_1: 'Address 1',
    LABEL_ADDRESS_2: 'Address 2',
    LABEL_CITY: 'City',
    LABEL_STATE: 'State',
    LABEL_POSTAL_CODE: 'Postal Code',


    /* Partners */
    LABEL_PARTNERS: 'Partners',
    LABEL_ADD_PARTNER: 'Add Partner',
    LABEL_EDIT_PARTNER: 'Edit Partner',
    LABEL_PARTNER_DETAILS: 'Partner Details',

    /* Category */
    LABEL_CATEGORY: 'Category',
    LABEL_ADD_CATEGORY: 'Add Category',
    LABEL_EDIT_CATEGORY: 'Edit Category',


    /* Roles */
    LABEL_ROLE: 'Role',
    LABEL_ADD_ROLE: 'Add Role',
    LABEL_EDIT_ROLE: 'Edit Role',

    /* Pages */
    LABEL_ADD_PAGE: 'Add Page',
    LABEL_EDIT_PAGE: 'Edit Page',

    /* Permissions */
    LABEL_ADD_PERMISSION: 'Add Permission',
    LABEL_EDIT_PERMISSION: 'Edit Permission',



    /* Advertisments */
    LABEL_ADD_ADVERTISMENTS: 'Add Advertisments',
    LABEL_EDIT_ADVERTISMENTS: 'Edit Advertisments',


    /*
    *   Payment Type
    */
    LABEL_PAYMENT_TYPE_NAME: 'Payment Type Name',
    LABEL_ADD_PAYMENT_TYPE: 'Add Payment Type',
    LABEL_EDIT_PAYMENT_TYPE: 'Edit Payment Type',


    /*
    *   Tag
    */
    LABEL_TAG_NAME: 'Name',
    LABEL_ADD_TAG: 'Add Tag',
    LABEL_EDIT_TAG: 'Edit Tag',

    /*
    * Coupons
    */
    LABEL_COUPON_TOKEN: 'Coupon Number',
    LABEL_EXPIRY_DATE: 'Expiry Date',
    LABEL_ADD_COUPON: 'Add Coupon',
    LABEL_EDIT_COUPON: 'Edit Coupon',

    /* 
    *  Settings 
    */
    LABEL_ADMIN_CONTACT: 'Contact Person Name',
    LABEL_ADMIN_EMAIL: 'Admin Email',
    LABEL_SUPPORT_EMAIL1: 'Support Email 1',
    LABEL_SUPPORT_EMAIL2: 'Support Email 2',
    LABEL_OFFICE_ADDRESS: 'Office Address',
    LABEL_OFFICE_CITY: 'Office City',
    LABEL_OFFICE_COUNTRY: 'Office Country',
    LABEL_CONTACT1: 'Contact 1',
    LABEL_CONTACT2: 'Contact 2',

    LABEL_COMMISSION_AMOUNT: 'Commission Amount',
    LABEL_INITIAL_AMOUNT: 'Initial Amount',
    LABEL_INITIAL_DISTANCE: 'Initial Distanace',
    LABEL_REGULAR_FARE: 'Regular Fare',
    ACCTIVATED_USER: 'Activated User',
    LABEL_EARNNG: 'Earnng',
    LABEL_EXPORT: 'Export',







    /* Terms */
    LABEL_ADD_TERMS: 'Add Terms',
    LABEL_EDIT_TERMS: 'Edit Terms',
    LABEL_TERMS_OF_SERVICE: 'Terms of Service',
    LABEL_PRIVACY_TERMS: 'Privacy Terms',




    /* Trades */
    LABEL_DEAL_NUMBER: 'Deal Number',
    LABEL_BUYER_NAME: 'Buyer Name',
    LABEL_SELLER_NAME: 'Seller Name',
    LABEL_CAR_PLATE_NUMBER: 'Car Plate Number',
    LABEL_DEAL_START_DATE: 'Deal Start Date',
    LABEL_TIME_TO_SEND_CAR: 'Time to Send a Car',
    LABEL_TIME_TO_RECEIVE_CAR: 'Time to Receive a Car',
    LABEL_DEAL_FINISH_TIME: 'DEAL Finish Time',
    LABEL_TOTAL_PRICE: 'Total Price',
    LABEL_COMMISSION: 'Commission',
    LABEL_CAR_ORIGINAL_PRICE: 'Car Original Price',

    /* Representative number */
    LABEL_REPERSENT_NUMBER: 'Representative Number',

    /* Notifications */
    LABEL_ADD_NOTIFICATIONS: 'Add Notifications',
    LABEL_EDIT_NOTIFICATIONS: 'Edit Notifications',



};