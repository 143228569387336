import { questionConstants } from '../_constants';


//-- get the list of terms
export function getQuestionsList(state = {}, action) {
  switch (action.type) {
    case questionConstants.GET_ALL_QUESTIONS_REQUEST:
      return {
        getQuestionsList: false
      };

    case questionConstants.GET_ALL_QUESTIONS_SUCCESS:
      return { getQuestionsList:action.successAction};

    case questionConstants.GET_ALL_QUESTIONS_FAILURE:
      return {
        getQuestionsList: false,
      };

    default:
      return state
  }
}

export function getCompanyReview(state = {}, action) {
    switch (action.type) {
      case questionConstants.GET_ALL_COMPANYREVIEW_REQUEST:
        return {
            getCompanyReview: false
        };
  
      case questionConstants.GET_ALL_COMPANYREVIEW_SUCCESS:
        return { getCompanyReview:action.successAction};
  
      case questionConstants.GET_ALL_COMPANYREVIEW_FAILURE:
        return {
            getCompanyReview: false,
        };
  
      default:
        return state
    }
}

export function getCompanyReviewById(state = {}, action) {
  switch (action.type) {
    case questionConstants.GET_COMPANYREVIEW_BY_ID_REQUEST:
      return {
        getCompanyReviewById: false
      };

    case questionConstants.GET_COMPANYREVIEW_BY_ID_SUCCESS:
      return { getCompanyReviewById:action.successAction};

    case questionConstants.GET_COMPANYREVIEW_BY_ID_FAILURE:
      return {
        getCompanyReviewById: false,
      };

    default:
      return state
  }
}

export function editCompanyReview(state = {}, action) {
  switch (action.type) {
    case questionConstants.EDIT_COMPANYREVIEW_REQUEST:
      return {
        editCompanyReview: false
      };

    case questionConstants.EDIT_COMPANYREVIEW_SUCCESS:
      return { editCompanyReview:action.successAction};

    case questionConstants.EDIT_COMPANYREVIEW_FAILURE:
      return {
        editCompanyReview: false,
      };

    default:
      return state
  }
}
