import { seekerConstants } from '../_constants';
import * as commonAction from './commonAction';
import { commonService } from '../_services';

export const seekerActions = {
  getSeeker
}

function getSeeker(apiName, eventData) {
  return dispatch => {
    dispatch(commonAction.request(seekerConstants.GET_SEEKER_REQUEST, eventData));
    commonService.withTokenAndBashUrl(apiName, eventData).then(
      seeker => {
        dispatch(commonAction.success(seekerConstants.GET_SEEKER_SUCCESS, seeker));
      },
      error => {
        dispatch(commonAction.failure(seekerConstants.GET_SEEKER_FAILURE, error.response.data.error));
      }
    )
  }
}