import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import {  useDispatch } from 'react-redux';
import { environmentalCauseAction } from '../../_actions/environmentalCause.action';
import { apiConstants } from '../../_constants/api.constants';

export default function AddEnvironmentalCause({ displayPopUp, hidePopUp }) {
  const [environmentalCauseData, setEnvironmentalCauseData] = useState({
    cause_name: "",
    created_by_admin: JSON.parse(sessionStorage.getItem('user')).data.id
  })
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEnvironmentalCauseData({ ...environmentalCauseData, [name]: value })
  }

  const handleSubmit = () => {
    dispatch(environmentalCauseAction.addEnvironmentalCause(apiConstants.ADD_ENVIRONMENTAL_CAUSE, environmentalCauseData));
    hidePopUp();
  }

  return (
    <div>
      <Dialog
        open={displayPopUp}
        aria-labelledby="add-products"
        aria-describedby="save-products-description"
        fullWidth={true}
      >
        <DialogTitle>Add Environmental Cause</DialogTitle>
        <DialogContent>
          <div className="row">

            <div className="col-md-12 col-xs-12">
              <div className="form-group">
                <label>Cause Name</label>
                <input
                  value={environmentalCauseData.cause_name}
                  type="text"
                  onChange={handleChange}
                  placeholder='Cause Name'
                  className="form-control"
                  name="cause_name"
                />
              </div>
              {/* <p className={classes.error}>{this.state.eventTitle_error}</p> */}
            </div>
          </div>
        </DialogContent>


        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
          <Button variant="contained" onClick={hidePopUp} >Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
