import React, { useEffect, useState } from 'react';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { apiConstants } from '../../_constants/api.constants';
// import { Form } from 'antd';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import UpdateBookingPayment from './UpdateBookingPayment'
import { providerActions, seekerActions, experienceActions, scheduleExperienceActions, companyActions, bookingActions } from '../../_actions';
import { MuiPickersUtilsProvider,  KeyboardDatePicker } from '@material-ui/pickers';
// import { getScheduleExperienceById } from '../../_reducers/schedule_experience.reducer';
// import { history } from '../../_helpers';
import swal from 'sweetalert';

export default function UpdateBooking(props) {

  const [bookingPaymentForm, setBookingPayment] = useState(false)
  const [payment, setPayment] = useState(0);
  const [getExperienceData, setExperience] = useState([]);
  const [scheduleExperienceData, setScheduleExperienceData] = useState([]);
  const [selectProvider, setSelectProvider] = useState('')
  const [seekerId, setSeekerId] = useState('');
  const [companyData, setCompanyData] = useState([])
  const [createdBy, setCreatedBy] = useState(JSON.parse(sessionStorage.getItem('user')).data.id)
  const [selectedDate, setSelectedDate] = useState(new Date('2014-08-18T21:11:54'));
  const [selectedScheduleExperience, setSelectedScheduleExperience] = useState({})
  const [selectExperience, setSelectExperience] = useState({});
  const [getBookingData, setGetBookingData] = useState({})
  const [uploadFile, setUploadFile] = useState({});
  const [formData, setFormData] = useState({});
  const [terms, setTerms] = useState(false)
  const [bookingAmount, setBookingAmount] = useState(0)
  const [scheduleBookingAmount, setScheduleBookingAmount] = useState(0)
  const [remainingAmount, setRemainingAmount] = useState(0)
  const [bookingData, setBookingData] = useState({})
  const [fileFlag, setFileFlag] = useState(false)
  const dispatch = useDispatch();
  const provider = useSelector(state => state.getProvider.allProvider);
  const company = useSelector(state => state.getCompany.getCompany);
  const scheduleExperience = useSelector(state => state.getScheduleExperienceByProviderId);
  const experience = useSelector((state) => state.getExperienceById);
  const seeker = useSelector(state => state.getSeeker.allSeeker)
  const booking = useSelector(state => state.getBookingById)
  const [booking_status, setBookingStatus] = useState('')


  useEffect(() => {
    if(booking && booking.getBookingById && booking.getBookingById.data){
     
      setGetBookingData(booking.getBookingById.data)
      setBookingAmount(booking.getBookingById.data.bookings.total_amount)
      setScheduleBookingAmount(booking.getBookingById.data.ScheduleExp?.amount)
      setSelectedDate(booking.getBookingById.data.bookings.start_date)
      let amount = booking.getBookingById.data.ScheduleExp?.amount - booking.getBookingById.data.bookings?.amount
      setRemainingAmount(amount)
      dispatch(experienceActions.getExperienceByProviderId(apiConstants.GET_EXPERIENCE_BY_PROVIDER_ID, {provider_id: booking.getBookingById.data.bookings.company_id}))
      dispatch(scheduleExperienceActions.getScheduleExperienceByProviderId(apiConstants.GET_SCHEDULE_EXPERIENCE_BY_PROVIDER_ID, {provider_id: booking.getBookingById.data.bookings.company_id}))
    }
  }, [booking])

  useEffect(() => {
    let obj = {
      id : parseInt(props.match.params.id)
    }
    dispatch(bookingActions.getBookingById(apiConstants.GET_BOOKING_BY_ID, obj))
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER));
    // dispatch(experienceActions.getExperienceList(apiConstants.GET_ALL_EXPERIENCE_DETAILS))
    dispatch(seekerActions.getSeeker(apiConstants.GET_SEEKER))
    dispatch(companyActions.getCompany(apiConstants.GET_ORGANISATION));
  }, [])

  useEffect(() => {
    if(company && company.data.organisation){
      setCompanyData(company.data.organisation)
    }
  }, [company])

  const bookingPayment = () => {
    let obj = {
      data: bookingData,
      file: uploadFile,
      getBookingData: getBookingData
    }
    setFormData(obj)
    setBookingPayment(true)
  }

  useEffect(() => {
    if (experience && experience.getExperienceById) {
      setExperience(experience.getExperienceById.experiences)
    }
}, [experience])

useEffect(() => {
  if (scheduleExperience && scheduleExperience.getScheduleExperienceByProviderId) {
    setScheduleExperienceData(scheduleExperience.getScheduleExperienceByProviderId.data)
  }
}, [scheduleExperience])

  const handleSelectProvider = (e) => {
    setSelectProvider(e.target.value)
    let obj = {
      provider_id: parseInt(e.target.value),
      
    };
    // let id = e.target.value;
    dispatch(experienceActions.getExperienceByProviderId(apiConstants.GET_EXPERIENCE_BY_PROVIDER_ID, obj))
  }

  const handleSelectSeeker = (e) => {
    setSeekerId(e.target.value)
  }

  const handleSelectExperience = (e) => {
    let index = e.target.value
    setSelectExperience(getExperienceData[index])
    let obj = {
      provider_id: 1
    };
    // let id = e.target.value;
    dispatch(scheduleExperienceActions.getScheduleExperienceByProviderId(apiConstants.GET_SCHEDULE_EXPERIENCE_BY_PROVIDER_ID, obj))
  }

  const handleSelectScheduleExperience = (e) => {
    let index = e.target.value;
    setSelectedScheduleExperience(scheduleExperienceData[index])
    setSelectedDate(scheduleExperienceData[index].start_date)
    setPayment(scheduleExperienceData[index]?.amount)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBookingData({ ...bookingData, [name]: value })
  }

  const backButton = () => {
    setBookingPayment(false)
  }

  const handleUpdate = () => {
   let formData = new FormData();
   for (const key in bookingData) {
    formData.append(key, bookingData[key])
   }
   if(fileFlag === true){
    formData.append('supporting_document', uploadFile)
   }

   formData.append('booking_id', getBookingData.bookings.id)
   formData.append('location_id', getBookingData.bookings.location_id)
   formData.append('seeker_id', getBookingData.bookings.seeker_id)
   formData.append('company_id', getBookingData.bookings.company_id)
   formData.append('sch_exp_id', getBookingData.bookings.sch_exp_id)

   formData.append('status','update requested')
  //  for (var pair of formData.entries()) {
  //   console.log(pair[0] + ', ' + pair[1]);
  //  }
   dispatch(bookingActions.editBooking(apiConstants.EDIT_BOOKINGS, formData))
  }

  const handleChangeFile = (e) => {
    setFileFlag(true)
    setUploadFile(e.target.files[0])
  }

  const handleOnSelect = (e, id) => {
    console.log(e.target.value)
    
    const { name, value } = e.target;
    getBookingData.bookings.payment_status = value

    setBookingData({ ...bookingData, [name]: value })
    setGetBookingData(getBookingData)

    const formData = new FormData();
    formData.append('booking_id', parseInt(id))
    if(value === "paid"){
      getBookingData.bookings.booking_status = 'confirmed'
      formData.append('payment_status', 'paid')
      formData.append('booking_status', 'confirmed')
      formData.append('status', 'paid')
    }else if(value === 'unpaid'){
      getBookingData.bookings.booking_status = 'reserved'
      formData.append('payment_status', 'unpaid')
      formData.append('booking_status', 'reserved')
      formData.append('status', 'payment pending')
    } else if(value === 'refund'){
      getBookingData.bookings.booking_status = 'cancelled'
      formData.append('payment_status', 'refund')
      formData.append('booking_status', 'cancelled')
      formData.append('status', 'payment cancelled')
    }

  dispatch(bookingActions.editBooking(apiConstants.UPDATE_PAYMENT_STATUS, formData))
    swal({
      title: 'Payment Success',
      text: 'Payment status updated successfully.',
      icon: 'success',
      timer: 2000,
      buttons: false,
    })

  }

  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Booking
          </li>
            </ol>
          </nav>
          {
            bookingPaymentForm === false ?  <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-2 text-gray-800">
                Update Booking
          </h1>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="table-responsive">

                  <div className="row">
                  <div className="col-sm-6">
                    <label>Seeker Name</label>
                    <span className="ml-2 text-primary" style={{cursor:"pointer"}}
                       onClick={()=>{ window.open(`/seeker_detail?id=${getBookingData.bookings.seeker_id}`,'_blank')}}
                      > See details</span><br></br>
                    <select className="form-control" name="seeker_name" value={(getBookingData && getBookingData.bookings) ? getBookingData.bookings.seeker_id : ''} disabled>
                      {/* <option>Select Seeker</option> */}
                      {seeker !== undefined && seeker.length > 0 ? seeker.map((seeker, index) => {
                        return (
                          <option value={seeker.id} selected>{seeker.first_name} {seeker.last_name}</option>
                        )
                      }) : null}
                    </select>
                  </div>
                  <div className="col-md-4 col-xs-12">
                      <div className="form-group">
                        <label>External Booking Id</label>
                        <input type="text" className="form-control form-control-user" name="external_booking_id"
                          value={(getBookingData && getBookingData.bookings) ? getBookingData.bookings.external_booking_id : ''} disabled/>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-group">
                        <label>CRM Type</label>
                        <input type="text" className="form-control form-control-user" name="crm"
                          value={(getBookingData && getBookingData.bookings) ? getBookingData.bookings.crm : ''} disabled/>
                      </div>
                    </div>
                  {/* <div className="col-sm-6">
                   
                  </div> */}
                  <div className="col-sm-4">
                    <label>Company Name</label>
                    <span className="ml-2 text-primary" style={{cursor:"pointer"}}
                       onClick={()=>{ window.open(`/company_detail?id=${getBookingData.bookings.company_id}`, '_blank')}}
                      > See details</span><br></br>
                    <select className="form-control" name="provide_id" onChange={handleSelectProvider} disabled>
                      <option>Select Company name</option>
                      {/* {companyData !== undefined && companyData.length > 0 ? (companyData || []).map((company, index) => {
                        return (
                          <>
                          {
                           company.organisation_type == 'provider' ?  <option value={company.user_id}>{company.company_name}</option> : ''
                          }
                          </>
                         )
                      }) : null} */}
                       {
                         getBookingData && getBookingData.organisation &&
                         <option value={getBookingData.organisation.id} selected> {getBookingData.organisation.company_name} </option>
                       }
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <label>Experience Name</label>
                    <span className="ml-2 text-primary" style={{cursor:"pointer"}}
                     onClick={()=>{ window.open(`/experience_detail?id=${getBookingData.ScheduleExp.experience_id}`, '_blank')
                            }}> See details</span>
                    <select className="form-control" name="experience_id"  onChange={handleSelectExperience} disabled>
                      <option>Select Experience</option>
                     
                         {
                           getBookingData && getBookingData.ScheduleExp &&
                           <option value={getBookingData.ScheduleExp.experience_id} selected> {getBookingData.ScheduleExp.experience_name} </option>
                         }
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <label>Schedule Experience</label>
                    <select className="form-control" name="sch_exp_id"  onChange={handleSelectScheduleExperience} disabled>
                      <option>Select Schedule Experience</option>
                      {/* {scheduleExperienceData && scheduleExperienceData !== undefined && scheduleExperienceData.length > 0 ? (scheduleExperienceData || []).map((scheduleExperienceData, index) => {
                        return (
                          <option value={index}>{scheduleExperienceData.experience_name}</option>
                        )
                      }) : null} */}
                        {
                           getBookingData && getBookingData.ScheduleExp &&
                           <option value={getBookingData.ScheduleExp.experience_id} selected> {getBookingData.ScheduleExp.experience_name} </option>
                        }
                    </select>
                  </div>
                    <div className="col-md-6 col-xs-6">
                      <div className="form-group">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justifyContent="space-around">
                            <KeyboardDatePicker
                              margin="normal"
                              id="time-picker"
                              name="days_time"
                              label="Start Date"
                              value={selectedDate}
                              defaultValue={selectedDate}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              disabled
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Team Size</label>
                        <input type="number" className="form-control form-control-user" name="team_size"
                          placeholder="Team Size" onChange={handleChange} defaultValue={(getBookingData && getBookingData.bookings) ? getBookingData.bookings.team_size : ''}/>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-group">
                        <label>Upload</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={handleChangeFile}
                          name="supporting_document"
                          accept=".pdf"
                        />
                        <span>{(getBookingData && getBookingData.bookings) ? getBookingData.bookings.supporting_document : ''}</span>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-group">
                        <label>Payment Status</label>
                        <select className="form-control" name="payment_status" value={(getBookingData && getBookingData.bookings) ? getBookingData.bookings.payment_status : ''} onChange={(e)=>handleOnSelect(e, getBookingData.bookings.id)}>
                                  <option>Select Status</option>
                                  <option value="paid">Paid</option>
                                  <option value="unpaid">UnPaid</option>
                                  {/* <option value="declined">Declined</option> */}
                                  <option value="refund">Refund</option>
                                </select>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-group">
                        <label>Booking Status</label>
                        <select className="form-control" value={(getBookingData && getBookingData.bookings) ? getBookingData.bookings.booking_status : ''} disabled>
                                  <option>Select Status</option>
                                  <option value="confirmed">Confirmed</option>
                                  <option value="cancelled">Cancelled</option>
                                  <option value="reserved">Reserved</option>
                                  {/* <option value="completed">NO BOOKING TO BE MADE</option> */}
                                </select>
                      </div>
                    </div>

                    <div className="col-md-4 col-xs-12">
                      <div className="form-group">
                        <label>External Booking Status</label>
                        <input type="text" className="form-control form-control-user" name="booking_status"
                          value={(getBookingData && getBookingData.bookings) ? getBookingData.bookings.external_booking_status : ''} disabled/>
                      </div>
                    </div>

                    <div className="col-md-4 col-xs-12">
                      <div className="form-group">
                        <label>Additional Details (Pickup Location)</label>
                        <input type="text" className="form-control form-control-user" name="details"
                          value={(getBookingData && getBookingData.bookings) ? getBookingData.bookings.notes.PickupLocation : ''} disabled/>
                      </div>
                    </div>

                    <div className="col-md-12 col-xs-12">
                      <div className="form-group">
                        <label>Special Request</label>
                        <input type="text" className="form-control form-control-user" name="special_request"
                          placeholder="Special Request" onChange={handleChange} defaultValue={(getBookingData && getBookingData.bookings) ? getBookingData.bookings.special_request : ''}/>
                      </div>
                    </div>
                    {/* <div className="col-md-12 col-xs-12">
                      <div className="form-group">
                        <input type="checkbox" onChange={handleChecked}/> I agree to the Terms and Conditions Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the
              </div>
                    </div> */}
                    <div className="col-md-4 col-xs-12">
                      <div className="form-group">
                        <p>Total Amount</p>
                        <h1>${bookingAmount}</h1>
                       
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-group">
                        <p>Additional Fee</p>
                        <h1>${(getBookingData && getBookingData.bookings) ? getBookingData.bookings.additional_amount : ''}</h1>                       
                      </div>
                    </div>

                    <div className="col-md-4 col-xs-12">
                      <div className="form-group">
                        <p>Refundable Fee</p>
                        <h1>${(getBookingData && getBookingData.bookings) ? getBookingData.bookings.refundable_amount : ''}</h1>                       
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        {
                          (scheduleBookingAmount > bookingAmount) ? <Button variant="contained" color="primary" onClick={bookingPayment}>Proceed to pay</Button> : <Button variant="contained" color="primary" onClick={handleUpdate}>Update</Button>
                        }
                       
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        : <UpdateBookingPayment backButton={backButton} data={formData}/>
        }
        </div>
        <Footer />
      </div>
    </div>
  )
}
