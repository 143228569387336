import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Grid from "@material-ui/core/Grid";
// import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
// } from "@material-ui/pickers";
import {
  providerActions,
  
  experienceActions,
  environmentalCauseAction,
} from "../../_actions";
import { apiConstants } from "../../_constants/api.constants";
// import Select from "react-select";
// import { history } from '../../_helpers';
import { Editor } from "@tinymce/tinymce-react";
import SideBar from "../Common/SideBar/SideBar";

export default function ExperienceDetailRoute() {
  const [ExperienceData, setExperienceData] = useState([]);
  const [highlights, setHighlights] = useState({});
  const [tempimagesArr, setTempImagesArr] = useState([]);
  const provider = useSelector((state) => state.getProvider.allProvider);
  const experience = useSelector((state) => state.getExperienceById);
  const [selectedEnvironmental, setSelectedEnvironmental] = useState([]);
  const dispatch = useDispatch();
  const environmental_cause = useSelector(
    (state) => state.getEnviromentalCause.allEnviromentalCause
  );

  useEffect(() => {
    var id = window.location.href.split("=")[1];
    let obj = {
      id: id,
    };
    dispatch(
      experienceActions.getExperienceById(
        apiConstants.GET_EXPERIENCE_BY_ID,
        obj
      )
    );
    dispatch(
      environmentalCauseAction.getEnvironmentalCause(
        apiConstants.GET_ENVIRONMENTAL_CAUSE,
        obj
      )
    );
  }, []);

  useEffect(() => {
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER));
  }, []);

  useEffect(() => {
    if (
      experience &&
      experience.getExperienceById &&
      experience.getExperienceById.data
    ) {
      setExperienceData(experience.getExperienceById.data);
      setHighlights(
        JSON.parse(experience.getExperienceById.data.experience.highlights)
      );
      let tempImageArr = JSON.parse(
        experience.getExperienceById.data.experience.images
      );
      let temImageArr = [];
      tempImageArr.forEach((i) => {
        temImageArr.push(i);
      });
      setTempImagesArr(temImageArr);
    }
  }, [experience.getExperienceById]);


  return (
    <div id="wrapper">
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
        <div className="container-fluid" >
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Experience Details</h1>
          </div>
          <div className="card shadow mb-4">
            <div className="card-body">
              <div className="table-responsive">
                <div className="row">
                  <div className="col-sm-12 formBorder">
                    <div className="p-5">
                      <div className="form-group row">
                        <div className="col-sm-6">
                          <label>Provider Name</label>
                          <select
                                  className="form-control"
                                  name="type"
                                  //value={getScheduleExpExperienceData ? getScheduleExpExperienceData.provider_id : ''}
                                  // onChange={handleSelectProvider}
                                  disabled
                                >
                                  {/* <option>Select Provider name</option> */}
                                  {provider !== undefined && provider.length > 0
                                    ? (provider || []).map((provider, index) => {
                                        return (
                                          <option key={index}  value={provider.id}>
                                            {provider.first_name}{" "}
                                            {provider.last_name}
                                          </option>
                                        );
                                      })
                                    : null}
                                </select>
                        </div>
                        <div className="col-sm-6">
                          <label>Location address</label>
                          <button className="btn btn-sm btn-secondary"
                          onClick={()=>{ window.open(`/location_detail?id=${ExperienceData.experience &&
                            ExperienceData.experience.location_id}`, '_blank')}}
                          > See details</button><br></br>
                          <select
                            className="form-control"
                            name="location_id"
                            disabled
                          >
                            <option>Select Location</option>
                            {
                              ExperienceData.experience &&
                              <option selected> { ExperienceData.location.location_address} </option>
                            }
                            {/* {locationsData !== undefined && locationsData.length > 0 ? locationsData.map((locationsData, index) => {
                              return (
                                <option value={locationsData.id}>{locationsData.location_address}</option>
                              )
                            }) : null} */}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                          <label>Experience Name</label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            name="experience_name"
                            placeholder="Experience Name"
                            defaultValue={
                              ExperienceData.experience &&
                              ExperienceData.experience.experience_name
                            }
                            disabled
                          />
                        </div>
                        <div className="col-sm-6">
                          <label>Team Size</label>
                          <input
                            type="number"
                            className="form-control form-control-user"
                            name="team_size"
                            placeholder="Team Size"
                            defaultValue={
                              ExperienceData.experience &&
                              ExperienceData.experience.team_size
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Experience Duration</label>
                        <select className="form-control" name="type" disabled>
                          <option value="1">Half Day</option>
                          <option value="2">One Day</option>
                          <option value="3">Multiple days</option>
                        </select>
                      </div>

                      <div className="form-group">
                        {ExperienceData.experience &&
                          ExperienceData.experience.charge_type == "flat_fee" && (
                            <>
                              <input
                                type="radio"
                                disabled
                                checked
                                className="form-check-input"
                                name="charge_type"
                                value="flat_fee"
                              />
                              <label>Flat fee</label>
                              <input
                                type="number"
                                defaultValue={
                                  ExperienceData.experience &&
                                  ExperienceData.experience.amount
                                }
                                className="input-with-radio"
                                name="amount"
                                placeholder="Amount"
                                disabled
                              />
                            </>
                          )}

                        {ExperienceData.experience &&
                          ExperienceData.experience.charge_type == "per_person" && (
                            <>
                              <input
                                type="radio"
                                checked
                                disabled
                                className="form-check-input"
                                name="charge_type"
                                value="per_person"
                              />
                              <label>Per Person</label>
                              <input
                                type="number"
                                className="input-with-radio"
                                name="amount"
                                defaultValue={
                                  ExperienceData.experience &&
                                  ExperienceData.experience.per_person_cost
                                }
                                placeholder="Amount"
                                disabled
                              />
                            </>
                          )}
                      </div>

                      <div className="form-group">
                        <label>Highlights</label>
                        <input
                          type="text"
                          className="form-control form-control-user"
                          placeholder="Highlights 1"
                          value={highlights.h1}
                          name="h1"
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-user"
                          placeholder="Highlights 2"
                          value={highlights.h2}
                          name="h2"
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-user"
                          placeholder="Highlights 3"
                          value={highlights.h3}
                          name="h3"
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-user"
                          placeholder="Highlights 4"
                          value={highlights.h4}
                          name="h4"
                          disabled
                        />
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                          <label>Image</label>
                          <br></br>

                          {tempimagesArr.map((i) => {
                            return (
                              <img
                                src={`${process.env.REACT_APP_IMAGE_PATH}/experience/${i}`}
                                width="50px"
                                height="50px"
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-6">
                          <label>Video Title</label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            name="video_title"
                            placeholder="Video Title"
                            defaultValue={
                              ExperienceData.experience &&
                              ExperienceData.experience.video_title
                            }
                            disabled
                          />
                        </div>
                        <div className="col-sm-6">
                          <label>Video Url</label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            name="video_url"
                            placeholder="Video Url"
                            defaultValue={
                              ExperienceData.experience &&
                              ExperienceData.experience.video_url
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Detail Description</label>
                          <Editor
                            apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                            name="detail_description"
                            initialValue={
                              ExperienceData.experience &&
                              ExperienceData.experience.detail_description
                            }
                            disabled
                            init={{
                              selector: "textarea",
                              menubar: false,
                              plugins: [
                                "advlist autolink lists link charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "table contextmenu paste",
                              ],
                              toolbar:
                                "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat",
                            }}
                          />
                          {/* <textarea className="form-control" name="detail_description"  defaultValue={data.detail_description} disabled></textarea> */}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Further Information</label>
                          <textarea
                            className="form-control"
                            name="further_information"
                            defaultValue={
                              ExperienceData.experience &&
                              ExperienceData.experience.further_information
                            }
                            disabled
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Whats Include</label>
                          <textarea
                            className="form-control"
                            name="whats_include"
                            defaultValue={
                              ExperienceData.experience &&
                              ExperienceData.experience.whats_include
                            }
                            disabled
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Tag Line</label>
                          <textarea
                            className="form-control"
                            name="tag_line"
                            defaultValue={
                              ExperienceData.experience &&
                              ExperienceData.experience.tag_line
                            }
                            disabled
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Last Sync Date { new Date(ExperienceData.experience.last_sync_date).toLocaleDateString('en-au')}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       </div>
      </div>
     </div>   
  );
}
