import axios from 'axios';

export const commonService = {
    withToken,
    withTokenPost,
    withOutToken,
    withTokenPut,
    getDataWithToken,
    withTokenAndFormData,
    handleResponse,
    withOutTokenAndBaseUrl,
    getDataWithTokenAndBaseUrl,
    withOutTokenAndFormData,
    withTokenAndBashUrl,
    withOutTokenAndFormDataPut,
    getDataWithTokenAndBashUrl,
    // getDataWithTokenAndBaseUrlParams
    withTokenAndFormDataApplicationJsonFormat
};

//-- It's common function for using the token
//-- get data
function getDataWithToken(apiName, userData) {
    let token = JSON.parse(sessionStorage.getItem('token'));
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL + apiName}`,
        // headers: { 'Content-Type': 'application/json', 'access_token': token },
        data: userData
    }).then(handleResponse);
}

function getDataWithTokenAndBashUrl(apiName, userData) {
    let token = JSON.parse(sessionStorage.getItem('token'));
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL + apiName}`,
        // headers: { 'Content-Type': 'application/json', 'access_token': token },
        data: userData
    }).then(handleResponse);
}

//-- It's common function for using the token
function withToken(apiName, data) {
    let tokenObj = JSON.parse(sessionStorage.getItem("token"))
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL + apiName}`,
        // headers: { 'Content-Type': 'application/json', 'access_token': tokenObj },
        data: data
    }).then((handleResponse) => {

        return handleResponse;
    });
};

function withTokenPost(apiName, data) {
    let tokenObj = JSON.parse(sessionStorage.getItem("token"))
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL + apiName}`,
        // headers: { 'Content-Type': 'application/json', 'access_token': tokenObj },
        headers: {'Content-Type': 'application/json' , 'Authorization': `Bearer ${tokenObj}`},
        data: data
    }).then((handleResponse) => {

        return handleResponse;
    });
};


function withTokenAndBashUrl(apiName, data) {
    let tokenObj = JSON.parse(sessionStorage.getItem("token"))
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL + apiName}`,
        // headers: { 'Content-Type': 'application/json', 'access_token': tokenObj },
        data: data
    }).then((handleResponse) => {

        return handleResponse;
    });
};

//-- It's common function for using the token with Put method
function withTokenPut(apiName, data) {
    let tokenObj = JSON.parse(sessionStorage.getItem("token"))
    return axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_URL + apiName}`,
        // headers: { 'Content-Type': 'application/json', 'access_token': tokenObj },
        data: data
    }).then((handleResponse) => {

        return handleResponse;
    });
};

//-- It's common function for using without token
function withOutToken(apiName, data) {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL + apiName}`,
        data: data
    }).then(handleResponse);
};

//-- It's common function for using without token
function withOutTokenAndBaseUrl(apiName, data) {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL + apiName}`,
        data: data
    }).then(handleResponse);
};

function getDataWithTokenAndBaseUrl(apiName, data) {
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL + apiName + '/' + data}`
    }).then(handleResponse);
};
// function getDataWithTokenAndBaseUrlParams(apiName, data) {
//     return axios({
//         method: 'GET',
//         url: `${process.env.REACT_APP_API_BASE_URL + apiName + '?provider_id=' + data}`
//     }).then(handleResponse);
// };

//-- It's common function for using with form data
function withTokenAndFormData(apiName, data) {
    let tokenObj = JSON.parse(sessionStorage.getItem("token"))
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL + apiName}`,
        // headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${tokenObj}` },
        data: data
    }).then((handleResponse) => {
        return handleResponse;
    });
};

// This function is for POST request with token (admin request) and to send JSON request data 
function withTokenAndFormDataApplicationJsonFormat(apiName, data) {
    let tokenObj = JSON.parse(sessionStorage.getItem("token"))
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL + apiName}`,
        // headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${tokenObj}` },
        headers: {'Content-Type': 'application/json' , 'Authorization': `Bearer ${tokenObj}`},
        data: data
    }).then((handleResponse) => {
        return handleResponse;
    });
};

//-- It's common function for using with form data
function withOutTokenAndFormData(apiName, data) {
    let tokenObj = JSON.parse(sessionStorage.getItem("token"))
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL + apiName}`,
        headers: {'Content-Type': 'application/json' , 'Authorization': `Bearer ${tokenObj}`},
        data: data
    }).then((handleResponse) => {
        return handleResponse;
    });
};

function withOutTokenAndFormDataPut(apiName, data) {
    let tokenObj = JSON.parse(sessionStorage.getItem("token"))
    return axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_BASE_URL + apiName}`,
        // headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${tokenObj}` },
        data: data
    }).then((handleResponse) => {
        return handleResponse;
    });
};

function handleResponse(response) {
    //console.log('response', response);
    if (response.status === 200 || response.status === 201) {
        return response;
    } else {
        const error = response;
        return Promise.reject(error);
    }
}