//-- React Standard
import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
//import compose from 'recompose/compose';
//-- Custom
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";

import { withStyles } from "@material-ui/core/styles";
import Footer from "../Common/Footer/Footer";
import { apiConstants } from '../../_constants/api.constants';
import Pagination from "../Common/Pagination/Pagination";
import PageLimit from "../Common/PageLimit/PageLimit";
import { eventActions } from "../../_actions";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import EventsDetail from "./EventsDetail";
import AddEvents from './AddEvents';
// import EditEvents from './EditEvents';

//-- CSS for Material
const styles = theme => ({
    error: {
        color: 'red',
    },
    loaderImg: {
        margin: '0 auto',
        display: 'block',
        width: '250px'
    },

    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: "#269abc",
        color: '#fff'
    },
    contentRoot: {
        padding: theme.spacing(2),
        fontSize: 14,
        width: 600
    },
    actionRoot: {
        margin: 0,
        padding: theme.spacing(1)
    }
});


class Events extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sort: {
                sortKey: null,
                direction: 'desc',
            },
            totalPage: '',
            totalDataCount: '',
            currentPage: 1,
            //getCouponList: [],
            productAdd: false,
            allCategories: [],
            soldIndChecked: false,
            editProductId: false,
            enableEdit: false,
            deletingProdId: false,
            deletePopup: false,
            varEventDetails: false,
        };
    }

    /*
    * componentDidMount - Initially it will call and get the advertisments data
    */
    componentDidMount() {
        const { dispatch } = this.props;
        let postData = {};
        postData.page = 1;
        postData.orderBy = "id";
        postData.orderByAscDesc = "ASC";
        postData.perPage = 10;

        dispatch(eventActions.getEventsList(apiConstants.GET_EVENTS, postData));
    }

    /*
    * componentWillReceiveProps - Whenever Props change, it will call and store data
    * update the state values with new props values, this method will get called whenever any change happens to props values
    */
    componentWillReceiveProps(props) {
        const { dispatch } = props;

        // check if product data returned by API
        if (props.getEventsList !== undefined) {
            if (props.getEventsList.getEventsList !== undefined &&
                props.getEventsList.getEventsList.events !== undefined &&
                props.getEventsList.getEventsList.events.list !== undefined) {


                this.setState({
                    totalPage: props.getEventsList.getEventsList.events.totalPages,
                    totalDataCount: props.getEventsList.getEventsList.events.totalCount,
                    getEventsList: props.getEventsList.getEventsList.events.list,
                })
            }
        }

        if (props.deleteProduct !== undefined) {
            if (props.deleteProduct.deletedProduct !== undefined &&
                props.deleteProduct.deletedProduct.data !== undefined &&
                props.deleteProduct.deletedProduct.data.type === "product-deleted") {

                if (this.state.deletePopup === true) {
                    // hide the conformation popup
                    this.closeDeletePopup();
                    //reload the table products again
                    this.reloadTable();
                }

            }
        }
    }

    reloadTable = () => {
        const { dispatch } = this.props;
        let postData = {};
        postData.page = 1;
        postData.orderBy = "id";
        postData.orderByAscDesc = "ASC";
        postData.perPage = 10;

        dispatch(eventActions.getEventsList(apiConstants.GET_EVENTS, postData));
    }


    /*
    *   Pagination function: It will call from pagination section and change data in table
    */
    makeHttpRequestWithPage = (pageNumber) => {
        const { dispatch } = this.props;
        this.setState({
            currentPage: pageNumber
        });

        let postData = {};
        postData.page = pageNumber;
        postData.orderBy = "id";
        postData.orderByAscDesc = "ASC";
        postData.perPage = 10;

        dispatch(eventActions.getEventsList(apiConstants.GET_EVENTS, postData));
    }

    // hit api and get category data for selected list range
    changeTableData = (e) => {
        const { dispatch } = this.props;

        let postData = {};
        postData.page = 1;
        postData.orderBy = "id";
        postData.orderByAscDesc = "ASC";
        postData.perPage = parseInt(e.target.value);

        dispatch(eventActions.getEventsList(apiConstants.GET_EVENTS, postData));
    }

    /*
    * searchCategories - User can search data from the database and it will appear
    */
    searchCategories(keyword) {
        const { dispatch } = this.props;
        let postData = {};
        postData.page = 1;
        postData.orderBy = "id";
        postData.orderByAscDesc = "ASC";
        postData.perPage = 10;


        if (keyword.target.value !== '') {
            postData.search = keyword.target.value;
            dispatch(eventActions.getEventsList(apiConstants.SEARCH_PRODUCT_BY_KEYWORD_FOR_SHOPKEEPER, postData));
        } else {
            dispatch(eventActions.getEventsList(apiConstants.GET_EVENTS, postData));
        }
    }

    //open add product popup
    openAddProducts = () => { this.setState({ productAdd: true }) }

    //close add product popup
    closeAddProductPop = (data) => {
        const { dispatch } = this.props;
        this.setState({ productAdd: false });
        if (data === 1) {
            let postData = {};
            postData.page = 1;
            postData.orderBy = "id";
            postData.orderByAscDesc = "ASC";
            postData.perPage = 10;

            dispatch(eventActions.getEventsList(apiConstants.GET_EVENTS, postData));
        }
    }

    //open edit product popup
    editProduct = (prodId) => { this.setState({ editProductId: prodId, enableEdit: true }) }

    //close edit product popup
    closeEditProductPop = (data) => {
        const { dispatch } = this.props;
        this.setState({ editProductId: false, enableEdit: false });
        if (data === 1) {
            let postData = {};
            postData.page = 1;
            postData.orderBy = "id";
            postData.orderByAscDesc = "ASC";
            postData.perPage = 10;

            dispatch(eventActions.getEventsList(apiConstants.GET_EVENTS, postData));
        }
    }

    //delete product popup
    deletePopupOpen = (proId) => {
        this.setState({ deletingProdId: proId, deletePopup: true });
    }
    // close the popup and reset the id for deleting product
    closeDeletePopup = () => {
        this.setState({ deletingProdId: false, deletePopup: false });
    }

    //delete a product
    deletingProduct = () => {
        const { deletingProdId } = this.state;
        const { dispatch } = this.props;
        if (deletingProdId) {
            const postData = {};
            postData.productId = deletingProdId;
            dispatch(eventActions.deleteProducts(apiConstants.DELETE_PRODUCT_BY_SHOPKEEPER, postData));
        }
    }


    /*
    *   User details- we can open seperate page for details
    */
    userDetails = (events) => {
        this.setState({
            events,
            varEventDetails: true
        });
    }

    /*
   *   Call from the Detail page
   */
    backButton = () => {
        this.setState({
            varEventDetails: false
        });
    }

    //-- Main function
    render() {
        //console.log("this sssssssssss  ", this.props);
        const { classes } = this.props;
        const { siteConstants } = this.props.language;
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return (
            <div id="wrapper">

                <SideBar />

                <div id="content-wrapper" className="d-flex flex-column">
                    {/* <!-- Main Content --> */}
                    <div id="content">
                        <TopBar />
                        {!this.state.varEventDetails ?
                            <Fragment>
                                <nav className="" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="/dashboard">{siteConstants.GEN_DASHBOARD}</a>
                                        </li>
                                        <li className="active breadcrumb-item" aria-current="page">
                                            {siteConstants.GEN_EVENTS}
                                        </li>
                                    </ol>
                                </nav>

                                {/* <!-- Begin Page Content --> */}
                                <div className="container-fluid">

                                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                        <h1 className="h3 mb-0 text-gray-800">{siteConstants.GEN_EVENTS}</h1>
                                        <a href="javascript:void(0)"
                                            onClick={() => this.openAddProducts()}
                                        >
                                            <span className="text">
                                                <button className="btn btn-primary">{siteConstants.BTN_ADD}</button>
                                            </span>
                                        </a>

                                    </div>


                                    <div className="card shadow mb-4">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <div className="row">
                                                    {/* Page limit drop down */}
                                                    <PageLimit changeLimit={(e) => this.changeTableData(e)} />

                                                    <div className="col-sm-12 col-md-6">
                                                        <div id="dataTable_filter" className="dataTables_filter">
                                                            <label>
                                                                <input type="search" placeholder={siteConstants.GEN_SEARCH} className="form-control form-control-sm" aria-controls="dataTable" onChange={(e) => this.searchCategories(e)} />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>



                                                <table className="table table-bordered" width="100%" cellSpacing="0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">{siteConstants.GEN_NUMBER}</th>
                                                            <th scope="col">{siteConstants.GEN_TITLE}</th>
                                                            <th scope="col">Date</th>
                                                            <th scope="col">Time</th>
                                                            <th scope="col">Location</th>
                                                            <th width="10%">{siteConstants.LABEL_ACTION}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.getEventsList != undefined && this.state.getEventsList.length > 0 ? this.state.getEventsList.map((events, index) => {
                                                                return (

                                                                    <tr key={index}>
                                                                        <td scope="row">{events.index}</td>
                                                                        <td>{events.title}</td>
                                                                        <td>{events.event_date}</td>
                                                                        <td>{events.event_time}</td>
                                                                        <td>{events.address1}</td>

                                                                        <td className="tdTextCenter">
                                                                            <a className="actionIcon" title="View" href="javascript:void(0)" onClick={() => this.userDetails(events)}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a>
                                                                            {/* <a
                                                                        className="actionIcon"
                                                                        title="Edit"
                                                                        href="javascript:void(0)"
                                                                        onClick={() => this.editProduct(events._id)}
                                                                    ><span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span></a> */}
                                                                            <a className="actionIcon"
                                                                                title="Delete"
                                                                                href="javascript:void(0)"
                                                                                onClick={() => this.deletePopupOpen(events._id)}
                                                                            ><span><img src="assets/img/svg/Bin.png" alt="Delete" /></span>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) : <tr>
                                                                    <td colSpan="5" className="noRecordFound">{siteConstants.GEN_NO_RECORD}</td>
                                                                </tr>
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* calling the another componenet */}
                                            <Pagination totalPage={this.state.totalPage} currentPage={this.state.currentPage} currentPage={this.state.currentPage} funPagination={this.makeHttpRequestWithPage} />



                                        </div>
                                    </div>

                                </div>
                            </Fragment>
                            : <EventsDetail userData={this.state.events} backButton={this.backButton} />}
                        {/* <!-- /.container-fluid --> */}

                    </div>
                    {/* <!-- End of Main Content --> */}

                    {/* add product popup */}
                    {
                        this.state.productAdd === true ? <AddEvents displayPopUp={this.state.productAdd} closeAddProductPop={(data) => this.closeAddProductPop(data)} /> : ''
                    }

                    {/* edit product popup */}
                    {
                        //this.state.editProductId !== false ? <EditEvents editProductId={this.state.editProductId} displayPopUp={this.state.enableEdit} closeEditProductPop={(data)=>this.closeEditProductPop(data)}/> : ''
                    }




                    {/* add product popup */}


                    <Footer />

                    {/* notify saving data */}

                    <Dialog open={this.state.deletePopup} aria-labelledby="delete-product-notify" >
                        <DialogTitle className={classes.root}>Delete</DialogTitle>
                        <DialogContent className={classes.contentRoot}>
                            Are you sure?
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="primary" onClick={(e) => this.deletingProduct(e)}>{siteConstants.BTN_DELETE}</Button>
                            <Button variant="contained" onClick={() => this.closeDeletePopup(0)}>{siteConstants.BTN_CANCEL}</Button>
                        </DialogActions>
                    </Dialog>

                </div>



            </div>
        );
    }
}


//-- Here we are adding Reducer names, so it can be get data from reducers using store
function mapStateToProps(state) {
    const { language, getEventsList, deleteProduct } = state;
    return {
        language,
        getEventsList,
        deleteProduct
    };
}
const connectedEvents = connect(mapStateToProps)(withStyles(styles)(Events));
export { connectedEvents as Events };
