import React from 'react'

export default function EnvironmentalCauseDetail({ backButton, data }) {
  return (
    <div className="container-fluid">

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Provider Details</h1>
        <a href="#" onClick={backButton} className="btn btn-secondary btn-icon-split">
          <span className="icon text-white-50">
            <i className="fas fa-arrow-left"></i>
          </span>
          <span className="text">Back</span>
        </a>
      </div>

      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <div className="row">
              <div className="col-sm-3"></div>
              <div className="col-sm-6 formBorder">
                <div className="p-5">
                  <div>
                    {/* <b>{userDetails.name}</b> */}
                    <h1 className="h4 text-gray-900 mb-4 headingBorderBottom">Name</h1>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label>First Name</label>
                      <input type="text" className="form-control form-control-user" id="txtName"
                        placeholder={data.first_name} readOnly />
                    </div>
                    <div className="col-sm-6">
                            <label>Last Name</label>
                            <input type="text" className="form-control form-control-user" id="txtCompanyName" name="last_name"
                              placeholder="Last Name" defaultValue={data.last_name} readOnly/>
                          </div>
                  </div>
                  <div className="form-group row">
                  <div className="col-sm-6">
                      <label>Company Name</label>
                      <input type="text" className="form-control form-control-user" id="txtCompanyName"
                        placeholder={data.company_name} readOnly />
                    </div>
                    <div className="col-sm-6 mb-3">
                            <label>Department</label>
                            <input type="text" className="form-control form-control-user" id="txtName" name="department"
                              placeholder="department" defaultValue={data.department} readOnly/>
                      </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label>Address</label>
                      <input type="text" className="form-control form-control-user" id="txtAddress"
                        placeholder="Address" readOnly />
                    </div>
                    <div className="col-sm-6">
                      <label>Email</label>
                      <input type="text" className="form-control form-control-user" id="txtEmail"
                        placeholder={data.email} readOnly />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label>Contact No.</label>
                      <input type="text" className="form-control form-control-user" id="txtContactNo"
                        placeholder={data.phone} readOnly />
                    </div>
                    <div className="col-sm-6">
                            <label>State</label>
                            <input type="text" className="form-control form-control-user" id="txtBuying"
                              placeholder="Company Address" defaultValue={data.state} readOnly/>
                          </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-6">
                      <label>Company Address</label>
                      <input type="text" className="form-control form-control-user" id="txtBuying"
                        placeholder={data.company_street_address} readOnly />
                    </div>
                    <div className="col-sm-6">
                            <label>Post code</label>
                            <input type="text" className="form-control form-control-user" id="txtBuying"
                              placeholder="Post code" defaultValue={data.post_code} readOnly/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
