import React, { useEffect, useState } from 'react';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import { useSelector, useDispatch } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';
// import { Form } from 'antd';
import { questionsActions } from '../../_actions/questions.action';
import Select from "react-select";
import StarRatings from 'react-star-ratings';
import {history} from "../../_helpers/history";


export default function UpdateCompanyReview(props) {
  const [createdBy, setCreatedBy] = useState(JSON.parse(sessionStorage.getItem('user')).data.id)
  const getCompanyReviewById = useSelector(state => state.getCompanyReviewById);
  const getQuestionsList = useSelector(state => state.getQuestionsList);
  const [companyReviewData, setcompanyReviewData] = useState([])
  const [questionList, setquestionList] = useState([])
  const [selectedAnswer1, setSelectedAnswer1] = useState({})
  const [selectedAnswer2, setSelectedAnswer2] = useState([])
  const [question5, setQuestion5] = useState({})
  const [question8, setQuestion8] = useState({})
  const [question1, setQuestion1] = useState('')
  const [question2, setQuestion2] = useState('')
  const [question3, setQuestion3] = useState('')
  const [rating, setRating] = useState(0)
  const [question6, setQuestion6] = useState('')
  const [question7, setQuestion7] = useState('')
  const [question9, setQuestion9] = useState('')


  const [submittedAnswer, setSubmittedAnswer] = useState([])
  const dispatch = useDispatch();


  useEffect(() => {
    let obj = {
      id: props.match.params.id
    }
    dispatch(questionsActions.getCompanyReviewById(apiConstants.GET_COMPANYREVIEW_BY_ID, obj))
    dispatch(questionsActions.getQuestionsList(apiConstants.GET_ALL_QUESTIONS));
  }, [])

  useEffect(() => {
    if (getCompanyReviewById && getCompanyReviewById.getCompanyReviewById) {
      setcompanyReviewData(getCompanyReviewById.getCompanyReviewById.data)
      let temp = JSON.parse(getCompanyReviewById.getCompanyReviewById.data.submitedanswers);
      let tempArray = [];
      Object.keys(temp).forEach(i => {
        let obj = { id: i, value: temp[i] }
        tempArray.push(obj)
      })
      // let tempArray = temp.split(",")
      setSubmittedAnswer(tempArray)
    }

  }, [getCompanyReviewById])

  useEffect(()=> {
    if(submittedAnswer.length > 0){
     
      let tempArray = [];
      let tempArray2 = [];
      let temp = submittedAnswer[4].value.split(",")
      let temp2 = submittedAnswer[7].value.split(",")
      let question1 = submittedAnswer[0].value
      let question6 = submittedAnswer[5].value
      let question7 = submittedAnswer[6].value
      setQuestion2(submittedAnswer[1].value)
      setQuestion9(submittedAnswer[8].value)
      temp.map(i=> {
        let obj = { value: i, label: i }
        tempArray.push(obj)
      })
      temp2.map(i=> {
        let obj = { value: i, label: i }
        tempArray2.push(obj)
      })
      setSelectedAnswer1(tempArray)
      setSelectedAnswer2(tempArray2)
      if(question1 === 'Yes'){
       setQuestion1(question1)
      }else if(question1 === 'No'){
       setQuestion1(question1)
      }

      if(question6 === 'Yes'){
        setQuestion6(question6)
      }else if(question6 === 'No'){
        setQuestion6(question6)
      }

      if(question7 === 'Yes'){
        setQuestion7(question7)
      }else if(question7 === 'No'){
        setQuestion7(question7)
      }

      setRating(parseInt(submittedAnswer[3].value))

    }
  },[submittedAnswer])

  useEffect(() => {
    if (getQuestionsList.getQuestionsList && getQuestionsList.getQuestionsList.data) {
      setquestionList(getQuestionsList.getQuestionsList.data)
      let question5Str = getQuestionsList.getQuestionsList.data[4].all_answers
      let question8Str = getQuestionsList.getQuestionsList.data[7].all_answers

      let question5 = question5Str.split(",");
      let question8 = question8Str.split(",");
      let tempArr = []
      let tempArr8 = []
      question5.map(i => {
        let temp = { value: i, label: i }
        tempArr.push(temp)
      })
      question8.map(i => {
        let temp = { value: i, label: i }
        tempArr8.push(temp)
      })
      setQuestion5(tempArr)
      setQuestion8(tempArr8)
    }
  }, [getQuestionsList.getQuestionsList])

  const handleQuestion2 = (e) => {
    setQuestion2(e.target.value)
  }

  const changeRating = (newRating)=>{
    setRating(newRating)
  }

  const handleMultiSelectChange5 = (selectedOption) => {
    setSelectedAnswer1(selectedOption)
  }

  const handleMultiSelectChange8 = (selectedOption) => {
    setSelectedAnswer2(selectedOption)
  }

  const handleQuestion9 = (e) => {
    setQuestion9(e.target.value)
  }

  const handleQuestion1 = (e) => {
    setQuestion1(e.target.value)
  }

  const handleQuestion6 = (e) => {
    setQuestion6(e.target.value)
  }

  const handleQuestion7 = (e) => {
    setQuestion7(e.target.value)
  }

  const handleSubmit = () => {
    
    let tempArr = []
    let tempArr2 = []
    selectedAnswer1.map(i=>{
      tempArr.push(i.value)
    })
    selectedAnswer2.map(i=>{
      tempArr2.push(i.value)
    })
    
    let temp = {
      "1": question1,
      "2": question2,
      "3": "10",
      "4": rating.toString(),
      "5": tempArr.toString(),
      "6": question6,
      "7": question7,
      "8": tempArr2.toString(),
      "9": question9,
    }
    let obj = {
      id: parseInt(props.match.params.id),
      submitedanswers: JSON.stringify(temp)
    }

    dispatch(questionsActions.editCompanyReview(apiConstants.edit_COMPANYREVIEW, obj));
    history.push('/submitedanswer')

  }
  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Edit Company Review
          </li>
            </ol>
          </nav>
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-2 text-gray-800">
                Edit Company Review
          </h1>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="table-responsive">

                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group">
                        <label>User Name</label>
                        <input type="text" value={companyReviewData.user_name} className="form-control form-control-user"
                          placeholder="User Name" disabled/>
                      </div>
                    </div>

                    <div className="col-md-12 col-xs-12">
                      <div className="form-group">
                        <label>Company Name</label>
                        <input type="text" value={companyReviewData.company_name} className="form-control form-control-user"
                          placeholder="Company Name" disabled/>
                      </div>
                    </div>

                    {
                      questionList.length > 0 ? <>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>1) {questionList[0].questions}</label>
                            <br />
                            <input type="radio" name="question_type1" value="Yes" checked = {question1 === 'Yes' ? true : false} onClick={handleQuestion1} />
                            <label >Yes</label>
                            <input type="radio" name="question_type1" value="No" checked = {question1 === 'No' ? true : false} onChange={handleQuestion1}/>
                            <label >No</label>
                          </div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>2) {questionList[1].questions}</label>
                            <input type="text" className="form-control form-control-user" defaultValue={question2} onChange={handleQuestion2} />
                          </div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>3) {questionList[2].questions}</label>
                            {/* <StarRatings
                                    rating={(submittedAnswer.length > 0 && submittedAnswer[3].id == 3) ? parseInt(submittedAnswer[3].value ): ''}
                                    starDimension="40px"
                                    starSpacing="15px"
                                  /> */}
                          </div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>4) {questionList[3].questions}</label>
                             <StarRatings
                                    rating={rating}
                                    starDimension="40px"
                                    starSpacing="15px"
                                    changeRating={changeRating}
                                  />
                          </div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>5) {questionList[4].questions}</label>
                            <Select
                              name="question5"
                              value={selectedAnswer1}
                              defaultValue={selectedAnswer1}
                              isMulti
                              onChange={handleMultiSelectChange5}
                              options={question5}
                              className="basic-multi-select"
                              classNamePrefix="select"
                          />
                          </div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>6) {questionList[5].questions}</label>
                            <br />
                            <input type="radio" name="question_type6" value="Yes" checked= {question6 === 'Yes' ? true : false} onClick={handleQuestion6}/>
                            <label >Yes</label>
                            <input type="radio" name="question_type6" value="No" checked={question6 === 'No' ? true : false} onClick={handleQuestion6}/>
                            <label >No</label>
                          </div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>7) {questionList[6].questions}</label>
                            <br />
                            <input type="radio" name="question_type7" value="Yes" checked= {question7 === 'Yes' ? true : false} onClick={handleQuestion7}/>
                            <label >Yes</label>
                            <input type="radio" name="question_type7" value="No" checked={question7 === 'No' ? true : false} onClick={handleQuestion7}/>
                            <label >No</label>
                          </div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>8) {questionList[7].questions}</label>
                            <Select
                              value={selectedAnswer2}
                              isMulti
                              onChange={handleMultiSelectChange8}
                              options={question8}
                              className="basic-multi-select"
                              classNamePrefix="select"
                          />
                          </div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>9) {questionList[8].questions}</label>
                            <input type="text" className="form-control form-control-user" defaultValue={question9} onChange={handleQuestion9}/>
                          </div>
                        </div>
                      </>
                        : ''}
                    <div className="col-lg-12">
                      <span className="text">
                        <button className="btn btn-primary" onClick={handleSubmit}>Update</button>
                      </span>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
