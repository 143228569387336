import { bookingConstants } from '../_constants';
import {apiConstants} from '../_constants/api.constants'
import * as commonAction from './commonAction';
import {  commonService } from '../_services';
import {history} from '../_helpers'

export const bookingActions = {
    getBookingList,
    addBooking,
    getBookingById,
    editBooking,
    searchBookingList,
    sortBooking
};

//-- Booking list
function getBookingList(apiName, data) {

    return dispatch => {
        dispatch(commonAction.request(bookingConstants.GET_BOOKING_REQUEST));
        commonService.withOutTokenAndBaseUrl(apiName, data)
            .then(
                bookingData => {
                    dispatch(commonAction.success(bookingConstants.GET_BOOKING_SUCCESS, bookingData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(bookingConstants.GET_BOOKING_FAILURE, error));
                  }
            );
    };
}

function sortBooking(apiName, data) {

    return dispatch => {
        dispatch(commonAction.request(bookingConstants.GET_BOOKING_REQUEST));
        commonService.withTokenAndBashUrl(apiName, data)
            .then(
                bookingData => {
                    dispatch(commonAction.success(bookingConstants.GET_BOOKING_SUCCESS, bookingData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(bookingConstants.GET_BOOKING_FAILURE, error));
                  }
            );
    };
}

function searchBookingList(apiName, data) {

    return dispatch => {
        dispatch(commonAction.request(bookingConstants.GET_BOOKING_REQUEST));
        commonService.withTokenAndBashUrl(apiName, data)
            .then(
                bookingData => {
                    dispatch(commonAction.success(bookingConstants.GET_BOOKING_SUCCESS, bookingData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(bookingConstants.GET_BOOKING_FAILURE, error));
                  }
            );
    };
}

function getBookingById(apiName, data) {

    return dispatch => {
        dispatch(commonAction.request(bookingConstants.GET_BOOKING_BY_ID_REQUEST));
        commonService.withOutTokenAndBaseUrl(apiName, data)
            .then(
                bookingData => {
                    dispatch(commonAction.success(bookingConstants.GET_BOOKING_BY_ID_SUCCESS, bookingData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(bookingConstants.GET_BOOKING_BY_ID_FAILURE, error));
                  }
            );
    };
}

function addBooking(apiName, data) {

    return dispatch => {
        dispatch(commonAction.request(bookingConstants.POST_BOOKING_REQUEST));
        commonService.withOutTokenAndFormData(apiName, data)
            .then(
                bookingData => {
                    dispatch(commonAction.success(bookingConstants.POST_BOOKING_SUCCESS, bookingData.data));
                    history.push('/booking')
                  },
                  error => {
                    dispatch(commonAction.failure(bookingConstants.POST_BOOKING_FAILURE, error));
                  }
            );
    };
}

function editBooking(apiName, data) {
    let obj = {
        limit: 10,
        offset: 0
      }
    return dispatch => {
        dispatch(commonAction.request(bookingConstants.EDIT_BOOKING_REQUEST));
        commonService.withOutTokenAndFormData(apiName, data)
            .then(
                bookingData => {
                    dispatch(commonAction.success(bookingConstants.EDIT_BOOKING_SUCCESS, bookingData.data));
                    dispatch(getBookingList(apiConstants.GET_BOOKINGS, obj))
                    history.push('/booking')
                  },
                  error => {
                    dispatch(commonAction.failure(bookingConstants.EDIT_BOOKING_FAILURE, error));
                  }
            );
    };
}