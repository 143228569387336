import React, { useEffect, useState } from 'react';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { apiConstants } from '../../_constants/api.constants';
// import { Form } from 'antd';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import BookingPayment from './BookingPayment'
import { providerActions, seekerActions, experienceActions, scheduleExperienceActions, companyActions } from '../../_actions';
import { MuiPickersUtilsProvider,  KeyboardDatePicker } from '@material-ui/pickers';
// import { getScheduleExperienceById } from '../../_reducers/schedule_experience.reducer';

export default function AddBooking(props) {

  const [bookingPaymentForm, setBookingPayment] = useState(false)
  const [payment, setPayment] = useState(0);
  const [getExperienceData, setExperience] = useState([]);
  const [scheduleExperienceData, setScheduleExperienceData] = useState([]);
  const [selectProvider, setSelectProvider] = useState('')
  const [seekerId, setSeekerId] = useState('');
  const [companyData, setCompanyData] = useState([])
  const [createdBy, setCreatedBy] = useState(JSON.parse(sessionStorage.getItem('user')).data.id)
  const [selectedDate, setSelectedDate] = useState(new Date('2014-08-18T21:11:54'));
  const [selectedScheduleExperience, setSelectedScheduleExperience] = useState({})
  const [selectExperience, setSelectExperience] = useState({});
  const [bookingData, setBookingData] = useState({})
  const [uploadFile, setUploadFile] = useState({});
  const [formData, setFormData] = useState({});
  const [terms, setTerms] = useState(false)
  const dispatch = useDispatch();
  const provider = useSelector(state => state.getProvider.allProvider);
  const company = useSelector(state => state.getCompany.getCompany);
  const scheduleExperience = useSelector(state => state.getScheduleExperienceByProviderId);
  const experience = useSelector((state) => state.getExperienceById);
  const seeker = useSelector(state => state.getSeeker.allSeeker)
 
  useEffect(() => {
    let obj = {
      offset: 0
    }
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER, obj));
    dispatch(experienceActions.getExperienceList(apiConstants.GET_ALL_EXPERIENCE_DETAILS))
    dispatch(seekerActions.getSeeker(apiConstants.GET_SEEKER))
    dispatch(companyActions.getCompany(apiConstants.GET_ORGANISATION));
  }, [])

  useEffect(() => {
    if(company && company.data.organisation){
      setCompanyData(company.data.organisation)
    }
  }, [company])

  const bookingPayment = () => {
    
    if(selectExperience.team_size >= bookingData.team_size){
      let obj = {
        created_by_admin: parseInt(createdBy),
        company_id: parseInt(selectProvider),
        seeker_id: seekerId,
        data: bookingData,
        file: uploadFile,
        start_time: selectedDate,
        experienceData: selectedScheduleExperience,
        experience_name: selectExperience.experience_duration
      }
      setFormData(obj)
      setBookingPayment(true)
    }else{
      alert(`Enter Team size not more than ${selectExperience.team_size}`)
    }
    
  }

  useEffect(() => {
    if (experience && experience.getExperienceById) {
      setExperience(experience.getExperienceById.experiences)
    }
}, [experience])

useEffect(() => {
  if (scheduleExperience && scheduleExperience.getScheduleExperienceByProviderId) {
    setScheduleExperienceData(scheduleExperience.getScheduleExperienceByProviderId.data)
  }
}, [scheduleExperience])

const handleChecked = (e) => {
  setTerms(true)
}

  const handleSelectProvider = (e) => {
    setSelectProvider(e.target.value)
    let obj = {
      provider_id: parseInt(e.target.value),
    };
    // let id = e.target.value;
    dispatch(experienceActions.getExperienceByProviderId(apiConstants.GET_EXPERIENCE_BY_PROVIDER_ID, obj))
  }

  const handleSelectSeeker = (e) => {
    setSeekerId(e.target.value)
  }

  const handleSelectExperience = (e) => {
    let index = e.target.value
    setSelectExperience(getExperienceData[index])
    let obj = {
      provider_id: parseInt(selectProvider)
    };
    // let id = e.target.value;
    dispatch(scheduleExperienceActions.getScheduleExperienceByProviderId(apiConstants.GET_SCHEDULE_EXPERIENCE_BY_PROVIDER_ID, obj))
  }

  const handleSelectScheduleExperience = (e) => {
    let index = e.target.value;
    setSelectedScheduleExperience(scheduleExperienceData[index])
    setSelectedDate(scheduleExperienceData[index].start_date)
    setPayment(scheduleExperienceData[index].amount)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBookingData({ ...bookingData, [name]: value })
  }

  const backButton = () => {
    setBookingPayment(false)
  }

  const handleChangeFile = (e) => {
    setUploadFile(e.target.files[0])
  }

  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Booking
          </li>
            </ol>
          </nav>
          {
            bookingPaymentForm === false ?  <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-2 text-gray-800">
                Add Booking
          </h1>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="table-responsive">

                  <div className="row">
                  <div className="col-sm-6">
                    <label>Seeker Name</label>
                    <select className="form-control" name="provide_id" onChange={handleSelectSeeker}>
                      <option>Select Seeker</option>
                      {seeker !== undefined && seeker.length > 0 ? seeker.map((seeker, index) => {
                        return (
                          <option value={seeker.id}>{seeker.first_name} {seeker.last_name}</option>
                        )
                      }) : null}
                    </select>
                  </div>
                  <div className="col-sm-6">
                   
                  </div>
                  <div className="col-sm-4">
                    <label>Company Name</label>
                    <select className="form-control" name="provide_id" onChange={handleSelectProvider}>
                      <option>Select Company name</option>
                      {companyData !== undefined && companyData.length > 0 ? (companyData || []).map((company, index) => {
                        return (
                         <>
                         {
                          company.organisation_type === 'provider' ?  <option value={company.user_id}>{company.company_name}</option> : ''
                         }
                         </>
                         
                        )
                      }) : null}
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <label>Experience Name</label>
                    <select className="form-control" name="experience_id" onChange={handleSelectExperience}>
                      <option>Select Experience</option>
                      {getExperienceData !== undefined && getExperienceData.length>0  ?
                        getExperienceData.map((data,index)=>(
                          <option value={index}>
                           {data.experience_name}
                         </option>
                        ))
                         
                        : null}
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <label>Schedule Experience</label>
                    <select className="form-control" name="sch_exp_id" onChange={handleSelectScheduleExperience}>
                      <option>Select Schedule Experience</option>
                      {scheduleExperienceData && scheduleExperienceData !== undefined && scheduleExperienceData.length > 0 ? (scheduleExperienceData || []).map((scheduleExperienceData, index) => {
                        return (
                          <option value={index}>{scheduleExperienceData.experience_name}</option>
                        )
                      }) : null}
                    </select>
                  </div>
                    <div className="col-md-6 col-xs-6">
                      <div className="form-group">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justifyContent="space-around">
                            <KeyboardDatePicker
                              margin="normal"
                              id="time-picker"
                              name="days_time"
                              label="Start Date"
                              value={selectedDate}
                              defaultValue={selectedDate}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              disabled
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Team Size</label>
                        <input type="number" className="form-control form-control-user" name="team_size"
                          placeholder="Team Size" onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <label>Upload</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={handleChangeFile}
                          name="supporting_document"
                          accept=".pdf"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group">
                        <label>Special Request</label>
                        <input type="text" className="form-control form-control-user" name="special_request"
                          placeholder="Special Request" onChange={handleChange} />
                      </div>
                    </div>
                   
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group">
                        <input type="checkbox" onChange={handleChecked}/> I agree to the Terms and Conditions Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the
              </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <h1>${payment}</h1>
                       
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        {
                          terms ? <Button variant="contained" color="primary" onClick={bookingPayment}>Proceed to pay</Button> : <Button variant="contained" color="primary" disabled>Proceed to pay</Button>
                        }
                       
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        : <BookingPayment backButton={backButton} data={formData}/>
        }
        </div>
        <Footer />
      </div>
    </div>
  )
}
