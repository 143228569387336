export const companyConstants = {
    COMPANY_ADDED_REQUEST: 'COMPANY_ADDED_REQUEST',
    COMPANY_ADDED_SUCCESS: 'COMPANY_ADDED_SUCCESS',
    COMPANY_ADDED_FAILURE: 'COMPANY_ADDED_FAILURE',

    GET_ORGANISATION_REQUEST: 'GET_ORGANISATION_REQUEST',
    GET_ORGANISATION_SUCCESS: 'GET_ORGANISATION_SUCCESS',
    GET_ORGANISATION_FAILURE: 'GET_ORGANISATION_FAILURE',

    GET_COMPANY_REQUEST: 'GET_COMPANY_REQUEST',
    GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
    GET_COMPANY_FAILURE: 'GET_COMPANY_FAILURE',

    GET_ALL_ORGANISATION_REQUEST: 'GET_ALL_ORGANISATION_REQUEST',
    GET_ALL_ORGANISATION_SUCCESS: 'GET_ALL_ORGANISATION_SUCCESS',
    GET_ALL_ORGANISATION_FAILURE: 'GET_ALL_ORGANISATION_FAILURE',

    GET_ALL_ORGANISATION_BY_ID_REQUEST: 'GET_ALL_ORGANISATION_BY_ID_REQUEST',
    GET_ALL_ORGANISATION_BY_ID_SUCCESS: 'GET_ALL_ORGANISATION_BY_ID_SUCCESS',
    GET_ALL_ORGANISATION_BY_ID_FAILURE: 'GET_ALL_ORGANISATION_BY_ID_FAILURE',
    
    UPDATE_ORGANISATION_BY_ID_REQUEST: 'UPDATE_ORGANISATION_BY_ID_REQUEST',
    UPDATE_ORGANISATION_BY_ID_SUCCESS: 'UPDATE_ORGANISATION_BY_ID_SUCCESS',
    UPDATE_ORGANISATION_BY_ID_FAILURE: 'UPDATE_ORGANISATION_BY_ID_FAILURE'
};
