import { experienceActivityTypeConstants } from "../_constants";

//-- get the list of activity types
export function getExperienceActivityTypeById(state = {}, action) {
    switch (action.type) {
        case experienceActivityTypeConstants.GET_EXPERIENCE_ACTIVITY_TYPE_BY_ID_REQUEST:
            return {
                getExperienceActivityTypeById: false
            };

        case experienceActivityTypeConstants.GET_EXPERIENCE_ACTIVITY_TYPE_BY_ID_SUCCESS:
            return { getExperienceActivityTypeById: action.successAction };

        case experienceActivityTypeConstants.GET_EXPERIENCE_ACTIVITY_TYPE_BY_ID_FAILURE:
            return {
                getExperienceActivityTypeById: false,
            };

        default:
            return state
    }
}