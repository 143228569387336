import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
// import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import {experienceActions } from "../../_actions";
import { shortlistActions } from "../../_actions/shortlist.action";
import {seekerActions} from "../../_actions/seeker.actions";
import { apiConstants } from "../../_constants/api.constants";

export default function AddShortlist({ displayPopUp, hidePopUp }) {
  const [feedbackData, setFeedbackData] = useState({});
  const [selectExperienceId, setSelectExperience] = useState("");
  const [experienceData, setExperienceData] = useState({});
  const [seekerId, setSeekerId] = useState("");
  const [name, setName] = useState("");
  const [expname, setExpName] = useState("");
  const [satisfactionRating, setSatisfactionRating] = useState("");
  const [qualityRating, setQualityRating] = useState("");
  const [managementRating, setManagementRating] = useState("");
  const experience = useSelector(state => state.getExperienceList)
  const providerDetails = useSelector(
    (state) => state.getProviderSeekerById.providerDetails
  );
  const seeker = useSelector(state => state.getSeeker.allSeeker)

  console.log("name", name)
 
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedbackData({ ...feedbackData, [name]: value });
  };

  useEffect(() => {
    let obj = {
      limit: 10,
      offset: 0,
    };
    dispatch(seekerActions.getSeeker(apiConstants.GET_SEEKER))
    dispatch(
      experienceActions.getExperienceList(
        apiConstants.GET_ALL_EXPERIENCE_DETAILS,
        obj
      )
    );
  }, []);

  useEffect(() => {
    if (experience && experience.getExperienceList) {
      console.log(experience.getExperienceList,"fhdfdfh")
        setExperienceData(experience.getExperienceList.data)
    }
}, [experience])
console.log(experienceData,"experienceData")


  const handleSelectSeeker = (e) => {
    console.log("value",e.target.value[0])
    // get seeker id
    setSeekerId(e.target.value)
    let obj = {
      id:e.target.value
  }
  // dispatch(experienceActions.getExperienceById(apiConstants.GET_EXPERIENCE_BY_ID, obj))
  };

  const handleSelectExperience =(e)=>{
    setSelectExperience(e.target.value)
  }

  const handleSubmit = (e, val) => {
    let newObj = {
      seeker_id: seekerId,
      experience_id: selectExperienceId,
      experience_name: '',
      seeker_name: ''
    };
    dispatch(shortlistActions.addShortlist(apiConstants.ADD_SHORTLIST, newObj));
    hidePopUp();
    // window.location.reload();
  };
  return (
    <div>
      <Dialog
        open={displayPopUp}
        aria-labelledby="add-products"
        aria-describedby="save-products-description"
        fullWidth={true}
      >
        <DialogTitle>Add Feedback</DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-sm-12 formBorder">
              <div className="p-5">
                <div className="form-group row">
                  <div className="col-sm-6">
                    <label>Select Seeker</label>
                    <select className="form-control" name="seeker_id" onChange={handleSelectSeeker}>
                      <option>Select Seeker</option>
                      {seeker !== undefined && seeker.length > 0 ? seeker.map((seeker, index) => {
                        return (
                          <option value={seeker.id} >{seeker.first_name} {seeker.last_name}</option>
                        )
                      }) : null}
                    </select>
                  </div>
                  <div className="col-sm-6">
                    <label>Experience Name</label>
                    <select
                      className="form-control"
                      name="experience_name"
                      onChange={handleSelectExperience}
                    >
                      <option>Select Experience</option>
                      {experienceData && experienceData.length >0 ? experienceData.map((exp,key) =>{
                        return (
                          <option value={exp.id}>{exp.experience_name} </option>
                        )
                      }) : null}
                    
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleSubmit(e, 1)}
          >
            Add Shortlist
          </Button>
          <Button variant="contained" onClick={hidePopUp}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
