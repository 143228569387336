import { bookingRequestConstants } from '../_constants';

//-- get the list of terms
export function getBookingRequest(state = {}, action) {
  switch (action.type) {
    case bookingRequestConstants.GET_BOOKING_REQUEST:
      return {
        getBooking: false
      };

    case bookingRequestConstants.GET_BOOKING_SUCCESS:
      return { getBooking:action.successAction};

    case bookingRequestConstants.GET_BOOKING_FAILURE:
      return {
        getBooking: false,
      };

    default:
      return state
  }
}

// get booking by id

export function getBookingRequestById(state = {}, action) {
  switch (action.type) {
    case bookingRequestConstants.GET_BOOKING_BY_ID_REQUEST:
      return {
        getBookingById: false
      };

    case bookingRequestConstants.GET_BOOKING_BY_ID_SUCCESS:
      return { getBookingById:action.successAction};

    case bookingRequestConstants.GET_BOOKING_BY_ID_FAILURE:
      return {
        getBookingById: false,
      };

    default:
      return state
  }
}

// export function addBooking(state = {}, action) {
//   switch (action.type) {
//     case bookingRequestConstants.POST_BOOKING_REQUEST:
//       return {
//         addBooking: false
//       };

//     case bookingRequestConstants.POST_BOOKING_SUCCESS:
//       console.log("REDUCER ", action);
//       return { addBooking:action.successAction};

//     case bookingRequestConstants.POST_BOOKING_FAILURE:
//       return {
//         addBooking: false,
//       };

//     default:
//       return state
//   }
// }

export function editBookingRequest(state = {}, action) {
  switch (action.type) {
    case bookingRequestConstants.EDIT_BOOKING_REQUEST:
      return {
        editBooking: false
      };

    case bookingRequestConstants.EDIT_BOOKING_SUCCESS:
      return { editBooking:action.successAction};

    case bookingRequestConstants.EDIT_BOOKING_FAILURE:
      return {
        editBooking: false,
      };

    default:
      return state
  }
}
