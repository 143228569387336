import { experienceEnvCauseConstants } from '../_constants';

//-- get the list of activity types
export function getExperienceEnvCauseById(state = {}, action) {
  switch (action.type) {
    case experienceEnvCauseConstants.GET_EXPERIENCE_ENV_CAUSE_BY_ID_REQUEST:
      return {
        getExperienceEnvCauseById: false,
      };

    case experienceEnvCauseConstants.GET_EXPERIENCE_ENV_CAUSE_BY_ID_SUCCESS:
      return { getExperienceEnvCauseById: action.successAction };

    case experienceEnvCauseConstants.GET_EXPERIENCE_ENV_CAUSE_BY_ID_FAILURE:
      return {
        getExperienceEnvCauseById: false,
      };

    default:
      return state;
  }
}
