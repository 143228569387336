import { bookingConstants } from '../_constants';

//-- get the list of terms
export function getBooking(state = {}, action) {
  switch (action.type) {
    case bookingConstants.GET_BOOKING_REQUEST:
      return {
        getBooking: false
      };

    case bookingConstants.GET_BOOKING_SUCCESS:
      return { getBooking:action.successAction};

    case bookingConstants.GET_BOOKING_FAILURE:
      return {
        getBooking: false,
      };

    default:
      return state
  }
}

// get booking by id

export function getBookingById(state = {}, action) {
  switch (action.type) {
    case bookingConstants.GET_BOOKING_BY_ID_REQUEST:
      return {
        getBookingById: false
      };

    case bookingConstants.GET_BOOKING_BY_ID_SUCCESS:
      return { getBookingById:action.successAction};

    case bookingConstants.GET_BOOKING_BY_ID_FAILURE:
      return {
        getBookingById: false,
      };

    default:
      return state
  }
}

export function addBooking(state = {}, action) {
  switch (action.type) {
    case bookingConstants.POST_BOOKING_REQUEST:
      return {
        addBooking: false
      };

    case bookingConstants.POST_BOOKING_SUCCESS:
      return { addBooking:action.successAction};

    case bookingConstants.POST_BOOKING_FAILURE:
      return {
        addBooking: false,
      };

    default:
      return state
  }
}

export function editBooking(state = {}, action) {
  switch (action.type) {
    case bookingConstants.EDIT_BOOKING_REQUEST:
      return {
        editBooking: false
      };

    case bookingConstants.EDIT_BOOKING_SUCCESS:
      return { editBooking:action.successAction};

    case bookingConstants.EDIT_BOOKING_FAILURE:
      return {
        editBooking: false,
      };

    default:
      return state
  }
}
