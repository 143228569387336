import { feedbackConstants } from '../_constants/feedback.constants';

//-- get the list of terms
export function getFeedbackList(state = {}, action) {
  switch (action.type) {
    case feedbackConstants.GET_ALL_FEEDBACK_REQUEST:
      return {
        getFeedbackList: false
      };
    case feedbackConstants.GET_ALL_FEEDBACK_SUCCESS:
      return { getFeedbackList:action.successAction};

    case feedbackConstants.GET_ALL_FEEDBACK_FAILURE:
      return {
        getFeedbackList: false,
      };

    default:
      return state
  }
}

export function addFeedback(state = {}, action) {
  switch (action.type) {
    case feedbackConstants.ADD_FEEDBACK_REQUEST:
      return {
        addFeedback: false
      };

    case feedbackConstants.ADD_FEEDBACK_SUCCESS:
      return { addFeedback:action.successAction};

    case feedbackConstants.ADD_FEEDBACK_FAILURE:
      return {
        addFeedback: false,
      };

    default:
      return state
  }
}

export function getFeedbackById(state = {}, action) {
  switch (action.type) {
    case feedbackConstants.GET_FEEDBACK_BY_ID_REQUEST:
      return {
        getFeedbackById: false
      };

    case feedbackConstants.GET_FEEDBACK_BY_ID_SUCCESS:
      return { getFeedbackById:action.successAction};

    case feedbackConstants.GET_FEEDBACK_BY_ID_FAILURE:
      return {
        getFeedbackById: false,
      };

    default:
      return state
  }
}


