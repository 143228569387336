import { activityTypeConstants } from "../_constants";

//-- get the list of activity types
export function getActivityTypeList(state = {}, action) {
    switch (action.type) {
        case activityTypeConstants.GET_ACTIVITY_TYPE_REQUEST:
            return {
                getActivityTypeList: false
            };

        case activityTypeConstants.GET_ACTIVITY_TYPE_SUCCESS:
            return { getActivityTypeList: action.successAction };

        case activityTypeConstants.GET_ACTIVITY_TYPE_FAILURE:
            return {
                getActivityTypeList: false,
            };

        default:
            return state
    }
}

export function addActivityType(state = {}, action) {
    switch (action.type) {
        case activityTypeConstants.ADD_ACTIVITY_TYPE_REQUEST:
            return {
                addActivityType: false
            };

        case activityTypeConstants.ADD_ACTIVITY_TYPE_SUCCESS:
            return { addActivityType:action.successAction};

        case activityTypeConstants.ADD_ACTIVITY_TYPE_FAILURE:
            return {
                addActivityType: action.errorAction,
            };

        default:
        return state
    }
}

export function editActivityType(state = {}, action) {
    switch (action.type) {
        case activityTypeConstants.EDIT_ACTIVITY_TYPE_REQUEST:
            return {
                editActivityType: false
            };

        case activityTypeConstants.EDIT_ACTIVITY_TYPE_SUCCESS:
            return { editActivityType: action.successAction };

        case activityTypeConstants.EDIT_ACTIVITY_TYPE_FAILURE:
            return {
                editActivityType: action.errorAction,
            };

        default:
            return state
    }
}

export function archiveActivityType(state = {}, action) {
    switch (action.type) {
        case activityTypeConstants.ARCHIVE_ACTIVITY_TYPE_REQUEST:
        return {
            archiveActivityType: false
        };

        case activityTypeConstants.ARCHIVE_ACTIVITY_TYPE_SUCCESS:
        return { archiveActivityType: action.successAction };

        case activityTypeConstants.ARCHIVE_ACTIVITY_TYPE_FAILURE:
        return {
            archiveActivityType: false,
        };

        default:
        return state
    }
}

export function filterActivityTypes(state = {}, action) {
    switch (action.type) {
        case activityTypeConstants.FILTER_ACTIVITY_TYPE_REQUEST:
            return {
                filterActivityTypes: false
            };

        case activityTypeConstants.FILTER_ACTIVITY_TYPE_SUCCESS:
            return { filterActivityTypes: action.successAction };

        case activityTypeConstants.FILTER_ACTIVITY_TYPE_FAILURE:
            return {
                filterActivityTypes: false,
            };

        default:
            return state
    }
}