import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';

import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import { apiConstants } from '../../_constants/api.constants';


class UsersProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            user: {},
            profileUpdate: false,
            //-- We are using for validation
            errors: {}
        };
    }

    //-- handle validation for page
    handleValidation() {
        const { siteConstants } = this.props.language;

        let fields = this.state.user;
        let errors = {};
        let formIsValid = true;

        //Name
        if ((fields["first_name"] == '') || (fields["last_name"] == '')) {
            formIsValid = false;
            if (fields["first_name"] == '') {
                errors["first_name"] = siteConstants.ERR_CAN_NOT_BLANK;
            }
            if (fields["last_name"] == '')
                errors["last_name"] = siteConstants.ERR_CAN_NOT_BLANK;
        }

        if (!fields["first_name"]) {
            if (!fields["first_name"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["first_name"] = siteConstants.ERR_ONLY_LETTERS;
            }
        }
        if (!fields["last_name"]) {
            if (!fields["last_name"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["last_name"] = siteConstants.ERR_ONLY_LETTERS;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    //-- update the user profile information
    updateAdminProfileInfo = (e) => {
        e.preventDefault();

        let obj = {};
        obj.id = JSON.parse(sessionStorage.getItem('user')).data.id;

        if (this.handleValidation()) {
            this.props.dispatch(userActions.updateAdminProfileInfo(apiConstants.UPDATE_ADMIN_PROFILE, this.state.user, apiConstants.GET_USERS_DETAILS + `?id=${obj.id}`));
            this.setState({ profileUpdate: true });
        } else {
            alert("Form has errors.")
        }
    }

    //-- we are setting the Values
    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    //-- Will need to fetch user data
    componentDidMount() {
        const { dispatch } = this.props;
        let obj = {};
        obj.id = JSON.parse(sessionStorage.getItem('user')).data.id;
        console.log(obj)
        dispatch(userActions.getUserDetails(apiConstants.GET_USERS_DETAILS + `?id=${obj.id}`, obj));
    }

    /*
    * componentWillReceiveProps - Whenever Props change, it will call and store data
    * update the state values with new props values, this method will get called whenever any change happens to props values
    */
    componentWillReceiveProps(props) {
        if (props.userDetails) {
            if (props.userDetails.userDetails) {
                if (props.userDetails.userDetails.AdminData) {
                    //-- profileUpdate - because only update list when we don't want to change the userlist
                    //if (this.state.profileUpdate === false) {
                    this.setState({
                        user: props.userDetails.userDetails.AdminData
                    })
                    //}
                }
            }
        }
    }



    /*
    *  It will open popup window, so user can select a new profile image
    */
    handleImageChange(e) {
        document.querySelector("input[type='file']").click();
    }

    /*
    *  Once user chagne his profile image, he can submit it 
    */
    handleImageSubmit(e) {
        e.preventDefault();

        let file = e.target.files[0];

        let postData = {};
        postData.files = file;
        postData.id = JSON.parse(sessionStorage.getItem('user')).id;

        if (this.handleValidation()) {
            this.props.dispatch(
                userActions.updateProfileImage(apiConstants.UPDATE_PROFILE_IMAGE, postData, apiConstants.GET_USERS_DETAILS)
            );

            this.setState({ profileUpdate: true });
        } else {
            alert("Form has errors.");
        }
    }

    render() {
        const { user } = this.state;
        const { siteConstants } = this.props.language;

        //-- Manage Profile Image 
        let profileImage = "./assets/img/svg/user.svg";
        if (this.state.user.profile_image !== undefined && this.state.user.profile_image !== "") {
            profileImage = process.env.REACT_APP_IMAGE_PATH + "/uploads/" + user.id + '/' + this.state.user.profile_image;
        }

        return (
            <div id="wrapper">
                <SideBar />

                <div id="content-wrapper" className="d-flex flex-column">
                    {/* <!-- Main Content --> */}
                    <div id="content">

                        <TopBar />

                        <div className="container-fluid">

                            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                <h1 className="h3 mb-0 text-gray-800">{siteConstants.LABEL_PROFILE}</h1>
                            </div>

                            <div className="card shadow mb-4">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div className="row">
                                            <div className="col-sm-3"></div>
                                            <div className="col-sm-6">
                                                <form role="form" method="multipart/form-data">
                                                    <div className="p-5">
                                                        <div className="text-center">
                                                            <div className="container profilePic">
                                                                <img
                                                                    className="img-profile rounded-circle"
                                                                    style={{ width: "150px" }}
                                                                    src={profileImage}
                                                                />
                                                                <div className="overlay">
                                                                    <a
                                                                        href="#"
                                                                        className="icon"
                                                                        title="User Profile"
                                                                        onClick={this.handleImageChange}
                                                                    >
                                                                        <i className="fas fa-edit"></i>
                                                                    </a>
                                                                </div>
                                                                <h1 className="h4 text-gray-900 mb-4">{user.first_name} {user.last_name}</h1>
                                                                <input
                                                                    className="fileInput form-control form-control-user"
                                                                    type="file"
                                                                    name="userImage"
                                                                    onChange={e => this.handleImageSubmit(e)}
                                                                    style={{ display: "none" }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>{siteConstants.LABEL_F_NAME}</label>
                                                            <label className="my-error">{this.state.errors["first_name"]}</label>
                                                            <input type="text" className="form-control form-control-user" name="first_name"
                                                                value={user.first_name} onChange={(e) => this.handleChange(e)} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>{siteConstants.LABEL_L_NAME}</label>
                                                            <label className="my-error">{this.state.errors["last_name"]}</label>
                                                            <input type="text" className="form-control form-control-user" name="last_name"
                                                                value={user.last_name} onChange={(e) => this.handleChange(e)} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>{siteConstants.LABEL_CONTACT_NO}</label>
                                                            <label className="my-error">{this.state.errors["phone_number"]}</label>
                                                            <input type="text" className="form-control form-control-user" name="phone"
                                                                value={user.phone} onChange={(e) => this.handleChange(e)} />
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit" className="btn btn-primary btn-user btn-block" onClick={this.updateAdminProfileInfo}>{siteConstants.BTN_SAVE}</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="col-sm-3"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <!-- End of Main Content --> */}

                    <Footer />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { userDetails, language } = state;
    return {
        userDetails,
        language
    };
}

const connectedUsersProfile = connect(mapStateToProps)(UsersProfile);
export { connectedUsersProfile as UsersProfile };