import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { apiConstants } from "../../_constants/api.constants";
import { providerActions, userActions } from "../../_actions";
import SideBar from "../Common/SideBar/SideBar";
// import { history } from '../../_helpers';

export default function ProviderDetailRoute() {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const [organisation, setOrganisation] = useState({});
  const providerDetails = useSelector(
    (state) => state.getProviderSeekerById.providerDetails
  );

  useEffect(() => {
    var id = window.location.href.split("=")[1];
    
    dispatch(
      providerActions.getProviderSeekerById(
        apiConstants.GET_PROVIDER_SEEKER_BY_ID,
        { id: id }
      )
    );
  }, []);

  useEffect(()=>{
    if(providerDetails.data){
      setUserData(providerDetails.data.userdata)
      setOrganisation(providerDetails.data.organisation)
    }
  }, [providerDetails.data])

  return (
    <div id="wrapper">
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray-800">Provider Details</h1>
            </div>

            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="table-responsive">
                  <div className="row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6 formBorder">
                      <div className="p-5">
                        <div>
                          {/* <b>{userDetails.name}</b> */}
                          <h1 className="h4 text-gray-900 mb-4 headingBorderBottom">
                            Name
                          </h1>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <label>Company Name</label>
                            <span className="ml-2 text-primary" style={{cursor:"pointer"}}
                            onClick={()=>{ window.open(`/company_detail?id=${userData.org_id}`, '_blank')
                            }}> See details</span>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="txtCompanyName"
                              placeholder={organisation.company_name}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <label>First Name</label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="txtName"
                              value={userData.first_name}
                              readOnly
                            />
                          </div>
                          <div className="col-md-6 col-xs-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                value={userData.last_name}
                                type="text"
                                placeholder="Last Name"
                                className="form-control"
                                name="last_name"
                                readOnly
                              />
                            </div>
                            {/* <p className={classes.error}>{this.state.eventDescription_error}</p> */}
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <label>Department</label>
                            <input
                              value={userData.department}
                              type="text"
                              min="0"
                              placeholder="Department"
                              className="form-control"
                              name="department"
                              readOnly
                            />
                          </div>
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <label>Job Title</label>
                            <input
                              value={userData.job_title}
                              type="text"
                              min="0"
                              placeholder="Job Title"
                              className="form-control"
                              name="job_title"
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <label>Address</label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="txtAddress"
                              value={organisation.company_street_address}
                              readOnly
                            />
                          </div>
                          <div className="col-sm-6">
                            <label>Email</label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="txtEmail"
                              value={userData.email}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <label>Contact No.</label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="txtContactNo"
                              value={userData.phone}
                              readOnly
                            />
                          </div>
                          <div className="col-sm-6">
                            <label>Provider Created Date</label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="txtCreateDate"
                              value={userData.createdAt}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6 mb-3 mb-sm-0">
                            <label>Provider Approved Date</label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="txtApprovalDate"
                              value={organisation.company_street_address}
                              readOnly
                            />
                          </div>
                          <div className="col-sm-6">
                            <label>Company Address</label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="txtBuying"
                              value={userData.updatedAt}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
