import React, { useEffect, useState } from 'react';
import TopBar from '../Common/TopBar/TopBar';
import SideBar from '../Common/SideBar/SideBar';
import Footer from '../Common/Footer/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { countryActions } from '../../_actions';

import { apiConstants } from '../../_constants/api.constants';
import { CSVLink } from 'react-csv';
import { Box, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Pagination from '../Common/Pagination/Pagination';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import swal from 'sweetalert';
import useDebounce from '../../hooks/useDebounce';

export default function CountryList() {
  const dispatch = useDispatch();
  const countryData = useSelector((state) => state.getCountryList);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
  const [NewOffset, setOffset] = useState(0);
  const [filteredCountry, setFilteredCountry] = useState([]);
  const [dbFilterCountry, setDbFilterCountry] = useState();
  const [limit, setLimit] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const debouncedSearchValue = useDebounce(dbFilterCountry?.name, 1000);
  const [isFiltered, setIsFiltered] = useState(false);

  // functions
  const getAllCountries = () => {
    let obj = {
      limit: 25,
      offset: 0,
    };

    dispatch(
      countryActions?.getCountryList(apiConstants.GET_ALL_COUNTRIES, obj),
    );
  };

  // hooks
  useEffect(() => {
    getAllCountries();
  }, []);

  useEffect(() => {
    if (countryData?.getCountryList?.country) {
      setFilteredCountry(countryData?.getCountryList?.country);
    }
  }, [countryData?.getCountryList]);

  useEffect(() => {
    setLoading(countryData?.loading);
  }, [countryData?.loading]);

  const searchCountry = (dbFilterCountry) => {
    let obj = {
      limit: 25,
      offset: 0,
    };
    obj['enabled'] = dbFilterCountry.enabled;
    obj['name'] = dbFilterCountry.name;

    dispatch(countryActions.getCountryList(apiConstants.FILTER_COUNTRY, obj));
  };

  useEffect(() => {
    if (isFiltered) {
      searchCountry(dbFilterCountry);
    }
  }, [dbFilterCountry?.enabled]);

  useEffect(() => {
    if (isFiltered) {
      searchCountry(dbFilterCountry);
    }
  }, [debouncedSearchValue]);

  const handleEnableSelect = (e) => {
    setIsFiltered(true);
    setDbFilterCountry({
      ...dbFilterCountry,
      enabled: e.target.value,
    });
  };

  const enableSwitchHandleChange = async (e, id) => {
    let obj = {
      limit: 25,
      offset: 0,
      enabled: e.target.checked,
      id,
    };
    setTimeout(async () => {
      dispatch(
        countryActions.enableCountry(apiConstants.ENABLE_COUNTRY, obj),
      ).then((res) => {
        swal({
          title: 'Update Success',
          text: 'Country status updated successfully',
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
      });
    }, 1500);
  };

  const makeHttpRequestWithPage = (pageNumber) => {
    let obj = {
      limit: limit,
      offset: limit * (pageNumber - 1),
    };
    setOffset(obj.offset);
    setCurrentPage(pageNumber);
    if (dbFilterCountry) {
      obj['enabled'] = Number(dbFilterCountry.enabled);
      obj['name'] = dbFilterCountry.name;
      return dispatch(
        countryActions.getCountryList(apiConstants.FILTER_COUNTRY, obj),
      );
    }
    dispatch(
      countryActions.getCountryList(apiConstants.GET_ALL_COUNTRIES, obj),
    );
  };

  return (
    <div id='wrapper'>
      <SideBar />
      <div id='content-wrapper' className='d-flex flex-column'>
        <div id='content'>
          <TopBar />
          <nav className='' aria-label='breadcrumb'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <a href='/dashboard'>Dashboard</a>
              </li>
              <li className='active breadcrumb-item' aria-current='page'>
                Country
              </li>
            </ol>
          </nav>
          <div className='container-fluid'>
            <div className='d-sm-flex align-items-center justify-content-between mb-4'>
              <h1 className='h3 mb-2 text-gray-800'>Countries</h1>
              <div className='d-sm-flex align-items-center justify-content-between'></div>
            </div>
            <div className='card shadow mb-4'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <div className='row mb-4'>
                    {/* Page limit drop down */}
                    {/* <PageLimit funChangePageLimit={changeTableData} /> */}

                    <div className='col-sm-3 col-md-3'>
                      <label>Search</label>
                      <div className='d-flex justify-content-center align-items-centers'>
                        <input
                          type='search'
                          className='form-control form-control-sm'
                          placeholder='Search by code or name'
                          onChange={(e) => {
                            setIsFiltered(true);
                            setDbFilterCountry({
                              ...dbFilterCountry,
                              name: e.target.value,
                            });
                          }}
                          aria-controls='dataTable'
                        />
                      </div>
                    </div>
                    <div className='col-sm-3 col-md-3'>
                      <label>Enabled</label>
                      <select
                        className='form-control form-control-sm'
                        name='provide_id'
                        onChange={handleEnableSelect}
                      >
                        <option value={''}>Show All</option>
                        <option value={'yes'}>Yes</option>
                        <option value={'no'}>No</option>
                      </select>
                    </div>
                  </div>
                  <table
                    className='table table-bordered'
                    width='100%'
                    cellSpacing='0'
                  >
                    <thead>
                      <tr>
                        <th scope='col'>No.</th>
                        <th scope='col'>Code</th>
                        <th scope='col'>Name</th>
                        <th scope='col'>Enable</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <>
                          <td className='' colSpan={'100%'}>
                            <div className='col-md-12 justify-content-center align-items-center'>
                              <Box className='d-flex justify-content-center'>
                                <CircularProgress />
                              </Box>
                            </div>
                          </td>
                        </>
                      ) : (
                        <>
                          {filteredCountry?.length > 0
                            ? filteredCountry.map((country, index) => {
                                var count = 0;
                                count = index + NewOffset + 1;
                                return (
                                  <tr key={index}>
                                    <td>{count}</td>
                                    <td>{country.code}</td>
                                    <td>{country.name}</td>
                                    <td>
                                      {user.data?.role === 'super admin' ? (
                                        <>
                                          <FormControlLabel
                                            control={
                                              <Switch
                                                name={`country_enabled_switch${country.id}`}
                                                checked={country.enabled}
                                                onChange={(e) => {
                                                  enableSwitchHandleChange(
                                                    e,
                                                    country.id,
                                                  );
                                                }}
                                                color='secondary'
                                              />
                                            }
                                          />
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            : null}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  totalPage={
                    countryData && countryData?.getCountryList
                      ? countryData?.getCountryList?.pages
                      : 0
                  }
                  currentPage={currentPage}
                  funPagination={makeHttpRequestWithPage}
                />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
