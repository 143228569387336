import { scheduleExperienceConstants } from '../_constants/schedule_experience.constants';
import * as commonAction from './commonAction';
import {  commonService } from '../_services';
import { apiConstants } from '../_constants/api.constants';
import {history} from '../_helpers';

export const scheduleExperienceActions = {
    getScheduleExperienceList,
    addScheduleExperience,
    editScheduleExperience,
    getScheduleExperienceById,
    deleteScheduleExperience,
    sortScheduleExperience,
    getScheduleExperienceByProviderId
};


//-- getExperienceList
function getScheduleExperienceList(apiName, data) {
    return dispatch => {
        dispatch(commonAction.request(scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_REQUEST, data));
        commonService.withOutTokenAndBaseUrl(apiName, data)
            .then(
                experience => {
                    dispatch(commonAction.success(scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_SUCCESS, experience.data));
                  },
                  error => {
                    dispatch(commonAction.failure(scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_FAILURE, error.response.data.error));
                  }
            );
    };
}
function sortScheduleExperience(apiName, data) {
    return dispatch => {
        dispatch(commonAction.request(scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_REQUEST, data));
        commonService.withToken(apiName, data)
            .then(
                experience => {
                    dispatch(commonAction.success(scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_SUCCESS, experience.data));
                  },
                  error => {
                    dispatch(commonAction.failure(scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_FAILURE, error.response.data.error));
                  }
            );
    };
}

function addScheduleExperience(apiName, experienceData) {
  
    return dispatch => {
        dispatch(commonAction.request(scheduleExperienceConstants.ADD_SCHEDULE_EXPERIENCE_REQUEST, experienceData));
        commonService.withToken(apiName, experienceData)
            .then(
                experience => {
                    dispatch(commonAction.success(scheduleExperienceConstants.ADD_SCHEDULE_EXPERIENCE_SUCCESS, experience.data));
                  },
                  error => {
                    dispatch(commonAction.failure(scheduleExperienceConstants.ADD_SCHEDULE_EXPERIENCE_FAILURE, error.response.data.error));
                  }
            );
    };
}

function getScheduleExperienceById(apiName, experienceData) {

    return dispatch => {
        dispatch(commonAction.request(scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_BY_ID_REQUEST, experienceData));
        commonService.withToken(apiName, experienceData)
            .then(
                experience => {
                    dispatch(commonAction.success(scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_BY_ID_SUCCESS, experience.data));
                  },
                  error => {
                    dispatch(commonAction.failure(scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_BY_ID_FAILURE, error.response.data.error));
                  }
            );
    };
}

function getScheduleExperienceByProviderId(apiName, experienceData) {

    return dispatch => {
        dispatch(commonAction.request(scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_BY_PROVIDER_ID_REQUEST, experienceData));
        commonService.withToken(apiName, experienceData)
            .then(
                experience => {
                    dispatch(commonAction.success(scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_BY_PROVIDER_ID_SUCCESS, experience.data));
                  },
                  error => {
                    dispatch(commonAction.failure(scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_BY_PROVIDER_ID_FAILURE, error.response.data.error));
                  }
            );
    };
}

function editScheduleExperience(apiName, experienceData) {
    let obj = {
        limit: 10,
        offset: 0
      }

    return dispatch => {
        dispatch(commonAction.request(scheduleExperienceConstants.EDIT_SCHEDULE_EXPERIENCE_REQUEST, experienceData));
        commonService.withToken(apiName, experienceData)
            .then(
                experience => {
                    dispatch(commonAction.success(scheduleExperienceConstants.EDIT_SCHEDULE_EXPERIENCE_SUCCESS, experience.data));
                    dispatch(getScheduleExperienceList(apiConstants.GET_ALL_SCHEDULE_EXPERIENCE_DETAILS, obj))
                    history.push('/schedule_experience')
                  },
                  error => {
                    dispatch(commonAction.failure(scheduleExperienceConstants.EDIT_SCHEDULE_EXPERIENCE_FAILURE, error.response.data.error));
                  }
            );
    };
}
function deleteScheduleExperience(apiName, experienceData) {

    return dispatch => {
        dispatch(commonAction.request(scheduleExperienceConstants.DELETE_SCHEDULE_EXPERIENCE_REQUEST, experienceData));
        commonService.withToken(apiName, experienceData)
            .then(
                experience => {
                    dispatch(commonAction.success(scheduleExperienceConstants.DELETE_SCHEDULE_EXPERIENCE_SUCCESS, experience.data));
                  },
                  error => {
                    dispatch(commonAction.failure(scheduleExperienceConstants.DELETE_SCHEDULE_EXPERIENCE_FAILURE, error.response.data.error));
                  }
            );
    };
}


