export const questionConstants = {
    
    GET_ALL_QUESTIONS_REQUEST: 'GET_ALL_QUESTIONS_REQUEST',
    GET_ALL_QUESTIONS_SUCCESS: 'GET_ALL_QUESTIONS_SUCCESS',
    GET_ALL_QUESTIONS_FAILURE: 'GET_ALL_QUESTIONS_FAILURE',

    GET_ALL_COMPANYREVIEW_REQUEST: 'GET_ALL_COMPANYREVIEW_REQUEST',
    GET_ALL_COMPANYREVIEW_SUCCESS: 'GET_ALL_COMPANYREVIEW_SUCCESS',
    GET_ALL_COMPANYREVIEW_FAILURE: 'GET_ALL_COMPANYREVIEW_FAILURE',

    EDIT_COMPANYREVIEW_REQUEST: 'EDIT_COMPANYREVIEW_REQUEST',
    EDIT_COMPANYREVIEW_SUCCESS: 'EDIT_COMPANYREVIEW_SUCCESS',
    EDIT_COMPANYREVIEW_FAILURE: 'EDIT_COMPANYREVIEW_FAILURE',

    GET_COMPANYREVIEW_BY_ID_REQUEST: 'GET_COMPANYREVIEW_BY_ID_REQUEST',
    GET_COMPANYREVIEW_BY_ID_SUCCESS: 'GET_COMPANYREVIEW_BY_ID_SUCCESS',
    GET_COMPANYREVIEW_BY_ID_FAILURE: 'GET_COMPANYREVIEW_BY_ID_FAILURE',
};
