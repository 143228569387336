import { notificationConstants } from '../_constants';
import * as commonAction from './commonAction';
import {  commonService } from '../_services';
import { history } from '../_helpers';


export const notificationActions = {
    getNotificationsList
};

//-- Questions list
function getNotificationsList(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(notificationConstants.GET_ALL_NOTIFICATIONS_REQUEST));
        commonService.withTokenAndBashUrl(apiName, obj)
            .then(
                questionData => {
                    dispatch(commonAction.success(notificationConstants.GET_ALL_NOTIFICATIONS_SUCCESS, questionData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(notificationConstants.GET_ALL_NOTIFICATIONS_FAILURE, error.response.data.error));
                  }
            );
    };
}
