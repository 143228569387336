import React, { Component } from "react";
import { connect } from 'react-redux';

class UsersDetail extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        const userDetails = this.props.userData;
        //console.log(" userDetails ", userDetails);
        const { siteConstants } = this.props.language;

        return (
            <div className="container-fluid">

                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">{siteConstants.LABEL_USER_DETAILS}</h1>
                    <a href="#" onClick={this.props.backButton} className="btn btn-secondary btn-icon-split">
                        <span className="icon text-white-50">
                            <i className="fas fa-arrow-left"></i>
                        </span>
                        <span className="text">{siteConstants.BTN_BACK}</span>
                    </a>
                </div>

                <div className="card shadow mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="row">
                                <div className="col-sm-3"></div>
                                <div className="col-sm-6 formBorder">
                                    <div className="p-5">
                                        <div>
                                            {/* <b>{userDetails.name}</b> */}
                                            <h1 className="h4 text-gray-900 mb-4 headingBorderBottom">{userDetails.name}</h1>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <label>{siteConstants.GEN_NAME}</label>
                                                <input type="text" className="form-control form-control-user" id="txtName"
                                                    placeholder={userDetails.name} readOnly />
                                            </div>
                                            <div className="col-sm-6">
                                                <label>{siteConstants.LABEL_COMPANY_NAME}</label>
                                                <input type="text" className="form-control form-control-user" id="txtCompanyName"
                                                    placeholder={userDetails.company_name} readOnly />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>{siteConstants.LABEL_CERTIFICATION_NO}</label>
                                            <input type="email" className="form-control form-control-user" id="txtCertificationNo"
                                                placeholder={userDetails.dealer_certification_number} readOnly />
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <label>{siteConstants.LABEL_ADDRESS}</label>
                                                <input type="text" className="form-control form-control-user" id="txtAddress"
                                                    placeholder={userDetails.address} readOnly />
                                            </div>
                                            <div className="col-sm-6">
                                                <label>{siteConstants.LABEL_EMAIL}</label>
                                                <input type="text" className="form-control form-control-user" id="txtEmail"
                                                    placeholder={userDetails.email} readOnly />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <label>{siteConstants.LABEL_CONTACT_NO}</label>
                                                <input type="text" className="form-control form-control-user" id="txtContactNo"
                                                    placeholder={userDetails.phone_number} readOnly />
                                            </div>
                                            <div className="col-sm-6">
                                                <label>{siteConstants.LABEL_USER_CREATED_DATE}</label>
                                                <input type="text" className="form-control form-control-user" id="txtCreateDate"
                                                    placeholder={userDetails.createdAt} readOnly />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <label>{siteConstants.LABEL_USER_APPROVAL_DATE}</label>
                                                <input type="text" className="form-control form-control-user" id="txtApprovalDate"
                                                    placeholder={userDetails.createdAt} readOnly />
                                            </div>
                                            <div className="col-sm-6">
                                                <label>{siteConstants.LABEL_NUMBER_OF_BUYING}</label>
                                                <input type="text" className="form-control form-control-user" id="txtBuying"
                                                    placeholder="dummy" readOnly />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <label>{siteConstants.LABEL_NUMBER_OF_SELLING}</label>
                                                <input type="text" className="form-control form-control-user" id="txtSelling"
                                                    placeholder="dummy" readOnly />
                                            </div>
                                            <div className="col-sm-6">
                                                <label>{siteConstants.LABEL_NUMBER_OF_SATISFACTION}</label>
                                                <input type="text" className="form-control form-control-user" id="txtNoOfSatisfaction"
                                                    placeholder="dummy" readOnly />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <label>{siteConstants.LABEL_BANK_ACCOUNTS}</label>
                                                <input type="text" className="form-control form-control-user" id="txtBankAccount"
                                                    placeholder="dummy" readOnly />
                                            </div>
                                            <div className="col-sm-6">
                                                <label>{siteConstants.LABEL_BANK}</label>
                                                <input type="text" className="form-control form-control-user" id="txtBank"
                                                    placeholder="dummy" readOnly />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <label>{siteConstants.LABEL_ACCOUNT}</label>
                                                <input type="text" className="form-control form-control-user" id="txtAccount"
                                                    placeholder="dummy" readOnly />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//-- Here we are adding Reducer names, so it can be get data from reducers using store
function mapStateToProps(state) {
    const { language } = state;
    return {
        language
    };
}

const connectedUsersDetail = connect(mapStateToProps)(UsersDetail);
export default connectedUsersDetail;