import { questionConstants } from '../_constants';
import * as commonAction from './commonAction';
import {  commonService } from '../_services';
import { history } from '../_helpers';


export const questionsActions = {
    getQuestionsList,
    getCompanyReview,
    editCompanyReview,
    getCompanyReviewById
};

//-- Questions list
function getQuestionsList(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(questionConstants.GET_ALL_QUESTIONS_REQUEST));
        commonService.getDataWithTokenAndBashUrl(apiName, obj)
            .then(
                questionData => {
                    dispatch(commonAction.success(questionConstants.GET_ALL_QUESTIONS_SUCCESS, questionData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(questionConstants.GET_ALL_QUESTIONS_FAILURE, error.response.data.error));
                  }
            );
    };
}

function getCompanyReview(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(questionConstants.GET_ALL_COMPANYREVIEW_REQUEST));
        commonService.withOutTokenAndBaseUrl(apiName, obj)
            .then(
                companyReviewData => {
                    dispatch(commonAction.success(questionConstants.GET_ALL_COMPANYREVIEW_SUCCESS, companyReviewData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(questionConstants.GET_ALL_COMPANYREVIEW_FAILURE, error.response.data.error));
                  }
            );
    };
}

function getCompanyReviewById(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(questionConstants.GET_COMPANYREVIEW_BY_ID_REQUEST));
        commonService.withOutTokenAndBaseUrl(apiName, obj)
            .then(
                companyReviewData => {
                    dispatch(commonAction.success(questionConstants.GET_COMPANYREVIEW_BY_ID_SUCCESS, companyReviewData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(questionConstants.GET_COMPANYREVIEW_BY_ID_FAILURE, error.response.data.error));
                  }
            );
    };
}

function editCompanyReview(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(questionConstants.EDIT_COMPANYREVIEW_REQUEST));
        commonService.withOutTokenAndBaseUrl(apiName, obj)
            .then(
                companyReviewData => {
                    dispatch(commonAction.success(questionConstants.EDIT_COMPANYREVIEW_SUCCESS, companyReviewData.data));
                    history.push("/submitedanswer")
                  },
                  error => {
                    dispatch(commonAction.failure(questionConstants.EDIT_COMPANYREVIEW_FAILURE, error.response.data.error));
                  }
            );
    };
}
