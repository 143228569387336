import React, { Component, Fragment } from "react";
import {  Card, CardBody,  CardHeader, Col,  Row,  } from 'reactstrap';

import { connect } from 'react-redux';


class EventsDetail extends Component {

    constructor(props) {
        super(props);
        this.toggleAccordion = this.toggleAccordion.bind(this);

        this.state = {
            accordion: [false, false, false],
        };
    }

    //-- It will toggle 
    toggleAccordion(tab) {
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => tab === index ? !x : false);

        this.setState({
            accordion: state,
        });
    }

    render() {
        const eventsDetails = this.props.userData;
        console.log("SDDDDDDDDDDDDDDDDDDDDD", eventsDetails);
        const { siteConstants } = this.props.language;
        const options = { year: 'numeric', month: 'long', day: 'numeric' };

        const created_date = new Date(eventsDetails.created_date);
        const dob = new Date(eventsDetails.dob);

        let userRole = '';
        const arrLength = eventsDetails.role_type !== undefined && eventsDetails.role_type.length > 0 ? eventsDetails.role_type.length : 0;

        if (eventsDetails.role_type !== undefined && eventsDetails.role_type.length > 0) {
            userRole = eventsDetails.role_type.map((usr, index) => {
                if (arrLength - 1 == index) { return usr; }
                return `${usr}, `;
            });
        }

        return (
            <Fragment>
                <nav className="" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/dashboard">{siteConstants.GEN_DASHBOARD}</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="/events">{siteConstants.GEN_EVENTS}</a>
                        </li>
                        <li className="active breadcrumb-item" aria-current="page">
                            Event Details
                        </li>
                        <li style={{ paddingLeft: "730px" }}>
                            <i class="fa fa-angle-left"></i>&nbsp;
                            <a href="javascript:void(0)" className="darkLink"
                                onClick={() => this.props.backButton()}>{siteConstants.BTN_BACK}</a>
                        </li>
                    </ol>
                </nav>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-12">
                                    <Card className="mb-2">
                                        <CardHeader>
                                            <Row>
                                                <Col xs="9">
                                                    <strong>{eventsDetails.title}</strong> <br />
                                                </Col>
                                                <Col xs="3">
                                                    {eventsDetails.event_date} <br />
                                                    {eventsDetails.event_time}
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col xs="12">
                                                    {eventsDetails.address1}<br />
                                                    {eventsDetails.city} {eventsDetails.state}<br />
                                                    {eventsDetails.country}<br />
                                                    {eventsDetails.postal_code}<br />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <Card className="mb-2">
                                        <CardHeader>
                                            <strong>{siteConstants.GEN_DESCRIPTION}</strong>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row">
                                                {eventsDetails.description}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <Card>
                                        <CardHeader>
                                            <strong>Images</strong>
                                        </CardHeader>
                                        <CardBody>
                                            <div id="accordion">
                                                Othing thing will come
                                                {/* <Card className="mb-2">
                                                    <CardHeader id="headingThree">
                                                        <div className="row">
                                                            <div className="col-md-9">
                                                                <a href="javascript:void(0)" block color="link" className="darkLink" onClick={() => this.toggleAccordion(2)} aria-expanded={this.state.accordion[2]} aria-controls="collapseThree">
                                                                    <i className="fa fa-align-justify"></i> Customer Overview
                                                                        </a>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <a href="#">Edit</a>
                                                            </div>
                                                        </div>
                                                    </CardHeader>
                                                    <Collapse isOpen={this.state.accordion[2]} data-parent="#accordion" id="collapseThree">
                                                        <CardBody>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <strong>{siteConstants.LABEL_FIRST_NAME}</strong>
                                                                        <input
                                                                            className="form-control"
                                                                            name="first_name"
                                                                            placeholder={siteConstants.LABEL_FIRST_NAME}
                                                                            value={eventsDetails.firstname}
                                                                            disabled="true"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <strong>{siteConstants.LABEL_LAST_NAME}</strong>
                                                                        <input
                                                                            className="form-control"
                                                                            name="first_name"
                                                                            placeholder={siteConstants.LABEL_LAST_NAME}
                                                                            value={eventsDetails.lastname}
                                                                            disabled="true"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <strong>{siteConstants.LABEL_EMAIL}</strong>
                                                                        <input
                                                                            className="form-control"
                                                                            name="first_name"
                                                                            placeholder={siteConstants.LABEL_EMAIL}
                                                                            value={eventsDetails.email}
                                                                            disabled="true"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <strong>{siteConstants.LABEL_CONTACT_NO}</strong>
                                                                        <input
                                                                            className="form-control"
                                                                            name="first_name"
                                                                            placeholder={siteConstants.LABEL_CONTACT_NO}
                                                                            value={eventsDetails.mobile_number}
                                                                            disabled="true"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <strong>{siteConstants.LABEL_DOB}</strong>
                                                                        <input
                                                                            className="form-control"
                                                                            name="first_name"
                                                                            placeholder={siteConstants.LABEL_DOB}
                                                                            value={dob !== '' ? dob.toLocaleString("en-US", options) : ''}
                                                                            disabled="true"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Collapse>
                                                </Card> */}

                                                {/* <Card className="mb-2">
                                                    <CardHeader id="headingTwo">

                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <a href="javascript:void(0)" block color="link" className="darkLink" onClick={() => this.toggleAccordion(1)} aria-expanded={this.state.accordion[1]} aria-controls="collapseTwo">
                                                                    <i className="fa fa-align-justify"></i> Default Address
                                                                        </a>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <a href="#">Manage</a>
                                                            </div>
                                                        </div>
                                                    </CardHeader>
                                                    <Collapse isOpen={this.state.accordion[1]} data-parent="#accordion" id="collapseTwo">
                                                        <CardBody>
                                                            test test <br />
tes<br />
tes<br />
452001 Indore Madhya Pradesh<br />
India
                      </CardBody>
                                                    </Collapse>
                                                </Card> */}

                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /.container-fluid --> */}
            </Fragment>
        );
    }
}

//-- Here we are adding Reducer names, so it can be get data from reducers using store
function mapStateToProps(state) {
    const { language } = state;
    return {
        language
    };
}

const connectedUsersDetail = connect(mapStateToProps)(EventsDetail);
export default connectedUsersDetail;