export const countryConstants = {
  GET_COUNTRY_REQUEST: 'GET_COUNTRY_REQUEST',
  GET_COUNTRY_SUCCESS: 'GET_COUNTRY_SUCCESS',
  GET_COUNTRY_FAILURE: 'GET_COUNTRY_FAILURE',

  ENABLE_COUNTRY_REQUEST: 'ENABLE_COUNTRY_REQUEST',
  ENABLE_COUNTRY_SUCCESS: 'ENABLE_COUNTRY_SUCCESS',
  ENABLE_COUNTRY_FAILURE: 'ENABLE_COUNTRY_FAILURE',

  FILTER_COUNTRY_REQUEST: 'FILTER_COUNTRY_REQUEST',
  FILTER_COUNTRY_SUCCESS: 'FILTER_COUNTRY_SUCCESS',
  FILTER_COUNTRY_FAILURE: 'FILTER_COUNTRY_FAILURE',
};
