import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { providerActions, seekerActions } from '../../_actions';
import { apiConstants } from '../../_constants/api.constants';

export default function AddSeeker({ displayPopUp, hidePopUp }) {
  const [seekerData, setSeekerData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    department: "",
    job_title: "",
    phone: "",
    company_street_address: "",
    subrub: "",
    post_code: "",
    state: "",
    country: "",
    email: "",
    password: "",
    forgot_password_code: "",
    role_type: "seeker",
    created_by_admin: parseInt(JSON.parse(sessionStorage.getItem('user')).data.id)
  })
  const dispatch = useDispatch();
  const addProviderSeekerData = useSelector(state => state.addProviderSeeker) 
  console.log("addProviderSeekerData==>", addProviderSeekerData)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeekerData({ ...seekerData, [name]: value })
  }

  // useEffect(() => {
  //   if(addProviderSeekerData && addProviderSeekerData.addProviderSeeker){
  //     console.log("add=>", addProviderSeekerData.addProviderSeeker)
  //   }
  // }, [addProviderSeekerData])

  const handleSubmit = () => {
    dispatch(providerActions.addProviderSeeker(apiConstants.ADD_PROVIDER_SEEKER, seekerData));
    hidePopUp();
    // dispatch(seekerActions.getSeeker(apiConstants.GET_SEEKER))
  }

  return (
    <div>
      <Dialog
        open={displayPopUp}
        aria-labelledby="add-products"
        aria-describedby="save-products-description"
        fullWidth={true}
      >
        <DialogTitle>Add Seeker</DialogTitle>
        <DialogContent>
          <div className="row">

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>First Name</label>
                <input
                  value={seekerData.first_name}
                  type="text"
                  onChange={handleChange}
                  placeholder='First Name'
                  className="form-control"
                  name="first_name"
                />
              </div>
              {/* <p className={classes.error}>{this.state.eventTitle_error}</p> */}
            </div>

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Last Name</label>
                <input
                  value={seekerData.last_name}
                  type="text"
                  onChange={handleChange}
                  placeholder='Last Name'
                  className="form-control"
                  name="last_name"
                />
              </div>
              {/* <p className={classes.error}>{this.state.eventDescription_error}</p> */}
            </div>


            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Company Name</label>
                <input
                  value={seekerData.company_name}
                  type="text"
                  min="1"
                  onChange={handleChange}
                  placeholder="Company Name"
                  className="form-control"
                  name="company_name"
                />
              </div>
              {/* <p className={classes.error}>{this.state.eventDate_error}</p> */}
            </div>


            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Department</label>
                <input
                  value={seekerData.department}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="Department"
                  className="form-control"
                  name="department"
                />
              </div>
              {/* <p className={classes.error}>{this.state.eventTime_error}</p> */}
            </div>
            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Job Title</label>
                <input
                  value={seekerData.job_title}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="Job Title"
                  className="form-control"
                  name="job_title"
                />
              </div>
              {/* <p className={classes.error}>{this.state.address_error}</p> */}
            </div>

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Phone</label>
                <input
                  value={seekerData.phone}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="Phone"
                  className="form-control"
                  name="phone"
                />
              </div>
              {/* <p className={classes.error}>{this.state.city_error}</p> */}
            </div>

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Company Street Address</label>
                <input
                  value={seekerData.company_street_address}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="Company Street Address"
                  className="form-control"
                  name="company_street_address"
                />
              </div>
              {/* <p className={classes.error}>{this.state.city_error}</p> */}
            </div>

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Subrub</label>
                <input
                  value={seekerData.subrub}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="Subrub"
                  className="form-control"
                  name="subrub"
                />
              </div>
              {/* <p className={classes.error}>{this.state.city_error}</p> */}
            </div>

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Post Code</label>
                <input
                  value={seekerData.post_code}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="Post Code"
                  className="form-control"
                  name="post_code"
                />
              </div>
              {/* <p className={classes.error}>{this.state.city_error}</p> */}
            </div>

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>State</label>
                <input
                  value={seekerData.state}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="State"
                  className="form-control"
                  name="state"
                />
              </div>
              {/* <p className={classes.error}>{this.state.city_error}</p> */}
            </div>

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Country</label>
                <input
                  value={seekerData.country}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="Country"
                  className="form-control"
                  name="country"
                />
              </div>
              {/* <p className={classes.error}>{this.state.city_error}</p> */}
            </div>

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Email</label>
                <input
                  value={seekerData.email}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="Email"
                  className="form-control"
                  name="email"
                />
              </div>
              {/* <p className={classes.error}>{this.state.city_error}</p> */}
            </div>

            <div className="col-md-12 col-xs-12">
              <div className="form-group">
                <label>Password</label>
                <input
                  value={seekerData.password}
                  type="password"
                  min="0"
                  onChange={handleChange}
                  placeholder="Password"
                  className="form-control"
                  name="password"
                />
              </div>
              {/* <p className={classes.error}>{this.state.city_error}</p> */}
            </div>

          </div>

        </DialogContent>


        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
          <Button variant="contained" onClick={hidePopUp} >Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
