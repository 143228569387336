import React, { useState, useEffect } from "react";
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import Button from "@material-ui/core/Button";
// import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
// import { history } from '../../_helpers';
import {
  providerActions,
  locationActions,
  experienceActions,
} from "../../_actions";
import { scheduleExperienceActions } from "../../_actions/schedule_experience.action";
import { apiConstants } from "../../_constants/api.constants";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import Select from "react-select";

export default function UpdateScheduleExperience(props) {
  const [experienceData, setExperienceData] = useState({
    // schedule_status: "",
    // repeat_weeks: "",
    // never_ends: false,
    // team_size: 0,
    // charge_type: 0,
    // amount: 0,
    // important_note: "",
    // per_person_cost: 0,
  });
  const [selectProvider, setSelectProvider] = useState("");
  const [selectExperience, setSelectExperience] = useState("");
  
  const [experienceSchedule, setExperienceSchedule] = useState([{}]);

  const [indexValue, setIndexValue] = useState(0);
  const [highlights, setHighlights] = useState({});
  const [getExperienceData, setExperience] = useState([]);
  const [getScheduleExpExperienceData,setScheduleExperience] = useState({})
  const [oneTime, setOnetime] = useState(true);
  const [schedule_statusonetime, setScheduleStatusOneTime] = useState(false)
  const [schedule_statusrepeat, setScheduleStatusRepeat] = useState(false)

  const [chargeTypeFlatFee, setChargeTypeFlatFee] = useState(false)
  const [chargeTypePerPerson, setChargeTypePerPerson] = useState(false)
  const [experienceName, setExperienceName] = useState("");
  const [locationId, setLocationId] = useState("");

  const provider = useSelector((state) => state.getProvider.allProvider);
  const experience = useSelector((state) => state.getExperienceById);
  const getScheduleExperienceById = useSelector(
    (state) => state.getScheduleExperienceById
  );
const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const [selectedStartTime,setSelectedStartTime] = useState(new Date())
  const [selectedEndTime,setSelectedEndTime] = useState(new Date())
  const [isDays,setDays] = useState('')
  const [repeatEndByDate, setRepeatEndByDate] = useState(new Date());

  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    if(value == 'flat_fee'){
      setChargeTypeFlatFee(true)
      setChargeTypePerPerson(false)
    }else if(value == 'per_person'){
        setChargeTypePerPerson(true)
        setChargeTypeFlatFee(false)
    }
    setExperienceData({ ...experienceData, [name]: value });
  };

  useEffect(() => {
    let obj = {
      id: props.match.params.id,
    };
    dispatch(
      scheduleExperienceActions.getScheduleExperienceById(
        apiConstants.GET_SCHEDULE_EXPERIENCE_BY_ID,
        obj
      )
    );
  }, []);

  useEffect(() => {
    let obj = {
      limit: 10,
      offset: 0,
    };
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER, obj));
    
  }, []);

  useEffect(() => {
    if (experience && experience.getExperienceById) {
      setExperience(experience.getExperienceById.experiences);
    }
  }, [experience]);

  useEffect(() => {
    if (getScheduleExperienceById && getScheduleExperienceById.getScheduleExperienceById) {
      setScheduleExperience(getScheduleExperienceById.getScheduleExperienceById.data.scheduleExp);
     
      // setExperienceData({team_size:getScheduleExperienceById.getScheduleExperienceById.data.scheduleExp.team_size})
      if(getScheduleExperienceById.getScheduleExperienceById.data.scheduleExp.charge_type == 'flat_fee'){
        setChargeTypeFlatFee(true)
        setChargeTypePerPerson(false)
      }else if(getScheduleExperienceById.getScheduleExperienceById.data.scheduleExp.charge_type == 'per_person'){
          setChargeTypePerPerson(true)
          setChargeTypeFlatFee(false)
      }
    }
  }, [getScheduleExperienceById]);

  useEffect(() => {
    if(getScheduleExpExperienceData){
      dispatch(
        experienceActions.getExperienceByProviderId(
          apiConstants.GET_EXPERIENCE_BY_PROVIDER_ID,
          {provider_id: getScheduleExpExperienceData.provider_id}
        )
      );

      if(getScheduleExpExperienceData.schedule_status == 'ONE TIME'){
        setScheduleStatusOneTime(true)
      }else if(getScheduleExpExperienceData.schedule_status == 'Repeat'){
        setScheduleStatusRepeat(true)
      }

      if(getScheduleExpExperienceData.charge_type == 'flat_fee'){
        setChargeTypeFlatFee(true)

      }else if(getScheduleExpExperienceData.charge_type == 'per_person'){
        setChargeTypePerPerson(true)
      }
      setSelectedStartDate(getScheduleExpExperienceData.start_date)
      setSelectedEndDate(getScheduleExpExperienceData.end_date)
      if(getScheduleExpExperienceData.days_time){
        let parseDate = JSON.parse(getScheduleExpExperienceData.days_time)
        setSelectedStartTime(parseDate[0].start_time)
        setSelectedEndTime(parseDate[0].end_time)
        setDays(parseDate[0].day)
      }
      
    }
  }, [getScheduleExpExperienceData])

  const handleStartDateChange = (date, index) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date, index) => {
    setSelectedEndDate(date);
  };
  const handleStartTimeChange = (data) => {
    setSelectedStartTime(data);
  };
  const handleEndTimeChange = (data) => {
    setSelectedEndTime(data);
  };
  const handleRepeatEndBydateChange = (data) => {
    setRepeatEndByDate(data);
  };

  const handleUpdate = () => {
    let selectedTime = [{
      day: isDays,
      start_time: selectedStartTime,
      end_time: selectedEndTime,   
    }]
    var per_person_cost = 0;
  
     let newObj = {
        ...experienceData,
        id: parseInt(props.match.params.id),
        // schedule_status:experienceData.shedule_type,
        start_date:selectedStartDate,
        end_date:selectedEndDate,
        days_time:JSON.stringify(selectedTime),
        repeat_weeks:JSON.stringify(experienceData.repeat_weeks) || "",
        repeat_end_date:repeatEndByDate,
        // never_ends:experienceData.never_ends,
        // team_size:experienceData.team_size,
        // charge_type:experienceData.charge_type,
        // amount:amount,
        // important_note:experienceData.important_note,
        per_person_cost:per_person_cost,
      }
     
    dispatch(
      scheduleExperienceActions.editScheduleExperience(apiConstants.EDIT_SCEHDULE_EXPERIENCE, newObj)
    );
  };

  return (
    <div id="wrapper">
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Experience
              </li>
            </ol>
          </nav>
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-2 text-gray-800">
                Update Schedule Experience
              </h1>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="table-responsive">
                  <div className="row">
                    <div className="col-sm-12 formBorder">
                      <div className="p-5">
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <label>Provider Name</label>
                            <span className="ml-2 text-primary" style={{cursor:"pointer"}}
                             onClick={()=>{ window.open(`/provider_detail?id=${getScheduleExpExperienceData.provider_id}`, '_blank')
                            }}> See details</span>
                            <select
                              className="form-control"
                              name="type"
                              value={getScheduleExpExperienceData ? getScheduleExpExperienceData.provider_id : ''}
                              // onChange={handleSelectProvider}
                              disabled
                            >
                              {/* <option>Select Provider name</option> */}
                              {provider !== undefined && provider.length > 0
                                ? (provider || []).map((provider, index) => {
                                    return (
                                      <option key={index}  value={provider.id}>
                                        {provider.first_name}{" "}
                                        {provider.last_name}
                                      </option>
                                    );
                                  })
                                : null}
                            </select>
                          </div>
                          <div className="col-sm-6">
                            <label>Experience Name </label>
                            <span className="ml-2 text-primary" style={{cursor:"pointer"}}
                            onClick={()=>{window.open(`/experience_detail?id=${getExperienceData[0].id}`, '_blank')
                            }}> See details</span>
                            <select
                              className="form-control"
                              name="type"
                              value={getScheduleExpExperienceData ? getScheduleExpExperienceData.experience_id : ''}
                              // onChange={handleSelectExperience}
                              disabled
                            >
                              {/* <option>Select Experience</option> */}
                              {getExperienceData !== undefined &&
                              getExperienceData.length > 0
                                ? (getExperienceData || []).map((data, index) => (
                                    <option key={index} value={index}>
                                      {data.experience_name}
                                    </option>
                                  ))
                                : null}
                            </select>
                          </div>
                        </div>
                        <div className="form-group row ml-3">
                          <div className="col-md-12">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="shedule_type"
                              value="ONE TIME"
                              onClick={() => {
                                setOnetime(true);
                              }}
                              onChange={handleChange}
                              checked={schedule_statusonetime}
                            />
                            <label>ONE TIME</label>
                          </div>
                          {/* <div className="col-md-12">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="shedule_type"
                              value="Repeat"
                              onClick={() => {
                                setOnetime(false);
                              }}
                              onChange={handleChange}
                              checked={schedule_statusrepeat}
                            />
                            <label>REPEAT</label>
                          </div> */}
                        </div>

                        {/* Start One time selection */}
                        {schedule_statusonetime === true ? (
                          <>
                            <div className="form-group row">
                              <div className="col-sm-6 mb-3 mb-sm-0">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    margin="normal"
                                    name="start_date"
                                    id="START DATE"
                                    label="START DATE"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                              <div className="col-sm-6">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    margin="normal"
                                    name="end_date"
                                    id="END DATE"
                                    label="END DATE"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                            </div>
                            {/* Time */}
                            <div className="form-group row">
                              <div className="col-sm-6 mb-3 mb-sm-0">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    name="start_time"
                                    label="Start Time"
                                    value={selectedStartTime}
                                    onChange={handleStartTimeChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time",
                                    }}
                                   
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                              <div className="col-sm-6">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    name="end_time"
                                    label="End Time"
                                    value={selectedEndTime}
                                    onChange={handleEndTimeChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time",
                                    }}
                                    
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                            </div>

                            <div className="form-group row ml-3">
                              <div className="col-sm-6 mb-3 mb-sm-0">
                                <input
                                  type="radio"
                                  class="form-check-input"
                                  name="charge_type"
                                  value="flat_fee"
                                  onChange={handleChange}
                                  checked={chargeTypeFlatFee}
                                />
                                <label>Flat fee</label>
                                {getScheduleExpExperienceData.charge_type == "flat_fee" ? (
                                  <input
                                    type="text"
                                    className="input-with-radio"
                                    name="amount"
                                    defaultValue={
                                      getScheduleExpExperienceData ? getScheduleExpExperienceData.amount : ''
                                    }
                                    placeholder="Amount"
                                    onChange={handleChange}
                                    
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    className="input-with-radio"
                                    name="amount"
                                    // defaultValue={
                                    //   getScheduleExpExperienceData ? getScheduleExpExperienceData.amount : ''
                                    // }
                                    placeholder="Amount"
                                    onChange={handleChange}
                                  />
                                )}
                              </div>
                              <div className="col-sm-6 mb-3 mb-sm-0">
                                <input
                                  type="radio"
                                  class="form-check-input"
                                  name="charge_type"
                                  value="per_person"
                                  checked={chargeTypePerPerson}
                                  onChange={handleChange}
                                />
                                <label>Per Person</label>
                                {getScheduleExpExperienceData.charge_type == "per_person" ? (
                                  <input
                                    type="text"
                                    className="input-with-radio"
                                    name="amount"
                                    defaultValue={
                                      getScheduleExpExperienceData ? getScheduleExpExperienceData.amount : ''
                                    }
                                    placeholder="Amount"
                                    onChange={handleChange}
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    className="input-with-radio"
                                    name="amount"
                                    // defaultValue={
                                    //   getScheduleExpExperienceData ? getScheduleExpExperienceData.per_person_cost : ''
                                    // }
                                    placeholder="Amount"
                                    onChange={handleChange}
                                    disabled
                                  />
                                )}
                              </div>
                            </div>
                            <div className="form-group row ">
                              <div className="col-md-12">
                                <label>Team Size</label>
                                <input
                                  type="number"
                                  className="form-control form-control-user"
                                  placeholder="Team Size"
                                  name="team_size"
                                  onChange={handleChange}
                                  value={experienceSchedule.team_size}
                                  defaultValue={
                                    getScheduleExpExperienceData && getScheduleExpExperienceData.team_size
                                      ? getScheduleExpExperienceData.team_size
                                      : ""
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Important Note</label>
                              <textarea
                                className="form-control"
                                name="important_note"
                                onChange={handleChange}
                                defaultValue={
                                  getScheduleExpExperienceData ? getScheduleExpExperienceData.important_note : ''
                                }
                              ></textarea>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="form-group row">
                              <div className="col-sm-6 mb-3 mb-sm-0">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    margin="normal"
                                    name="start_date"
                                    disablePast
                                    id="START DATE"
                                    label="START DATE"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                              <div className="col-sm-6">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    margin="normal"
                                    name="end_date"
                                    disablePast
                                    id="END DATE"
                                    label="END DATE"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-md-12 mb-3 mb-sm-0">
                                <label>Experience Duration</label>
                                <select
                                  className="form-control"
                                  name="repeat_weeks"
                                  multiple
                                  // value={experienceData.repeat_weeks}
                                  onChange={handleChange}
                                >
                                  <option value="1">Monday</option>
                                  <option value="2">Tuesday</option>
                                  <option value="3">Wednesday</option>
                                  <option value="4">Thursday</option>
                                  <option value="5">Friday</option>
                                  <option value="6">Saturday</option>
                                  <option value="7">Sunday</option>
                                </select>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-md-6">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    margin="normal"
                                    name="repeat_endby_date"
                                    id="Repeat will end by"
                                    label="Repeat will end by"
                                    value={repeatEndByDate}
                                    onChange={handleRepeatEndBydateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                              <div className="col-md-6 mt-5">
                                <input
                                  type="radio"
                                  class="form-check-input"
                                  name="never_ends"
                                  value={true}
                                  onChange={handleChange}
                                />
                                <label>Never ends</label>
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-6 mb-3 mb-sm-0">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    name="start_time"
                                    label="Start Time"
                                    value={selectedStartTime}
                                    onChange={handleStartTimeChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                              <div className="col-sm-6">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    name="end_time"
                                    label="End Time"
                                    value={selectedEndTime}
                                    onChange={handleEndTimeChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                            </div>
                            <div className="form-group row ">
                              <div className="col-md-12">
                                <label>Team Size</label>
                                <input
                                  type="number"
                                  className="form-control form-control-user"
                                  placeholder="Team Size"
                                  name="team_size"
                                  onChange={handleChange}
                                  // value={experienceSchedule.team_size}
                                  defaultValue={
                                    getScheduleExpExperienceData && getScheduleExpExperienceData.team_size
                                      ? getScheduleExpExperienceData.team_size
                                      : ""
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row ml-3">
                              <div className="col-sm-6 mb-3 mb-sm-0">
                                <input
                                  type="radio"
                                  class="form-check-input"
                                  name="charge_type"
                                  value="flat_fee"
                                  onChange={handleChange}
                                  checked={chargeTypeFlatFee}
                                />
                                <label>Flat fee</label>
                                {getScheduleExpExperienceData.charge_type == "flat_fee" ? (
                                  <input
                                    type="text"
                                    className="input-with-radio"
                                    name="amount"
                                    placeholder="Amount"
                                    onChange={handleChange}
                                    defaultValue={
                                      getScheduleExpExperienceData ? getScheduleExpExperienceData.amount : ''
                                    }
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    className="input-with-radio"
                                    name="amount"
                                    placeholder="Amount"
                                    // defaultValue={
                                    //   getScheduleExpExperienceData ? getScheduleExpExperienceData.amount : ''
                                    // }
                                    onChange={handleChange}
                                    disabled
                                  />
                                )}
                              </div>
                              <div className="col-sm-6 mb-3 mb-sm-0">
                                <input
                                  type="radio"
                                  class="form-check-input"
                                  name="charge_type"
                                  value="per_person"
                                  onChange={handleChange}
                                  checked={chargeTypePerPerson}
                                />
                                <label>Per Person</label>
                                {getScheduleExpExperienceData.charge_type == "per_person" ? (
                                  <input
                                    type="text"
                                    className="input-with-radio"
                                    name="per_person_cost"
                                    placeholder="Amount"
                                    onChange={handleChange}
                                    defaultValue={
                                      getScheduleExpExperienceData && getScheduleExpExperienceData.per_person_cost ? getScheduleExpExperienceData.per_person_cost : ''
                                    }
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    className="input-with-radio"
                                    name="per_person_cost"
                                    placeholder="Amount"
                                    onChange={handleChange}
                                    disabled
                                  />
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Important Note</label>
                              <textarea
                                className="form-control"
                                name="important_note"
                                onChange={handleChange}
                                defaultValue={
                                  getScheduleExpExperienceData ? getScheduleExpExperienceData.important_note : ''
                                }
                              ></textarea>
                            </div>
                          </>
                        )}
                          <div className="form-group">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleUpdate}
                        >
                          Update
                        </Button>
                      </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
