import React, { Component } from 'react';
import { Router, Route, Switch, BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from './_helpers';
import { PrivateRoute } from './Components/PrivateRoute';
import { Dashboard } from './Components/Dashboard';
import { Login } from './Components/Login';
import { alertActions } from './_actions';
import { ForgetPassword } from './Components/ForgetPassword';
import { ChangePassword } from './Components/ChangePassword';
import { NotFound } from './Components/NotFound';
import { Users, UsersProfile, Staff } from './Components/Users';
import { Events } from './Components/Events';
import { UserReport, Revenue, Ride } from './Components/Report';
import Provider from './Components/provider/Provider';
import ProviderDetailRoute from './Components/provider/ProviderDetailRoute';
import Seeker from './Components/seeker/Seeker';
import Environmental_cause from './Components/environmental_cause/environmental_cause';
import UpdateEnvironmentalCause from './Components/environmental_cause/updateEnvironmentalCause';
import Company from './Components/Company/Company';
import AddCompany from './Components/Company/AddCompany';
import UpdateProvider from './Components/provider/UpdateProvider';
import UpdateSeeker from './Components/seeker/UpdateSeeker';
import UpdateCompany from './Components/Company/UpdateCompany';
import Experience from './Components/Experience/Experience';
import ActivityType from './Components/ActivityType/ActivityType';
import ScheduleExperience from './Components/Schedule_experience/Schedule_experience';
import UpdateExperience from './Components/Experience/UpdateExperience';
import UpdateScheduleExperience from './Components/Schedule_experience/UpdateScheduleExperience';
import Location from './Components/Location/Location';
import UpdateLocation from './Components/Location/UpdateLocation';
import Booking from './Components/Booking/Booking';
import BookingDetails from './Components/Booking/BookingDetail';
import UpdateBooking from './Components/Booking/UpdateBooking';
import AddBooking from './Components/Booking/AddBooking';
import Feedback from './Components/Feedback/Feedback';
import UpdateFeedback from './Components/Feedback/UpdateFeedback';
import Shortlist from './Components/Shortlist/Shortlist';
import Transaction from './Components/Transaction/Transaction';
import Questions from './Components/Questions/Questions';
import CompanyReview from './Components/CompanyReview/CompanyReview';
import EditCompanyReview from './Components/CompanyReview/UpdateCompanyReview';
import ViewCompanyReview from './Components/CompanyReview/ViewCompanyReview';
import Notification from './Components/Notifications/Notifications';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './node_modules/bootstrap/dist/css/bootstrap.min.css';
import ExperienceDetailRoute from './Components/Experience/ExperienceDetailRoute';
import 'react-toggle/style.css';
import SeekerDetailRoute from './Components/seeker/SeekerDetailRoute';
import LocationDetailRoute from './Components/Location/LocationDetailRoute';
import CompanyDetailRoute from './Components/Company/CompanyDetailRoute';
import BookingRequest from './Components/Booking_request/Booking_request';
import BookingRequestDetail from './Components/Booking_request/Booking_requestDetail';
import CountryList from './Components/Country/CountryList';
class App extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }
  render() {
    const { alert } = this.props;
    return (
      <div>
        {alert.message && (
          <div className={`alert ${alert.type}`}>{alert.message}</div>
        )}
        <BrowserRouter history={history}>
          <div>
            {/* <PrivateRoute exact path="/tables" component={Tables} />
                        <PrivateRoute exact path="/users" component={Users} />
                        <PrivateRoute exact path="/profile" component={UsersProfile} /> */}
            <Switch>
              <Route exact path='/' component={Login} />
              <Route path='/login' component={Login} />
              <Route exact path='/forget-password' component={ForgetPassword} />
              <PrivateRoute path='/dashboard' component={Dashboard} />
              <PrivateRoute exact path='/users' component={Users} />
              <PrivateRoute exact path='/staff' component={Staff} />
              <PrivateRoute exact path='/events' component={Events} />
              <PrivateRoute exact path='/provider' component={Provider} />
              <PrivateRoute
                exact
                path='/provider_detail'
                component={ProviderDetailRoute}
              />
              <PrivateRoute
                exact
                path='/update-provider/:id'
                component={UpdateProvider}
              />
              <PrivateRoute exact path='/seeker' component={Seeker} />
              <PrivateRoute
                exact
                path='/seeker_detail'
                component={SeekerDetailRoute}
              />
              <PrivateRoute
                exact
                path='/environmental_cause'
                component={Environmental_cause}
              />
              <PrivateRoute
                exact
                path='/update_environmental_cause/:id'
                component={UpdateEnvironmentalCause}
              />
              <PrivateRoute exact path='/company' component={Company} />
              <PrivateRoute
                exact
                path='/company_detail'
                component={CompanyDetailRoute}
              />
              <PrivateRoute
                exact
                path='/update-seeker/:id'
                component={UpdateSeeker}
              />
              <PrivateRoute
                exact
                path='/update-company/:id'
                component={UpdateCompany}
              />
              <PrivateRoute
                exact
                path='/add-company/:id'
                component={AddCompany}
              />
              <PrivateRoute exact path='/experience' component={Experience} />
              <PrivateRoute
                exact
                path='/activity-type'
                component={ActivityType}
              />
              <PrivateRoute
                exact
                path='/experience_detail'
                component={ExperienceDetailRoute}
              />
              <PrivateRoute
                exact
                path='/schedule_experience'
                component={ScheduleExperience}
              />
              <PrivateRoute
                exact
                path='/update-experience/:id'
                component={UpdateExperience}
              />
              <PrivateRoute
                exact
                path='/update-schedule_experience/:id'
                component={UpdateScheduleExperience}
              />
              <PrivateRoute exact path='/location' component={Location} />
              <PrivateRoute exact path='/country' component={CountryList} />
              <PrivateRoute
                exact
                path='/location_detail'
                component={LocationDetailRoute}
              />
              <PrivateRoute
                exact
                path='/update-location/:id'
                component={UpdateLocation}
              />
              <PrivateRoute exact path='/booking' component={Booking} />
              <PrivateRoute
                exact
                path='/booking-details/:id'
                component={BookingDetails}
              />
              <PrivateRoute
                exact
                path='/update-booking/:id'
                component={UpdateBooking}
              />
              <PrivateRoute exact path='/add-booking' component={AddBooking} />
              <PrivateRoute
                exact
                path='/experience_feedback'
                component={Feedback}
              />
              <PrivateRoute
                exact
                path='/update-feedback/:id'
                component={UpdateFeedback}
              />
              <PrivateRoute exact path='/shortlist' component={Shortlist} />
              <PrivateRoute exact path='/transaction' component={Transaction} />
              <PrivateRoute exact path='/questions' component={Questions} />
              <PrivateRoute
                exact
                path='/submitedanswer'
                component={CompanyReview}
              />
              <PrivateRoute
                exact
                path='/editCompanyReview/:id'
                component={EditCompanyReview}
              />
              <PrivateRoute
                exact
                path='/viewCompanyReview/:id'
                component={ViewCompanyReview}
              />
              <PrivateRoute
                exact
                path='/notification'
                component={Notification}
              />
              <PrivateRoute exact path='/profile' component={UsersProfile} />
              <PrivateRoute
                exact
                path='/change-password'
                component={ChangePassword}
              />
              <PrivateRoute exact path='/reports' component={UserReport} />
              <PrivateRoute exact path='/revenue' component={Revenue} />
              <PrivateRoute exact path='/ride' component={Ride} />
              <PrivateRoute
                exact
                path='/booking-request'
                component={BookingRequest}
              />
              <PrivateRoute
                exact
                path='/booking-request-details/:id'
                component={BookingRequestDetail}
              />
              <Route path='*' exact={true} component={NotFound} />
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert,
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
