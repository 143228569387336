import { commonService } from '../_services';
import { history } from '../_helpers';
import { apiConstants } from '../_constants/api.constants';
import * as commonAction from './commonAction';
import { activityTypeConstants } from '../_constants';

export const activityTypeActions = {
    getActivityTypeList,
    filterActivityTypes,
    addActivityType,
    editActivityType,
    archiveActivityType
};

function getActivityTypeList(apiName, activityTypeData) {
    return dispatch => {
        dispatch(commonAction.request(activityTypeConstants.GET_ACTIVITY_TYPE_REQUEST, activityTypeData));
        commonService.withOutTokenAndBaseUrl(apiName, activityTypeData)
            .then(
                activityType => {
                    dispatch(commonAction.success(activityTypeConstants.GET_ACTIVITY_TYPE_SUCCESS, activityType.data));
                },
                error => {
                    dispatch(commonAction.failure(activityTypeConstants.GET_ACTIVITY_TYPE_FAILURE, error.response.data.error));
                }
            );
    };
}

function filterActivityTypes(apiName, activityTypeData) {
    return dispatch => {
        dispatch(commonAction.request(activityTypeConstants.FILTER_ACTIVITY_TYPE_REQUEST, activityTypeData));
        commonService.withOutTokenAndBaseUrl(apiName, activityTypeData)
            .then(
                activityType => {
                    dispatch(commonAction.success(activityTypeConstants.FILTER_ACTIVITY_TYPE_SUCCESS, activityType.data));
                },
                error => {
                    dispatch(commonAction.failure(activityTypeConstants.FILTER_ACTIVITY_TYPE_FAILURE, error.response.data.error));
                }
            );
    };
}

    
function addActivityType (apiName, activityTypeData) {
    let obj = {
        limit: 25,
        offset: 0
    }
    return dispatch => {
        dispatch(commonAction.request(activityTypeConstants.ADD_ACTIVITY_TYPE_REQUEST, activityTypeData));
        commonService.withTokenAndFormDataApplicationJsonFormat(apiName, activityTypeData)
            .then(
                activityType => {
                    dispatch(commonAction.success(activityTypeConstants.ADD_ACTIVITY_TYPE_SUCCESS, activityType.data));
                    dispatch(getActivityTypeList(apiConstants.GET_ALL_ACTIVITY_TYPES_ADMIN, obj))
                },
                error => {
                    dispatch(commonAction.failure(activityTypeConstants.ADD_ACTIVITY_TYPE_FAILURE, error.response.data.error));
                }
            );
    };
}

function editActivityType(apiName, activityTypeData) {
    let obj = {
        limit: 25,
        offset: 0
    }
    return dispatch => {
        dispatch(commonAction.request(activityTypeConstants.EDIT_ACTIVITY_TYPE_REQUEST, activityTypeData));
        commonService.withTokenAndFormDataApplicationJsonFormat(apiName, activityTypeData)
            .then(
                activityType => {
                    dispatch(commonAction.success(activityTypeConstants.EDIT_ACTIVITY_TYPE_SUCCESS, activityType.data));
                    dispatch(getActivityTypeList(apiConstants.GET_ALL_ACTIVITY_TYPES_ADMIN, obj))
                },
                error => {
                    dispatch(commonAction.failure(activityTypeConstants.EDIT_ACTIVITY_TYPE_FAILURE, error.response.data));
                }
            );
    };
}

function archiveActivityType(apiName, activityTypeData) {
    let obj = {
        limit: 25,
        offset: 0
    }
    return dispatch => {
        dispatch(commonAction.request(activityTypeConstants.ARCHIVE_ACTIVITY_TYPE_REQUEST, activityTypeData));
        commonService.withTokenAndFormDataApplicationJsonFormat(apiName, activityTypeData)
            .then(
                activityType => {
                    dispatch(commonAction.success(activityTypeConstants.ARCHIVE_ACTIVITY_TYPE_SUCCESS, activityType.data));
                    dispatch(getActivityTypeList(apiConstants.GET_ALL_ACTIVITY_TYPES_ADMIN, obj))
                },
                error => {
                    dispatch(commonAction.failure(activityTypeConstants.ARCHIVE_ACTIVITY_TYPE_FAILURE, error.response.data.error));
                }
            );
    };
}
