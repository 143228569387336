import React, { useState, useEffect } from "react";
// import ReactStars from "react-rating-stars-component";
import StarRatings from 'react-star-ratings';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import { useSelector, useDispatch } from "react-redux";
import { providerActions, experienceActions } from "../../_actions";
import { feedbackActions } from "../../_actions/feedback.action";
import { apiConstants } from "../../_constants/api.constants";

export default function UpdateFeedback(props) {
    const [feedbackData, setFeedbackData] = useState({});
    const [selectExperienceId, setSelectExperience] = useState("");
    const [getExperienceData, setExperience] = useState([]);
    const [feedbackId_Data, setFeedbackId_Data] = useState({})
    const [userData, setUserData] = useState({});
    const [satisfactionRating, setSatisfactionRating] = useState("");
    const [qualityRating, setQualityRating] = useState("");
    const [managementRating, setManagementRating] = useState("");
    const experience = useSelector((state) => state.getExperienceList);
    const providerDetails = useSelector(
        (state) => state.getProviderSeekerById.providerDetails
    );
    const getFeedbackById = useSelector(
        (state) => state.getFeedbackById
      );
  
    const dispatch = useDispatch();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFeedbackData({ ...feedbackData, [name]: value });
    };
    useEffect(() => {
        if (Object(providerDetails).data) {
            setUserData(providerDetails.data.userdata);
        }
    }, [providerDetails.data]);
    useEffect(()=>{
    
     if(Object(getFeedbackById) && getFeedbackById.getFeedbackById && getFeedbackById.getFeedbackById.data){
         setFeedbackId_Data(getFeedbackById.getFeedbackById.data[0])    
         dispatch(providerActions.getProviderSeekerById(apiConstants.GET_PROVIDER_SEEKER_BY_ID, {id:getFeedbackById.getFeedbackById.data[0].seeker_id}));  
 
     }
    },[getFeedbackById])
    
    useEffect(() => {
        let obj = {
            limit: 10,
            offset: 0,
            id: props.match.params.id,
        };
        dispatch(
            feedbackActions.getFeedbackById(
                apiConstants.GET_FEEDBACK_BY_ID,
                obj
            )
        );
    }, []);

    useEffect(() => {
        if (experience.getExperienceList && experience.getExperienceList.data) {
            setExperience(experience.getExperienceList.data);
        }
    }, [experience.getExperienceList]);

    const handleSelectExperience = (e) => {
        setSelectExperience(getExperienceData[e.target.value].id);
        // get seeker id
        let dataObj = {
            id: getExperienceData[e.target.value].id,
        };
        dispatch(
            providerActions.getProviderSeekerById(
                apiConstants.GET_PROVIDER_SEEKER_BY_ID,
                dataObj
            )
        );
    };

    // const firstExample = {
    //     size: 20,
    //     count: 5,
    //     color: "black",
    //     activeColor: "red",
    //     value: 4,
    //     a11y: true,
    //     isHalf: false,
    //     emptyIcon: <i className="far fa-star" />,
    //     halfIcon: <i className="fa fa-star-half-alt" />,
    //     filledIcon: <i className="fa fa-star" />,
    // };
    // const secondExample = {
    //     size: 20,
    //     count: 5,
    //     color: "black",
    //     activeColor: "red",
    //     value: 0,
    //     a11y: true,
    //     isHalf: false,
    //     emptyIcon: <i className="far fa-star" />,
    //     halfIcon: <i className="fa fa-star-half-alt" />,
    //     filledIcon: <i className="fa fa-star" />,
    // };
    // const thirdExample = {
    //     size: 20,
    //     count: 5,
    //     color: "black",
    //     activeColor: "red",
    //     value: 0,
    //     a11y: true,
    //     isHalf: false,
    //     emptyIcon: <i className="far fa-star" />,
    //     halfIcon: <i className="fa fa-star-half-alt" />,
    //     filledIcon: <i className="fa fa-star" />,
    // };

    const handleSubmit = (e, val) => {
        let newObj = {
            seeker_id: userData.id,
            provider_id: '',
            experience_id: selectExperienceId,
            exp_name: '',
            cmp_name: '',
            feedback: feedbackData.feedback,
            satisfaction: satisfactionRating,
            quality: qualityRating,
            management: managementRating,
        };
        dispatch(feedbackActions.addFeedback(apiConstants.ADD_FEEDBACK, newObj));
        // hidePopUp();
    };
    return (
        <div id='wrapper'>
            <SideBar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <TopBar />
                    <nav className="" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/dashboard">Dashboard</a>
                            </li>
                            <li className="active breadcrumb-item" aria-current="page">
                                Experience Feedback
                            </li>
                        </ol>
                    </nav>
                    <div className="container-fluid">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-2 text-gray-800">
                                Update Experience Feedback
                            </h1>
                        </div>
                        <div className="card shadow mb-4">
                            <div className="card-body">
                                <div className="table-responsive">

                                    <div className="row">
                                        <div className="col-sm-12 formBorder">
                                            <div className="p-5">
                                                <div className="form-group row">
                                                    <div className="col-sm-6">
                                                        <label>Experience Name</label>
                                                        <select
                                                            className="form-control"
                                                            name="experience_name"
                                                            onChange={handleSelectExperience}
                                                            disabled
                                                        >
                                                            <option value={feedbackId_Data.exp_name} selected> {feedbackId_Data.exp_name} </option>
                                                           
                                                            {getExperienceData !== undefined &&
                                                                getExperienceData.length > 0
                                                                ? getExperienceData.map((data, index) => (
                                                                    <option value={index}>
                                                                        {data.experience_name}
                                                                    </option>
                                                                ))
                                                                : null}
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <label>Select Seeker</label>
                                                        <select className="form-control" disabled>
                                                            <option>Select Seeker</option>
                                                            {userData.role_type == 'seeker' ? (
                                                                <option value={userData.id} selected>
                                                                    {userData.first_name + " " + userData.last_name}
                                                                </option>
                                                            ) : null}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Feedback</label>
                                                            <textarea
                                                                className="form-control"
                                                                name="feedback"
                                                                onChange={handleChange}
                                                                defaultValue={feedbackId_Data.feedback}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label>Satisfaction</label>
                                                            <div>
                                                                {/* <ReactStars
                                                                    onChange={(e) => setSatisfactionRating(e)}
                                                                    {...firstExample}
                                                                /> */}
                                                                <StarRatings
                                                                    rating={feedbackId_Data.satisfaction}
                                                                    starRatedColor="orange"
                                                                    numberOfStars={5}
                                                                    starDimension="20px"
                                                                    starSpacing="1px"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label>Quality</label>
                                                            <div>
                                                                {/* <ReactStars
                                                                    onChange={(e) => setQualityRating(e)}
                                                                    {...secondExample}
                                                                /> */}
                                                                <StarRatings
                                                                    rating={feedbackId_Data.quality}
                                                                    starRatedColor="orange"
                                                                    numberOfStars={5}
                                                                    starDimension="20px"
                                                                    starSpacing="1px"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label>Management</label>
                                                            <div>
                                                                {/* <ReactStars
                                                                    onChange={(e) => setManagementRating(e)}
                                                                    {...thirdExample}
                                                                /> */}
                                                                <StarRatings
                                                                    rating={feedbackId_Data.management}
                                                                    starRatedColor="orange"
                                                                    numberOfStars={5}
                                                                    starDimension="20px"
                                                                    starSpacing="1px"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}
