export const apiConstants = {
  GET_USERS: '/getusers',
  GET_DASHBOARD: '/dashboard',
  CHK_LOGIN: '/login',
  GET_PROFILE: '/profile',
  GET_SETTINGS: '/settings',
  UPDATE_PASSWORD: '/change_password',
  UPDATE_USER_STATUS: '/updateuserstatus',
  GET_USERS_DETAILS: '/getProfile',
  UPDATE_PROFILE_IMAGE: '/updateprofileimage',
  UPDATE_USER_PROFILE: '/seeker/edit-profile',

  //-- we are using same api, might be update in future
  UPDATE_USER_DATA: '/updateadminprofile',
  UPDATE_ADMIN_PROFILE: '/update',

  //-- Categories
  GET_CATEGORY_LIST: '/getcategories',

  //-- Roles
  GET_ROLES: '/getroles',

  //-- Payment Type
  GET_PAYMENT_TYPES: '/getpaymenttypes',
  ADD_UPDATE_PAYMENT_TYPE: '/updatepaymenttypes',
  DELETE_PAYMENT_TYPE: '/deletepaymenttype',

  //-- Settings
  UPDATE_GENERAL_SETTINGS: '/update-setting',

  //-- Tags
  ADD_UPDATE_TAGS: '/updatetagsdetail',
  GET_TAGS_LIST: '/gettags',
  DELETE_TAG: '/deletetag',

  //-- Coupons
  ADD_UPDATE_COUPON: '/updatecouponsdetail',
  GET_COUPONS: '/getcoupons',
  DELETE_COUPON: '/deletecoupon',

  //-- Reports
  GET_ALL_USERS_COUNT: '/user-stats',

  //-- Roles & Permission
  GET_ROLES: '/getroles',
  ADD_UPDATE_ROLE_DATA: '/updateroledetail',
  DELETE_ROLE: '/deleterole',
  GET_PAGES: '/getpages',
  ADD_UPDATE_PAGE_DATA: '/updatepagedetail',
  DELETE_PAGE: '/deletepage',
  GET_PERMISSIONS: '/getpermissions',
  ADD_UPDATE_PERMISSION_DATA: '/updatepermissiondetail',
  DELETE_PERMISSION: '/deletepermission',

  //-- Terms
  GET_TERMS: '/getterms',
  ADD_UPDATE_TERM_DATA: '/updatetermdetail',
  DELETE_TERM: '/deleteterm',

  //-- Advertisments
  GET_ADVERTISMENTS: '/getadvertisments',
  ADD_UPDATE_ADVERTISMENT_DATA: '/updateadvertismentdetail',
  DELETE_ADVERTISMENT: '/deleteadvertisment',
  IMAGE_PATH: '/getimage',

  //-- Trades
  GET_TRADES: '/gettrades',

  //-- Represetative numbers
  GET_REPRESENTATIVE_NUMBER: '/getrepresentativenumber',
  UPDATE_REPRESENTATIVE_NUMBER: '/updaterepresentativenumber',

  //-- Notifications
  GET_NOTIFICATIONS: '/getnotifications',
  ADD_UPDATE_NOTIFICATION_DATA: '/updatenotificationdetail',
  DELETE_NOTIFICATION: '/deletenotification',
  UPDATE_NOTIFICATION_STATUS: '/updatenotificationstatus',

  //-- Speakers
  GET_SPEAKERS: '/getspeakers',

  //-- Events
  GET_EVENTS: '/getevents',
  SAVE_EVENT: '/saveevent',

  //-- Provider
  GET_PROVIDER: '/getProviders',

  //-- Seeker
  GET_SEEKER: '/getSeekers',

  //-- Environmental Cause
  GET_ENVIRONMENTAL_CAUSE: '/getEnviromentalCause',
  GET_ENVIRONMENTAL_CAUSE_BY_ID: '/getEnviromentalCauseByid',
  ADD_ENVIRONMENTAL_CAUSE: '/create-enviromentalCause',
  UPDATE_ENVIRONMENTAL_CAUSE: '/edit-enviromentalCause',
  DELETE_ENVIRONMENTAL_CAUSE: '/delete-enviromentalCause',
  GET_ALL_EXPERIENCE_ENV_CAUSES_BY_ID: '/get-all-experience-env_causes-by-id',
  FILTER_ENV_CAUSE: '/filterEnvCauses',

  //-- add provdier seeker
  ADD_PROVIDER_SEEKER: '/sign-up',
  //-- get provider seeker by id
  GET_PROVIDER_SEEKER_BY_ID: '/seeker/getSeekerById',

  // Create company

  ADD_COMPANY: '/provider/create-organisation',

  // get organisation details by id

  GET_ORGANISATION_DETAILS: '/seeker/org-detail',
  GET_ORGANISATION: '/provider/show-organisation',
  GET_ORGANISATION_BY_ID: '/seeker/organisation-detail',
  UPDATE_ORGANISATION_BY_ID: '/provider/edit-organisation',

  GET_ALL_ORGANISATION_DETAILS: '/getCompany',
  SORT_COMPANY: '/sortCompany',
  SORT_PROVIDER: '/sortProvidersByFirstName',
  SORT_SEEKER: '/sortSeekersByFirstName',
  SEARCH_SEEKER: '/searchSeekers',
  SEARCH_PROVIDER: '/searchProviders',
  SEARCH_BOOKING: '/searchBooking',
  SORT_BOOKING: '/sortBooking',
  SORT_LOCATION: '/sortLocation',
  SEARCH_LOCATION: '/searchLocation',

  GET_ALL_EXPERIENCE_DETAILS: '/show-experience',
  SORT_EXPERIENCE: '/sort-experience',
  SEARCH_EXPERIENCE: '/search-experience',
  ADD_EXPERIENCE: '/provider/create-experience',
  EDIT_EXPERIENCE: '/provider/edit-experience',
  UNPUBLISH_EXPERIENCE: '/provider/unpublishExperience',
  DELETE_EXPERIENCE: '/provider/deleteExperience',
  GET_EXPERIENCE_BY_ID: '/provider/experienceById',
  GET_EXPERIENCE_BY_PROVIDER_ID: '/provider/publish-experience',

  // Activity Type
  GET_ALL_ACTIVITY_TYPES_COMMON: '/common/getAllActivityTypes',
  GET_ALL_ACTIVITY_TYPES_ADMIN: '/admin/getAllActivityTypes',
  ADD_ACTIVITY_TYPE: '/create-activity-type',
  EDIT_ACTIVITY_TYPE: '/edit-activity-type',
  ARCHIVE_ACTIVITY_TYPE: '/archive-activity-type',
  FILTER_ACTIVITY_TYPE: '/admin/filterActivityTypes',
  GET_ALL_EXPERIENCE_ACTIVITY_TYPES_BY_ID:
    '/get-all-experience-activity-types-by-id',

  // Schedule Experience
  GET_ALL_SCHEDULE_EXPERIENCE_DETAILS: '/provider/getAllScheduleExp',
  ADD_SCHEDULE_EXPERIENCE: '/provider/schedule-experience',
  EDIT_SCEHDULE_EXPERIENCE: '/provider/edit-scheduleExperience',
  DELETE_SCEHDULE_EXPERIENCE: '/provider/unscheduleExperience',
  GET_SCHEDULE_EXPERIENCE_BY_ID: '/provider/scheduleExpById',
  SORT_SCHEDULE_EXPERIENCE: '/provider/sortScheduleExp',
  SEARCH_SCHEDULE_EXPERIENCE: '/provider/searchScheduleExp',
  GET_SCHEDULE_EXPERIENCE_BY_PROVIDER_ID: '/provider/ProviderScheduleExp',

  GET_ALL_LOCATIONS_By_Id: '/provider/show-location',
  GET_ALL_LOCATIONS: '/provider/showalllocation',
  ADD_LOCATIONS: '/provider/add-location',
  GET_LOCATION_BY_ID: '/provider/LocationById',
  UPDATE_LOCATION: '/provider/edit-location',

  GET_BOOKINGS: '/seeker/allBookings',
  ADD_BOOKINGS: '/seeker/create-booking',
  EDIT_BOOKINGS: '/seeker/edit-booking',
  UPDATE_PAYMENT_STATUS: '/seeker/updatePaymentStatus',
  EDIT_BOOKINGS_REQUEST: '/seeker/edit-booking-update',
  GET_BOOKING_BY_ID: '/seeker/booking-Byid',

  // get all booking requests
  GET_BOOKING_REQUEST: '/get-all-bookingrequest',
  GET_BOOKING_REQUEST_BY_ID: '/seeker/get-bookingrequestbyid',

  // Feedback
  ADD_FEEDBACK: '/seeker/add-feedback',
  GET_ALL_FEEDBACK: '/seeker/get-feedback',
  GET_FEEDBACK_BY_ID: '/seeker/get-feedbackById',
  DELETE_FEEDBACK: '/seeker/delete-feedback',
  SORT_FEEDBACK: '/seeker/sort-feedback',

  // fevourite
  ADD_SHORTLIST: '/seeker/add-to-fevourite',
  GET_ALL_SHORTLIST: '/seeker/get-allfevourites',
  GET_SHORTLIST_BY_ID: '/seeker/get-feedbackById',
  DELETE_SHORTLIST: '/seeker/delete-feedback',
  SORT_SHORTLIST: '/seeker/sort-feedback',

  GET_TRANSACATION_BY_ID: '/seeker/get-transactonById',
  GET_TRANSACATION_LIST: '/seeker/get-transactionList',

  //  Questions

  GET_ALL_QUESTIONS: '/common/getQuestions',

  GET_ALL_COMPANYREVIEW: '/common/getCompanyReview',

  edit_COMPANYREVIEW: '/common/editCompanyReview',

  GET_COMPANYREVIEW_BY_ID: '/common/getCompanyReviewById',

  GET_NOTIFICATION: '/getnotification',

  NOTIFICATION_SORT: '/notification-sort',

  GET_DASHBOARD_DATA: '/get-dashboard-data',

  // COUNTRY
  GET_ALL_COUNTRIES: '/common/get-all-countries',
  FILTER_COUNTRY: '/common/filter-country',
  ENABLE_COUNTRY: '/admin/enable-country',
};
