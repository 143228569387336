import { enviromentalConstants } from '../_constants/environmental.constants';

const initialState = {
  allEnviromentalCause: [],
};

export function getEnviromentalCause(state = initialState, action) {
  switch (action.type) {
    case enviromentalConstants.GET_ENVIRONMENTAL_CAUSE_REQUEST:
      return {
        ...state,
        getAllEnviromentalCause: false,
        loading: true,
      };
    case enviromentalConstants.GET_ENVIRONMENTAL_CAUSE_SUCCESS:
      return {
        ...state,
        allEnviromentalCause: action.successAction.data.data,
        total_count: action.successAction.data.total_count,
        pages: action.successAction.data.pages,
        loading: false,
      };
    case enviromentalConstants.GET_ENVIRONMENTAL_CAUSE_FAILURE:
      return {
        ...state,
        allEnviromentalCause: action,
        loading: false,
      };
    default:
      return state;
  }
}

export function getEnviromentalCauseById(state = initialState, action) {
  switch (action.type) {
    case enviromentalConstants.GET_ENVIRONMENTAL_CAUSE_BY_ID_REQUEST:
      return {
        ...state,
        allEnviromentalCause: false,
      };
    case enviromentalConstants.GET_ENVIRONMENTAL_CAUSE_BY_ID_SUCCESS:
      return {
        ...state,
        allEnviromentalCause: action.successAction.data,
      };
    case enviromentalConstants.GET_ENVIRONMENTAL_CAUSE_BY_ID_FAILURE:
      return {
        ...state,
        allEnviromentalCause: false,
      };
    default:
      return state;
  }
}

export function addEnviromentalCause(state = {}, action) {
  switch (action.type) {
    case enviromentalConstants.ADD_ENVIRONMENTAL_CAUSE_REQUEST:
      return {
        allEnviromentalCause: false,
      };
    case enviromentalConstants.ADD_ENVIRONMENTAL_CAUSE_SUCCESS:
      return {
        allEnviromentalCause: action.successAction.data.data,
      };
    case enviromentalConstants.ADD_ENVIRONMENTAL_CAUSE_FAILURE:
      return {
        allEnviromentalCause: false,
      };
    default:
      return state;
  }
}
