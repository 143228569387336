export const userConstants = {
    // REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    // REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    // REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    // PROFILE_REQUEST: 'USER_PROFILE_REQUEST',
    // PROFILE_SUCCESS: 'USER_PROFILE_SUCCESS',
    // PROFILE_FAILURE: 'USER_PROFILE_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'USER_CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'USER_CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_ERROR: 'USER_CHANGE_PASSWORD_ERROR',

    USER_LIST_REQUEST: 'USER_LIST_REQUEST',
    USER_LIST_SUCCESS: 'USER_LIST_SUCCESS',
    USER_LIST_ERROR: 'USER_LIST_ERROR',
    USER_SEARCH_REQUEST: 'USER_SEARCH_REQUEST',

    USER_UPDATE_STATUS_REQUEST: 'USER_UPDATE_STATUS_REQUEST',
    USER_UPDATE_STATUS_SUCCESS: 'USER_UPDATE_STATUS_SUCCESS',
    USER_UPDATE_STATUS_FAILURE: 'USER_UPDATE_STATUS_FAILURE',    

    // USER_DETAIL_REQUEST: 'USER_DETAIL_REQUEST',
    // USER_DETAIL_SUCCESS: 'USER_DETAIL_SUCCESS',
    // USER_DETAIL_ERROR: 'USER_DETAIL_ERROR',


    USER_GET_PROFILE_REQUEST: 'USER_GET_PROFILE_REQUEST',
    USER_GET_PROFILE_SUCCESS: 'USER_GET_PROFILE_SUCCESS',
    USER_GET_PROFILE_FAILURE: 'USER_GET_PROFILE_FAILURE',

    ADMIN_UPDATE_PROFILE_REQUEST: 'ADMIN_UPDATE_PROFILE_REQUEST',
    ADMIN_UPDATE_PROFILE_SUCCESS: 'ADMIN_UPDATE_PROFILE_SUCCESS',
    ADMIN_UPDATE_PROFILE_FAILURE: 'ADMIN_UPDATE_PROFILE_FAILURE',

    ADMIN_UPDATE_PROFILE_IMAGE_REQUEST:'ADMIN_UPDATE_PROFILE_IMAGE_REQUEST',
    ADMIN_UPDATE_PROFILE_IMAGE_SUCCESS:'ADMIN_UPDATE_PROFILE_IMAGE_SUCCESS',
    ADMIN_UPDATE_PROFILE_IMAGE_ERROR:'ADMIN_UPDATE_PROFILE_IMAGE_ERROR',



    USER_COUNT_REQUEST: 'USER_COUNT_REQUEST',
    USER_COUNT_SUCCESS: 'USER_COUNT_SUCCESS',
    USER_COUNT_ERROR: 'USER_COUNT_ERROR',

    UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',


    GET_DASHBOARD_DATA_REQUEST: "GET_DASHBOARD_DATA_REQUEST",
    GET_DASHBOARD_DATA_SUCCESS: "GET_DASHBOARD_DATA_SUCCESS",
    GET_DASHBOARD_DATA_ERROR: "GET_DASHBOARD_DATA_ERROR"
};
