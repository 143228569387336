import { countryConstants } from '../_constants';

export function getCountryList(state = {}, action) {
  switch (action.type) {
    case countryConstants.GET_COUNTRY_REQUEST:
      return {
        getCountryList: false,
        loading: true,
      };

    case countryConstants.GET_COUNTRY_SUCCESS:
      return {
        getCountryList: action.successAction,
        loading: false,
      };

    case countryConstants.GET_COUNTRY_FAILURE:
      return {
        getCountryList: false,
        loading: true,
      };

    default:
      return state;
  }
}

export function enableCountry(state = {}, action) {
  switch (action.type) {
    case countryConstants.ENABLE_COUNTRY_REQUEST:
      return {
        getCountryList: false,
      };

    case countryConstants.ENABLE_COUNTRY_SUCCESS:
      return { getCountryList: action.successAction };

    case countryConstants.ENABLE_COUNTRY_FAILURE:
      return {
        getCountryList: false,
      };

    default:
      return state;
  }
}
