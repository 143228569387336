export const scheduleExperienceConstants = {
    
    GET_SCHEDULE_EXPERIENCE_REQUEST: 'GET_SCHEDULE_EXPERIENCE_REQUEST',
    GET_SCHEDULE_EXPERIENCE_SUCCESS: 'GET_SCHEDULE_EXPERIENCE_SUCCESS',
    GET_SCHEDULE_EXPERIENCE_FAILURE: 'GET_SCHEDULE_EXPERIENCE_FAILURE',

    GET_SCHEDULE_EXPERIENCE_BY_ID_REQUEST: 'GET_SCHEDULE_EXPERIENCE_BY_ID_REQUEST',
    GET_SCHEDULE_EXPERIENCE_BY_ID_SUCCESS: 'GET_SCHEDULE_EXPERIENCE_BY_ID_SUCCESS',
    GET_SCHEDULE_EXPERIENCE_BY_ID_FAILURE: 'GET_SCHEDULE_EXPERIENCE_BY_ID_FAILURE',

    GET_SCHEDULE_EXPERIENCE_BY_PROVIDER_ID_REQUEST: 'GET_SCHEDULE_EXPERIENCE_BY_PROVIDER_ID_REQUEST',
    GET_SCHEDULE_EXPERIENCE_BY_PROVIDER_ID_SUCCESS: 'GET_SCHEDULE_EXPERIENCE_BY_PROVIDER_ID_SUCCESS',
    GET_SCHEDULE_EXPERIENCE_BY_PROVIDER_ID_FAILURE: 'GET_SCHEDULE_EXPERIENCE_BY_PROVIDER_ID_FAILURE',

    ADD_SCHEDULE_EXPERIENCE_REQUEST: 'ADD_SCHEDULE_EXPERIENCE_REQUEST',
    ADD_SCHEDULE_EXPERIENCE_SUCCESS: 'ADD_SCHEDULE_EXPERIENCE_SUCCESS',
    ADD_SCHEDULE_EXPERIENCE_FAILURE: 'ADD_SCHEDULE_EXPERIENCE_FAILURE',

    EDIT_SCHEDULE_EXPERIENCE_REQUEST: 'EDIT_SCHEDULE_EXPERIENCE_REQUEST',
    EDIT_SCHEDULE_EXPERIENCE_SUCCESS: 'EDIT_SCHEDULE_EXPERIENCE_SUCCESS',
    EDIT_SCHEDULE_EXPERIENCE_FAILURE: 'EDIT_SCHEDULE_EXPERIENCE_FAILURE',


    DELETE_SCHEDULE_EXPERIENCE_REQUEST: 'DELETE_SCHEDULE_EXPERIENCE_REQUEST',
    DELETE_SCHEDULE_EXPERIENCE_SUCCESS: 'DELETE_SCHEDULE_EXPERIENCE_SUCCESS',
    DELETE_SCHEDULE_EXPERIENCE_FAILURE: 'DELETE_ENVIRONMENTAL_CAUSE_FAILURE',
};
