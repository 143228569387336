import React from 'react'

export default function LocationDetail({ backButton, data }) {
  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Location Details</h1>
        <a href="#" onClick={backButton} className="btn btn-secondary btn-icon-split">
          <span className="icon text-white-50">
            <i className="fas fa-arrow-left"></i>
          </span>
          <span className="text">Back</span>
        </a>
      </div>
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <div className="row">

              <div className="col-md-12 col-xs-12">
                <div className="form-group">
                  <label>Location Address</label>
                  <input
                    value={data.location_address}
                    type="text"
                    placeholder='Location Address'
                    className="form-control"
                    disabled
                  />
                </div>
                {/* <p className={classes.error}>{this.state.eventTitle_error}</p> */}
              </div>
             
              <div className="col-md-12 col-xs-12">
                <div className="form-group">
                  <label>Location Name</label>
                  <input
                    value={data.location_name}
                    type="text"
                    min="0"
                    placeholder="Video Title"
                    className="form-control"
                    disabled
                  />
                </div>
                {/* <p className={classes.error}>{this.state.city_error}</p> */}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
