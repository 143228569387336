import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';

export default function FeedbackDetails({ backButton, data }) {
  const [selectedDate, setSelectedDate] = useState(new Date('2014-08-18T21:11:54'));

  return (
    <div className="container-fluid">

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Feedback Details</h1>
        <a href="#" onClick={backButton} className="btn btn-secondary btn-icon-split">
          <span className="icon text-white-50">
            <i className="fas fa-arrow-left"></i>
          </span>
          <span className="text">Back</span>
        </a>
      </div>

      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <div className="row">
              <div className="col-sm-3"></div>
              <div className="col-sm-6 formBorder">
                <div className="p-5">
                  <div>
                    {/* <b>{userDetails.name}</b> */}
                    {/* <h1 className="h4 text-gray-900 mb-4 headingBorderBottom">Name</h1> */}
                  </div>
                  <div className="form-group row">
                  <div className="col-sm-6">
                      <label>Location</label>
                      <input type="text" value={data.location_id} className="form-control form-control-user" 
                        placeholder="Location" readOnly />
                    </div>
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label>Experience Name</label>
                      <input type="text" value={data.experience_name} className="form-control form-control-user"
                        placeholder="Experience Name" readOnly />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-6">
                      <label>Amount</label>
                      <input type="text" className="form-control form-control-user" id="txtEmail"
                        placeholder="Amount" value={data.amount} readOnly />
                    </div>
                    <div className="col-sm-6">
                      <label>Team Size</label>
                      <input type="text" className="form-control form-control-user" id="txtCompanyName"
                        placeholder="Team Size" value={data.team_size} readOnly />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Experience Duration</label>
                    <input type="text" className="form-control form-control-user"
                        placeholder="Experience Duration" value={data.experience_duration} readOnly />
                  </div>
                  {/* {
                    data.days_time.map((data, i)=>{ */}
                      <div className="form-group row">
                  <div className="col-sm-2">
                    <label>Day 1</label>
                  </div>
                  <div className="col-sm-5">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Start Time"
                        value={selectedDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  </div>
                  <div className="col-sm-5">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="End picker"
                        value={selectedDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  </div>
                  </div>
                    {/* })
                  }
                   */}
                  <div className="form-group">
                      <label>Highlights</label>
                      {/* {
                       Object.keys(data.highlights).forEach(key=>{ */}
                        <input type="text" className="form-control form-control-user" id="txtContactNo"
                        placeholder="Highlights 1" readOnly />
                       {/* })
                      } */}
                  </div>
                 
                  <div className="form-group row">  
                    <div className="col-sm-6">
                      <label>Environmental Causes</label>
                      <input type="text" className="form-control form-control-user" id="txtCreateDate"
                        placeholder="Environmental Causes" value={data.environmental_causes} readOnly />
                    </div>
                    <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Image</label>
                      <input
                           type="file"
                          //  onChange={handleChangeCoverPhoto}
                           className="form-control"
                           name="images"
                           accept=".png,.jpg,.jpeg"
                       />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-6">
                      <label>Video Title</label>
                      <input type="text" className="form-control form-control-user" id="txtCreateDate"
                        placeholder="Video Title" value={data.video_title} readOnly />
                    </div>
                    <div className="col-sm-6">
                      <label>Video Url</label>
                      <input type="text" className="form-control form-control-user" id="txtCreateDate"
                        placeholder="Video Url" value={data.video_url} readOnly />
                    </div>
                  </div>
                  <div className="col-lg-12">
                      <div className="form-group">
                          <label>Detail Description</label>
                          <textarea className="form-control" name="description" ></textarea>
                      </div>
                  </div>
                  <div className="col-lg-12">
                      <div className="form-group">
                          <label>Further Information</label>
                          <textarea className="form-control" name="description" ></textarea>
                      </div>
                  </div>
                  <div className="col-lg-12">
                      <div className="form-group">
                          <label>Whats Include</label>
                          <textarea className="form-control" name="description" ></textarea>
                      </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
