import React, { useState,useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { environmentalCauseAction } from '../../_actions/environmentalCause.action';
import { apiConstants } from '../../_constants/api.constants';
import {history} from "../../_helpers/history";

export default function UpdateEnvironmentalCause({ displayPopUp, hidePopUp,id }) {
  const dispatch = useDispatch();

  const [enviromentalCause, setEnviromentalCause] = useState({})
 const [userData, setUserData] = useState({})

  useEffect(() => {
    let dataObj = {
      id:id
    }
    dispatch(environmentalCauseAction.getEnvironmentalCauseById(apiConstants.GET_ENVIRONMENTAL_CAUSE_BY_ID, dataObj));
  }, [])
  const environmental_cause = useSelector(state => state.getEnviromentalCause.allEnviromentalCause);
  console.log(environmental_cause.data)

  useEffect(()=>{
    if(environmental_cause.data){
      setEnviromentalCause(environmental_cause.data)
    }
  }, [environmental_cause.data])

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("value==>",name, value)
    setUserData({ ...userData, [name]: value })
  }

  const handleUpdate = () => {
    console.log(userData)
    dispatch(environmentalCauseAction.updateEnvironmentalCause(apiConstants.UPDATE_ENVIRONMENTAL_CAUSE, userData));
    history.push('/environmental_cause')
  }

  return (
    <div >
     <Dialog
        open={displayPopUp}
        aria-labelledby="add-products"
        aria-describedby="save-products-description"
        fullWidth={true}
      >
        <DialogTitle>Update Environmental Cause</DialogTitle>
        <DialogContent>
          <div className="row">

            <div className="col-md-12 col-xs-12">
              <div className="form-group">
                <label>Cause Name</label>
                <input
                  // value={updateEnviromentalCauseData.cause_name}
                  type="text"
                  onChange={handleChange}
                  placeholder='Cause Name'
                  className="form-control"
                  name="cause_name"
                />
              </div>
              {/* <p className={classes.error}>{this.state.eventTitle_error}</p> */}
            </div>
          </div>
        </DialogContent>


        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleUpdate}>Save</Button>
          <Button variant="contained" onClick={hidePopUp} >Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
