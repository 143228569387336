import { bookingConstants, transactionConstants } from '../_constants';
import * as commonAction from './commonAction';
import {  commonService } from '../_services';

export const transactionActions = {
    getTransactionById,
    getTransactionList
};

//-- Transaction list
function getTransactionList(apiName,data) {

    return dispatch => {
        dispatch(commonAction.request(transactionConstants.GET_TRANSACTION_LIST_REQUEST));
        commonService.withOutTokenAndBaseUrl(apiName,data)
            .then(
                transactionData => {
                    dispatch(commonAction.success(transactionConstants.GET_TRANSACTION_LIST_SUCCESS, transactionData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(transactionConstants.GET_TRANSACTION_LIST_FAILURE, error.response.data.error));
                  }
            );
    };
}

function getTransactionById(apiName, data) {

    return dispatch => {
        dispatch(commonAction.request(transactionConstants.GET_TRANSACTION_BY_ID_REQUEST));
        commonService.withOutTokenAndBaseUrl(apiName, data)
            .then(
                transactionData => {
                    dispatch(commonAction.success(transactionConstants.GET_TRANSACTION_BY_ID_SUCCESS, transactionData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(transactionConstants.GET_TRANSACTION_BY_ID_FAILURE, error.response.data.error));
                  }
            );
    };
}
