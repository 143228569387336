import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { providerActions } from '../../_actions';
import { apiConstants } from '../../_constants/api.constants';

export default function AddProvider({ displayPopUp, hidePopUp }) {
  const [providerData, setProviderData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    department: "",
    job_title: "",
    phone: "",
    company_street_address: "",
    subrub: "",
    post_code: "",
    state: "",
    country: "",
    email: "",
    password: "",
    external_id:"",
    crm:"",
    sync_enabled:"false",
    forgot_password_code: "",
    role_type: "provider",
    created_by_admin: parseInt(JSON.parse(sessionStorage.getItem('user')).data.id)
  })
  const [error, setError] = useState('')
  const dispatch = useDispatch();
  const addProvider = useSelector(state=>state.addProviderSeeker)
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProviderData({ ...providerData, [name]: value })
  }

  const handleSubmit = () => {
    dispatch(providerActions.addProviderSeeker(apiConstants.ADD_PROVIDER_SEEKER, providerData));
    // hidePopUp();
   
  }

  useEffect(()=> {
    if(addProvider.addProviderSeeker && addProvider.addProviderSeeker.success){
      hidePopUp()
    }else if(addProvider.addProviderSeeker && addProvider.addProviderSeeker.errors){
      setError(addProvider.addProviderSeeker.errors.password)
    }
  },[addProvider])

  return (
    <div>
      <Dialog
        open={displayPopUp}
        aria-labelledby="add-products"
        aria-describedby="save-products-description"
        fullWidth={true}
      >
        <DialogTitle>Add Provider</DialogTitle>
        <DialogContent>
          <div className="row">

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>First Name</label>
                <input
                  value={providerData.first_name}
                  type="text"
                  onChange={handleChange}
                  placeholder='First Name'
                  className="form-control"
                  name="first_name"
                />
              </div>
              {/* <p className={classes.error}>{this.state.eventTitle_error}</p> */}
            </div>

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Last Name</label>
                <input
                  value={providerData.last_name}
                  type="text"
                  onChange={handleChange}
                  placeholder='Last Name'
                  className="form-control"
                  name="last_name"
                />
              </div>
              {/* <p className={classes.error}>{this.state.eventDescription_error}</p> */}
            </div>


            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Company Name</label>
                <input
                  value={providerData.company_name}
                  type="text"
                  min="1"
                  onChange={handleChange}
                  placeholder="Company Name"
                  className="form-control"
                  name="company_name"
                />
              </div>
              {/* <p className={classes.error}>{this.state.eventDate_error}</p> */}
            </div>


            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Department</label>
                <input
                  value={providerData.department}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="Department"
                  className="form-control"
                  name="department"
                />
              </div>
              {/* <p className={classes.error}>{this.state.eventTime_error}</p> */}
            </div>
            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Job Title</label>
                <input
                  value={providerData.job_title}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="Job Title"
                  className="form-control"
                  name="job_title"
                />
              </div>
              {/* <p className={classes.error}>{this.state.address_error}</p> */}
            </div>

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Phone</label>
                <input
                  value={providerData.phone}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="Phone"
                  className="form-control"
                  name="phone"
                />
              </div>
              {/* <p className={classes.error}>{this.state.city_error}</p> */}
            </div>

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Company Street Address</label>
                <input
                  value={providerData.company_street_address}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="Company Street Address"
                  className="form-control"
                  name="company_street_address"
                />
              </div>
              {/* <p className={classes.error}>{this.state.city_error}</p> */}
            </div>

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Subrub</label>
                <input
                  value={providerData.subrub}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="Subrub"
                  className="form-control"
                  name="subrub"
                />
              </div>
              {/* <p className={classes.error}>{this.state.city_error}</p> */}
            </div>

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Post Code</label>
                <input
                  value={providerData.post_code}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="Post Code"
                  className="form-control"
                  name="post_code"
                />
              </div>
              {/* <p className={classes.error}>{this.state.city_error}</p> */}
            </div>

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>State</label>
                <input
                  value={providerData.state}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="State"
                  className="form-control"
                  name="state"
                />
              </div>
              {/* <p className={classes.error}>{this.state.city_error}</p> */}
            </div>

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Country</label>
                <input
                  value={providerData.country}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="Country"
                  className="form-control"
                  name="country"
                />
              </div>
              {/* <p className={classes.error}>{this.state.city_error}</p> */}
            </div>

            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Email</label>
                <input
                  value={providerData.email}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="Email"
                  className="form-control"
                  name="email"
                />
              </div>
              {/* <p className={classes.error}>{this.state.city_error}</p> */}
            </div>

            <div className="col-md-12 col-xs-12">
              <div className="form-group">
                <label>Password</label>
                <input
                  value={providerData.password}     
                  type="password"
                  min="0"
                  onChange={handleChange}
                  placeholder="Password"
                  className="form-control"
                  name="password"
                />
              </div>

            </div>


            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>External Id</label>
                <input
                  value={providerData.external_id}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="External Id"
                  className="form-control"
                  name="external_id"
                />
              </div>
            </div>
            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>CRM</label>
                <input
                  value={providerData.crm}
                  type="text"
                  min="0"
                  onChange={handleChange}
                  placeholder="CRM"
                  className="form-control"
                  name="crm"
                />
              </div>
            </div>
            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label>Sync Enabled</label>
                <input
                  type="text"
                  value={providerData.sync_enabled}
                  onChange={handleChange}
                  placeholder="Sync Enabled"
                  className="form-control"
                  name="sync_enabled"
                />
              </div>
            </div>


            <div className="col-md-12 col-xs-12">
              {/* <div className="form-group"> */}
              <p style={{color:'red'}}>{error}</p>
              {/* </div> */}

            </div>
            
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
          <Button variant="contained" onClick={hidePopUp} >Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
