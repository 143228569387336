import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import PageLimit from "../Common/PageLimit/PageLimit";
import Pagination from "../Common/Pagination/Pagination";
import { useSelector, useDispatch } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';
import { bookingActions, companyActions } from '../../_actions';
import 'antd/dist/antd.css';
import BookingDetail from './BookingDetail';
import Toggle from 'react-toggle'
import { CSVLink } from "react-csv";
import { GTranslate } from '@material-ui/icons';
// import AddLocation from './AddBooking';

export default function Booking() {
  const [bookingDetails, setBookingDetails] = useState(false);
  const [saveBookings, setSaveBookings] = useState({})
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [deleteDetail, setDeleteDetail] = useState('')
  const [search, setSearch] = useState("");
  const [filteredBooking, setFilteredBooking] = useState([]);
  const [booking, setBooking] = useState([])
  const [bookingFlag, setBookingFlag] = useState(0);
  const [limit, setLimit] = useState(10);
  const [NewOffset, setOffset] = useState(0);
  const [checked, setChecked] = useState({})
  const [currentPage, setCurrentPage] = useState(1);
  // const [switchState, setSwitchState] = useState({})
  const dispatch = useDispatch();

  // const total_count = useSelector(state => state.getAllCompany.total_count);
  // const total_pages = useSelector(state => state.getAllCompany.pages);

  useEffect(() => {
    let obj = {
      limit: 10,
      offset: 0
    }
    dispatch(bookingActions.getBookingList(apiConstants.GET_BOOKINGS, obj));
  }, [])

  const handleSearch = (e) => {
    let obj = {
      searchQuery: e.target.value,
      limit: 10,
      offset: 0
    }
    dispatch(bookingActions.searchBookingList(apiConstants.SEARCH_BOOKING, obj));
  }

  const changeTableData = (e) => {
    setLimit(e.target.value)
    let obj = {
      limit: parseInt(e.target.value),
      offset: 0,
    };
    dispatch(bookingActions.getBookingList(apiConstants.GET_BOOKINGS, obj));
  };

  const getBooking = useSelector(state => state.getBooking);

  useEffect(() => {
    if (getBooking && getBooking.getBooking) {
      setBooking(getBooking.getBooking.data)
    }
  }, [getBooking])


  useEffect(() => {
    setFilteredBooking(booking
      // booking.filter((data) =>

      // data.experience_name.includes(search)
      // data.team_size.includes(parseInt(search))
      // )
    );
  }, [search, booking]);

  const bookingDetail = (booking) => {
    setBookingDetails(true)
    setSaveBookings(booking)
  }

  const backButton = () => {
    setBookingDetails(false)
  }

  const closeDeletePopup = () => {
    setDeletePopUp(false);
  }

  const deleteLocation = (id) => {
    setDeletePopUp(true);
    setDeleteDetail(id)
  }

  const handleChange = (e, id, status, payment_status, booking_status) => {
    const formData = new FormData();
    formData.append('booking_id', parseInt(id))
    if (status === 'update requested') {
      formData.append('status', 'request accepted')
    } else if (status === 'cancellation requested') {
      formData.append('status', 'cancelled')
    } else if (payment_status === 'unpaid' && booking_status === 'reserved' && status === 'payment pending') {
      formData.append('payment_status', 'paid')
      formData.append('booking_status', 'confirmed')
      formData.append('status', 'paid')
    }
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }
    dispatch(bookingActions.editBooking(apiConstants.EDIT_BOOKINGS_REQUEST, formData))
  }

  const handleOnSelect = (e, id) => {
    const formData = new FormData();
    formData.append('booking_id', parseInt(id))
    if(e.target.value = "paid"){
      formData.append('payment_status', 'paid')
      formData.append('booking_status', 'confirmed')
      formData.append('status', 'paid')
    }
    dispatch(bookingActions.editBooking(apiConstants.UPDATE_PAYMENT_STATUS, formData))
  }

  const handleSort = (e) => {
    if (bookingFlag === 1) {
      setBookingFlag(0)
    } else if (bookingFlag === 0) {
      setBookingFlag(1)
    }
    let obj = {
      sortByStatus: bookingFlag,
      sortBy: e.target.name
    }
    //  setCompany(company.sort((a,b) => parseFloat(a.user_id) - parseFloat(b.user_id)))
    dispatch(bookingActions.sortBooking(apiConstants.SORT_BOOKING, obj))
  }

  const makeHttpRequestWithPage = (pageNumber) => {
    setCurrentPage(pageNumber)
    let obj = {
      limit: limit,
      offset: pageNumber - 1
    }
    setOffset(obj.offset)
    dispatch(bookingActions.getBookingList(apiConstants.GET_BOOKINGS, obj));
  }

  const handleDelete = () => {
    let formData = new FormData();
    formData.append("id", deleteDetail)
    formData.append("is_deleted", 1)

    dispatch(companyActions.updateOrganisationByOrgId(apiConstants.UPDATE_ORGANISATION_BY_ID, formData));
    setDeletePopUp(false);
  }

  // const headers = [
  //   { label: "ID", key: "id" },
  //   { label: "Seeker Id", key: "seeker_id" },
  //   { label: "Company Id", key: "company_id" },
  //   { label: "Schedule Experience Id", key: "sch_exp_id" },
  //   { label: "Location Id", key: "location_id" },
  //   { label: "Experience Name", key: "experience_name" },
  //   { label: "Experience Duration", key: "experience_duration" },
  //   { label: "Days Time", key: "days_time" },
  //   { label: "Start date", key: "start_date" },
  //   { label: "Special request", key: "special_request" },
  //   { label: "Supporting_document", key: "supporting_document" },
  //   { label: "Images", key: " exp_images" },
  //   { label: "Team Size", key: "team_size" },

  //   { label: "Charge Type", key: "charge_type" },
  //   { label: "Amount", key: "amount" },
  //   { label: "GST Amount", key: "gst_amount" },
  //   { label: "Total Amount", key: "total_amount" },
  //   { label: "Payment Type", key: "payment_type" },
  //   { label: "Full Name", key: "full_name" },
  //   { label: "Address 1", key: "address1" },
  //   { label: "Address 2", key: "address2" },
  //   { label: "Post Code", key: "post_code" },
  //   { label: "City", key: "city" },

  //   { label: "Country", key: "country" },
  //   { label: "Company Name", key: "company_name" },
  //   { label: "Email Id", key: "email_id" },
  //   { label: "Abn Acn", key: "abn_acn" },
  //   { label: "Contact Name", key: "contact_name" },
  //   { label: "Payment Status", key: "payment_status" },
  //   { label: "Booking Status", key: "booking_status" },
  //   { label: "Status", key: "status" }




  // ];
  const headers = [
    { label: "First Name", key: "userbooking.first_name" },
    { label: "Last Name", key: "userbooking.last_name" },
    { label: "Experience Name", key: "experience_name" },
    { label: "Experience Duration", key: "experience_duration" },
    { label: "Start date", key: "start_date" },
    { label: "Team Size", key: "team_size" },
    { label: "Charge Type", key: "charge_type" },
    { label: "Amount", key: "amount" },
    { label: "GST Amount", key: "gst_amount" },
    { label: "Total Amount", key: "total_amount" },
    { label: "Payment Type", key: "payment_type" },
    { label: "Full Name", key: "full_name" },
    { label: "Address 1", key: "address1" },
    { label: "Address 2", key: "address2" },
    { label: "Post Code", key: "post_code" },
    { label: "City", key: "city" },
    { label: "Country", key: "country" },
    { label: "Company Name", key: "company_name" },
    { label: "Seeker organisation", key: "company_data.company_name" },
    { label: "Payment Status", key: "payment_status" },
    { label: "Booking Status", key: "booking_status" },
    { label: "Status", key: "status" }
  ];

  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Booking
              </li>
            </ol>
          </nav>
          {!bookingDetails ?
            <div className="container-fluid">

              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-2 text-gray-800">
                  Booking
                </h1>
                <div className="d-sm-flex align-items-center justify-content-between">
                  <div className="d-sm-flex align-items-center justify-content-between">
                    {/* <CSVLink className="btn btn-primary mr-3" data={filteredBooking?.map(Object.values)} headers={headers.map(Object.values)}>Export</CSVLink> */}
                    <CSVLink className="btn btn-primary mr-3" data={filteredBooking || []} headers={headers}>Export</CSVLink>
                    <a href="#!"
                      onClick={() => console.log("provider add")}
                    >
                      <span className="text">
                        <Link to="/add-booking" className="btn btn-primary">Add Booking</Link>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="row">
                      {/* Page limit drop down */}
                      <PageLimit funChangePageLimit={changeTableData} />

                      <div className="col-sm-12 col-md-6">
                        <div id="dataTable_filter" className="dataTables_filter">
                          <label>
                            <input type="search" className="form-control form-control-sm" placeholder="Search" aria-controls="dataTable" onChange={handleSearch} />
                          </label>

                        </div>
                      </div>
                    </div>
                    <table className="table table-bordered" width="100%" cellSpacing="0">
                      <thead>
                        <tr>
                          <th scope="col">No.</th>
                          <th scope="col">
                            Experience Name
                            <img src="assets/img/svg/sort.png" className="imageSort" name="experience_name" onClick={handleSort} />
                          </th>

                          <th scope="col">Team Size</th>
                          <th scope="col">Payment Status</th>
                          <th scope="col">Booking Status</th>
                          {/* <th scope="col">Status</th> */}
                          {/* <th scope="col">Status</th> */}
                          {/* <th scope="col">Update Status</th> */}
                          {/* <th scope="col">Update Status</th> */}
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredBooking !== undefined && filteredBooking.length > 0 ? booking?.map((booking, index) => {
                          var count = 0;
                          //  var pageData = config.perPageData;
                          count = index + (NewOffset * 10) + 1
                          return (
                            <tr key={index}>
                              <td scope="row">{count}</td>
                              <td>{booking.experience_name}</td>
                              <td>{booking.team_size}</td>
                              {/* <td> */}
                              {/* {company.is_deleted === 0 ? <Badge className="ml-3" status="success" text="Active" /> : <Badge className="ml-3" status="error" text="Inactive" />} */}
                              {/* </td> */}
                              <td>{booking.payment_status}</td>
                              <td>{booking.booking_status}</td>
                              {/* <td>{booking.status}</td> */}
                              {/* <td>
                                <label>
                                  <Toggle
                                    onChange={(e) => handleChange(e, booking.id, booking.status, booking.payment_status, booking.booking_status)} />
                                </label>
                              </td> */}
                              {/* <td>
                                {
                                  booking.booking_status == 'completed' ? <select className="form-control" disabled>
                                  <option>Select Status</option>
                                  <option value="paid">Paid</option>
                                  <option value="unpaid">UnPaid</option>
                                </select> : <select className="form-control"  onChange={(e)=>handleOnSelect(e, booking.id)}>
                                  <option>Select Status</option>
                                  <option value="paid">Paid</option>
                                  <option value="unpaid">UnPaid</option>
                                </select>
                                }
                                
                              </td> */}
                              <td>
                                <Link className="actionIcon" title="View" to={`/booking-details/${booking.id}`}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></Link>
                                <Link className="actionIcon" title="Edit" to={`/update-booking/${booking.id}`}><span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span></Link>
                                {/* <a className="actionIcon" title="Delete" href="#!" onClick={() => deleteLocation(location.id)}><span><img src="assets/img/svg/Bin.png" alt="Delete" /></span></a> */}
                              </td>
                            </tr>
                          )
                        }) : null}

                      </tbody>
                    </table>
                  </div>
                  {/* calling the another componenet */}
                  <Pagination totalPage={(getBooking && getBooking.getBooking) ? getBooking.getBooking.pages : 0} currentPage={currentPage} funPagination={makeHttpRequestWithPage} />
                </div>
              </div>
            </div> :
            <BookingDetail data={saveBookings} backButton={backButton} />
          }

        </div>
        <Dialog open={deletePopUp} fullWidth={true} aria-labelledby="delete-product-notify" >
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            Are you sure?
            <h5 className="mt-3">You want to delete {deleteDetail}?</h5>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={() => handleDelete()}>Delete</Button>
            <Button variant="contained" onClick={closeDeletePopup}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Footer />
      </div>
    </div>
  )
}
