import React, { useEffect, useState } from 'react';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import { useSelector, useDispatch } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';
// import { Form } from 'antd';
import { questionsActions } from '../../_actions/questions.action';
import Select from "react-select";
import StarRatings from 'react-star-ratings';


export default function ViewCompanyReview(props) {
  const [createdBy, setCreatedBy] = useState(JSON.parse(sessionStorage.getItem('user')).data.id)
  const getCompanyReviewById = useSelector(state => state.getCompanyReviewById);
  const getQuestionsList = useSelector(state => state.getQuestionsList);
  const [companyReviewData, setcompanyReviewData] = useState([])
  const [questionList, setquestionList] = useState([])
  const [selectedAnswer1, setSelectedAnswer1] = useState({})
  const [selectedAnswer2, setSelectedAnswer2] = useState([])
  const [question5, setQuestion5] = useState({})
  const [question8, setQuestion8] = useState({})

  const [submittedAnswer, setSubmittedAnswer] = useState([])
  const dispatch = useDispatch();


  useEffect(() => {
    let obj = {
      id: props.match.params.id
    }
    dispatch(questionsActions.getCompanyReviewById(apiConstants.GET_COMPANYREVIEW_BY_ID, obj))
    dispatch(questionsActions.getQuestionsList(apiConstants.GET_ALL_QUESTIONS));
  }, [])

  useEffect(() => {
    if (getCompanyReviewById && getCompanyReviewById.getCompanyReviewById) {
      setcompanyReviewData(getCompanyReviewById.getCompanyReviewById.data)
      let temp = JSON.parse(getCompanyReviewById.getCompanyReviewById.data.submitedanswers);
      let tempArray = [];
      Object.keys(temp).forEach(i => {
        let obj = { id: i, value: temp[i] }
        tempArray.push(obj)
      })
      // let tempArray = temp.split(",")
      setSubmittedAnswer(tempArray)
    }

  }, [getCompanyReviewById])

  useEffect(()=> {
    if(submittedAnswer.length > 0){
     
      let tempArray = [];
      let tempArray2 = [];
      let temp = submittedAnswer[4].value.split(",")
      let temp2 = submittedAnswer[7].value.split(",")
      temp.map(i=> {
        let obj = { value: i, label: i }
        tempArray.push(obj)
      })
      temp2.map(i=> {
        let obj = { value: i, label: i }
        tempArray2.push(obj)
      })
      setSelectedAnswer1(tempArray)
      setSelectedAnswer2(tempArray2)
    }
  },[submittedAnswer])

  useEffect(() => {
    if (getQuestionsList.getQuestionsList && getQuestionsList.getQuestionsList.data) {
      setquestionList(getQuestionsList.getQuestionsList.data)
      let question5Str = getQuestionsList.getQuestionsList.data[4].all_answers
      let question8Str = getQuestionsList.getQuestionsList.data[7].all_answers

      let question5 = question5Str.split(",");
      let question8 = question8Str.split(",");
      let tempArr = []
      let tempArr8 = []
      question5.map(i => {
        let temp = { value: i, label: i }
        tempArr.push(temp)
      })
      question8.map(i => {
        let temp = { value: i, label: i }
        tempArr8.push(temp)
      })
      setQuestion5(tempArr)
      setQuestion8(tempArr8)
    }
  }, [getQuestionsList.getQuestionsList])

  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Edit Company Review
          </li>
            </ol>
          </nav>
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-2 text-gray-800">
                Edit Company Review
          </h1>
            </div>
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="table-responsive">

                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group">
                        <label>User Name</label>
                        <input type="text" value={companyReviewData.user_name} className="form-control form-control-user"
                          placeholder="User Name" disabled/>
                      </div>
                      {/* <p className={classes.error}>{this.state.eventTime_error}</p> */}
                    </div>

                    <div className="col-md-12 col-xs-12">
                      <div className="form-group">
                        <label>Company Name</label>
                        <input type="text" value={companyReviewData.company_name} className="form-control form-control-user"
                          placeholder="Company Name" />
                      </div>
                      {/* <p className={classes.error}>{this.state.eventTime_error}</p> */}
                    </div>

                    {
                      questionList.length > 0 ? <>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>1) {questionList[0].questions}</label>
                            <br />
                            <input type="radio" name="question_type1" value="Yes" checked= {(submittedAnswer.length > 0 && submittedAnswer[0].id === 1  && submittedAnswer[0].value == 'Yes') ? true : false} disabled/>
                            <label>Yes</label>
                            <input type="radio" name="question_type1" value="No" checked={(submittedAnswer.length > 0 && submittedAnswer[0].id === 1  && submittedAnswer[0].value == 'No') ? true : false} disabled/>
                            <label>No</label>
                          </div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>2) {questionList[1].questions}</label>
                            <input type="text" className="form-control form-control-user" defaultValue={(submittedAnswer.length > 0 && submittedAnswer[1].id == 2) ? submittedAnswer[1].value : '' } disabled />
                          </div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>3) {questionList[2].questions}</label>
                            {/* <StarRatings
                                    rating={(submittedAnswer.length > 0 && submittedAnswer[3].id == 3) ? parseInt(submittedAnswer[3].value ): ''}
                                    starDimension="40px"
                                    starSpacing="15px"
                                  /> */}
                          </div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>4) {questionList[3].questions}</label>
                             <StarRatings
                                    rating={(submittedAnswer.length > 0 && submittedAnswer[3].id === 4) ? parseInt(submittedAnswer[3].value) : 0}
                                    starDimension="40px"
                                    starSpacing="15px"
                                    disabled
                                  />
                          </div>=
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>5) {questionList[4].questions}</label>
                            <Select
                              value={selectedAnswer1}
                              isMulti
                              options={question5}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              isDisabled={true}
                          />
                          </div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>6) {questionList[5].questions}</label>
                            <br />
                            <input type="radio" name="question_type6" value="Yes" checked= {(submittedAnswer.length > 0 && submittedAnswer[5].id === 6  && submittedAnswer[5].value == 'Yes') ? true : false} disabled/>
                            <label>Yes</label>
                            <input type="radio" name="question_type6" value="No" checked={(submittedAnswer.length > 0 && submittedAnswer[5].id === 6  && submittedAnswer[5].value == 'No') ? true : false} disabled/>
                            <label>No</label>
                          </div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>7) {questionList[6].questions}</label>
                            <br />
                            <input type="radio" name="question_type7" value="Yes" checked= {(submittedAnswer.length > 0 && submittedAnswer[6].id === 7  && submittedAnswer[6].value == 'Yes') ? true : false} disabled/>
                            <label>Yes</label>
                            <input type="radio" name="question_type7" value="No" checked={(submittedAnswer.length > 0 && submittedAnswer[6].id === 7  && submittedAnswer[6].value == 'No') ? true : false} disabled/>
                            <label>No</label>
                          </div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>8) {questionList[7].questions}</label>
                            <Select
                              value={selectedAnswer2}
                              isMulti
                              options={question8}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              isDisabled={true}
                          />
                          </div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>9) {questionList[8].questions}</label>
                            <input type="text" className="form-control form-control-user" defaultValue={(submittedAnswer.length > 0 && submittedAnswer[8].id == 9) ? submittedAnswer[8].value : '' } disabled/>
                          </div>
                        </div>
                      </>
                        : ''}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
