import { eventConstants } from '../_constants';
import * as commonAction from './commonAction';
import { eventService, commonService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';
import { apiConstants } from '../_constants/api.constants';


export const eventActions = {
    saveEvent,
    getEventsList,
    getEventDetails,
};

//-- Save
function saveEvent(apiName, eventData) {
    return dispatch => {
        dispatch(commonAction.request(eventConstants.EVENT_ADDED_REQUEST, eventData));
        commonService.withToken(apiName, eventData).then(
            getProduct => {
                dispatch(commonAction.success(eventConstants.EVENT_ADDED_SUCCESS, getProduct));
            },
            error => {
                dispatch(
                    commonAction.failure(eventConstants.EVENT_ADDED_FAILURE, error.response.data.error)
                );
            }
        );
    };
}

//-- getEventsList
function getEventsList(apiName, eventData) {

    return dispatch => {
        dispatch(commonAction.request(eventConstants.EVENT_LIST_REQUEST, eventData));
        eventService.getEventsList(apiName, eventData)
            .then(
                user => {

                    //console.log("Successfully",user.data);
                    dispatch(commonAction.success(eventConstants.EVENT_LIST_SUCCESS, user.data));
                    if (user.success == false) {
                        dispatch(commonAction.failure(eventConstants.EVENT_LIST_ERROR, user.message));
                        dispatch(alertActions.error(user.message));
                    }
                },
                error => {
                    dispatch(alertActions.error(error.response.data.error));
                }
            );
    };
}


//-- Get user detail on profile page
function getEventDetails(apiName, eventData) {
    return dispatch => {
        dispatch(commonAction.request(eventConstants.EVENT_GET_PROFILE_REQUEST, eventData));
        commonService.withToken(apiName, eventData)
            .then(
                user => {
                    //console.log("Successfully",user.data);
                    dispatch(commonAction.success(eventConstants.EVENT_GET_PROFILE_SUCCESS, user.data));
                    if (user.success == false) {
                        dispatch(commonAction.failure(eventConstants.EVENT_GET_PROFILE_FAILURE, user.message));
                        dispatch(alertActions.error(user.message));
                    }
                },
                error => {
                    dispatch(alertActions.error(error.response.data.error));
                }
            );
    };
}
