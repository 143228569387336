import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { apiConstants } from '../../../_constants/api.constants';

class TopBar extends Component {
    logout() {
        sessionStorage.setItem("user", null);
    }

    render() {
        const { siteConstants } = this.props.language;
        //-- Manage Profile Image 
        let profileImage = "./assets/img/svg/user.svg";
        let profileImageDB = JSON.parse(sessionStorage.getItem('user')).profile_image;
        if (profileImageDB !== "") {
            profileImage = process.env.REACT_APP_IMAGE_PATH + "/uploads/" + JSON.parse(sessionStorage.getItem('user')).id + '/' + profileImageDB;
        }

        return (
            <Fragment>
                {/* <!-- Topbar --> */}
                <nav className="navbar navbar-expand navbar-light bg-white topbar static-top">

                    {/* <!-- Sidebar Toggle (Topbar) --> */}
                    <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                        <i className="fa fa-bars"></i>
                    </button>

                    {/* <!-- Topbar Search --> */}
                    {/* <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                        <div className="input-group">
                            <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button className="btn btn-primary" type="button">
                                    <i className="fas fa-search fa-sm"></i>
                                </button>
                            </div>
                        </div>
                    </form> */}
                    <div>{siteConstants.GEN_DISPLAY_DESCRIPTION}</div>

                    {/* <!-- Topbar Navbar --> */}
                    <ul className="navbar-nav ml-auto">

                        
                        {/* <!-- Nav Item - Messages --> */}
                        

                        <div className="topbar-divider d-none d-sm-block"></div>

                        {/* <!-- Nav Item - User Information --> */}
                        <li className="nav-item dropdown no-arrow">
                            <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="mr-2 d-none d-lg-inline text-gray-600 small">{JSON.parse(sessionStorage.getItem('user')).data.first_name} {JSON.parse(sessionStorage.getItem('user')).data.last_name}</span>
                                <img
                                    className="img-profile rounded-circle"
                                    src={profileImage}
                                />
                            </a>
                            {/* <!-- Dropdown - User Information --> */}
                            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                                <a className="dropdown-item" href="/profile">
                                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Profile
                                </a>
                                <a className="dropdown-item" href="/change-password">
                                    <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                    {siteConstants.LABEL_CHANGE_PASSWORD}
                                </a>
                                <div className="dropdown-divider"></div>
                                <Link className="dropdown-item" to="/login" onClick={this.logout}></Link>
                                <a className="dropdown-item" href="/login" onClick={this.logout} data-toggle="modal" data-target="#logoutModal">
                                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                    {siteConstants.MENU_LOGOUT}
                                </a>
                            </div>
                        </li>

                    </ul>

                </nav>
                {/* <!-- End of Topbar --> */}
            </Fragment>
        );
    }
}

//-- Here we are adding Reducer names, so it can be get data from reducers using store
function mapStateToProps(state) {
    const { language } = state;
    return {
        language
    };
}

const connectedTopBar = connect(mapStateToProps)(TopBar);
export default connectedTopBar;