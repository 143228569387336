export const locationConstants = {
    
    GET_ALL_LOCATION_REQUEST: 'GET_ALL_LOCATION_REQUEST',
    GET_ALL_LOCATION_SUCCESS: 'GET_ALL_LOCATION_SUCCESS',
    GET_ALL_LOCATION_FAILURE: 'GET_ALL_LOCATION_FAILURE',

    ADD_LOCATION_REQUEST: 'ADD_LOCATION_REQUEST',
    ADD_LOCATION_SUCCESS: 'ADD_LOCATION_SUCCESS',
    ADD_LOCATION_FAILURE: 'ADD_LOCATION_FAILURE',

    UPDATE_LOCATION_REQUEST: 'UPDATE_LOCATION_REQUEST',
    UPDATE_LOCATION_SUCCESS: 'UPDATE_LOCATION_SUCCESS',
    UPDATE_LOCATION_FAILURE: 'UPDATE_LOCATION_FAILURE',

    GET_ALL_LOCATION_BY_ID_REQUEST: 'GET_ALL_LOCATION_BY_ID_REQUEST',
    GET_ALL_LOCATION_BY_ID_SUCCESS: 'GET_ALL_LOCATION_BY_ID_SUCCESS',
    GET_ALL_LOCATION_BY_ID_FAILURE: 'GET_ALL_LOCATION_BY_ID_FAILURE',
};
