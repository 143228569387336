import { transactionConstants } from '../_constants';

//-- get the list of terms
export function getTransactionById(state = {}, action) {
  switch (action.type) {
    case transactionConstants.GET_TRANSACTION_BY_ID_REQUEST:
      return {
        getTransactionById: false
      };

    case transactionConstants.GET_TRANSACTION_BY_ID_SUCCESS:
      return { getTransactionById:action.successAction};

    case transactionConstants.GET_TRANSACTION_BY_ID_FAILURE:
      return {
        getTransactionById: false,
      };

    default:
      return state
  }
}

export function getTransactionList(state = {}, action) {
  switch (action.type) {
    case transactionConstants.GET_TRANSACTION_LIST_REQUEST:
      return {
        getTransactionList: false
      };

    case transactionConstants.GET_TRANSACTION_LIST_SUCCESS:
      return { getTransactionList:action.successAction};

    case transactionConstants.GET_TRANSACTION_LIST_FAILURE:
      return {
        getTransactionList: false,
      };

    default:
      return state
  }
}
