import { scheduleExperienceConstants } from '../_constants/schedule_experience.constants';

//-- get the list of terms
export function getScheduleExperienceList(state = {}, action) {
  switch (action.type) {
    case scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_REQUEST:
      return {
        getScheduleExperienceList: false
      };

    case scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_SUCCESS:
      return { getScheduleExperienceList:action.successAction};

    case scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_FAILURE:
      return {
        getScheduleExperienceList: false,
      };

    default:
      return state
  }
}

export function addScheduleExperience(state = {}, action) {
  switch (action.type) {
    case scheduleExperienceConstants.ADD_SCHEDULE_EXPERIENCE_REQUEST:
      return {
        addScheduleExperience: false
      };

    case scheduleExperienceConstants.ADD_SCHEDULE_EXPERIENCE_SUCCESS:
      return { addScheduleExperience:action.successAction};

    case scheduleExperienceConstants.ADD_SCHEDULE_EXPERIENCE_FAILURE:
      return {
        addScheduleExperience: false,
      };

    default:
      return state
  }
}

export function getScheduleExperienceById(state = {}, action) {
  switch (action.type) {
    case scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_BY_ID_REQUEST:
      return {
        getScheduleExperienceById: false
      };

    case scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_BY_ID_SUCCESS:
      return { getScheduleExperienceById:action.successAction};

    case scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_BY_ID_FAILURE:
      return {
        getScheduleExperienceById: false,
      };

    default:
      return state
  }
}

export function getScheduleExperienceByProviderId(state = {}, action) {
  switch (action.type) {
    case scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_BY_PROVIDER_ID_REQUEST:
      return {
        getScheduleExperienceByProviderId: false
      };

    case scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_BY_PROVIDER_ID_SUCCESS:
      return { getScheduleExperienceByProviderId:action.successAction};

    case scheduleExperienceConstants.GET_SCHEDULE_EXPERIENCE_BY_PROVIDER_ID_FAILURE:
      return {
        getScheduleExperienceByProviderId: false,
      };

    default:
      return state
  }
}


export function editScheduleExperience(state = {}, action) {
  switch (action.type) {
    case scheduleExperienceConstants.EDIT_SCHEDULE_EXPERIENCE_REQUEST:
      return {
        editScheduleExperience: false
      };

    case scheduleExperienceConstants.EDIT_SCHEDULE_EXPERIENCE_SUCCESS:
      return { editScheduleExperience:action.successAction};

    case scheduleExperienceConstants.EDIT_SCHEDULE_EXPERIENCE_FAILURE:
      return {
        editScheduleExperience: false,
      };

    default:
      return state
  }
}

export function deleteScheduleExperience(state = {}, action) {
  switch (action.type) {
    case scheduleExperienceConstants.DELETE_SCHEDULE_EXPERIENCE_REQUEST:
      return {
        deleteScheduleExperience: false
      };

    case scheduleExperienceConstants.DELETE_SCHEDULE_EXPERIENCE_SUCCESS:
      return { deleteScheduleExperience:action.successAction};

    case scheduleExperienceConstants.DELETE_SCHEDULE_EXPERIENCE_FAILURE:
      return {
        deleteScheduleExperience: false,
      };

    default:
      return state
  }
}

