import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import FeedbackDetails from './FeedbackDetails';
import { CSVLink } from "react-csv";
import PageLimit from "../Common/PageLimit/PageLimit";
import Pagination from "../Common/Pagination/Pagination";
import AddFeedback from './AddFeedback';
// import { Badge } from 'antd';
// import { Switch } from 'antd';
import 'antd/dist/antd.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
// import { experienceActions } from '../../_actions';
import {feedbackActions} from "../../_actions/feedback.action";
import { apiConstants } from '../../_constants/api.constants';
import StarRatings from 'react-star-ratings';

export default function Feedback() {
  const [experienceDetails, setExperienceDetails] = useState(false);
  const [experienceAdd, setExperienceAdd] = useState(false);
  const [savExperience, setSaveExperience] = useState({})
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [feedback, setFeedback] = useState([])
  const [sortFlag, setSortFlag] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [NewOffset, setOffset] = useState(0);
  const [deleteDetail, setDeleteDetail] = useState("");
  const [search, setSearch] = useState("");
  const [filteredFeedback, setFilteredFeedback] = useState([]);
  const [rating, setRating] = useState(0)

  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')))
  const dispatch = useDispatch();
  const feedbackData = useSelector(state => state.getFeedbackList);

  useEffect(() => {
    let obj = {
      limit: 10,
      offset: 0
    }
    dispatch(feedbackActions.getFeedbackList(apiConstants.GET_ALL_FEEDBACK, obj));
  }, [])
  
  const makeHttpRequestWithPage = (pageNumber) => {
    setCurrentPage(pageNumber)
    let obj = {
      limit: limit,
      offset:pageNumber - 1
    }
    setOffset(obj.offset)
    dispatch(feedbackActions.getFeedbackList(apiConstants.GET_ALL_FEEDBACK, obj));
}

const changeTableData = (e) => {
  setLimit(e.target.value)
  let obj = {
    limit: parseInt(e.target.value),
    offset: 0,
  };
  dispatch(feedbackActions.getFeedbackList(apiConstants.GET_ALL_FEEDBACK, obj));
};

  useEffect(()=> {
    if(feedbackData.getFeedbackList && feedbackData.getFeedbackList.data){
      setFeedback(feedbackData.getFeedbackList.data)
      
    }
  },[feedbackData.getFeedbackList])


  const openAddExperiencerDialog = () => {
    setExperienceAdd(true);
  }

  const closeAddExperienceDialog = () => {
    setExperienceAdd(false);
  }

  const experienceDetail = (experience) => {
    setExperienceDetails(true);
    setSaveExperience(experience)
  }

  const backButton = () => {
    setExperienceDetails(false);
  }

  const closeDeletePopup = () => {
    setDeletePopUp(false);
  }

   // Search function defined for search
   useEffect(() => {
    setFilteredFeedback(
      feedback.filter((data) =>
      data.feedback.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, feedback]);

  const deleteExperience = (id) => {
    setDeletePopUp(true);
    setDeleteDetail(id);
  }
  const handleDelete = () => {
    let feedbackId = { id: deleteDetail };
    dispatch(
      feedbackActions.deleteFeedback(
        apiConstants.DELETE_FEEDBACK,
        feedbackId
      )
    );
    window.location.reload();
  };

  // const headers = [
  //   { label: "ID", key: "id" },
  //   { label: "Seeker Id", key: "seeker_id" },
  //   { label: "EXperience Id", key: "experience_id" },
  //   { label: "Provider Id", key: "provider_id" },
  //   { label: "Booking Id", key: "booking_id" },
  //   { label: "Company Name", key: "cmp_name" },
  //   { label: "Experience Nae", key: "exp_name" },
  //   { label: "Feedback", key: "feedback" },
  //   { label: "Satisfaction", key: "satisfaction" },
  //   { label: "Quality", key: "quality" },
  //   { label: "Management", key: "management" },
  //   { label: "Reply", key: "reply" },
  //   { label: "Reply delete", key: "reply_delete" },
  //   { label: "Status", key: "status" },
  //   { label: "Delete", key: "is_deleted" },
    
  // ];
  const headers = [
    { label: "Company Name", key: "cmp_name" },
    { label: "Experience Nae", key: "exp_name" },
    { label: "Feedback", key: "feedback" },
    { label: "Satisfaction", key: "satisfaction" },
    { label: "Quality", key: "quality" },
    { label: "Management", key: "management" },
    { label: "Reply", key: "reply" }
    
  ];
  const handleSort = (e) => {
    if(sortFlag == 1){
      setSortFlag(0)
    }else if(sortFlag == 0){
      setSortFlag(1)
    }
   let obj = {
     sortByStatus: sortFlag,
     sortBy: e.target.name
   }
   dispatch(feedbackActions.sortFeedbackList(apiConstants.SORT_FEEDBACK, obj))
  }

  const changeRating = (newRating)=>{
    setRating(newRating)
  }

  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Experience Feedback
              </li>
            </ol>
          </nav>
          {!experienceDetails ?
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-2 text-gray-800">
                Experience Feedback
                </h1>
                <div className="d-sm-flex align-items-center justify-content-between">
                  {/* <CSVLink className="btn btn-primary mr-3" data={feedback.map(Object.values)} headers={headers.map(Object.values)}>Export</CSVLink> */}
                  <CSVLink className="btn btn-primary mr-3" data={feedback} headers={headers}>Export</CSVLink>
                 
                    <span className="text">
                      <button onClick={openAddExperiencerDialog} className="btn btn-primary">Add New Feedback</button>
                    </span>
               
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="row">
                      {/* Page limit drop down */}
                      <PageLimit funChangePageLimit={changeTableData} />

                      <div className="col-sm-12 col-md-6">
                        <div id="dataTable_filter" className="dataTables_filter">
                          <label>
                            <input type="search" onChange={(e) => setSearch(e.target.value)} className="form-control form-control-sm" placeholder="Search" aria-controls="dataTable" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <table className="table table-bordered" width="100%" cellSpacing="0">
                      <thead>
                        <tr>
                          <th scope="col">No.</th>
                          <th scope="col">Feedback
                          <img src="assets/img/svg/sort.png" name="feedback" className="imageSort" onClick={handleSort}/>
                          </th>
                          <th scope="col">Satisfaction</th>
                          <th scope="col">Quality</th>
                          <th scope="col">Management</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {filteredFeedback !== undefined ? filteredFeedback.map((feedback, index) => {
                        var count = 0;
                        //  var pageData = config.perPageData;
                         count = index + (NewOffset*10)+1
                       return (
                        <tr key={index}>
                          <td scope="row">{count}</td>
                          <td>{feedback.feedback}</td>                  
                          <td>
                          <StarRatings
                                    rating={feedback.satisfaction}
                                    starDimension="10px"
                                    starSpacing="1px"
                                    starRatedColor="orange"
                                  />
                            </td>
                          <td>
                          <StarRatings
                                    rating={feedback.quality}
                                    starDimension="10px"
                                    starSpacing="1px"
                                    starRatedColor="orange"
                                  />
                            </td>
                          <td>
                          <StarRatings
                                    rating={feedback.management}
                                    starDimension="10px"
                                    starSpacing="1px"
                                    starRatedColor="orange"
                                  />
                            </td>
                         
                          <td>
                            {/* <a className="actionIcon" title="View" href="#" onClick={() => experienceDetail(feedback)}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a> */}
                            {/* <a className="actionIcon" title="View" href="#!" onClick={() => this.popupOpen(users, 'viewOpen')}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a> */}
                            <a className="actionIcon" title="Delete" href="#!" onClick={() => deleteExperience(feedback.id)}><span><img src="assets/img/svg/Bin.png" alt="Delete" /></span></a>
                            <Link className="actionIcon" title="Edit" to={`/update-feedback/${feedback.id}`}><span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span></Link>
                          </td>
                        </tr>
                          )
                        }) : null}
                      </tbody>
                    </table>
                  </div>
                  {/* calling the another componenet */}
                  <Pagination totalPage={(feedbackData && feedbackData.getFeedbackList) ? feedbackData.getFeedbackList.pages : 0 } currentPage={currentPage} funPagination={makeHttpRequestWithPage} />
                </div>
              </div>
            </div> :
            <FeedbackDetails data={savExperience} backButton={backButton} />
          }
        </div>

        {/* add experience popup */}
        {
          experienceAdd === true ? <AddFeedback displayPopUp={experienceAdd} hidePopUp={closeAddExperienceDialog} /> : ''
        }


        <Dialog open={deletePopUp} fullWidth={true} aria-labelledby="delete-product-notify" >
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            Are you sure?
            <h5 className="mt-3">You want to delete feedback</h5>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={() => handleDelete()}>Delete</Button>
            <Button variant="contained" onClick={closeDeletePopup}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Footer />
      </div>
    </div>
  )
}
