import { authHeader,history } from '../_helpers';
import axios from 'axios';

export const eventService = {
    getEventsList,
};

//-- getEventsList
function getEventsList(apiName, eventData) {
    let token = JSON.parse(sessionStorage.getItem('token'));    
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL+apiName}`,
        headers: { 'Content-Type': 'application/json', 'Authorization': token},
        data: eventData
    }).then(handleResponse);
}

function handleResponse(response) {

    //console.log('response', response);

    if (response.status === 200||response.status === 201) {
        return response;
    } else {
        const error = response;
        return Promise.reject(error);
    }

}

