import React, { useEffect, useState } from 'react';
import TopBar from '../Common/TopBar/TopBar';
import SideBar from '../Common/SideBar/SideBar';
import Footer from '../Common/Footer/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { activityTypeActions } from '../../_actions';
import { apiConstants } from '../../_constants/api.constants';
import { CSVLink } from 'react-csv';
import { Box, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AddEditActivityType from './AddEditActivityType';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Pagination from '../Common/Pagination/Pagination';
import useDebounce from '../../hooks/useDebounce';

export default function ActivityType() {
  const dispatch = useDispatch();
  const activityTypeData = useSelector((state) => state.getActivityTypeList);
  const [currActivityType, setCurrActivityType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idEdit, setIsEdit] = useState(false);
  // modal is open state for archiving
  const [isArchive, setIsArchive] = useState(false);
  // modal is open state for unarchiving
  const [isUnArchive, setIsUnArchive] = useState(false);
  const [activityTypeDetails, setActivityTypeDetails] = useState(false);
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
  const [NewOffset, setOffset] = useState(0);
  const [addActivityTypeModalIsOpen, setActivityTypeModalIsOpen] =
    useState(false);
  const [filteredActivityType, setFilteredActivityType] = useState([]);
  const [dbFilterActivityTYpe, setDbFilterActivityType] = useState();
  const [limit, setLimit] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const debouncedSearchValue = useDebounce(
    dbFilterActivityTYpe?.activityType,
    1000,
  );
  const [isFiltered, setIsFiltered] = useState(false);

  // functions
  const getAllActivityTypes = () => {
    let obj = {
      limit: 25,
      offset: 0,
    };

    dispatch(
      activityTypeActions.getActivityTypeList(
        apiConstants.GET_ALL_ACTIVITY_TYPES_ADMIN,
        obj,
      ),
    );
    setLoading(true);
  };

  // hooks
  useEffect(() => {
    getAllActivityTypes();
  }, []);

  useEffect(() => {
    if (activityTypeData?.getActivityTypeList?.activity_type) {
      setLoading(false);
      setFilteredActivityType(
        activityTypeData.getActivityTypeList?.activity_type,
      );
    }
  }, [activityTypeData.getActivityTypeList]);

  const searchActivityType = (dbFilterActivityTYpe) => {
    let obj = {
      limit: 25,
      offset: 0,
    };
    obj['is_deleted'] = dbFilterActivityTYpe?.is_deleted;
    obj['activity_type'] = dbFilterActivityTYpe?.activityType;

    dispatch(
      activityTypeActions.getActivityTypeList(
        apiConstants.FILTER_ACTIVITY_TYPE,
        obj,
      ),
    );
    setLoading(true);
  };
  useEffect(() => {
    if (isFiltered) {
      searchActivityType(dbFilterActivityTYpe);
    }
  }, [dbFilterActivityTYpe?.is_deleted]);

  useEffect(() => {
    if (isFiltered) {
      searchActivityType(dbFilterActivityTYpe);
    }
  }, [debouncedSearchValue]);

  const handleArchive = () => {
    dispatch(
      activityTypeActions.archiveActivityType(
        apiConstants.ARCHIVE_ACTIVITY_TYPE,
        currActivityType,
      ),
    );
    setIsArchive(false);
  };

  const handleUnArchive = () => {
    dispatch(
      activityTypeActions.archiveActivityType(
        apiConstants.ARCHIVE_ACTIVITY_TYPE,
        {
          ...currActivityType,
          unarchive: true,
        },
      ),
    );
    setIsUnArchive(false);
  };

  const handleArchiveSelect = (e) => {
    setIsFiltered(true);
    setDbFilterActivityType({
      ...dbFilterActivityTYpe,
      is_deleted: e.target.value,
    });
  };

  const makeHttpRequestWithPage = (pageNumber) => {
    let obj = {
      limit: limit,
      offset: limit * (pageNumber - 1),
    };
    setCurrentPage(pageNumber);
    setOffset(obj.offset);
    if (dbFilterActivityTYpe) {
      obj['is_deleted'] = Number(dbFilterActivityTYpe.is_deleted);
      obj['activity_type'] = dbFilterActivityTYpe.activityType;
      setLoading(true);
      return dispatch(
        activityTypeActions.getActivityTypeList(
          apiConstants.FILTER_ACTIVITY_TYPE,
          obj,
        ),
      );
    }

    dispatch(
      activityTypeActions.getActivityTypeList(
        apiConstants.GET_ALL_ACTIVITY_TYPES_ADMIN,
        obj,
      ),
    );
    setLoading(true);
  };

  return (
    <div id='wrapper'>
      <SideBar />
      <div id='content-wrapper' className='d-flex flex-column'>
        <div id='content'>
          <TopBar />
          <nav className='' aria-label='breadcrumb'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <a href='/dashboard'>Dashboard</a>
              </li>
              <li className='active breadcrumb-item' aria-current='page'>
                Activity Type
              </li>
            </ol>
          </nav>
          {/* Activity type details */}
          {!activityTypeDetails ? (
            <div className='container-fluid'>
              <div className='d-sm-flex align-items-center justify-content-between mb-4'>
                <h1 className='h3 mb-2 text-gray-800'>Activity Type</h1>
                <div className='d-sm-flex align-items-center justify-content-between'>
                  {/* <CSVLink className="btn btn-primary mr-3" data={experience.map(Object.values)} headers={headers.map(Object.values)}>Export</CSVLink> */}
                  {/* <CSVLink className="btn btn-primary mr-3" data={activityType || []} headers={headers}>Export</CSVLink> */}
                  <a href='#!'>
                    <span className='text'>
                      <button
                        onClick={() => setActivityTypeModalIsOpen(true)}
                        className='btn btn-primary'
                      >
                        Add New Activity Type
                      </button>
                    </span>
                  </a>
                </div>
              </div>
              <div className='card shadow mb-4'>
                <div className='card-body'>
                  <div className='table-responsive'>
                    <div className='row mb-4'>
                      {/* Page limit drop down */}
                      {/* <PageLimit funChangePageLimit={changeTableData} /> */}

                      <div className='col-sm-2 col-md-2'>
                        <label>Search by type</label>
                        <div className='d-flex justify-content-center align-items-centers'>
                          <input
                            type='search'
                            className='form-control form-control-sm'
                            placeholder='Search...'
                            onChange={(e) => {
                              setIsFiltered(true);
                              setDbFilterActivityType({
                                ...dbFilterActivityTYpe,
                                activityType: e.target.value,
                              });
                            }}
                            aria-controls='dataTable'
                          />
                        </div>
                      </div>
                      <div className='col-sm-3 col-md-3'>
                        <label>Archived</label>
                        <select
                          className='form-control form-control-sm'
                          name='provide_id'
                          onChange={handleArchiveSelect}
                        >
                          <option value={''}>Show All</option>
                          <option value={'yes'}>Yes</option>
                          <option value={'no'}>No</option>
                        </select>
                      </div>
                    </div>
                    <table
                      className='table table-bordered'
                      width='100%'
                      cellSpacing='0'
                    >
                      <thead>
                        <tr>
                          <th scope='col'>No.</th>
                          <th scope='col'>
                            Activity Type
                            <img
                              alt='sort'
                              src='assets/img/svg/sort.png'
                              name='experience_name'
                              className='imageSort'
                              onClick={() => {}}
                            />
                          </th>
                          <th scope='col'>Archived</th>
                          <th scope='col'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <div className=' width-100 d-flex justify-content-center align-items-center'>
                                    <Box className='d-flex justify-content-center'>
                                    <CircularProgress />
                                    </Box>
                                </div> */}
                        {loading ? (
                          <>
                            <td className='' colSpan={'100%'}>
                              <div className='col-md-12 justify-content-center align-items-center'>
                                <Box className='d-flex justify-content-center'>
                                  <CircularProgress />
                                </Box>
                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                            {filteredActivityType !== undefined &&
                            filteredActivityType.length > 0
                              ? filteredActivityType.map(
                                  (activity_type, index) => {
                                    var count = 0;
                                    //  var pageData = config.perPageData;
                                    count = index + NewOffset + 1;
                                    return (
                                      <tr key={index}>
                                        <td scope='row'>{count}</td>
                                        <td>{activity_type.activity_type}</td>
                                        <td>
                                          {activity_type.is_deleted
                                            ? 'Yes'
                                            : 'No'}
                                        </td>
                                        <td>
                                          {/* <a className="actionIcon" title="View" href="#!" onClick={() => {}}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a> */}
                                          {/* <a className="actionIcon" title="View" href="#!" onClick={() => this.popupOpen(users, 'viewOpen')}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></a> */}
                                          {user.data?.role === 'super admin' ? (
                                            <>
                                              <span
                                                className='mr-3'
                                                onClick={() => {
                                                  setActivityTypeModalIsOpen(
                                                    true,
                                                  );
                                                  setIsEdit(true);
                                                  setCurrActivityType(
                                                    activity_type,
                                                  );
                                                }}
                                              >
                                                <img
                                                  src='assets/img/svg/EditIcon.png'
                                                  alt='Edit'
                                                />
                                              </span>
                                              <span className=''>
                                                {activity_type.is_deleted ? (
                                                  <Button
                                                    onClick={() => {
                                                      setCurrActivityType(
                                                        activity_type,
                                                      );
                                                      setIsUnArchive(true);
                                                    }}
                                                    variant='contained'
                                                    size='xs'
                                                  >
                                                    Unarchive
                                                  </Button>
                                                ) : (
                                                  <Button
                                                    onClick={() => {
                                                      setIsArchive(true);
                                                      setCurrActivityType(
                                                        activity_type,
                                                      );
                                                    }}
                                                    variant='contained'
                                                    size='xs'
                                                  >
                                                    Archive
                                                  </Button>
                                                )}
                                              </span>
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  },
                                )
                              : null}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* calling the another componenet */}
                  <Pagination
                    totalPage={
                      activityTypeData && activityTypeData.getActivityTypeList
                        ? activityTypeData.getActivityTypeList.pages
                        : 0
                    }
                    currentPage={currentPage}
                    funPagination={makeHttpRequestWithPage}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        {addActivityTypeModalIsOpen && (
          <>
            {idEdit ? (
              <>
                <AddEditActivityType
                  isOpen={addActivityTypeModalIsOpen}
                  setIsOpen={setActivityTypeModalIsOpen}
                  isEdit={currActivityType}
                  setCurrActivity={setCurrActivityType}
                  setIsEdit={setActivityTypeModalIsOpen}
                />
              </>
            ) : (
              <AddEditActivityType
                isOpen={addActivityTypeModalIsOpen}
                setIsOpen={setActivityTypeModalIsOpen}
              />
            )}
          </>
        )}

        <Dialog
          open={isArchive}
          fullWidth={true}
          aria-labelledby='delete-product-notify'
        >
          <DialogTitle>Archive</DialogTitle>
          <DialogContent>
            Are you sure?
            <h5 className='mt-3'>You want to archive activity type?</h5>
            <h6 className='mt-3'>
              It will delete all mappings with experience.
            </h6>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='secondary'
              onClick={handleArchive}
            >
              Archive
            </Button>
            <Button variant='contained' onClick={() => setIsArchive(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isUnArchive}
          fullWidth={true}
          aria-labelledby='delete-product-notify'
        >
          <DialogTitle>Archive</DialogTitle>
          <DialogContent>
            Are you sure?
            <h5 className='mt-3'>You want to unarchive activity type?</h5>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='secondary'
              onClick={handleUnArchive}
            >
              Unarchive
            </Button>
            <Button variant='contained' onClick={() => setIsUnArchive(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Footer />
      </div>
    </div>
  );
}
