import React, { useState, useEffect } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
// import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import {
  providerActions,
  locationActions,
  experienceActions
} from "../../_actions";
import {
  scheduleExperienceActions,
} from "../../_actions/schedule_experience.action";
import { apiConstants } from "../../_constants/api.constants";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import Select from "react-select";

export default function Add_schedule_experience({ displayPopUp, hidePopUp }) {
  const [experienceData, setExperienceData] = useState({
    schedule_status:"",
    repeat_weeks:"",
    never_ends:false,
    team_size:0,
    charge_type:0,
    amount:0,
    important_note:"",
    per_person_cost:0,
  });
  const [selectProvider, setSelectProvider] = useState("");
  const [selectExperience, setSelectExperience] = useState("");
  const [imagesArr, setImagesArr] = useState([]);
  const [selectDuration, setSelectDuration] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(
    new Date()
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    new Date()
  );
  const [repeatEndByDate,setRepeatEndByDate]= useState(
    null
  );
  const [selectedStartTime,setSelectedStartTime] = useState(new Date())
  const [selectedEndTime,setSelectedEndTime] = useState(new Date())

  const [experienceSchedule, setExperienceSchedule] = useState([
    {
      day: "1",
      start_time: "",
      end_time: "",
    },
  ]);

  const [indexValue, setIndexValue] = useState(0);
  const [highlights, setHighlights] = useState({});
  const [getExperienceData, setExperience] = useState([]);
  const provider = useSelector((state) => state.getProvider.allProvider);
  const experience = useSelector((state) => state.getExperienceById);
  const [selectedEnvironmental, setSelectedEnvironmental] = useState([]);
  const [oneTime, setOnetime] = useState(true);
  const [experienceName, setExperienceName] = useState("");
  const [locationId, setLocationId] = useState("");

  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setExperienceData({ ...experienceData, [name]: value });
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  useEffect(() => {
    let obj = {
      limit: 10,
      offset: 0,
    };
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER, obj));
  }, []);

  useEffect(() => {
    if (experience && experience.getExperienceById) {
      setExperience(experience.getExperienceById.experiences)
    }
}, [experience])

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };
  const handleEndDateChange = (date, index) => {
    setSelectedEndDate(date);
  };
  const handleStartTimeChange = (data) => {
    setSelectedStartTime(data);
  };
  const handleEndTimeChange = (data) => {
    setSelectedEndTime(data);
  };
  const handleRepeatEndBydateChange = (data) => {
    setRepeatEndByDate(data);
  };

  const handleSelectDuration = (e) => {
    setSelectDuration(e.target.value);
  };

  const handleSelectProvider = (e) => {
    setSelectProvider(e.target.value);
    let obj = {
      provider_id: parseInt(e.target.value),
    };
    // let id = e.target.value;
    dispatch(experienceActions.getExperienceByProviderId(apiConstants.GET_EXPERIENCE_BY_PROVIDER_ID, obj))
  };
   
  const handleSelectExperience = (e) => {
    setSelectExperience(getExperienceData[e.target.value].id);
    setExperienceName(getExperienceData[e.target.value].experience_name)
    setLocationId(getExperienceData[e.target.value].provider_id)
  };

  const handleHighlights = (e) => {
    const { name, value } = e.target;
    setHighlights({ ...highlights, [name]: value });
  };

  const handleChangeImages = (e) => {
    if (e.target.files.length <= 10) {
      setImagesArr(e.target.files);
    } else {
      e.preventDefault();
      e.target.value = null;
      alert(`Cannot upload files more than 10`);
    }
  };

  const handleMultiSelectChange = (selectedOption) => {
    setSelectedEnvironmental(selectedOption);
  };
  // handleChange = selectedOption => {
  //   this.setState({ selectedOption });
  //   console.log(`Option selected:`, selectedOption);
  // };

  const handleAddMore = () => {
    let i = indexValue + 1;
    setIndexValue(i);
    const values = [...experienceSchedule];
    values.push({
      day: "1",
      start_time: "",
      end_time: "",
    });
    setExperienceSchedule(values);
  };

  const handleSubmit = () => {
   
    var per_person_cost = 0;
    if (experienceData.charge_type === "flat_fee") {
      per_person_cost =
        parseInt(experienceData.amount) / parseInt(experienceData.team_size);
    } else {
      per_person_cost = parseInt(experienceData.amount);
    }

    let selectedTime = {
      start_time: selectedStartTime,
      end_time: selectedEndTime
    }

    let newObj = {
      provider_id:selectProvider,
      location_id:locationId,
      experience_id:selectExperience,
      experience_name:experienceName,
      schedule_status:experienceData.shedule_type,
      start_date:selectedStartDate,
      end_date:selectedEndDate,
      days_time: JSON.stringify(selectedTime),
      repeat_weeks:JSON.stringify(experienceData.repeat_weeks) || "",
      repeat_end_date:repeatEndByDate,
      never_ends:experienceData.never_ends,
      team_size:experienceData.team_size,
      charge_type:experienceData.charge_type,
      amount:experienceData.amount,
      important_note:experienceData.important_note,
      per_person_cost:per_person_cost,
    }

    let formData = new FormData();
    formData.append(
      "created_by_admin",
      parseInt(JSON.parse(sessionStorage.getItem("user")).data.id)
    );
    formData.append("provider_id", experienceData.provider_id);
    formData.append("experience_name",experienceData.experience_name);
    formData.append("schedule_status", experienceData.shedule_type);
    formData.append("start_date", selectedStartDate);
    formData.append("end_date", selectedEndDate);
    formData.append("days_time", "");
    formData.append("repeat_weeks", "");
    formData.append("repeat_end_date", experienceData.repeat_endby_date);
    formData.append("never_ends", experienceData.never_ends);
    formData.append("team_size", experienceData.team_size);
    formData.append("charge_type", experienceData);
    formData.append("amount", experienceData.amount);
    formData.append("important_note", experienceData.important_note);
    formData.append("per_person_cost", experienceData.per_person_cost);
   
  
    dispatch(
      scheduleExperienceActions.addScheduleExperience(apiConstants.ADD_SCHEDULE_EXPERIENCE, newObj)
    );
    hidePopUp();
  };
  return (
    <div>
      <Dialog
        open={displayPopUp}
        aria-labelledby="add-products"
        aria-describedby="save-products-description"
        fullWidth={true}
      >
        <DialogTitle>Add Schedule Experience</DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-sm-12 formBorder">
              <div className="p-5">
                <div className="form-group row">
                  <div className="col-sm-6">
                    <label>Provider Name</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={handleSelectProvider}
                    >
                      <option>Select Provider name</option>
                      {provider !== undefined && provider.length > 0
                        ? provider.map((provider, index) => {
                            return (
                              <option value={provider.id}>
                                {provider.first_name} {provider.last_name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                  <div className="col-sm-6">
                    <label>Experience Name</label>
                    <select
                      className="form-control"
                      name="experience_name"
                      onChange={handleSelectExperience}
                    >
                      <option>Select Experience</option>
                      {getExperienceData !== undefined && getExperienceData.length>0  ?
                        getExperienceData.map((data,index)=>(
                          <option value={index}>
                           {data.experience_name}
                         </option>
                        ))
                         
                        : null}
                    </select>
                  </div>
                </div>
                <div className="form-group row ml-3">
                  <div className="col-sm-6">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="shedule_type"
                      value="ONE TIME"
                      onClick={() => {
                        setOnetime(true);
                      }}
                      onChange={handleChange}
                    />
                    <label>ONE TIME</label>
                  </div>
                  {/* <div className="col-sm-6">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="shedule_type"
                      value="Repeat"
                      onClick={() => {
                        setOnetime(false);
                      }}
                      onChange={handleChange}
                    />
                    <label>REPEAT</label>
                  </div> */}
                </div>
                {oneTime === true ? (
                  <>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            margin="normal"
                            name="start_date"
                            disablePast
                            id="START DATE"
                            label="START DATE"
                            value={selectedStartDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-sm-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            margin="normal"
                            name="end_date"
                            disablePast
                            id="END DATE"
                            label="END DATE"
                            value={selectedEndDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                    {/* Time */}
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            name="start_time"
                            label="Start Time"
                            value={selectedStartTime}
                            onChange={handleStartTimeChange}
                            KeyboardButtonProps={{
                              "aria-label": "change time",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-sm-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            name="end_time"
                            label="End Time"
                            value={selectedEndTime}
                            onChange={handleEndTimeChange}
                            KeyboardButtonProps={{
                              "aria-label": "change time",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>

                    <div className="form-group row ml-3">
                      <div className="col-sm-6">
                        <input
                          type="radio"
                          class="form-check-input"
                          name="charge_type"
                          value="flat_fee"
                          onChange={handleChange}
                        />
                        <label>Flat fee</label>
                        {experienceData.charge_type == "flat_fee" ? (
                          <input
                            type="text"
                            className="input-with-radio"
                            name="amount"
                            placeholder="Amount"
                            onChange={handleChange}
                          />
                        ) : (
                          <input
                            type="text"
                            className="input-with-radio"
                            name="amount"
                            placeholder="Amount"
                            onChange={handleChange}
                            disabled
                          />
                        )}
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="radio"
                          class="form-check-input"
                          name="charge_type"
                          value="per_person"
                          onChange={handleChange}
                        />
                        <label>Per Person</label>
                        {experienceData.charge_type == "per_person" ? (
                          <input
                            type="text"
                            className="input-with-radio"
                            name="amount"
                            placeholder="Amount"
                            onChange={handleChange}
                          />
                        ) : (
                          <input
                            type="text"
                            className="input-with-radio"
                            name="amount"
                            placeholder="Amount"
                            onChange={handleChange}
                            disabled
                          />
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Team Size</label>
                      <input
                        type="text"
                        className="form-control form-control-user"
                        placeholder="Team size"
                        name="team_size"
                        onChange={handleChange}
                        value={experienceSchedule.team_size}
                      />
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Important Note</label>
                          <textarea
                            className="form-control"
                            name="important_note"
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            margin="normal"
                            name="start_date"
                            id="START DATE"
                            label="START DATE"
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-sm-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            margin="normal"
                            name="end_date"
                            id="END DATE"
                            label="END DATE"
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Experience Duration</label>
                      <select
                        className="form-control"
                        name="repeat_weeks"
                        multiple
                        // value={experienceData.repeat_weeks}
                        onChange={handleChange}
                      >
                        <option value="1">Monday</option>
                        <option value="2">Tuesday</option>
                        <option value="3">Wednesday</option>
                        <option value="4">Thursday</option>
                        <option value="5">Friday</option>
                        <option value="6">Saturday</option>
                        <option value="7">Sunday</option>
                      </select>
                    </div>

                    
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            margin="normal"
                            name="repeat_endby_date"
                            id="Repeat will end by"
                            label="Repeat will end by"
                            value={repeatEndByDate}
                            onChange={handleRepeatEndBydateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                    <div className="form-group row ml-3">
                      <div className="col-sm-6">
                        <input
                          type="radio"
                          class="form-check-input"
                          name="never_ends"
                          value={true}
                          onChange={handleChange}
                        />
                        <label>Never ends</label>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            name="start_time"
                            label="Start Time"
                            value={selectedStartTime}
                            onChange={handleStartTimeChange}
                            KeyboardButtonProps={{
                              "aria-label": "change time",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-sm-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            name="end_time"
                            label="End Time"
                            value={selectedEndTime}
                            onChange={handleEndTimeChange}
                            KeyboardButtonProps={{
                              "aria-label": "change time",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Team Size</label>
                      <input
                        type="text"
                        className="form-control form-control-user"
                        placeholder="Team size"
                        name="team_size"
                        onChange={handleChange}
                        value={experienceData.team_size}
                      />
                    </div>
                    <div className="form-group row ml-3">
                      <div className="col-sm-6">
                        <input
                          type="radio"
                          class="form-check-input"
                          name="charge_type"
                          value="flat_fee"
                          onChange={handleChange}
                        />
                        <label>Flat Fee</label>
                        {experienceData.charge_type == "flat_fee" ? (
                          <input
                            type="text"
                            className="input-with-radio"
                            name="amount"
                            placeholder="Amount"
                            onChange={handleChange}
                          />
                        ) : (
                          <input
                            type="text"
                            className="input-with-radio"
                            name="amount"
                            placeholder="Amount"
                            onChange={handleChange}
                            disabled
                          />
                        )}
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="radio"
                          class="form-check-input"
                          name="charge_type"
                          value="per_person"
                          onChange={handleChange}
                        />
                        <label>Per Person</label>
                        {experienceData.charge_type == "per_person" ? (
                          <input
                            type="text"
                            className="input-with-radio"
                            name="amount"
                            placeholder="Amount"
                            onChange={handleChange}
                          />
                        ) : (
                          <input
                            type="text"
                            className="input-with-radio"
                            name="amount"
                            placeholder="Amount"
                            onChange={handleChange}
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Important Note</label>
                          <textarea
                            className="form-control"
                            name="important_note"
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
          <Button variant="contained" onClick={hidePopUp}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
