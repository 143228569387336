export const eventConstants = {
    EVENT_LIST_REQUEST: 'EVENT_LIST_REQUEST',
    EVENT_LIST_SUCCESS: 'EVENT_LIST_SUCCESS',
    EVENT_LIST_ERROR: 'EVENT_LIST_ERROR',
    EVENT_SEARCH_REQUEST: 'EVENT_SEARCH_REQUEST',

    EVENT_ADDED_REQUEST: 'EVENT_ADDED_REQUEST',
    EVENT_ADDED_SUCCESS: 'EVENT_ADDED_SUCCESS',
    EVENT_ADDED_FAILURE: 'EVENT_ADDED_FAILURE',
};
