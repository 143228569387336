export const notificationConstants = {
    
    GET_ALL_NOTIFICATIONS_REQUEST: 'GET_ALL_NOTIFICATIONS_REQUEST',
    GET_ALL_NOTIFICATIONS_SUCCESS: 'GET_ALL_NOTIFICATIONS_SUCCESS',
    GET_ALL_NOTIFICATIONS_FAILURE: 'GET_ALL_NOTIFICATIONS_FAILURE',

    GET_NOTIFICATIONS_SORT_REQUEST: 'GET_NOTIFICATIONS_SORT_REQUEST',
    GET_NOTIFICATIONS_SORT_SUCCESS: 'GET_NOTIFICATIONS_SORT_SUCCESS',
    GET_NOTIFICATIONS_SORT_FAILURE: 'GET_NOTIFICATIONS_SORT_FAILURE'

};
