export const enviromentalConstants = {
  GET_ENVIRONMENTAL_CAUSE_REQUEST: 'GET_ENVIRONMENTAL_CAUSE_REQUEST',
  GET_ENVIRONMENTAL_CAUSE_SUCCESS: 'GET_ENVIRONMENTAL_CAUSE_SUCCESS',
  GET_ENVIRONMENTAL_CAUSE_FAILURE: 'GET_ENVIRONMENTAL_CAUSE_FAILURE',

  GET_ENVIRONMENTAL_CAUSE_BY_ID_REQUEST: 'GET_ENVIRONMENTAL_CAUSE_BY_ID_REQUEST',
  GET_ENVIRONMENTAL_CAUSE_BY_ID_SUCCESS: 'GET_ENVIRONMENTAL_CAUSE_BY_ID_SUCCESS',
  GET_ENVIRONMENTAL_CAUSE_BY_ID_FAILURE: 'GET_ENVIRONMENTAL_CAUSE_BY_ID_FAILURE',

  ADD_ENVIRONMENTAL_CAUSE_REQUEST: 'ADD_ENVIRONMENTAL_CAUSE_REQUEST',
  ADD_ENVIRONMENTAL_CAUSE_SUCCESS: 'ADD_ENVIRONMENTAL_CAUSE_SUCCESS',
  ADD_ENVIRONMENTAL_CAUSE_FAILURE: 'ADD_ENVIRONMENTAL_CAUSE_FAILURE',

  UPDATE_ENVIRONMENTAL_CAUSE_REQUEST: 'UPDATE_ENVIRONMENTAL_CAUSE_REQUEST',
  UPDATE_ENVIRONMENTAL_CAUSE_SUCCESS: 'UPDATE_ENVIRONMENTAL_CAUSE_SUCCESS',
  UPDATE_ENVIRONMENTAL_CAUSE_FAILURE: 'UPDATE_ENVIRONMENTAL_CAUSE_FAILURE',

  DELETE_ENVIRONMENTAL_CAUSE_REQUEST: 'DELETE_ENVIRONMENTAL_CAUSE_REQUEST',
  DELETE_ENVIRONMENTAL_CAUSE_SUCCESS: 'DELETE_ENVIRONMENTAL_CAUSE_SUCCESS',
  DELETE_ENVIRONMENTAL_CAUSE_FAILURE: 'DELETE_ENVIRONMENTAL_CAUSE_FAILURE',
}