import React, { useState, useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import {
  providerActions,
  locationActions,
  experienceActions,
  environmentalCauseAction,
  countryActions,
  companyActions,
} from '../../_actions';
import { activityTypeActions } from '../../_actions';
import { apiConstants } from '../../_constants/api.constants';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import Select from 'react-select';
import { Editor } from '@tinymce/tinymce-react';
// import { history } from '../../_helpers';

export default function AddExperience({ displayPopUp, hidePopUp }) {
  const [experienceData, setExperienceData] = useState({});
  const [videoUrl, setVideoUrl] = useState({ video_url: '' });
  const [selectProvider, setSelectProvider] = useState('');
  const [selectLocation, setSelectLocation] = useState('');
  const [imagesArr, setImagesArr] = useState([]);
  const [selectedActivityTypeData, setSelectedActivityTypeData] = useState([]);
  const [isDraft, setIsDraft] = useState(0);
  const [tempDescription, setDescription] = useState('');
  const [selectDuration, setSelectDuration] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(
    new Date('2014-08-18T21:11:54'),
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    new Date('2014-08-18T21:11:54'),
  );
  const [experienceSchedule, setExperienceSchedule] = useState([
    {
      day: 1,
      start_time: '',
      end_time: '',
    },
  ]);

  const [indexValue, setIndexValue] = useState(1);
  const [highlights, setHighlights] = useState({});
  const [locationsData, setLocationData] = useState([]);
  const [activityTypeData, setActivityTypeData] = useState([]);
  const activityTypeSelector = useSelector(
    (state) => state.getActivityTypeList,
  );
  const provider = useSelector((state) => state.getProvider.allProvider);
  const locations = useSelector((state) => state.getLocationById);
  const [selectedEnvironmental, setSelectedEnvironmental] = useState([]);
  const [options, setOption] = useState([]);
  const dispatch = useDispatch();
  const environmental_cause = useSelector(
    (state) => state.getEnviromentalCause.allEnviromentalCause,
  );
  const [countryData, setCountryData] = useState([]);
  const [selectCountry, setSelectCountry] = useState('');
  const countries = useSelector((state) => state.getCountryList);

  const [orgData, setOrgData] = useState([]);
  const [selectOrg, setSelectOrg] = useState('');
  const orgs = useSelector((state) => state.getCompany);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setExperienceData({ ...experienceData, [name]: value });
  };
  const handleVideoUrlChange = (e) => {
    const { value } = e.target;
    setVideoUrl({ video_url: value });
  };

  // const options = [
  //   'Habitat Restoration',
  //   'Healthy Biodiversity',
  //   'Empowering Farmers',
  //   'Combatting Species Extinction',
  //   'Environmental Sustainability',
  //   'Indigenous Communities',
  // ];

  useEffect(() => {
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER));
    dispatch(
      activityTypeActions.getActivityTypeList(
        apiConstants.GET_ALL_ACTIVITY_TYPES_COMMON,
      ),
    );
    dispatch(
      environmentalCauseAction.getEnvironmentalCause(
        apiConstants.GET_ENVIRONMENTAL_CAUSE,
        {
          is_deleted: 0,
        },
      ),
    );
    dispatch(countryActions?.getCountryList(apiConstants.GET_ALL_COUNTRIES));
    dispatch(
      companyActions.getCompany(apiConstants.GET_ORGANISATION, {
        is_deleted: 0,
      }),
    );
  }, []);

  useEffect(() => {
    if (locations && locations.getLocationById) {
      setLocationData(locations.getLocationById.data);
    }
  }, [locations]);

  useEffect(() => {
    if (countries?.getCountryList) {
      setCountryData(countries.getCountryList.country);
    }
  }, [countries]);

  useEffect(() => {
    if (orgs?.getCompany) {
      setOrgData(orgs.getCompany.data?.organisation);
    }
  }, [orgs]);

  useEffect(() => {
    if (activityTypeSelector?.getActivityTypeList?.activity_type) {
      const data = activityTypeSelector.getActivityTypeList.activity_type;
      const updatedActivityType = data.map((val) => {
        return {
          value: val.id,
          label: val.activity_type,
        };
      });
      setActivityTypeData(updatedActivityType);
    }
  }, [activityTypeSelector]);

  useEffect(() => {
    if (environmental_cause) {
      setOption(environmental_cause);
      const arr = environmental_cause.map((data) => {
        return {
          value: data.id,
          label: data.cause_name,
        };
      });
      setOption(arr);
    }
  }, [environmental_cause]);

  const handleStartDateChange = (date, index) => {
    const values = [...experienceSchedule];
    values[index]['start_time'] = date;
    setSelectedStartDate(date);
    setExperienceSchedule(values);
  };

  const handleEndDateChange = (date, index) => {
    const values = [...experienceSchedule];
    values[index]['end_time'] = date;
    setSelectedEndDate(date);
    setExperienceSchedule(values);
  };

  const handleSelectDuration = (e) => {
    setSelectDuration(e.target.value);
  };

  const handleSelectProvider = (e) => {
    setSelectProvider(e.target.value);
    let obj = {
      provider_id: e.target.value,
    };
    dispatch(
      locationActions.getLocationListById(
        apiConstants.GET_ALL_LOCATIONS_By_Id,
        obj,
      ),
    );
  };

  const handleSelectAddress = (e) => {
    setSelectLocation(e.target.value);
  };

  const handleSelectCountry = (e) => {
    setSelectCountry(e.target.value);
  };

  const handleSelectOrg = (e) => {
    setSelectOrg(e.target.value);
  };

  const handleHighlights = (e) => {
    const { name, value } = e.target;
    setHighlights({ ...highlights, [name]: value });
  };

  const handleChangeImages = (e) => {
    if (e.target.files.length <= 10) {
      setImagesArr(e.target.files);
    } else {
      e.preventDefault();
      e.target.value = null;
      alert(`Cannot upload files more than 10`);
    }
  };

  const handleMultiSelectChange = (selectedOption) => {
    setSelectedEnvironmental(selectedOption);
  };
  // handleChange = selectedOption => {
  //   this.setState({ selectedOption });
  //   console.log(`Option selected:`, selectedOption);
  // };

  const handleAddMore = () => {
    let i = indexValue + 1;
    setIndexValue(i);
    const values = [...experienceSchedule];
    values.push({
      day: i,
      start_time: '',
      end_time: '',
    });
    setExperienceSchedule(values);
  };

  const handleChangeInputTextArea = (content) => {
    setDescription(content);
  };

  const handleSubmit = (e, val) => {
    var youtube_Url = '';
    var youtube_main_url = videoUrl.video_url;

    if (youtube_main_url.includes('=')) {
      youtube_Url =
        'https://www.youtube.com/embed/' + youtube_main_url.split('=')[1];
    } else if (youtube_main_url.includes('/')) {
      youtube_Url =
        'https://www.youtube.com/embed/' + youtube_main_url.split('/')[4];
    } else if (youtube_main_url) {
      youtube_Url = 'https://www.youtube.com/embed/' + youtube_main_url;
    } else {
      youtube_Url = '';
    }

    var per_person_cost = 0;
    if (experienceData.charge_type === 'flat_fee') {
      per_person_cost =
        parseInt(experienceData.amount) / parseInt(experienceData.team_size);
    } else {
      per_person_cost = parseInt(experienceData.amount);
    }
    let formData = new FormData();
    formData.append(
      'created_by_admin',
      parseInt(JSON.parse(sessionStorage.getItem('user')).data.id),
    );
    formData.append('highlights', JSON.stringify(highlights));
    formData.append('provider_id', parseInt(selectProvider));
    formData.append('location_id', parseInt(selectLocation));
    formData.append('experience_duration', 1);
    formData.append('detail_description', tempDescription);
    formData.append('days_time', JSON.stringify(experienceSchedule));
    formData.append('per_person_cost', per_person_cost);
    formData.append('video_url', youtube_Url);
    if (selectedEnvironmental?.length > 0) {
      formData.append('env_causes', JSON.stringify(selectedEnvironmental));
    }
    // formData.append('tag_line', 'What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry')
    if (val === 0) {
      formData.append('is_draft', 1);
      formData.append('is_publish', 0);
    } else if (val === 1) {
      formData.append('is_draft', 0);
      formData.append('is_publish', 1);
    }
    for (const key in experienceData) {
      formData.append(key, experienceData[key]);
    }
    for (let i = 0; i < imagesArr.length; i++) {
      formData.append('files', imagesArr[i]);
    }

    if (selectedActivityTypeData && selectedActivityTypeData.length > 0) {
      formData.append(
        'activity_types',
        JSON.stringify(selectedActivityTypeData),
      );
    }
    formData.append('country_id', parseInt(selectCountry));
    formData.append('org_id', parseInt(selectOrg));
    dispatch(
      experienceActions.addExperience(apiConstants.ADD_EXPERIENCE, formData),
    );
    hidePopUp();
    // history.push('/experience')
  };
  return (
    <div>
      <Dialog
        open={displayPopUp}
        aria-labelledby='add-products'
        aria-describedby='save-products-description'
        fullWidth={true}
      >
        <DialogTitle>Add Experience</DialogTitle>
        <DialogContent>
          <div className='row'>
            <div className='col-sm-12 formBorder'>
              <div className='p-5'>
                <div className='form-group row'>
                  <div className='col-sm-6'>
                    <label>Provider Name</label>
                    <select
                      className='form-control'
                      name='type'
                      onChange={handleSelectProvider}
                    >
                      <option>Select Provider name</option>
                      {provider !== undefined && provider.length > 0
                        ? provider.map((provider, index) => {
                            return (
                              <option value={provider.id}>
                                {provider.first_name} {provider.last_name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                  <div className='col-sm-6 mb-3 mb-sm-0'>
                    <label>Country</label>
                    <select
                      className='form-control'
                      name='country'
                      onChange={handleSelectCountry}
                    >
                      <option>Select Country</option>
                      {countryData?.length > 0
                        ? countryData.map((country, index) => {
                            return (
                              <option value={country.id}>{country.name}</option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-sm-6'>
                    <label>Location address</label>
                    <select
                      className='form-control'
                      name='type'
                      onChange={handleSelectAddress}
                    >
                      <option>Select Location</option>
                      {locationsData !== undefined && locationsData.length > 0
                        ? locationsData.map((locationsData, index) => {
                            return (
                              <option value={locationsData.id}>
                                {locationsData.location_address}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>

                  <div className='col-sm-6'>
                    <label>Team Size</label>
                    <input
                      type='number'
                      className='form-control form-control-user'
                      name='team_size'
                      min='0'
                      placeholder='Team Size'
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <label>Organisation</label>
                  <select
                    className='form-control'
                    name='organisation'
                    onChange={handleSelectOrg}
                  >
                    <option>Select Organisation</option>
                    {orgData?.length > 0
                      ? orgData.map((org, index) => {
                          return (
                            <option value={org.id}>{org.company_name}</option>
                          );
                        })
                      : null}
                  </select>
                </div>
                <div className='form-group'>
                  <label>Experience Name</label>
                  <input
                    type='text'
                    className='form-control form-control-user'
                    name='experience_name'
                    placeholder='Experience Name'
                    onChange={handleChange}
                  />
                </div>
                <div className='form-group'>
                  <label>Experience Duration</label>
                  <select
                    className='form-control'
                    name='type'
                    onChange={handleSelectDuration}
                  >
                    <option value='1'>Half Day</option>
                    <option value='2'>One Day</option>
                    <option value='3'>Multiple days</option>
                  </select>
                </div>
                <div className='form-group'>
                  <label>Activity Types</label>
                  <Select
                    name='experience_activity_types'
                    isMulti
                    options={activityTypeData}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onChange={(e, options) => {
                      setSelectedActivityTypeData(e);
                    }}
                  />
                </div>

                {experienceSchedule.map((experiencedata, index) => {
                  return (
                    <div key={index}>
                      <div className='form-group row'>
                        <div className='col-sm-2'>
                          <label>Day {index + 1}</label>
                        </div>
                        <div className='col-sm-5'>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent='space-around'>
                              <TimePicker
                                margin='normal'
                                id='time-picker'
                                name='start_time'
                                label='Start Time'
                                value={selectedStartDate}
                                defaultValue={selectedStartDate}
                                onChange={(e) =>
                                  handleStartDateChange(e, index)
                                }
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className='col-sm-5'>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent='space-around'>
                              <TimePicker
                                margin='normal'
                                id='time-picker'
                                label='End picker'
                                value={selectedEndDate}
                                defaultValue={selectedEndDate}
                                onChange={(e) => handleEndDateChange(e, index)}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {selectDuration == 3 ? (
                  <div className='col-lg-12'>
                    <div className='form-group'>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={handleAddMore}
                      >
                        Add More
                      </Button>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className='form-group'>
                  <input
                    type='radio'
                    class='form-check-input'
                    name='charge_type'
                    value='flat_fee'
                    onChange={handleChange}
                  />
                  <label>Flat fee</label>
                  {experienceData.charge_type == 'flat_fee' ? (
                    <input
                      type='text'
                      className='input-with-radio'
                      name='amount'
                      placeholder='Amount'
                      onChange={handleChange}
                    />
                  ) : (
                    <input
                      type='text'
                      className='input-with-radio'
                      name='amount'
                      placeholder='Amount'
                      onChange={handleChange}
                      disabled
                    />
                  )}

                  <input
                    type='radio'
                    class='form-check-input'
                    name='charge_type'
                    value='per_person'
                    onChange={handleChange}
                  />
                  <label>Per Person</label>
                  {experienceData.charge_type == 'per_person' ? (
                    <input
                      type='text'
                      className='input-with-radio'
                      name='amount'
                      placeholder='Amount'
                      onChange={handleChange}
                    />
                  ) : (
                    <input
                      type='text'
                      className='input-with-radio'
                      name='amount'
                      placeholder='Amount'
                      onChange={handleChange}
                      disabled
                    />
                  )}
                </div>

                <div className='form-group'>
                  <label>Highlights</label>
                  <input
                    type='text'
                    className='form-control form-control-user'
                    placeholder='Highlights 1'
                    value={highlights.h1}
                    name='h1'
                    onChange={handleHighlights}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control form-control-user'
                    placeholder='Highlights 2'
                    value={highlights.h2}
                    name='h2'
                    onChange={handleHighlights}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control form-control-user'
                    placeholder='Highlights 3'
                    value={highlights.h3}
                    name='h3'
                    onChange={handleHighlights}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control form-control-user'
                    placeholder='Highlights 4'
                    value={highlights.h4}
                    name='h4'
                    onChange={handleHighlights}
                  />
                </div>
                <div className='form-group row'>
                  <div className='col-sm-6'>
                    <label>Environmental Causes</label>
                    {/* <input type="text" className="form-control form-control-user" name="environmental_causes"
                      placeholder="Environmental Causes" onChange={handleChange} /> */}
                    <Select
                      name='environmental_causes'
                      value={selectedEnvironmental}
                      isMulti
                      // onChange={handleMultiSelectChange}
                      onChange={(e, options) => {
                        setSelectedEnvironmental(e);
                      }}
                      options={options}
                      className='basic-multi-select'
                      classNamePrefix='select'
                    />
                  </div>
                  <div className='col-sm-6 mb-3 mb-sm-0'>
                    <label>Image</label>
                    <input
                      type='file'
                      onChange={handleChangeImages}
                      className='form-control'
                      name='images'
                      accept='.png,.jpg,.jpeg'
                      multiple
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-sm-6'>
                    <label>Video Title</label>
                    <input
                      type='text'
                      className='form-control form-control-user'
                      name='video_title'
                      placeholder='Video Title'
                      onChange={handleChange}
                    />
                  </div>
                  <div className='col-sm-6'>
                    <label>Video Url</label>
                    <input
                      type='text'
                      className='form-control form-control-user'
                      name='video_url'
                      placeholder='Video Url'
                      onChange={handleVideoUrlChange}
                    />
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='form-group'>
                    <label>Detail Description</label>
                    <Editor
                      apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                      name='detail_description'
                      value={tempDescription}
                      onEditorChange={handleChangeInputTextArea}
                      init={{
                        selector: 'textarea',
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'table contextmenu paste',
                        ],
                        toolbar:
                          'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat',
                      }}
                    />
                    {/* <textarea className="form-control" name="detail_description" onChange={handleChange}></textarea> */}
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='form-group'>
                    <label>Further Information</label>
                    <textarea
                      className='form-control'
                      name='further_information'
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='form-group'>
                    <label>Whats Include</label>
                    <textarea
                      className='form-control'
                      name='whats_include'
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='form-group'>
                    <label>Tag Line</label>
                    <textarea
                      className='form-control'
                      name='tag_line'
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => handleSubmit(e, 0)}
          >
            Is Draft
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => handleSubmit(e, 1)}
          >
            Publish
          </Button>
          <Button variant='contained' onClick={hidePopUp}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
