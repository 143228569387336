import { locationConstants } from '../_constants';
import * as commonAction from './commonAction';
import {  commonService } from '../_services';
import {history} from '../_helpers';
import { apiConstants } from '../_constants/api.constants';

export const locationActions = {
    getLocationList,
    addLocation,
    getLocationListById,
    updateLocation,
    sortLocation
};


//-- Location list
function getLocationListById(apiName, id) {

    return dispatch => {
        dispatch(commonAction.request(locationConstants.GET_ALL_LOCATION_BY_ID_REQUEST, id));
        commonService.withOutTokenAndBaseUrl(apiName, id)
            .then(
                locationData => {
                    dispatch(commonAction.success(locationConstants.GET_ALL_LOCATION_BY_ID_SUCCESS, locationData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(locationConstants.GET_ALL_LOCATION_BY_ID_FAILURE, error.response.data.error));
                  }
            );
    };
}

//-- Location list
function getLocationList(apiName, obj) {

    return dispatch => {
        dispatch(commonAction.request(locationConstants.GET_ALL_LOCATION_REQUEST));
        commonService.withOutTokenAndBaseUrl(apiName, obj)
            .then(
                locationData => {
                    dispatch(commonAction.success(locationConstants.GET_ALL_LOCATION_SUCCESS, locationData.data));
                  },
                  error => {
                    dispatch(commonAction.failure(locationConstants.GET_ALL_LOCATION_FAILURE, error.response.data.error));
                  }
            );
    };
}

function sortLocation(apiName, obj) {

  return dispatch => {
      dispatch(commonAction.request(locationConstants.GET_ALL_LOCATION_REQUEST));
      commonService.withTokenAndBashUrl(apiName, obj)
          .then(
              locationData => {
                  dispatch(commonAction.success(locationConstants.GET_ALL_LOCATION_SUCCESS, locationData.data));
                },
                error => {
                  dispatch(commonAction.failure(locationConstants.GET_ALL_LOCATION_FAILURE, error.response.data.error));
                }
          );
  };
}

//-- Location ad
function addLocation(apiName, data) {
   let obj = {
    limit: 10,
    offset: 0
  }
    return dispatch => {
        dispatch(commonAction.request(locationConstants.ADD_LOCATION_REQUEST, data));
        commonService.withTokenPost(apiName, data)
            .then(
                locationData => {
                    dispatch(commonAction.success(locationConstants.ADD_LOCATION_SUCCESS, locationData.data));
                    dispatch(getLocationList(apiConstants.GET_ALL_LOCATIONS, obj));
                    history.push('/location')

                },
                  error => {
                    dispatch(commonAction.failure(locationConstants.ADD_LOCATION_FAILURE, error.response.data.error));
                  }
            );
    };
}

//-- Location update
function updateLocation(apiName, data) {
    let obj = {
        limit: 10,
        offset: 0
      }
     return dispatch => {
         dispatch(commonAction.request(locationConstants.UPDATE_LOCATION_REQUEST, data));
         commonService.withOutTokenAndBaseUrl(apiName, data)
             .then(
                 locationData => {
                     dispatch(commonAction.success(locationConstants.UPDATE_LOCATION_SUCCESS, locationData.data));
                     dispatch(getLocationList(apiConstants.GET_ALL_LOCATIONS, obj));
                     history.push('/location')
                   },
                   error => {
                     dispatch(commonAction.failure(locationConstants.UPDATE_LOCATION_FAILURE, error.response.data.error));
                   }
             );
     };
 }
 
