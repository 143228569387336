import React, { useState, useEffect } from 'react';
import TopBar from '../Common/TopBar/TopBar';
import SideBar from '../Common/SideBar/SideBar';
import Footer from '../Common/Footer/Footer';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import {
  providerActions,
  locationActions,
  experienceActions,
  environmentalCauseAction,
  experienceActivityTypeActions,
  experienceEnvCauseActions,
  countryActions,
  companyActions,
} from '../../_actions';
import { apiConstants } from '../../_constants/api.constants';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import Select from 'react-select';
import { Editor } from '@tinymce/tinymce-react';
// import { history } from '../../_helpers';
import { activityTypeActions } from '../../_actions';
import { history } from '../../_helpers';
import { useHistory } from 'react-router-dom';

export default function UpdateExperience(props) {
  const history = useHistory();

  const [experienceData, setExperienceData] = useState({});
  const [filedsData, setFiledsData] = useState({});
  const [videoUrl, setVideoUrl] = useState({ video_url: '' });
  const [selectProvider, setSelectProvider] = useState('');
  const [options, setOption] = useState([]);
  const [selectLocation, setSelectLocation] = useState('');
  const [imagesArr, setImagesArr] = useState([]);
  const [tempimagesArr, setTempImagesArr] = useState([]);
  const [selectDuration, setSelectDuration] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [tempDescription, setDescription] = useState('');
  const [experienceSchedule, setExperienceSchedule] = useState([{}]);
  const [chargeTypeFlatFee, setChargeTypeFlatFee] = useState(false);
  const [chargeTypePerPerson, setChargeTypePerPerson] = useState(false);
  const [experienceId, setExperienceId] = useState(0);
  const [indexValue, setIndexValue] = useState(1);
  const [highlights, setHighlights] = useState({});
  const [locationsData, setLocationData] = useState([]);
  const provider = useSelector((state) => state.getProvider.allProvider);
  const locations = useSelector((state) => state.getLocationById);
  const experience = useSelector((state) => state.getExperienceById);
  const [selectedEnvironmental, setSelectedEnvironmental] = useState([]);
  const [experienceActivityTypeData, setExperienceActivityTypeData] = useState(
    [],
  );
  const [selectedActivityTypeData, setSelectedActivityTypeData] = useState([]);
  const [activityTypeData, setActivityTypeData] = useState([]);
  const experienceActivityTypeSelector = useSelector(
    (state) => state.getExperienceActivityTypeById,
  );
  const experienceEnvCauseSelector = useSelector(
    (state) => state.getExperienceEnvCauseById,
  );

  const activityTypeSelector = useSelector(
    (state) => state.getActivityTypeList,
  );
  const dispatch = useDispatch();
  const environmental_cause = useSelector(
    (state) => state.getEnviromentalCause.allEnviromentalCause,
  );
  const [countryData, setCountryData] = useState([]);
  const [selectCountry, setSelectCountry] = useState('');
  const [defaultCountryData, setDefaultCountryData] = useState();
  const countries = useSelector((state) => state.getCountryList);

  const [orgData, setOrgData] = useState([]);
  const [defaultOrgData, setDefaultOrgData] = useState();
  const [selectOrg, setSelectOrg] = useState('');
  const orgs = useSelector((state) => state.getCompany);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value == 'flat_fee') {
      setChargeTypeFlatFee(true);
      setChargeTypePerPerson(false);
    } else if (value == 'per_person') {
      setChargeTypePerPerson(true);
      setChargeTypeFlatFee(false);
    }
    setFiledsData({ ...filedsData, [name]: value });
  };
  const handleVideoUrlChange = (e) => {
    const { value } = e.target;
    setVideoUrl({ video_url: value });
  };
  useEffect(() => {
    let obj = {
      id: props.match.params.id,
    };
    dispatch(
      experienceActions.getExperienceById(
        apiConstants.GET_EXPERIENCE_BY_ID,
        obj,
      ),
    );
    dispatch(
      activityTypeActions.getActivityTypeList(
        apiConstants.GET_ALL_ACTIVITY_TYPES_COMMON,
      ),
    );
    dispatch(
      environmentalCauseAction.getEnvironmentalCause(
        apiConstants.GET_ENVIRONMENTAL_CAUSE,
        {
          ...obj,
          is_deleted: 0,
        },
      ),
    );
    dispatch(
      experienceEnvCauseActions.getExperienceEnvCauseById(
        apiConstants.GET_ALL_EXPERIENCE_ENV_CAUSES_BY_ID,
        {
          ...obj,
        },
      ),
    );
    dispatch(
      experienceActivityTypeActions.getExperienceActivityTypeById(
        apiConstants.GET_ALL_EXPERIENCE_ACTIVITY_TYPES_BY_ID,
        obj,
      ),
    );
    dispatch(countryActions.getCountryList(apiConstants.GET_ALL_COUNTRIES));
    dispatch(
      companyActions.getCompany(apiConstants.GET_ORGANISATION, {
        is_deleted: 0,
      }),
    );
  }, []);

  useEffect(() => {
    if (activityTypeSelector?.getActivityTypeList?.activity_type) {
      const data = activityTypeSelector.getActivityTypeList.activity_type;
      const updatedActivityType = data.map((val) => {
        return {
          value: val.id,
          label: val.activity_type,
        };
      });
      setActivityTypeData(updatedActivityType);
    }
  }, [activityTypeSelector]);

  useEffect(() => {
    if (environmental_cause) {
      //   setOption(environmental_cause)
      let arr = [];
      environmental_cause.map((data) => {
        let temObj = {
          value: data.id,
          label: data.cause_name,
        };
        arr.push(temObj);
      });
      setOption(arr);
    }
  }, [environmental_cause]);

  // fetching
  useEffect(() => {
    if (
      experienceActivityTypeSelector?.getExperienceActivityTypeById
        ?.experience_activity_type
    ) {
      const data =
        experienceActivityTypeSelector.getExperienceActivityTypeById
          .experience_activity_type;
      const updatedActivityType = data.map((val) => {
        return {
          value: val.ActivityType?.id,
          label: val.ActivityType?.activity_type,
        };
      });
      setExperienceActivityTypeData(updatedActivityType);
    }
  }, [experienceActivityTypeSelector]);

  useEffect(() => {
    if (
      experienceEnvCauseSelector?.getExperienceEnvCauseById
        ?.experience_env_cause
    ) {
      const data =
        experienceEnvCauseSelector.getExperienceEnvCauseById
          .experience_env_cause;
      const updatedEnvCause = data.map((val) => {
        return {
          value: val.EnvironmentalCause?.id,
          label: val.EnvironmentalCause?.cause_name,
        };
      });
      setSelectedEnvironmental(updatedEnvCause);
    }
  }, [experienceEnvCauseSelector]);

  useEffect(() => {
    dispatch(providerActions.getProvider(apiConstants.GET_PROVIDER));
  }, []);

  useEffect(() => {
    if (locations && locations.getLocationById) {
      setLocationData(locations.getLocationById.data);
    }
  }, [locations]);

  useEffect(() => {
    if (experience && experience.getExperienceById) {
      setExperienceData(experience.getExperienceById.data);
      setHighlights(
        JSON.parse(experience.getExperienceById.data.experience.highlights),
      );
      setExperienceSchedule(
        JSON.parse(experience.getExperienceById.data.experience.days_time),
      );
      setSelectProvider(
        experience.getExperienceById.data.experience.provider_id,
      );
      setSelectLocation(
        experience.getExperienceById.data.experience.location_id,
      );
      setSelectedEnvironmental(
        experience.getExperienceById.data.experience.environmental_causes,
      );
      // setSelectedStartDate(tempScheduleExperience.start_time)
      setExperienceId(
        experience.getExperienceById.data.experience.experience_id,
      );
      setSelectDuration(
        experience.getExperienceById.data.experience.experience_duration,
      );
      setDescription(
        experience.getExperienceById.data.experience.detail_description,
      );
      if (
        experience.getExperienceById.data.experience.charge_type == 'flat_fee'
      ) {
        setChargeTypeFlatFee(true);
      } else if (
        experience.getExperienceById.data.experience.charge_type == 'per_person'
      ) {
        setChargeTypePerPerson(true);
      }

      let temp = experience.getExperienceById.data.experience
        .environmental_causes
        ? JSON.parse(
            experience.getExperienceById.data.experience.environmental_causes,
          )
        : [];
      let temArr = [];
      temp.forEach((i) => {
        let obj = {
          value: i?.value,
          label: i?.label,
        };
        temArr.push(obj);
      });
      setSelectedEnvironmental(temArr);

      let tempImageArr = experience.getExperienceById.data.experience.images
        ? JSON.parse(experience.getExperienceById.data.experience.images)
        : [];
      let temImageArr = [];
      tempImageArr.forEach((i) => {
        temImageArr.push(i);
      });
      setTempImagesArr(temImageArr);
      dispatch(
        locationActions.getLocationListById(
          apiConstants.GET_ALL_LOCATIONS_By_Id,
          {
            provider_id:
              experience.getExperienceById.data.experience.provider_id,
          },
        ),
      );
    }
  }, [experience]);

  useEffect(() => {
    if (countries?.getCountryList) {
      setCountryData(countries.getCountryList.country);
    }
  }, [countries]);

  useEffect(() => {
    // if (useSelector.experience && useSelector.country) !== null,
    // filter on country and select a default value using country_id in experience

    if (experience?.getExperienceById?.data && countries?.getCountryList) {
      const countryDataFiltered = countries.getCountryList.country?.filter(
        (val) =>
          val.id ===
          experience?.getExperienceById?.data?.experience?.country_id,
      );

      setDefaultCountryData(countryDataFiltered[0] || {});
    }
  }, [countries, experience]);

  useEffect(() => {
    if (orgs?.getCompany) {
      setOrgData(orgs.getCompany.data?.organisation);
    }
  }, [orgs]);

  useEffect(() => {
    // if (useSelector.experience && useSelector.org) !== null,
    // filter on org and select a default value using org_id in experience
    if (experience?.getExperienceById?.data && orgs?.getCompany?.data) {
      const orgDataFiltered = orgs.getCompany.data?.organisation?.filter(
        (val) =>
          val.id === experience?.getExperienceById?.data?.experience?.org_id,
      );

      setDefaultOrgData(orgDataFiltered[0] || {});
    }
  }, [orgs, experience]);

  const handleStartDateChange = (date, index) => {
    const values = [...experienceSchedule];
    values[index]['start_time'] = date;
    setSelectedStartDate(date);
    setExperienceSchedule(values);
  };

  const handleEndDateChange = (date, index) => {
    const values = [...experienceSchedule];
    values[index]['end_time'] = date;
    setSelectedEndDate(date);
    setExperienceSchedule(values);
  };

  const handleSelectDuration = (e) => {
    setSelectDuration(e.target.value);
  };

  const handleSelectProvider = (e) => {
    setSelectProvider(e.target.value);
  };

  const handleSelectAddress = (e) => {
    setSelectLocation(e.target.value);
  };

  const handleHighlights = (e) => {
    const { name, value } = e.target;
    setHighlights({ ...highlights, [name]: value });
  };

  const handleChangeImages = (e) => {
    if (e.target.files.length <= 10) {
      setImagesArr(e.target.files);
    } else {
      e.preventDefault();
      e.target.value = null;
      alert(`Cannot upload files more than 10`);
    }
  };

  const handleMultiSelectChange = (e, selectedOption) => {
    setSelectedEnvironmental(e);
  };

  const handleMultiSelectActivityTypeChange = (e, options) => {
    setExperienceActivityTypeData(e);
  };

  const handleAddMore = () => {
    let i = indexValue + 1;
    setIndexValue(i);
    const values = [...experienceSchedule];
    values.push({
      day: i,
      start_time: '',
      end_time: '',
    });
    setExperienceSchedule(values);
  };

  const handleSelectCountry = (e) => {
    setSelectCountry(e.target.value);
  };

  const handleSelectOrg = (e) => {
    setSelectOrg(e.target.value);
  };

  const handleUpdate = (e, val) => {
    var per_person_cost = 0;
    var youtube_Url = '';
    var youtube_main_url = videoUrl.video_url;

    if (youtube_main_url.includes('=')) {
      youtube_Url =
        'https://www.youtube.com/embed/' + youtube_main_url.split('=')[1];
    } else if (youtube_main_url.includes('/')) {
      youtube_Url =
        'https://www.youtube.com/embed/' + youtube_main_url.split('/')[4];
    } else if (youtube_main_url) {
      youtube_Url = 'https://www.youtube.com/embed/' + youtube_main_url;
    } else {
      youtube_Url = experienceData.experience.video_url;
    }

    if (experienceData.experience.charge_type === 'flat_fee') {
      per_person_cost =
        parseInt(experienceData.experience.amount) /
        parseInt(experienceData.experience.team_size);
    } else {
      per_person_cost = parseInt(experienceData.experience.amount);
    }
    let formData = new FormData();
    formData.append('highlights', JSON.stringify(highlights));
    formData.append('experience_id', parseInt(props.match.params.id));
    // formData.append('experience_id', experienceId)
    formData.append('provider_id', parseInt(selectProvider));
    formData.append('location_id', parseInt(selectLocation));
    formData.append('experience_duration', selectDuration);
    formData.append('detail_description', tempDescription);
    formData.append('days_time', JSON.stringify(experienceSchedule));
    formData.append('env_causes', JSON.stringify(selectedEnvironmental));
    formData.append(
      'experience_activity_types',
      JSON.stringify(experienceActivityTypeData),
    );
    formData.append('per_person_cost', per_person_cost);
    formData.append('video_url', youtube_Url);
    formData.append(
      'experience_name',
      experienceData.experience.experience_name,
    );
    if (val == 0) {
      formData.append('is_draft', 1);
      formData.append('is_publish', 0);
    } else if (val == 1) {
      formData.append('is_draft', 0);
      formData.append('is_publish', 1);
    }
    for (const key in filedsData) {
      formData.append(key, filedsData[key]);
    }
    for (let i = 0; i < imagesArr.length; i++) {
      formData.append('files', imagesArr[i]);
    }

    // only update country and organisation if experience.crm is not Rezdy
    if (experienceData?.experience?.crm !== 'Rezdy') {
      if (selectCountry) formData.append('country_id', selectCountry);
      if (selectOrg) formData.append('org_id', selectOrg);
    }

    dispatch(
      experienceActions.editExperience(apiConstants.EDIT_EXPERIENCE, formData),
    );
    history.push('/experience');
  };

  const handleChangeInputTextArea = (content) => {
    setDescription(content);
  };

  return (
    <div id='wrapper'>
      <SideBar />
      <div id='content-wrapper' className='d-flex flex-column'>
        <div id='content'>
          <TopBar />
          <nav className='' aria-label='breadcrumb'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <a href='/dashboard'>Dashboard</a>
              </li>
              <li className='active breadcrumb-item' aria-current='page'>
                Experience
              </li>
            </ol>
          </nav>
          <div className='container-fluid'>
            <div className='d-sm-flex align-items-center justify-content-between mb-4'>
              <h1 className='h3 mb-2 text-gray-800'>Update Experience</h1>
            </div>
            <div className='card shadow mb-4'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <div className='row'>
                    <div className='col-sm-12 formBorder'>
                      <div className='p-5'>
                        <div className='form-group row'>
                          <div className='col-sm-6'>
                            <label>Provider Name</label>
                            <span
                              className='ml-2 text-primary'
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                window.open(
                                  `/provider_detail?id=${experienceData.experience.provider_id}`,
                                  '_blank',
                                );
                              }}
                            >
                              {' '}
                              See details
                            </span>
                            <br></br>
                            <select
                              className='form-control'
                              name='provider_id'
                              value={
                                experienceData && experienceData.experience
                                  ? experienceData.experience.provider_id
                                  : ''
                              }
                              onChange={handleSelectProvider}
                              disabled
                            >
                              <option>Select Provider name</option>
                              {provider !== undefined && provider.length > 0
                                ? provider.map((provider, index) => {
                                    return (
                                      <option value={provider.id}>
                                        {provider.first_name}{' '}
                                        {provider.last_name}
                                      </option>
                                    );
                                  })
                                : null}
                            </select>
                          </div>
                          <div className='col-sm-6 mb-3 mb-sm-0'>
                            <label>Country</label>
                            <select
                              className='form-control'
                              name='country'
                              onChange={handleSelectCountry}
                              disabled={
                                experienceData?.experience?.crm === 'Rezdy'
                              }
                            >
                              <option>Select Country</option>
                              {countryData?.length > 0
                                ? countryData.map((country, index) => {
                                    return (
                                      <option
                                        selected={
                                          country.id === defaultCountryData?.id
                                        }
                                        value={country.id}
                                      >
                                        {country.name}
                                      </option>
                                    );
                                  })
                                : null}
                            </select>
                          </div>
                        </div>
                        <div className='form-group row'>
                          <div className='col-sm-6'>
                            <label>Location address</label>
                            <select
                              className='form-control'
                              name='location_id'
                              value={
                                experienceData && experienceData.experience
                                  ? experienceData.experience.location_id
                                  : ''
                              }
                              onChange={handleSelectAddress}
                              disabled
                            >
                              <option>Select Location</option>
                              {locationsData !== undefined &&
                              locationsData.length > 0
                                ? locationsData.map((locationsData, index) => {
                                    return (
                                      <option value={locationsData.id}>
                                        {locationsData.location_address}
                                      </option>
                                    );
                                  })
                                : null}
                            </select>
                          </div>

                          <div className='col-sm-6'>
                            <label>Team Size</label>
                            <input
                              type='number'
                              className='form-control form-control-user'
                              name='team_size'
                              min='0'
                              placeholder='Team Size'
                              onChange={handleChange}
                              defaultValue={
                                experienceData && experienceData.experience
                                  ? experienceData.experience.team_size
                                  : ''
                              }
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label>Organisation</label>
                          <select
                            className='form-control'
                            name='organisation'
                            onChange={handleSelectOrg}
                            disabled={
                              experienceData?.experience?.crm === 'Rezdy'
                            }
                          >
                            <option value={''}>Select Organisation</option>
                            {orgData?.length > 0
                              ? orgData.map((org, index) => {
                                  return (
                                    <option
                                      selected={org.id === defaultOrgData?.id}
                                      value={org.id}
                                    >
                                      {org.company_name}
                                    </option>
                                  );
                                })
                              : null}
                          </select>
                        </div>
                        <div className='form-group'>
                          <label>Experience Name</label>
                          <span
                            className='ml-2 text-primary'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              window.open(
                                `/experience_detail?id=${experienceData.experience.id}`,
                                '_blank',
                              );
                            }}
                          >
                            {' '}
                            See details
                          </span>
                          <input
                            type='text'
                            className='form-control form-control-user'
                            name='experience_name'
                            placeholder='Experience Name'
                            onChange={handleChange}
                            defaultValue={
                              experienceData && experienceData.experience
                                ? experienceData.experience.experience_name
                                : ''
                            }
                          />
                        </div>
                        <div className='form-group'>
                          <label>Experience Duration</label>
                          <select
                            className='form-control'
                            name='type'
                            value={
                              experienceData && experienceData.experience
                                ? experienceData.experience.experience_duration
                                : ''
                            }
                            onChange={handleSelectDuration}
                          >
                            <option value='1'>Half Day</option>
                            <option value='2'>One Day</option>
                            <option value='3'>Multiple days</option>
                          </select>
                        </div>

                        <div className='form-group'>
                          <label>Activity Types</label>
                          <Select
                            name='experience_activity_types'
                            value={experienceActivityTypeData}
                            defaultValue={experienceActivityTypeData}
                            isMulti
                            onChange={handleMultiSelectActivityTypeChange}
                            options={activityTypeData}
                            className='basic-multi-select'
                            classNamePrefix='select'
                          />
                        </div>

                        {experienceSchedule
                          ? experienceSchedule.map((experiencedata, index) => {
                              return (
                                <div key={index}>
                                  <div className='form-group row'>
                                    <div className='col-sm-2'>
                                      <label>Day {index + 1}</label>
                                    </div>
                                    <div className='col-sm-5'>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <Grid
                                          container
                                          justifyContent='space-around'
                                        >
                                          <TimePicker
                                            margin='normal'
                                            id='time-picker'
                                            name='start_time'
                                            label='Start Time'
                                            value={
                                              experienceSchedule[index]
                                                .start_time
                                            }
                                            defaultValue={
                                              experienceSchedule[index]
                                                .start_time
                                            }
                                            onChange={(e) =>
                                              handleStartDateChange(e, index)
                                            }
                                            KeyboardButtonProps={{
                                              'aria-label': 'change time',
                                            }}
                                          />
                                        </Grid>
                                      </MuiPickersUtilsProvider>
                                    </div>
                                    <div className='col-sm-5'>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <Grid
                                          container
                                          justifyContent='space-around'
                                        >
                                          <TimePicker
                                            margin='normal'
                                            id='time-picker'
                                            label='End picker'
                                            value={
                                              experienceSchedule[index].end_time
                                            }
                                            defaultValue={
                                              experienceSchedule[index].end_time
                                            }
                                            onChange={(e) =>
                                              handleEndDateChange(e, index)
                                            }
                                            KeyboardButtonProps={{
                                              'aria-label': 'change time',
                                            }}
                                          />
                                        </Grid>
                                      </MuiPickersUtilsProvider>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : ''}

                        {selectDuration == 3 ? (
                          <div className='col-lg-12'>
                            <div className='form-group'>
                              <Button
                                variant='contained'
                                color='primary'
                                onClick={handleAddMore}
                              >
                                Add More
                              </Button>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        <div className='form-group'>
                          <input
                            type='radio'
                            className='form-check-input'
                            name='charge_type'
                            value='flat_fee'
                            onChange={handleChange}
                            checked={chargeTypeFlatFee}
                          />
                          <label>Flat fee</label>
                          {experienceData &&
                          experienceData.charge_type == 'flat_fee' ? (
                            <input
                              type='number'
                              className='input-with-radio'
                              name='amount'
                              placeholder='Amount'
                              onChange={handleChange}
                              defaultValue={
                                experienceData && experienceData.experience
                                  ? experienceData.experience.amount
                                  : ''
                              }
                            />
                          ) : (
                            <input
                              type='number'
                              className='input-with-radio'
                              name='amount'
                              placeholder='Amount'
                              onChange={handleChange}
                              defaultValue={
                                experienceData && experienceData.experience
                                  ? experienceData.experience.amount
                                  : ''
                              }
                            />
                          )}

                          <input
                            type='radio'
                            className='form-check-input'
                            name='charge_type'
                            value='per_person'
                            onChange={handleChange}
                            checked={chargeTypePerPerson}
                          />
                          <label>Per Person</label>
                          {experienceData &&
                          experienceData.charge_type == 'per_person' ? (
                            <input
                              type='number'
                              className='input-with-radio'
                              name='amount'
                              placeholder='Amount'
                              onChange={handleChange}
                              defaultValue={
                                experienceData && experienceData.experience
                                  ? experienceData.experience.per_person_cost
                                  : ''
                              }
                            />
                          ) : (
                            <input
                              type='number'
                              className='input-with-radio'
                              name='amount'
                              placeholder='Amount'
                              onChange={handleChange}
                              defaultValue={
                                experienceData && experienceData.experience
                                  ? experienceData.experience.per_person_cost
                                  : ''
                              }
                            />
                          )}
                        </div>

                        <div className='form-group'>
                          <label>Highlights</label>
                          <input
                            type='text'
                            className='form-control form-control-user'
                            placeholder='Highlights 1'
                            value={highlights?.h1}
                            name='h1'
                            onChange={handleHighlights}
                          />
                        </div>
                        <div className='form-group'>
                          <input
                            type='text'
                            className='form-control form-control-user'
                            placeholder='Highlights 2'
                            value={highlights?.h2}
                            name='h2'
                            onChange={handleHighlights}
                          />
                        </div>
                        <div className='form-group'>
                          <input
                            type='text'
                            className='form-control form-control-user'
                            placeholder='Highlights 3'
                            value={highlights?.h3}
                            name='h3'
                            onChange={handleHighlights}
                          />
                        </div>
                        <div className='form-group'>
                          <input
                            type='text'
                            className='form-control form-control-user'
                            placeholder='Highlights 4'
                            value={highlights?.h4}
                            name='h4'
                            onChange={handleHighlights}
                          />
                        </div>
                        <div className='form-group row'>
                          <div className='col-sm-6'>
                            <label>Environmental Causes</label>

                            <Select
                              name='environmental_causes'
                              value={selectedEnvironmental}
                              defaultValue={selectedEnvironmental}
                              isMulti
                              onChange={handleMultiSelectChange}
                              options={options}
                              className='basic-multi-select'
                              classNamePrefix='select'
                            />
                          </div>
                          <div className='col-sm-6 mb-3 mb-sm-0'>
                            <label>Image</label>
                            <input
                              type='file'
                              onChange={handleChangeImages}
                              className='form-control'
                              name='images'
                              accept='.png,.jpg,.jpeg'
                              multiple
                            />
                            {tempimagesArr.map((i) => {
                              return (
                                <img
                                  src={`${process.env.REACT_APP_IMAGE_PATH}/experience/${i}`}
                                  width='50px'
                                  height='50px'
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className='form-group row'>
                          <div className='col-sm-6'>
                            <label>Video Title</label>
                            <input
                              type='text'
                              className='form-control form-control-user'
                              name='video_title'
                              placeholder='Video Title'
                              onChange={handleChange}
                              defaultValue={
                                experienceData && experienceData.experience
                                  ? experienceData.experience.video_title
                                  : ''
                              }
                            />
                          </div>
                          <div className='col-sm-6'>
                            <label>Video Url</label>
                            <input
                              type='text'
                              className='form-control form-control-user'
                              name='video_url'
                              placeholder='Video Url'
                              onChange={handleVideoUrlChange}
                              defaultValue={
                                experienceData && experienceData.experience
                                  ? experienceData.experience.video_url
                                  : ''
                              }
                            />
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='form-group'>
                            <label>Detail Description</label>
                            <Editor
                              apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                              name='detail_description'
                              value={tempDescription}
                              onEditorChange={handleChangeInputTextArea}
                              init={{
                                selector: 'textarea',
                                menubar: false,
                                plugins: [
                                  'advlist autolink lists link charmap print preview anchor',
                                  'searchreplace visualblocks code fullscreen',
                                  'table contextmenu paste',
                                ],
                                toolbar:
                                  'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat ',
                              }}
                            />
                            {/* <textarea className="form-control" name="detail_description" onChange={handleChange} defaultValue={(experienceData && experienceData.experience) ? experienceData.experience.detail_description : ''}></textarea> */}
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='form-group'>
                            <label>Further Information</label>
                            <textarea
                              className='form-control'
                              name='further_information'
                              onChange={handleChange}
                              defaultValue={
                                experienceData && experienceData.experience
                                  ? experienceData.experience
                                      .further_information
                                  : ''
                              }
                            ></textarea>
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='form-group'>
                            <label>Whats Include</label>
                            <textarea
                              className='form-control'
                              name='whats_include'
                              onChange={handleChange}
                              defaultValue={
                                experienceData && experienceData.experience
                                  ? experienceData.experience.whats_include
                                  : ''
                              }
                            ></textarea>
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='form-group'>
                            <label>Tag Line</label>
                            <textarea
                              className='form-control'
                              name='tag_line'
                              defaultValue={
                                experienceData && experienceData.experience
                                  ? experienceData.experience.tag_line
                                  : ''
                              }
                              onChange={handleChange}
                            ></textarea>
                          </div>
                        </div>

                        <div className='col-lg-12'>
                          <div className='form-group'>
                            <label>
                              Last Sync Date of Experience is{' '}
                              {experienceData && experienceData.experience
                                ? new Date(
                                    experienceData.experience.last_sync_date,
                                  ).toLocaleDateString('en-au')
                                : ''}
                            </label>
                          </div>
                        </div>

                        <div className='col-lg-12'>
                          <span className='text'>
                            {/* <button className="btn btn-primary" onClick={handleUpdate}>Update</button> */}
                            <button
                              className='btn btn-primary mr-3'
                              onClick={(e) => handleUpdate(e, 0)}
                            >
                              Convert To Draft
                            </button>
                            <button
                              className='btn btn-primary'
                              onClick={(e) => handleUpdate(e, 1)}
                            >
                              Save
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
