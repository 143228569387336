import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TopBar from "../Common/TopBar/TopBar";
import SideBar from "../Common/SideBar/SideBar";
import Footer from "../Common/Footer/Footer";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import PageLimit from "../Common/PageLimit/PageLimit";
import Pagination from "../Common/Pagination/Pagination";
import { useSelector, useDispatch } from 'react-redux';
import { apiConstants } from '../../_constants/api.constants';
import { bookingRequestActions ,bookingActions, companyActions} from '../../_actions';
import 'antd/dist/antd.css';
// import BookingDetail from './BookingDetail';
import Toggle from 'react-toggle'
// import { CSVLink } from "react-csv";
// import AddLocation from './AddBooking';

export default function Booking() {
  const [bookingDetails, setBookingDetails] = useState(false);
  const [saveBookings, setSaveBookings] = useState({})
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [deleteDetail, setDeleteDetail] = useState('')
  const [search, setSearch] = useState("");
  const [filteredBooking, setFilteredBooking] = useState([]);
  const [booking, setBooking] = useState([])
  const [bookingFlag, setBookingFlag] = useState(0);
  const [limit, setLimit] = useState(10);
  const [NewOffset, setOffset] = useState(0);
  const [checked, setChecked] = useState({})
  const [currentPage, setCurrentPage] = useState(1);
  const [switchState, setSwitchState] = useState({})
  const dispatch = useDispatch();

  // const total_count = useSelector(state => state.getAllCompany.total_count);
  // const total_pages = useSelector(state => state.getAllCompany.pages);

  console.log(filteredBooking,"filteredBooking")

  useEffect(() => {
    let obj = {
      limit: 10,
      offset: 0
    }
    dispatch(bookingRequestActions.getBookingList(apiConstants.GET_BOOKING_REQUEST, obj));
  }, [])

  const handleSearch = (e) => {
    let obj = {
      searchQuery: e.target.value,
      limit: 10,
      offset: 0
    }
    // dispatch(bookingRequestActions.searchBookingList(apiConstants.SEARCH_BOOKING, obj));
  }

  const changeTableData = (e) => {
    setLimit(e.target.value)
    let obj = {
      limit: parseInt(e.target.value),
      offset: 0,
    };
    dispatch(bookingRequestActions.getBookingList(apiConstants.GET_BOOKING_REQUEST, obj));
  };

  const getBooking = useSelector(state => state.getBooking);

  useEffect(()=>{
    console.log(getBooking,"get")
    if(getBooking && getBooking.getBooking){
      setBooking(getBooking.getBooking.data)
      console.log("get12", getBooking)
    }

  },[getBooking])

  useEffect(() => {
    console.log("booking", booking)
    // setFilteredBooking(
    //   booking && booking.filter((data) =>
    //   data.experience_name.toLowerCase().includes(search.toLowerCase())
    //   // data.team_size.includes(parseInt(search))
    //   )
    // );
  }, [search, booking]);

  const bookingDetail = (booking) => {
    setBookingDetails(true)
    setSaveBookings(booking)
  }

  const backButton = () => {
    setBookingDetails(false)
  }

  const closeDeletePopup = () => {
    setDeletePopUp(false);
  }

  const deleteLocation = (id) => {
    setDeletePopUp(true);
    setDeleteDetail(id)
  }

  const handleChange = (e, id, additionalAmt, status,refundableAmt,payment_status,payment_type) => {
    console.log("e===>", e.target.value, additionalAmt)
    let value = e.target.value
    const formData = new FormData();
    formData.append('booking_id', id)
    formData.append('additional_amount', additionalAmt)
    formData.append('refundable_amount', refundableAmt)
    formData.append('status1',status )
    formData.append('payment_status1',payment_status )
    formData.append('payment_type1',payment_type )


// debugger
    if(payment_status == 'paid' && payment_type === "credit_card" ){
      if(value === 'request accepted' && additionalAmt > 0 && status === "update requested"){
        formData.append('payment_status', 'unpaid')
        formData.append('booking_status', 'reserved')
        formData.append('status', 'payment pending')
      }
    }else if(payment_status == 'paid' && payment_type === "credit_card" ){
      if(value === 'request accepted' && refundableAmt > 0 && status === "update requested"){
        formData.append('payment_status', 'paid')
        formData.append('booking_status', 'confirmed')
        formData.append('status', 'paid')
      }
    }else if(payment_status == 'unpaid' && payment_type === "via_invoice" ){
      if(value === 'request accepted' && additionalAmt > 0 && status === "update requested"){
        formData.append('payment_status', 'unpaid')
        formData.append('booking_status', 'reserved')
        formData.append('status', 'payment pending')
      }
    }
    else if(payment_status == 'paid' && payment_type === "via_invoice" ){
      if(value === 'request accepted' && additionalAmt > 0 && status === "update requested"){
        formData.append('payment_status', 'unpaid')
        formData.append('booking_status', 'reserved')
        formData.append('status', 'payment pending')
      }
    }else if(payment_status == 'paid' && payment_type === "via_invoice" ){
      if(value === 'request accepted' && refundableAmt > 0 && status === "update requested"){
        formData.append('payment_status', 'paid')
        formData.append('booking_status', 'confirmed')
        formData.append('status', 'paid')
      }
    }else if(payment_status == 'paid' && payment_type === "credit_card" ){
      if(value === 'request accepted' && status === "cancellation requested"){
        formData.append('payment_status', 'refund')
        formData.append('booking_status', 'cancelled')
        formData.append('status', 'cancelled')
      }
    }else if(payment_status == 'paid' && payment_type === "via_invoice" ){
      if(value === 'request accepted' && status === "cancellation requested"){
        formData.append('payment_status', 'refund')
        formData.append('booking_status', 'cancelled')
        formData.append('status', 'cancelled')
      }
    }else if(payment_status == 'unpaid' && payment_type === "via_invoice" ){
      if(value === 'request accepted' && status === "cancellation requested"){
        formData.append('payment_status', 'unpaid')
        formData.append('booking_status', 'cancelled')
        formData.append('status', 'cancelled')
      }
    }

    for (var pair of formData.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
  }
    
    dispatch(bookingActions.editBooking(apiConstants.EDIT_BOOKINGS_REQUEST, formData))
  }

  const handleSort = (e) => {
    if(bookingFlag === 1){
      setBookingFlag(0)
    }else if(bookingFlag === 0){
      setBookingFlag(1)
    }
   let obj = {
     sortByStatus: bookingFlag,
     sortBy: e.target.name
   }
  //  setCompany(company.sort((a,b) => parseFloat(a.user_id) - parseFloat(b.user_id)))
   dispatch(bookingRequestActions.sortBooking(apiConstants.SORT_BOOKING, obj))
  }
  
  const makeHttpRequestWithPage = (pageNumber) => {
    setCurrentPage(pageNumber)
    let obj = {
      limit: limit,
      offset:pageNumber - 1
    }
    setOffset(obj.offset)
    dispatch(bookingRequestActions.getBookingList(apiConstants.GET_BOOKING_REQUEST, obj));
}

  const handleDelete = () => {
    let formData = new FormData();
    formData.append("id", deleteDetail)
    formData.append("is_deleted", 1)
  
    dispatch(companyActions.updateOrganisationByOrgId(apiConstants.UPDATE_ORGANISATION_BY_ID, formData));
    setDeletePopUp(false);
  }

  const headers = [
    { label: "ID", key: "id" },
    { label: "Seeker Id", key: "seeker_id" },
    { label: "Company Id", key: "company_id" },
    { label: "Schedule Experience Id", key: "sch_exp_id" },
    { label: "Location Id", key: "location_id" },
    { label: "Experience Name", key: "experience_name" },
    { label: "Experience Duration", key: "experience_duration" },
    { label: "Days Time", key: "days_time" },
    { label: "Supporting Document", key: "supporting_document" },
    { label: "Team Size", key: "team_size" },

    { label: "Charge Type", key: "charge_type" },
    { label: "Amount", key: "amount" },
    { label: "Payment Type", key: "payment_type" },
    { label: "Full Name", key: "full_name" },
    { label: "Address 1", key: "address1" },
    { label: "Address 2", key: "address2" },
    { label: "Post Code", key: "post_code" },
    { label: "City", key: "city" },

    { label: "Country", key: "country" },
    { label: "Company Name", key: "company_name" },
    { label: "Email Id", key: "email_id" },
    { label: "Abn Acn", key: "abn_acn" },
    { label: "Contact Name", key: "contact_name" },
    { label: "Payment Status", key: "payment_status" },
    { label: "Status", key: "status" }
  ];

  return (
    <div id='wrapper'>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopBar />
          <nav className="" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                Booking Request
              </li>
            </ol>
          </nav>
          {!bookingDetails ?
            <div className="container-fluid">
             
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-2 text-gray-800">
                  Booking
                </h1>
                <div className="d-sm-flex align-items-center justify-content-between">
                <div className="d-sm-flex align-items-center justify-content-between">
                  {/* <CSVLink className="btn btn-primary mr-3" data={filteredBooking.map(Object.values)} headers={headers.map(Object.values)}>Export</CSVLink> */}
                    {/* <a href="#!"
                      onClick={() => console.log("provider add")}
                    >
                      <span className="text">
                        <Link to="/add-booking" className="btn btn-primary">Add Booking</Link>
                      </span>
                    </a> */}
                </div>
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="row">
                      {/* Page limit drop down */}
                      <PageLimit funChangePageLimit={changeTableData} />

                      <div className="col-sm-12 col-md-6">
                        <div id="dataTable_filter" className="dataTables_filter">
                          <label>
                            <input type="search" className="form-control form-control-sm" placeholder="Search" aria-controls="dataTable" onChange={handleSearch} />
                          </label>
                         
                        </div>
                      </div>
                    </div>
                    <table className="table table-bordered" width="100%" cellSpacing="0">
                      <thead>
                        <tr>
                          <th scope="col">No.</th>
                          <th scope="col">
                          Experience Name
                            <img src="assets/img/svg/sort.png" className="imageSort" name="experience_name" onClick={handleSort} />
                          </th>
                          
                          <th scope="col">Team Size</th>
                          <th scope="col">Payment Status</th>
                          {/* <th scope="col">Booking Status</th> */}
                          <th scope="col">Status</th>
                          {/* <th scope="col">Status</th> */}
                          <th>Accept Request</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        { booking && booking.map((booking, index) => {
                          var count = 0;
                          //  var pageData = config.perPageData;
                           count = index + (NewOffset*10)+1
                          return (
                            <tr key={index}>
                              <td scope="row">{count}</td>
                              <td>{booking.experience_name}</td>
                              <td>{booking.team_size}</td>
                              {/* <td> */}
                                  {/* {company.is_deleted === 0 ? <Badge className="ml-3" status="success" text="Active" /> : <Badge className="ml-3" status="error" text="Inactive" />} */}
                              {/* </td> */}
                              <td>{booking.payment_status}</td>
                              {/* <td>{booking.booking_status}</td> */}
                              <td>{booking.status}</td>
                              <td>
                                {/* {
                                  ((booking.booking_status == 'confirmed' && booking.status != 'update requested') || booking.status == 'cancelled' || booking.status == 'request accepted') ? '-' :  <label> */}
{/*                                 
                                  <Toggle
                                    // defaultChecked={checked}
                                    onChange={(e)=>handleChange(e, booking.id, booking.status)} /> */}
                                  {/* <span>Wrapper label tag</span> */}
                                 
                                {/* </label>
                                }
                              */}
                               {
                                 <select className="form-control" onChange={(e)=>handleChange(e, booking.booking_id, booking.additional_amount, booking.status, booking.refundable_amount, booking.payment_status, booking.payment_type)}>
                                  <option>Select Status</option>
                                  <option value="request accepted">Request accepted</option>
                                  <option value="cancelled">Cancelled</option>
                                  {/* <option value="deny">Deny</option> */}
                                </select>
                                }
                              </td>
                              <td>
                                <Link className="actionIcon" title="View" to={`/booking-request-details/${booking.booking_id}`}><span><img src="assets/img/svg/eye-icon.png" alt="View" /></span></Link>
                                {/* <Link className="actionIcon" title="Edit" to={`/update-booking/${booking.id}`}><span><img src="assets/img/svg/EditIcon.png" alt="Edit" /></span></Link> */}
                                {/* <a className="actionIcon" title="Delete" href="#!" onClick={() => deleteLocation(location.id)}><span><img src="assets/img/svg/Bin.png" alt="Delete" /></span></a> */}
                              </td>
                            </tr>
                          )
                        }) }

                      </tbody>
                    </table>
                  </div>
                  {/* calling the another componenet */}
                  <Pagination totalPage={(getBooking && getBooking.getBooking) ? getBooking.getBooking.pages : 0} currentPage={currentPage} funPagination={makeHttpRequestWithPage} />
                </div>
              </div>
            </div> : null
            // <BookingDetail data={saveBookings} backButton={backButton} />
          }

        </div>
        <Dialog open={deletePopUp} fullWidth={true} aria-labelledby="delete-product-notify" >
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            Are you sure?
            <h5 className="mt-3">You want to delete {deleteDetail}?</h5>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={()=>handleDelete()}>Delete</Button>
            <Button variant="contained" onClick={closeDeletePopup}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Footer />
      </div>
    </div>
  )
}
