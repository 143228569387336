import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import Select from "react-select";

//-- Material
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

//-- CSS for Material
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: '#269abc'

    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey,
    },
    contentRoot: {
        padding: theme.spacing(2),
        fontSize: 14,
        width: 600,

    },
    actionRoot: {
        margin: 0,
        padding: theme.spacing(1),
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(styles)(props => {
    const { children, classes } = props;
    return (
        <MuiDialogContent dividers className={classes.contentRoot}>
            {children}
        </MuiDialogContent>
    );
});

const DialogActions = withStyles(styles)(props => {
    const { children } = props;
    return (
        <MuiDialogActions>
            {children}
        </MuiDialogActions>
    );
});


class EditPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultData: {},
            optionsData: {},
            validated: { errors: {} }
        };
    }

    /*
    * componentWillReceiveProps - Whenever Props change, it will call and store data
    * update the state values with new props values, this method will get called whenever any change happens to props values
    */
    componentWillReceiveProps(props) {
        //console.log("......... .................. REC ", props);
        const { defaultData, optionsData } = props;
        this.setState({
            defaultData,
            optionsData
        });
        if (!props.editOpen) {
            this.setState({
                validated: { errors: {} }
            });
        }

        //-- It will only work for Coupon
        if (props.popupType === 'CouponsComponenet') {
            var editableDate = Date.parse(props.defaultData.expiry_date);
            this.setState({
                defaultData: {
                    ...defaultData,
                    expiry_date: editableDate
                }
            });
        }


        //-- It will only work for permissions
        if (props.popupType === 'PermissionsComponenet') {
            let roleId = props.defaultData.role_id;
            let pageIds = '';
            let selectedPages = [];
            let selectedRoles = [];

            if (props.defaultData.page_ids !== '') {
                pageIds = props.defaultData.page_ids.split(",");

                pageIds.map((page) => {
                    this.state.optionsData.pagesOption.map((tempPageOptions) => {
                        if (tempPageOptions.value === page) {
                            selectedPages.push(tempPageOptions);
                        }
                    });
                });
            }

            if (roleId !== '') {
                this.state.optionsData.rolesOption.map((tempRoleOptions) => {
                    if (tempRoleOptions.value === roleId) {
                        selectedRoles.push(tempRoleOptions);
                    }
                });
            }

            this.setState({
                defaultData: {
                    ...defaultData,
                    expiry_date: editableDate
                },
                selectedPages,
                selectedRoles
            });
        }

    }

    /*
    *   we are setting the Values
    */
    handleChange = (e) => {
        const { name, value } = e.target;
        const { defaultData } = this.state;
        this.setState({
            defaultData: {
                ...defaultData,
                [name]: value
            }
        });
    }

    /*
    *   Date Changed
    */
    handleDateChange = date => {
        const { defaultData } = this.state;
        this.setState({
            defaultData: {
                ...defaultData,
                expiry_date: date
            }
        });
    }

    /*
    *   Status change
    */
    handleStatusChange(e) {
        const { defaultData } = this.state;
        this.setState({
            defaultData: {
                ...defaultData,
                status: e.target.checked
            }
        });
    }

    /*
    *   Handle Option change for pages 
    */
    handleOptionsChange = e => {
        const { name } = e.target;
        let options = [];
        if (this.state.defaultData.options && typeof this.state.defaultData.options !== 'string') {
            options = [...this.state.defaultData.options];
        } else {
            options = [...JSON.parse(this.state.defaultData.options)];
        }
        if (options.includes(name)) {
            options.splice(options.findIndex(x => x === name), 1);
        } else {
            options.push(name);
        }
        let newState = { ...this.state.defaultData };
        newState.options = options;

        this.setState({ defaultData: newState });
    };

    componentWillUnmount() {
        this.setState({
            defaultData: {},
            validated: { errors: {} }
        });
    }

    //-- for Permission section
    handleSelectChange = data => {
        console.log("DATA.....................",data);
        const { defaultData } = this.state;

        this.setState({
            defaultData: {
                ...defaultData,
                role_id_db: data.value
            },
            selectedRoles: [data],
        });
    };

    //-- for Permission section
    handleMultiSelectChange = data => {
        const { defaultData } = this.state;
        //console.log("DATA.....................",data);
        let pageArr = [];
        let pageArrDB = [];
        data.map((d) => {
            pageArr.push(d);
            pageArrDB.push(d.value);
        });
        this.setState({
            defaultData: {
                ...defaultData,
                page_ids_db: pageArrDB.toString()
            },
            selectedPages: pageArr,
        });
    };

    render() {
        console.log("...... PPOP UP............", this.state);
        const { siteConstants } = this.props.language;

        let popupType = this.props.popupType;
        if (popupType === 'TermsComponenet') {
            //-- check Add/Edit label
            let addEditTitle = siteConstants.LABEL_EDIT_TERMS;
            if (this.props.addButton)
                addEditTitle = siteConstants.LABEL_ADD_TERMS;

            //-- Popup will open on terms page for add/edit the terms
            return (
                <Dialog onClose={() => this.props.funPopupClose('editOpen')} aria-labelledby="customized-dialog-title" open={this.props.editOpen}>
                    <DialogTitle id="customized-dialog-title" onClose={() => this.props.funPopupClose('editOpen')}>
                        {addEditTitle}
                    </DialogTitle>
                    <DialogContent>
                        <div className="card shadow mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <form role="form">
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_TITLE}</label>
                                                                        <input className="form-control" name="title" placeholder={siteConstants.GEN_TITLE} value={this.state.defaultData.title} onChange={this.handleChange} />
                                                                        <span className="my-error">{this.state.validated.errors["title"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_TYPE}</label>
                                                                        <select className="form-control" name="type" onChange={this.handleChange} value={this.state.defaultData.type} >
                                                                            <option value="">{siteConstants.GEN_PLEASE_SELECT}</option>
                                                                            <option value={siteConstants.LABEL_TERMS_OF_SERVICE}>{siteConstants.LABEL_TERMS_OF_SERVICE}</option>
                                                                            <option value={siteConstants.LABEL_PRIVACY_TERMS}>{siteConstants.LABEL_PRIVACY_TERMS}</option>
                                                                        </select>
                                                                        <span className="my-error">{this.state.validated.errors["type"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_DESCRIPTION}</label>
                                                                        <textarea className="form-control" name="description" onChange={this.handleChange}>{this.state.defaultData.description}</textarea>
                                                                        <span className="my-error">{this.state.validated.errors["description"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <button type="button" className="btn btn-primary" onClick={(event) => this.setState({ validated: this.props.funTermsAddUpdate(event, this.state) })}>{siteConstants.BTN_SAVE}</button>&nbsp;
                                                                        <button type="button" className="btn btn-info" onClick={() => this.props.funPopupClose('editOpen')}>{siteConstants.BTN_CANCEL}</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        } else if (popupType === 'NoticesComponenet') {
            //-- check Add/Edit label
            let addEditTitle = siteConstants.LABEL_EDIT_NOTICES;
            if (this.props.addButton)
                addEditTitle = siteConstants.LABEL_ADD_NOTICES;

            //-- Popup will open on notices page for add/edit the NOTICES
            return (
                <Dialog onClose={() => this.props.funPopupClose('editOpen')} aria-labelledby="customized-dialog-title" open={this.props.editOpen}>
                    <DialogTitle id="customized-dialog-title" onClose={() => this.props.funPopupClose('editOpen')}>
                        {addEditTitle}
                    </DialogTitle>
                    <DialogContent>
                        <div className="card shadow mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <form role="form">
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_TITLE}</label>
                                                                        <input className="form-control" name="title" placeholder={siteConstants.GEN_TITLE} value={this.state.defaultData.title} onChange={this.handleChange} />
                                                                        <span className="my-error">{this.state.validated.errors["title"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_DESCRIPTION}</label>
                                                                        <textarea className="form-control" name="description" onChange={this.handleChange}>{this.state.defaultData.description}</textarea>
                                                                        <span className="my-error">{this.state.validated.errors["description"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.LABEL_PUSH_MESSAGE}</label>
                                                                        <textarea className="form-control" name="push_message" onChange={this.handleChange}>{this.state.defaultData.push_message}</textarea>
                                                                        {/* <span className="my-error">{this.state.validated.errors["push_message"]}</span> */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <button type="button" className="btn btn-primary" onClick={(event) => this.setState({ validated: this.props.funNoticesAddUpdate(event, this.state) })}>{siteConstants.BTN_SAVE}</button>&nbsp;
                                                                        <button type="button" className="btn btn-info" onClick={() => this.props.funPopupClose('editOpen')}>{siteConstants.BTN_CANCEL}</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        } else if (popupType === 'AdvertismentsComponenet') {
            //-- check Add/Edit label
            let addEditTitle = siteConstants.LABEL_EDIT_ADVERTISMENTS;
            if (this.props.addButton)
                addEditTitle = siteConstants.LABEL_ADD_ADVERTISMENTS;

            //-- Popup will open on notices page for add/edit the Advertisment
            return (
                <Dialog onClose={() => this.props.funPopupClose('editOpen')} aria-labelledby="customized-dialog-title" open={this.props.editOpen}>
                    <DialogTitle id="customized-dialog-title" onClose={() => this.props.funPopupClose('editOpen')}>
                        {addEditTitle}
                    </DialogTitle>
                    <DialogContent>
                        <div className="card shadow mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <form role="form">
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_TITLE}</label>
                                                                        <input className="form-control" name="title" placeholder={siteConstants.GEN_TITLE} value={this.state.defaultData.title} onChange={this.handleChange} />
                                                                        <span className="my-error">{this.state.validated.errors["title"]}</span>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_IMAGE}</label>
                                                                        <textarea className="form-control" name="advertisment_image11" onChange={this.handleChange}>{this.state.defaultData.advertisment_image}</textarea>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_IMAGE}</label>
                                                                        <input type="file" className="form-control" name="advertisment_image" onChange={(event) => this.props.funImageUpload(event)} />
                                                                        {/* <span className="my-error">{this.state.validated.errors["advertisment_image"]}</span> */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <button type="submit" className="btn btn-primary" onClick={(event) => this.setState({ validated: this.props.funAdvertismentsAddUpdate(event, this.state) })}>{siteConstants.BTN_SAVE}</button>&nbsp;
                                                                        <button type="button" className="btn btn-info" onClick={() => this.props.funPopupClose('editOpen')}>{siteConstants.BTN_CANCEL}</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        } else if (popupType === 'NotificationsComponenet') {
            //-- check Add/Edit label
            let addEditTitle = siteConstants.LABEL_EDIT_NOTIFICATIONS;
            if (this.props.addButton)
                addEditTitle = siteConstants.LABEL_ADD_NOTIFICATIONS;

            //-- Popup will open on notices page for add/edit the Notifications
            return (
                <Dialog onClose={() => this.props.funPopupClose('editOpen')} aria-labelledby="customized-dialog-title" open={this.props.editOpen}>
                    <DialogTitle id="customized-dialog-title" onClose={() => this.props.funPopupClose('editOpen')}>
                        {addEditTitle}
                    </DialogTitle>
                    <DialogContent>
                        <div className="card shadow mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <form role="form">
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_TITLE}</label>
                                                                        <input className="form-control" name="title" placeholder={siteConstants.GEN_TITLE} value={this.state.defaultData.title} onChange={this.handleChange} />
                                                                        <span className="my-error">{this.state.validated.errors["title"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_MESSAGE}</label>
                                                                        <textarea className="form-control" name="notification_message" onChange={this.handleChange}>{this.state.defaultData.notification_message}</textarea>
                                                                        <span className="my-error">{this.state.validated.errors["notification_message"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <button type="button" className="btn btn-primary" onClick={(event) => this.setState({ validated: this.props.funNotificationsAddUpdate(event, this.state) })}>{siteConstants.BTN_SAVE}</button>&nbsp;
                                                                        <button type="button" className="btn btn-info" onClick={() => this.props.funPopupClose('editOpen')}>{siteConstants.BTN_CANCEL}</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        } else if (popupType === 'CategoryComponenet') {
            //-- check Add/Edit label
            let addEditTitle = siteConstants.LABEL_EDIT_CATEGORY;
            if (this.props.addButton)
                addEditTitle = siteConstants.LABEL_ADD_CATEGORY;

            //-- Popup will open on notices page for add/edit the Notifications
            return (
                <Dialog onClose={() => this.props.funPopupClose('editOpen')} aria-labelledby="customized-dialog-title" open={this.props.editOpen}>
                    <DialogTitle id="customized-dialog-title" onClose={() => this.props.funPopupClose('editOpen')}>
                        {addEditTitle}
                    </DialogTitle>
                    <DialogContent>
                        <div className="card shadow mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <form role="form">
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.LABEL_CATEGORY}</label>
                                                                        <select className="form-control" name="type" onChange={this.handleChange} value={this.state.defaultData.parent_id} >
                                                                            <option value="">{siteConstants.GEN_PLEASE_SELECT}</option>
                                                                            <option value={siteConstants.LABEL_TERMS_OF_SERVICE}>{siteConstants.LABEL_TERMS_OF_SERVICE}</option>
                                                                            <option value={siteConstants.LABEL_PRIVACY_TERMS}>{siteConstants.LABEL_PRIVACY_TERMS}</option>
                                                                        </select>
                                                                        <span className="my-error">{this.state.validated.errors["parent_id"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_NAME}</label>
                                                                        <input className="form-control" name="name" placeholder={siteConstants.GEN_NAME} value={this.state.defaultData.name} onChange={this.handleChange} />
                                                                        <span className="my-error">{this.state.validated.errors["title"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_DESCRIPTION}</label>
                                                                        <textarea className="form-control" name="description" onChange={this.handleChange}>{this.state.defaultData.description}</textarea>
                                                                        <span className="my-error">{this.state.validated.errors["description"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_IMAGE}</label>
                                                                        <input type="file" className="form-control" name="picture" onChange="" />
                                                                        {/* <span className="my-error">{this.state.validated.errors["advertisment_image"]}</span> */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <button type="button" className="btn btn-primary" onClick={(event) => this.setState({ validated: this.props.funCategoriesAddUpdate(event, this.state) })}>{siteConstants.BTN_SAVE}</button>&nbsp;
                                                                        <button type="button" className="btn btn-info" onClick={() => this.props.funPopupClose('editOpen')}>{siteConstants.BTN_CANCEL}</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        } else if (popupType === 'PaymentTypesComponenet') {
            //-- check Add/Edit label
            let addEditTitle = siteConstants.LABEL_EDIT_PAYMENT_TYPE;
            if (this.props.addButton)
                addEditTitle = siteConstants.LABEL_ADD_PAYMENT_TYPE;

            //-- Popup will open on notices page for add/edit the Payment Type
            return (
                <Dialog onClose={() => this.props.funPopupClose('editOpen')} aria-labelledby="customized-dialog-title" open={this.props.editOpen}>
                    <DialogTitle id="customized-dialog-title" onClose={() => this.props.funPopupClose('editOpen')}>
                        {addEditTitle}
                    </DialogTitle>
                    <DialogContent>
                        <div className="card shadow mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <form role="form">
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.LABEL_PAYMENT_TYPE_NAME}</label>
                                                                        <input className="form-control" name="title" placeholder={siteConstants.LABEL_PAYMENT_TYPE_NAME} value={this.state.defaultData.title} onChange={this.handleChange} />
                                                                        <span className="my-error">{this.state.validated.errors["title"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_DESCRIPTION}</label>
                                                                        <textarea className="form-control" name="description" onChange={this.handleChange}>{this.state.defaultData.description}</textarea>
                                                                        <span className="my-error">{this.state.validated.errors["description"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_IMAGE}</label>
                                                                        <input type="file" className="form-control" name="payment_type_icon" onChange="" />
                                                                        {/* <span className="my-error">{this.state.validated.errors["advertisment_image"]}</span> */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <button type="button" className="btn btn-primary" onClick={(event) => this.setState({ validated: this.props.funPaymentTypesAddUpdate(event, this.state) })}>{siteConstants.BTN_SAVE}</button>&nbsp;
                                                                        <button type="button" className="btn btn-info" onClick={() => this.props.funPopupClose('editOpen')}>{siteConstants.BTN_CANCEL}</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        } else if (popupType === 'TagComponenet') {
            //-- check Add/Edit label
            let addEditTitle = siteConstants.LABEL_EDIT_TAG;
            if (this.props.addButton)
                addEditTitle = siteConstants.LABEL_ADD_TAG;
            //console.log("..................", this.state.defaultData);
            //-- Popup will open on notices page for add/edit the Payment Type
            return (
                <Dialog onClose={() => this.props.funPopupClose('editOpen')} aria-labelledby="customized-dialog-title" open={this.props.editOpen}>
                    <DialogTitle id="customized-dialog-title" onClose={() => this.props.funPopupClose('editOpen')}>
                        {addEditTitle}
                    </DialogTitle>
                    <DialogContent>
                        <div className="card shadow mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <form role="form">
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.LABEL_NAME}</label>
                                                                        <input className="form-control" name="name" placeholder={siteConstants.LABEL_NAME} value={this.state.defaultData.name} onChange={this.handleChange} />
                                                                        <span className="my-error">{this.state.validated.errors["name"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <button type="button" className="btn btn-primary" onClick={(event) => this.setState({ validated: this.props.funTagsAddUpdate(event, this.state) })}>{siteConstants.BTN_SAVE}</button>&nbsp;
                                                                        <button type="button" className="btn btn-info" onClick={() => this.props.funPopupClose('editOpen')}>{siteConstants.BTN_CANCEL}</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        } else if (popupType === 'CouponsComponenet') {
            //-- check Add/Edit label
            let addEditTitle = siteConstants.LABEL_EDIT_COUPON;
            if (this.props.addButton)
                addEditTitle = siteConstants.LABEL_ADD_COUPON;

            //-- Popup will open on notices page for add/edit the Payment Type
            return (
                <Dialog onClose={() => this.props.funPopupClose('editOpen')} aria-labelledby="customized-dialog-title" open={this.props.editOpen}>
                    <DialogTitle id="customized-dialog-title" onClose={() => this.props.funPopupClose('editOpen')}>
                        {addEditTitle}
                    </DialogTitle>
                    <DialogContent>
                        <div className="card shadow mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <form role="form">
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_TITLE}</label>
                                                                        <input className="form-control" name="title" placeholder={siteConstants.GEN_TITLE} value={this.state.defaultData.title} onChange={this.handleChange} />
                                                                        <span className="my-error">{this.state.validated.errors["title"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_DESCRIPTION}</label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            placeholder={siteConstants.GEN_DESCRIPTION}
                                                                            name="description"
                                                                            onChange={this.handleChange}
                                                                        >
                                                                            {this.state.defaultData.description}
                                                                        </textarea>
                                                                        <span className="my-error">
                                                                            {this.state.validated.errors.description}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.LABEL_EXPIRY_DATE}</label>
                                                                        <br />
                                                                        <DatePicker
                                                                            name="expiry_date"
                                                                            dateFormat="yyyy-MM-dd"
                                                                            selected={this.state.defaultData.expiry_date}
                                                                            onChange={e => this.handleDateChange(e)}
                                                                            className="form-control"
                                                                            popperPlacement='top-start'
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.LABEL_STATUS}</label>
                                                                        <div className="custom-control custom-checkbox small">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                htmlFor="customCheck"
                                                                                id="status"
                                                                                name="status"
                                                                                checked={this.state.defaultData.status}
                                                                                onChange={e => this.handleStatusChange(e)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <button type="button" className="btn btn-primary" onClick={(event) => this.setState({ validated: this.props.funCouponsAddUpdate(event, this.state) })}>{siteConstants.BTN_SAVE}</button>&nbsp;
                                                                        <button type="button" className="btn btn-info" onClick={() => this.props.funPopupClose('editOpen')}>{siteConstants.BTN_CANCEL}</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        } else if (popupType === 'RolesComponenet') {
            //-- check Add/Edit label
            let addEditTitle = siteConstants.LABEL_EDIT_ROLE;
            if (this.props.addButton)
                addEditTitle = siteConstants.LABEL_ADD_ROLE;

            //-- Popup will open on notices page for add/edit the Roles
            return (
                <Dialog onClose={() => this.props.funPopupClose('editOpen')} aria-labelledby="customized-dialog-title" open={this.props.editOpen}>
                    <DialogTitle id="customized-dialog-title" onClose={() => this.props.funPopupClose('editOpen')}>
                        {addEditTitle}
                    </DialogTitle>
                    <DialogContent>
                        <div className="card shadow mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <form role="form">
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_TITLE}</label>
                                                                        <input className="form-control" name="title" placeholder={siteConstants.GEN_TITLE} value={this.state.defaultData.title} onChange={this.handleChange} />
                                                                        <span className="my-error">{this.state.validated.errors["title"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_MESSAGE}</label>
                                                                        <textarea className="form-control" name="description" onChange={this.handleChange}>{this.state.defaultData.description}</textarea>
                                                                        <span className="my-error">{this.state.validated.errors["description"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <button type="button" className="btn btn-primary" onClick={(event) => this.setState({ validated: this.props.funRolesAddUpdate(event, this.state) })}>{siteConstants.BTN_SAVE}</button>&nbsp;
                                                                        <button type="button" className="btn btn-info" onClick={() => this.props.funPopupClose('editOpen')}>{siteConstants.BTN_CANCEL}</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        } else if (popupType === 'PagesComponenet') {
            const options = ["Add", "Delete", "Edit", "View", "Listing", "Search"];

            //-- check Add/Edit label
            let addEditTitle = siteConstants.LABEL_EDIT_PAGE;
            if (this.props.addButton)
                addEditTitle = siteConstants.LABEL_ADD_PAGE;

            //console.log(",,,,,,,,,,,,,,,, ", this.state);
            //-- Popup will open on notices page for add/edit the Roles
            return (
                <Dialog onClose={() => this.props.funPopupClose('editOpen')} aria-labelledby="customized-dialog-title" open={this.props.editOpen}>
                    <DialogTitle id="customized-dialog-title" onClose={() => this.props.funPopupClose('editOpen')}>
                        {addEditTitle}
                    </DialogTitle>
                    <DialogContent>
                        <div className="card shadow mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <form role="form">
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_TITLE}</label>
                                                                        <input className="form-control" name="name" placeholder={siteConstants.GEN_TITLE} value={this.state.defaultData.name} onChange={this.handleChange} />
                                                                        <span className="my-error">{this.state.validated.errors["name"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_MESSAGE}</label>
                                                                        <textarea className="form-control" name="description" onChange={this.handleChange}>{this.state.defaultData.description}</textarea>
                                                                        <span className="my-error">{this.state.validated.errors["description"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.GEN_OPTION}</label>
                                                                        <br />
                                                                        <div className="d-sm-inline-flex">

                                                                            {options.map((item, i) => {

                                                                                let value = 0;
                                                                                if (this.state.defaultData.options) {
                                                                                    value = this.state.defaultData.options.includes(item);
                                                                                }

                                                                                return (
                                                                                    <div
                                                                                        className="custom-control custom-checkbox small mr-3"
                                                                                        key={item}
                                                                                    >
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            htmlFor="customCheck"
                                                                                            id={item}
                                                                                            name={item}
                                                                                            checked={value}
                                                                                            onChange={e =>
                                                                                                this.handleOptionsChange(e)
                                                                                            }
                                                                                        />

                                                                                        <label>{item}</label>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                        <span className="my-error">
                                                                            {this.state.validated.errors["option"]}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <button type="button" className="btn btn-primary" onClick={(event) => this.setState({ validated: this.props.funPagesAddUpdate(event, this.state) })}>{siteConstants.BTN_SAVE}</button>&nbsp;
                                                                        <button type="button" className="btn btn-info" onClick={() => this.props.funPopupClose('editOpen')}>{siteConstants.BTN_CANCEL}</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        } else if (popupType === 'PermissionsComponenet') {
            //-- check Add/Edit label
            let addEditTitle = siteConstants.LABEL_EDIT_PERMISSION;
            if (this.props.addButton)
                addEditTitle = siteConstants.LABEL_ADD_PERMISSION;

            console.log("REST................ ", this.props);

            //-- Popup will open on notices page for add/edit the Roles
            return (
                <Dialog onClose={() => this.props.funPopupClose('editOpen')} aria-labelledby="customized-dialog-title" open={this.props.editOpen}>
                    <DialogTitle id="customized-dialog-title" onClose={() => this.props.funPopupClose('editOpen')}>
                        {addEditTitle}
                    </DialogTitle>
                    <DialogContent>
                        <div className="card shadow mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <form role="form">
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.LABEL_ROLE}</label>
                                                                        <Select
                                                                            name="role_id"
                                                                            value={this.state.selectedRoles}
                                                                            onChange={this.handleSelectChange}
                                                                            options={this.state.optionsData.rolesOption}
                                                                        />
                                                                        <span className="my-error">{this.state.validated.errors["role_id"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label>{siteConstants.MENU_PAGES}</label>
                                                                        <Select
                                                                            name="page_ids"
                                                                            value={this.state.selectedPages}
                                                                            isMulti
                                                                            onChange={this.handleMultiSelectChange}
                                                                            options={this.state.optionsData.pagesOption}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="select"
                                                                        />
                                                                        <span className="my-error">{this.state.validated.errors["page_ids"]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <button type="button" className="btn btn-primary" onClick={(event) => this.setState({ validated: this.props.funPermissionsAddUpdate(event, this.state) })}>{siteConstants.BTN_SAVE}</button>&nbsp;
                                                                        <button type="button" className="btn btn-info" onClick={() => this.props.funPopupClose('editOpen')}>{siteConstants.BTN_CANCEL}</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        } else {
            return (
                <div>No Popup</div>
            );
        }
    }
}

//-- Here we are adding Reducer names, so it can be get data from reducers using store
function mapStateToProps(state) {
    const { language } = state;
    return {
        language
    };
}

const connectedEditPopup = connect(mapStateToProps)(EditPopup);
export default connectedEditPopup;