import { locationConstants } from '../_constants';

//-- get the list of terms
export function getLocationById(state = {}, action) {
  switch (action.type) {
    case locationConstants.GET_ALL_LOCATION_BY_ID_REQUEST:
      return {
        getLocationById: false
      };

    case locationConstants.GET_ALL_LOCATION_BY_ID_SUCCESS:
      return { getLocationById:action.successAction};

    case locationConstants.GET_ALL_LOCATION_BY_ID_FAILURE:
      return {
        getLocationById: false,
      };

    default:
      return state
  }
}

//-- get the list of terms
export function getLocationList(state = {}, action) {
  switch (action.type) {
    case locationConstants.GET_ALL_LOCATION_REQUEST:
      return {
        getLocationList: false
      };

    case locationConstants.GET_ALL_LOCATION_SUCCESS:
      return { getLocationList:action.successAction};

    case locationConstants.GET_ALL_LOCATION_FAILURE:
      return {
        getLocationList: false,
      };

    default:
      return state
  }
}

//  add Location
export function addLocation(state = {}, action) {
  switch (action.type) {
    case locationConstants.ADD_LOCATION_REQUEST:
      return {
        addLocation: false
      };

    case locationConstants.ADD_LOCATION_SUCCESS:
      return { addLocation:action.successAction};

    case locationConstants.ADD_LOCATION_FAILURE:
      return {
        addLocation: false,
      };

    default:
      return state
  }
}

//  update Location
export function updateLocation(state = {}, action) {
  switch (action.type) {
    case locationConstants.UPDATE_LOCATION_REQUEST:
      return {
        updateLocation: false
      };

    case locationConstants.UPDATE_LOCATION_SUCCESS:
      return { updateLocation:action.successAction};

    case locationConstants.UPDATE_LOCATION_FAILURE:
      return {
        updateLocation: false,
      };

    default:
      return state
  }
}

