export const shortlistConstants = {
    
    GET_ALL_SHORTLIST_REQUEST: 'GET_ALL_SHORTLIST_REQUEST',
    GET_ALL_SHORTLIST_SUCCESS: 'GET_ALL_SHORTLIST_SUCCESS',
    GET_ALL_SHORTLIST_FAILURE: 'GET_ALL_SHORTLIST_FAILURE',

    ADD_SHORTLIST_REQUEST: 'ADD_SHORTLIST_REQUEST',
    ADD_SHORTLIST_SUCCESS: 'ADD_SHORTLIST_SUCCESS',
    ADD_SHORTLIST_FAILURE: 'ADD_SHORTLIST_FAILURE',

    GET_SHORTLIST_BY_ID_REQUEST: 'GET_SHORTLIST_BY_ID_REQUEST',
    GET_SHORTLIST_BY_ID_SUCCESS: 'GET_SHORTLIST_BY_ID_SUCCESS',
    GET_SHORTLIST_BY_ID_FAILURE: 'GET_SHORTLIST_BY_ID_FAILURE',

    DELETE_SHORTLIST_REQUEST: 'DELETE_SHORTLIST_REQUEST',
    DELETE_SHORTLIST_SUCCESS: 'DELETE_SHORTLIST_SUCCESS',
    DELETE_SHORTLIST_FAILURE: 'DELETE_SHORTLIST_FAILURE',
};
