import { enviromentalConstants } from '../_constants/environmental.constants';
import * as commonAction from './commonAction';
import { commonService } from '../_services';
import { history } from '../_helpers';
import { alertActions } from './';
import { apiConstants } from '../_constants/api.constants';

export const environmentalCauseAction = {
  getEnvironmentalCause,
  addEnvironmentalCause,
  updateEnvironmentalCause,
  getEnvironmentalCauseById,
  deleteEnvironmentalCause,
};

function getEnvironmentalCause(apiName, eventData = { limit: 25, offset: 0 }) {
  // added default parameter value for eventData
  return (dispatch) => {
    dispatch(
      commonAction.request(
        enviromentalConstants.GET_ENVIRONMENTAL_CAUSE_REQUEST,
        eventData,
      ),
    );
    commonService.withTokenAndBashUrl(apiName, eventData).then(
      (environmentalCause) => {
        dispatch(
          commonAction.success(
            enviromentalConstants.GET_ENVIRONMENTAL_CAUSE_SUCCESS,
            environmentalCause,
          ),
        );
      },
      (error) => {
        console.log('error', error);
        dispatch(
          commonAction.failure(
            enviromentalConstants.GET_ENVIRONMENTAL_CAUSE_FAILURE,
            error,
          ),
        );
      },
    );
  };
}
function getEnvironmentalCauseById(apiName, eventData) {
  return (dispatch) => {
    dispatch(
      commonAction.request(
        enviromentalConstants.GET_ENVIRONMENTAL_CAUSE_BY_ID_REQUEST,
        eventData,
      ),
    );
    commonService.withTokenAndBashUrl(apiName, eventData).then(
      (environmentalCause) => {
        dispatch(
          commonAction.success(
            enviromentalConstants.GET_ENVIRONMENTAL_CAUSE_BY_ID_SUCCESS,
            environmentalCause,
          ),
        );
      },
      (error) => {
        dispatch(
          commonAction.failure(
            enviromentalConstants.GET_ENVIRONMENTAL_CAUSE_BY_ID_FAILURE,
            error,
          ),
        );
      },
    );
  };
}
function addEnvironmentalCause(apiName, eventData) {
  return (dispatch) => {
    dispatch(
      commonAction.request(
        enviromentalConstants.ADD_ENVIRONMENTAL_CAUSE_REQUEST,
        eventData,
      ),
    );
    commonService.withTokenAndBashUrl(apiName, eventData).then(
      (environmentalCause) => {
        dispatch(
          commonAction.success(
            enviromentalConstants.ADD_ENVIRONMENTAL_CAUSE_SUCCESS,
            environmentalCause,
          ),
        );
        history.push('/environmental_cause');
        dispatch(getEnvironmentalCause(apiConstants.GET_ENVIRONMENTAL_CAUSE));
      },
      (error) => {
        dispatch(
          commonAction.failure(
            enviromentalConstants.ADD_ENVIRONMENTAL_CAUSE_FAILURE,
            error,
          ),
        );
      },
    );
  };
}

function updateEnvironmentalCause(apiName, cause_name) {
  return (dispatch) => {
    dispatch(
      commonAction.request(
        enviromentalConstants.UPDATE_ENVIRONMENTAL_CAUSE_REQUEST,
        { cause_name },
      ),
    );
    commonService.withTokenAndBashUrl(apiName, cause_name).then(
      (environmental) => {
        dispatch(
          commonAction.success(
            enviromentalConstants.UPDATE_ENVIRONMENTAL_CAUSE_SUCCESS,
            environmental,
          ),
        );
        history.push('/environmental_cause');
        dispatch(getEnvironmentalCause(apiConstants.GET_ENVIRONMENTAL_CAUSE));
      },
      (error) => {
        dispatch(
          commonAction.failure(
            enviromentalConstants.UPDATE_ENVIRONMENTAL_CAUSE_FAILURE,
            error,
          ),
        );
        dispatch(alertActions.error(error.response.data.error.msg));
      },
    );
  };
}
function deleteEnvironmentalCause(apiName, cause_name) {
  return (dispatch) => {
    dispatch(
      commonAction.request(
        enviromentalConstants.DELETE_ENVIRONMENTAL_CAUSE_REQUEST,
        { cause_name },
      ),
    );
    commonService.withTokenAndBashUrl(apiName, cause_name).then(
      (environmental) => {
        dispatch(
          commonAction.success(
            enviromentalConstants.DELETE_ENVIRONMENTAL_CAUSE_SUCCESS,
            environmental,
          ),
        );
        history.push('/environmental_cause');
        dispatch(getEnvironmentalCause(apiConstants.GET_ENVIRONMENTAL_CAUSE));
      },
      (error) => {
        dispatch(
          commonAction.failure(
            enviromentalConstants.DELETE_ENVIRONMENTAL_CAUSE_FAILURE,
            error,
          ),
        );
        dispatch(alertActions.error(error.response.data.error.msg));
      },
    );
  };
}
