export const providerConstants = {
  GET_PROVIDER_REQUEST: 'GET_PROVIDER_REQUEST',
  GET_PROVIDER_SUCCESS: 'GET_PROVIDER_SUCCESS',
  GET_PROVIDER_FAILURE: 'GET_PROVIDER_FAILURE',

  ADD_PROVIDER_SEEKER_REQUEST: 'ADD_PROVIDER_SEEKER_REQUEST',
  ADD_PROVIDER_SEEKER_SUCCESS: 'ADD_PROVIDER_SEEKER_SUCCESS',
  ADD_PROVIDER_SEEKER_FAILURE: 'ADD_PROVIDER_SEEKER_FAILURE',

  GET_PROVIDER_SEEKER_BY_ID_REQUEST: 'GET_PROVIDER_SEEKER_BY_ID_REQUEST',
  GET_PROVIDER_SEEKER_BY_ID_SUCCESS: 'GET_PROVIDER_SEEKER_BY_ID_SUCCESS',
  GET_PROVIDER_SEEKER_BY_ID_FAILURE: 'GET_PROVIDER_SEEKER_BY_ID_FAILURE',
}