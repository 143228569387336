import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from "react-select";

class Pagination extends Component {
    render() {
        // console.log('PROPS', this.props)
        const { siteConstants } = this.props.language;
        const pageNumbers = [];
        const totalPageNumbers = Array.from({ length: this.props.totalPage }, (_, index) => index + 1);

        if (this.props.totalPage !== null && this.props.totalPage !== undefined) {
 
             if (this.props.totalPage === 1) { pageNumbers.push(1); }
             else {
                 for (let i = 1; i <= Math.ceil(this.props.totalPage); i++) {
 
                     if (i === (this.props.currentPage)) {
                         var j = i - 1;
                         var k = i + 1;
                         if (i === 1) {
                             pageNumbers.push(i);
                             pageNumbers.push(k);
                         }
                         else if (i === Math.ceil(this.props.totalPage)) {
                             pageNumbers.push(j);
                             pageNumbers.push(i);
                         }
                         else {
                             pageNumbers.push(j);
                             pageNumbers.push(i);
                             pageNumbers.push(k);
                         }
                     }
 
 
                 }
             }
             var renderPageNumbers = pageNumbers.map((number, key) => {
                const isDisabled = this.props.totalPage < 2;
                let tempClass = this.props.currentPage === number ? 'paginate_button page-item active' : 'paginate_button page-item';
            
                return (
                    <li className={tempClass} key={key}>
                        <a
                            href="#"
                            aria-controls="dataTable"
                            data-dt-idx="1"
                            tabIndex="0"
                            className={`page-link${isDisabled ? ' disabled' : ''}`}
                            disabled={isDisabled}
                            onClick={(e) => {
                                e.preventDefault();
                                if (!isDisabled) {
                                    if (number !== this.props.currentPage) {
                                        this.props.funPagination(number);
                                    }
                                }
                            }}
                        >
                            {number}
                        </a>
                    </li>
                );
            });
        }
        //-- first page
        let firstPageClass = this.props.currentPage === 1 ? 'paginate_button page-item next disabled' : 'paginate_button page-item next';
        //-- last page
        let lastPageClass = this.props.currentPage === this.props.totalPage ? 'paginate_button page-item next disabled' : 'paginate_button page-item next';
        return (

            <div className="row">
                <div className="col-sm-12 col-md-5">
                    {/* <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">
                        Showing 0 to 0 of 0 entries
                    </div> */}
                </div>
                
                <div className="col-sm-12 col-md-7">
                    <div className="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                        <div className="row justify-content-end">
                        <div className="col pagination flex-grow-1 justify-content-end">
                            <Select
                                isDisabled={this.props.totalPage < 2}
                                name="page_numbers"
                                options={totalPageNumbers.map(num => ({
                                    value: num,
                                    label: num
                                }))}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Select Page"
                                isClearable={true}
                                maxMenuHeight={150}
                                menuPlacement="top"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        this.props.funPagination(selectedOption.value);
                                    } else {
                                        // Handle clear action if needed
                                        // For example, you might want to reset pagination to the first page
                                        this.props.funPagination(1);
                                    }
                                }}
                            />
                        </div>
                        <ul className="col pagination flex-grow-0">
                            <li className={firstPageClass} id="dataTable_previous">
                                <a key={0} href="#" aria-controls="dataTable" data-dt-idx="0" tabIndex="0" className="page-link" onClick={() => this.props.funPagination(1)}>{siteConstants.GEN_FIRST}</a>
                            </li>
                            {renderPageNumbers !== undefined && renderPageNumbers}
                            <li className={lastPageClass} id="dataTable_next">
                                <a key={this.props.totalPage} href="#" aria-controls="dataTable" data-dt-idx="1" tabIndex="0" className="page-link" onClick={() => this.props.funPagination(this.props.totalPage)}>{siteConstants.GEN_LAST}</a>
                            </li>
                        </ul>
                        </div>

                    </div>
                </div>
            </div>


            // <div className="row">
            //     <div className="col-lg-12">
            //         <ul className="paginateLinksDatabaseTables">
            //             <li key={0} className="btn-info" onClick={() => this.props.funPagination(1)}>{siteConstants.GEN_FIRST}</li>
            //             {renderPageNumbers !== undefined && renderPageNumbers}
            //             <li key={this.props.totalPage} className="btn-info" onClick={() => this.props.funPagination(this.props.totalPage)}>{siteConstants.GEN_LAST}</li>
            //         </ul>
            //     </div>
            // </div>            
        );
    }
}

//-- Here we are adding Reducer names, so it can be get data from reducers using store
function mapStateToProps(state) {
    const { language } = state;
    return {
        language
    };
}

const connectedPagination = connect(mapStateToProps)(Pagination);
export default connectedPagination;